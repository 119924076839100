import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GetRole } from "../common/CommonController";
import userApp from "../Shared/Controller/userApp";
import * as Constants from "../partials/CommonConstant";
import DownlodUserLog from "../downloalog/DownlodUserLog";

class ViewUser extends userApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      userid: "",
    }
  }

  componentDidMount = () => {
    let splitPath = window.location.pathname.split("/")
    let userId = "";
    if (splitPath.length > 2) {
      userId = splitPath[2];
      this.setState({ userid: userId });
    }
    if (userId) {
      this.getUser(userId);
    }
  }


  render() {
    const fieldLayout = {
      sm: "12",
      md: "12",
      lg: "6",
      xl: "6",
    }

    return (
      <React.Fragment>
        <div className="dashboard-card-wrapper">
          <div className="row align-items-center">
            <div className="col-12 text-end">
              <Link to={Constants.users} className="btn btn-blue btn-nex">Back</Link>
            </div>
          </div>
          <div className="profile-block mt-3">
            <div className="card-main">
              <div className="profile-text-inner">
                {this.state.loading === false &&
                  <Row>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>First Name</label>
                        </div>
                        <div className="profile-value">
                          <p>{this.state.firstname}</p>
                        </div>
                      </div>
                    </Col>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Last Name</label>
                        </div>
                        <div className="profile-value">
                          <p>{this.state.lastname}</p>
                        </div>
                      </div>
                    </Col>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Email</label>
                        </div>
                        <div className="profile-value">
                          <p>{this.state.email}</p>
                        </div>
                      </div>
                    </Col>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Username</label>
                        </div>
                        <div className="profile-value">
                          <p>{this.state.username}</p>
                        </div>
                      </div>
                    </Col>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Role</label>
                        </div>
                        <div className="profile-value">
                          <p>{GetRole(this.state.role)}</p>
                        </div>
                      </div>
                    </Col>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Status</label>
                        </div>
                        <div className="profile-value">
                          <p>{this.state.status === 1 ? 'Active' : 'Inactive'}</p>
                        </div>
                      </div>
                    </Col>
                    {this.state.userid !== "" &&
                      <DownlodUserLog userid={this.state.userid} />
                    }
                  </Row>
                }
                {this.state.loading &&
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}
export default ViewUser;