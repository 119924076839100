import React, { useContext } from "react";
import { CheckmarkImg, CloseImg } from "../common/ImageConstants";
import DelinquencyQuestion from "./DelinquencyQuestion";
import FileQuestion from "./FileQuestion";
import LoanQuestion from "./LoanQuestion";
import $ from "jquery";
import { ReviewContext } from "../context/ReviewContext";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import NotFound from "../common/NotFound";

const ReviewRow = () => {

  const RowProps = useContext(ReviewContext)
  let navigate = useNavigate();
  const onAccordian1Click = (e) => {
    e.preventDefault();
    $(".accord1").toggle();
    $(".btnacc1").parent().toggleClass('tclass');
  }
  const onAccordian2Click = (e) => {
    e.preventDefault();
    $(".accord2").toggle();
    $(".btnacc2").parent().toggleClass('tclass');
  }

  // const rowDetailFirst = RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
  //   return i === 0 ? RowProps.RowData.SingleRow.row_details[item] : "";
  // });

  // console.log("ReviewRow", RowProps)
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mt-md-0">
          <h5>{RowProps.RowData.SingleRow.project_name}</h5>
        </div>
        <div className="col-md-12  mt-md-0">
          {RowProps.isReviewFinish === false &&
            <React.Fragment>
              {/* <h1 className="content-title">Review</h1> */}
              <div className="right-panel">
                <React.Fragment>
                  <div className="tagn">
                    {RowProps.isRowAnswerFilled === false &&
                      <img src={CloseImg} alt="close" />
                    }
                    {RowProps.isRowAnswerFilled === true &&
                      <img src={CheckmarkImg} alt="Checkmark" />
                    }
                    Questions
                  </div>
                  <div className="tagn">
                    {RowProps.grade !== "" &&
                      <img src={CheckmarkImg} alt="Checkmark" />
                    }
                    {RowProps.grade === "" &&
                      <img src={CloseImg} alt="close" />
                    }
                    Grade
                  </div>
                  <div className="tagn">
                    {RowProps.row_comment !== "" &&
                      <img src={CheckmarkImg} alt="Checkmark" />
                    }
                    {RowProps.row_comment === "" &&
                      <img src={CloseImg} alt="close" />
                    }
                    Comments
                  </div>
                </React.Fragment>
                <div className="btn-block btn-review">
                  {RowProps.saveRowLoading === false &&
                    <Button id="btnReviewSave" onClick={RowProps.onSaveRowReview} variant="secondary"
                      className={"btn btn-blue btn-nex me-1 mb-1"}>
                      Save & Close
                    </Button>
                  }
                  {RowProps.saveRowLoading === false &&
                    <Button id="btnReviewSaveNext" onClick={RowProps.onSaveRowReview} variant="secondary"
                      className={"btn btn-blue btn-nex mb-1 me-1"}>
                      {parseInt(RowProps.RowData.SingleRow.review_completed_percentage) === 100 ? "Review Completed" : "Save & Next"}
                    </Button>
                  }
                  {RowProps.saveRowLoading === true &&
                    <Button variant="secondary" className="btn btn-blue btn-nex mb-1 me-1">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                  {(RowProps.nextRowLoading === false && parseInt(RowProps.RowData.SingleRow.review_completed_percentage) === 100) &&
                    <Button id="btnIntegritySaveNext"
                      type="button"
                      onClick={RowProps.checkNextRow}
                      variant="secondary" className={"btn btn-blue btn-nex mb-1 me-1"}>
                      Next
                    </Button>
                  }
                  {(RowProps.nextRowLoading === true && parseInt(RowProps.RowData.SingleRow.review_completed_percentage) === 100) &&
                    <Button variant="secondary" className="btn btn-blue btn-nex mb-1 me-1">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                  {(RowProps.RowData.SingleRow?.only_review !== 1 && RowProps.saveRowLoading === false) &&
                    <Button id="btnReviewSaveIntegrity" onClick={RowProps.onSaveRowReview} variant="secondary"
                      className={"btn btn-blue btn-nex me-1 mb-1 me-1"}>
                      Save & Integrity
                    </Button>
                  }
                  {/* <Link to={Constants.projectList} className="btn btn-blue btn-nex mb-1">Back</Link> */}
                  <Button onClick={() => navigate(-1)} className="btn btn-blue btn-nex mb-1">Back</Button>
                </div>
              </div>
              <p>Investor Reference - {RowProps.RowData.SingleRow.Account_Reference}, Owner - {RowProps.RowData.SingleRow.owner_name} {(RowProps.take_ownership === 0 && RowProps.userDetail.role === 1 && RowProps.integrity_review === 1) ? <Link to="#" onClick={RowProps.onTakeOwnsership}>[Take Ownership]</Link> : null} <br />
                Supplemental Data</p>
            </React.Fragment>
          }
        </div>
      </div>
      {RowProps.isReviewFinish === false &&
        <React.Fragment>
          <div className="row">
            <div className="col-md-12 mt-3 mt-md-0 mb-3">
              <div className="card-main p-0">
                <div className="dark-table-wrapper table-scroll smtb">
                  {RowProps.RowData &&
                    <table className="table mb-0">
                      <thead className="thead-dark">
                        <tr>
                          {RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
                            return (
                              <React.Fragment key={"rowheader" + item}>
                                <th>{item}</th>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
                            return (
                              <React.Fragment key={"rowValue" + item + RowProps.RowData.SingleRow.row_details[item]}>
                                <td>{RowProps.RowData.SingleRow.row_details[item]}</td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3 mt-md-0 accordian-row">
              <button onClick={(e) => onAccordian1Click(e)} className="btnacc1"> </button>
            </div>
          </div>
          <div className="row accord1">
            {/* <ReviewContext.Provider value={
              {
                AllQuestions: RowProps.RowData.AllQuestions,
                setQuestionChoice: RowProps.setQuestionChoice,
                setQuestionComment: RowProps.setQuestionComment,
                answers: RowProps.answers,
                reviewAnswerErrors: RowProps.reviewAnswerErrors,
                userDetail: RowProps.userDetail,
                take_ownership: RowProps.take_ownership,
                integrity_review: RowProps.integrity_review,
              }
            }> */}
            <FileQuestion />
            <LoanQuestion />
            {/* 10-Mar-2023 check delinquency Flag 1 or 0 to hide/unhide question */}
            {RowProps.RowData?.SingleRow?.row_details?.Delinquency_Flag === 1 &&
              <DelinquencyQuestion />
            }
            {/* </ReviewContext.Provider> */}
          </div>
          <div className="row">
            <div className="col-md-12  accordian-row">
              <button onClick={(e) => onAccordian2Click(e)} className="btnacc2"> </button>
            </div>
          </div>

          <div className="row accord2">
            <div className="col-md-12  mt-md-0 mb-3">
              <div className="card-main">
                <div className="row g-4">
                  <div className="col-md-4">
                    <div className="m-1">
                      Grade
                      <Form.Control
                        as="select"
                        name="grade"
                        required
                        disabled={(RowProps.userDetail.role === 1 && RowProps.take_ownership === 0 && RowProps.integrity_review === 1) ? true : false}
                        value={RowProps.grade}
                        isInvalid={!!RowProps.reviewAnswerErrors["grade"]}
                        onChange={(e) => RowProps.SetRowGrade(e)}
                      >
                        <option value="">Select Grade</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{RowProps.reviewAnswerErrors["grade"]}</Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="m-1">
                      Comment
                      <Form.Control
                        required
                        rows={4}
                        as="textarea"
                        name={"row_comment"}
                        value={RowProps.row_comment}
                        placeholder="Comment"
                        isInvalid={!!RowProps.reviewAnswerErrors["row_comment"]}
                        onChange={(e) => RowProps.SetRowComment(e)}
                        disabled={(RowProps.userDetail.role === 1 && RowProps.take_ownership === 0 && RowProps.integrity_review === 1) ? true : false}
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{RowProps.reviewAnswerErrors["row_comment"]}</Form.Control.Feedback>
                      {/* <input type="text" className="form-control" id="c_name" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      }
      {RowProps.isReviewFinish === true &&
        <div className="card mt-3">
          <NotFound type="content" message={RowProps.integrity_review === 1 ? "Integrity Finished" : "Review Finished"} />
        </div>
      }
    </React.Fragment>
  )
}
export default ReviewRow;