import React, { useEffect, useState } from 'react'
import axios from 'axios'
import * as Constants from "./partials/CommonConstant";
import * as ImgConstants from "./common/ImageConstants";
import LoginLeftBlock from './LoginLeftBlock';
import VerifiedProfileForm from './profile/VerifiedProfileForm';
import MessageBox from './common/MessageBox';
import { Spinner } from 'react-bootstrap';


const VerfiyEmail = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [token] = useState(queryParams.get('token'));
  const [success_message, setSuccessMsg] = useState('');
  const [error_message, setErrorMsg] = useState('');
  const [errors, setErrors] = useState([]);
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [varifiedUser, setVarifiedUser] = useState({});

  useEffect(() => {
    verifyUser();
  }, [])

  const verifyUser = () => {
    let send_data = {
      token: token,
    }
    setLoading(true);
    axios.post(Constants.VerifyUserEmail, send_data)
      .then(response => {
        if (response.data.code === 1) {
          console.log("verified email", response.data)
          setIsVerifiedUser(true);
          setSuccessMsg(response.data.message);
          setVarifiedUser(response.data.data[0])
          setErrorMsg("")
          setErrors([])
        }
        else {
          setIsVerifiedUser(false);
          setSuccessMsg("");
          setErrorMsg(response.data.message);
        }
        setLoading(false);
      })
      .catch(error => {
        setIsVerifiedUser(false);
        setErrors(error.response.data.errors);
        setLoading(false);
      })
  }

  return (
    <div className='main'>
      <div className='login-form-block'>
        <div className='mobile-logo'>
          <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} className='' />
          <div className='heading'>
            <h2>Qflow</h2>
          </div>
        </div>

        <div className='login-banner-block'>
          <img src={ImgConstants.LoginBanner} alt={Constants.ImageALT} title={Constants.ImageTitle} />
        </div>
        <div className='login-form-box-main'>
          <LoginLeftBlock />
          <div className='login-form-right-block'>
            <div className='login-form-logo'>
              <img src={ImgConstants.FormLogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} />
            </div>
            {loading &&
              <div className='text-center mt-1'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <div>
                  <span>Please wait while you are verified</span>
                </div>
              </div>
            }
            {loading === false &&
              <>
                {success_message !== "" &&
                  <MessageBox message={success_message} type="success" />
                }
                {error_message !== "" &&
                  <MessageBox message={error_message} type="fail" />
                }
                {isVerifiedUser &&
                  <VerifiedProfileForm varifiedUser={varifiedUser} />
                }
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default VerfiyEmail