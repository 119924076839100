import React from "react";
import { Button, Form, ProgressBar, Spinner } from "react-bootstrap";
import { ValidateCharacter } from "../common/CommonController";
import projectApp from "../Shared/Controller/projectApp";
import $ from "jquery";

class AddProjectImport extends projectApp {

  constructor(props) {
    super(props)
    this.state = {
      projectfile: {},
      validated: false,
      importLoading: false,
      numberofrows: "",
      importProgress: 0,
    }
  }

  componentDidMount = () => {
    if (this.props.projectDetail.last_completed_step > 1) {
      this.props.callBackImportSuccess({ id: this.props.projectDetail.projectfile.id });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      let frmData = new FormData();
      frmData.append("projectfile", this.state.projectfile);
      frmData.append("numberofrows", this.state.numberofrows)
      this.importFile(frmData);
    }
    this.setState({ validated: true });
  }

  handleChange = (event) => {
    this.setState({ validated: false });
    this.setState({ [event.target.name]: event.target.files[0] })
  }

  onSuccess = (data) => {
    // this.getUsers();
  }

  onChangeNoOfRecords = (e) => {
    if (!(parseInt(e.target.value) > 1048576 || parseInt(e.target.value) === 0)) {
      this.setState({ [e.target.name]: e.target.value })
    }
    else {
      e.preventDefault();
    }
  }

  handleMouseOver = () => {
    $(".uplod-file").addClass("hover-file");
  }

  handleDragExit = () => {
    $(".uplod-file").removeClass("hover-file");
  }

  render() {
    return (
      <React.Fragment>
        <div className="step-list-wrap">
          <h2 className="step-title">Import</h2>
          <div className="step-content">
            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} className="step-form">
              <div>
                <ul>
                  <li>
                    File must be excel sheet with .xlsx extension.
                  </li>
                  <li>
                    Excel sheet column header/top row can not be empty.
                  </li>
                  <li>
                    Excel sheet must contain columns with names 'Account_Reference', 'Current_Balance' and 'Delinquency_Flag'.
                  </li>
                  <li>
                    Rows with empty 'Account_Reference'/'Current_Balance'/'Delinquency_Flag' will be skipped.
                  </li>
                  <li>
                    Add exact number of rows present in excel sheet(including header row) in below '# Of Rows' field.
                  </li>
                  <li>Data will be imported from 1st sheet only.</li>
                  <li>The 'Current_Balance' fields should not have alpha numeric values.</li>
                </ul>
              </div>
              <div className="uplod-file">
                <Form.Group controlId="projectfileupload">
                  <Form.Control
                    as="input"
                    required
                    type="file"
                    id="projectfile"
                    name="projectfile"
                    bsPrefix="file"
                    className="mb-2"
                    // value={name}
                    onChange={this.handleChange}
                    onDragEnter={this.handleMouseOver}
                    onDragLeave={this.handleDragExit}
                  />
                  <Form.Control.Feedback type="invalid">Please upload file</Form.Control.Feedback>
                </Form.Group>
                <button>+</button>
                <span className="mx-2">You can also drag & drop into the above</span>
              </div>
              {/* {this.state.projectfile && this.state.projectfile.name && */}
              <div className="col-md-3 mt-3">
                <Form.Group controlId="noofrows">
                  <label># Of Rows</label>
                  <Form.Control
                    as="input"
                    required
                    name="numberofrows"
                    value={this.state.numberofrows}
                    onKeyPress={(event) => {
                      if (!ValidateCharacter({ key: event.key, type: "numbers_only" })) {
                        event.preventDefault();
                      }
                    }}
                    onChange={this.onChangeNoOfRecords}
                  />
                  <Form.Control.Feedback type="invalid">Please enter no of rows</Form.Control.Feedback>
                </Form.Group>
              </div>
              {/* } */}
              {this.state.importProgress !== 0 &&
                <div className="uplod-inner mt-3">
                  <span>File is being uploaded. Please wait for some time.</span>
                  <ProgressBar animated
                    now={this.state.importProgress}
                    label={this.state.importProgress + "%"} />
                </div>
              }
              <div className="setp-btn-row">
                {this.state.importLoading === false &&
                  <Button className="btn btn-blue btn-nex" variant="secondary" type="submit">
                    Next
                  </Button>
                }
                {this.state.importLoading &&
                  <Button className="btn btn-blue btn-nex" variant="secondary">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Button>
                }
              </div>
              <div className="clear"></div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default AddProjectImport;