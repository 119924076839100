import React, { useContext } from "react";
import { ReviewContext } from "../context/ReviewContext";
import { getQuestionsCols } from "../Shared/Controller/projectReviewApp";
import ViewRowQuestions from "./ViewRowQuestions";

const ViewLoanQuestion = () => {
  const LoanProps = useContext(ReviewContext);

  return (
    <React.Fragment>
      {LoanProps.RowData.AllQuestions.loan_questions &&
        <div className={getQuestionsCols({ typesOfQuestion: Object.keys(LoanProps.RowData.AllQuestions).length, type: "loan" }) + " mb-3"}>
          <div className="card-main">
            <h3>Loan</h3>
            {LoanProps.RowData.AllQuestions.loan_questions.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <ViewRowQuestions item={item}
                    index={index}
                  />
                </React.Fragment>
              )
            })
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}
export default ViewLoanQuestion;