import React from "react";
import { Row, Table } from "react-bootstrap";
import { GetRole } from "../common/CommonController";

const ProjectAssignUser = (props) => {
  return (
    <React.Fragment>
      <Row>
        <h5>Assign Users</h5>
        <div>
          <Table className="tbl_view_project" size="sm">
            <thead className="rdt_TableHead">
              <tr className="rdt_TableHeadRow">
                <th style={{ width: "33%" }} className="rdt_TableCol_Sortable">Name</th>
                <th style={{ width: "33%" }} className="rdt_TableCol_Sortable">Role</th>
                <th style={{ width: "37%", paddingLeft: "7px" }} className="rdt_TableCol_Sortable">
                  {(props.showType === "integrity") ? "# Of Integrity Completed" : "# Of Review Completed"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3} style={{ padding: "0px" }}>
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <table style={{ width: "100%" }}>
                      {props.projectDetail.assignedusers.map((item) => {
                        return (
                          <tr key={"user" + item.id}>
                            <td style={{ width: "33%" }}>
                              {item.firstname + " " + item.lastname}
                            </td>
                            <td style={{ width: "33%" }}>{GetRole(item.role)}</td>
                            <td style={{ width: "37%" }}>{(props.showType === "integrity") ? item.integrity_completed_by_user : item.completed_tasks}</td>
                          </tr>
                        )
                      })
                      }
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Row>
    </React.Fragment>
  )
}
export default ProjectAssignUser;