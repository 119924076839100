import React, { useEffect, useState } from "react";
import { ShowToast } from "../common/CommonController";
import { Spinner } from "react-bootstrap";
import NotFound from "../common/NotFound";
import LogList from "../Logs/LogList";
import { deleteSiteLogs, GetSiteLogs } from "../Shared/Controller/LogsApp";
import DeleteLog from "../Logs/DeleteLog";
import { useNavigate } from "react-router-dom";

const SiteLogs = () => {
  const [siteLogList, setSiteLogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [validated, setValidate] = useState(false);
  const [deleteTimeLog, setDeleteTimeLog] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getSiteLogs();
  }, []);

  const getSiteLogs = () => {
    setLoading(true);
    getSiteLogsApi();
  }

  const getSiteLogsApi = (data = {}) => {
    setValidate(false);
    GetSiteLogs(data).then((response) => {
      if (response.data.code === 1) {
        setSiteLogList(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const setTimeLog = (e) => {
    setDeleteTimeLog(e.target.value);
  }

  const deleteLogSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      setDeleteLoading(true);
      deleteSiteLogs(deleteTimeLog).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message)
          getSiteLogs();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setDeleteLoading(false);
      }).catch(error => {
        setDeleteLoading(false);
        ShowToast("message", "error", "" + error);
      });
    }
    setValidate(true);
  }

  const getTableColumns = () => {
    let table_columns = [];
    table_columns = [
      {
        name: "Log Type",
        selector: row => row.log_type,
        sortable: true,
      },
      {
        name: "Message",
        selector: row => { return row.message },
        width: "30%",
        cell: row => { return getColumnData({ type: "message", item: row }) },
        sortable: true,
      },
      {
        name: "Module",
        selector: row => { return row.module },
        sortable: true,
      },
      {
        name: "Created At",
        selector: row => { return row.created_at },
        cell: row => { return row.created_time },
        sortable: true,
      },
      {
        name: "Username",
        selector: row => { return row.username },
        sortable: true,
      },
    ]
    return table_columns;
  }

  const getColumnData = (data) => {
    let columnData = "";
    if (data.type === "file_name") {
      columnData = data.item.file_details !== null ? data.item.file_details.original_filename : "";
    }
    if (data.type === "owner") {
      columnData = data.item.file_details !== null ? data.item.file_details.owner_name : "";
    }
    /* if (data.type === "date") {
      let DownloadDate = new Date(data.item.created_at);
      const yyyy = DownloadDate.getFullYear();
      let mm = DownloadDate.getMonth() + 1; // Months start at 0!
      let dd = DownloadDate.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedDate = dd + '/' + mm + '/' + yyyy;
      columnData = formattedDate;
    } */
    if (data.type === "message") {
      return columnData = (<span title={data.item.message}>{data.item.message}</span>);
    }
    return columnData;
  }

  const data = siteLogList;
  const columns = getTableColumns();

  const tableData = {
    columns,
    data
  };

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end">
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        {siteLogList.length !== 0 &&
          <DeleteLog deleteLoading={deleteLoading}
            deleteLogSubmit={deleteLogSubmit}
            validated={validated}
            setTimeLog={setTimeLog}
          />
        }
        <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
          <div className="card-main p-0">
            <div className="dark-table-wrapper table-scroll">
              <LogList LogListData={siteLogList} loading={loading} tableData={tableData} />
              {loading === false && siteLogList.length === 0 &&
                <NotFound message="No Data Found" type="content" />
              }
              {loading === true &&
                <table className="mb-0 table">
                  <tbody>
                    <tr>
                      <td colSpan={10} className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default SiteLogs;