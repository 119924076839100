import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { VerifyFileToken } from "../Shared/Controller/commonApp";
import { addUser } from "../Shared/Redux/Actions/CommonAction";
import { LogOut, ShowToast } from "./CommonController";
import ManageToken from "./ManageToken";

const VerifyShortcut = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { token, setToken } = ManageToken();
  const dispatch = useDispatch();

  useEffect(() => {
    let fileToken = location.pathname.split("redirect-url/")[1];
    setLoading(true);
    VerifyFileToken(fileToken).then(response => {
      if (response.data.code === 1) {
        let url = response.data.data.shortcut_url;
        setToken(response.data.data.authentication_token);
        dispatch(addUser(response.data.data.user_details));
        window.location.href = url;
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        LogOut();
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        {loading &&
          <div className="text-center p-5">
            <Spinner animation="border" size="lg" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      </div>
    </React.Fragment>
  )
}
export default VerifyShortcut;