import React, { useEffect, useState } from "react";
import AddProjectImport from "../projects/AddProjectImport";
import checkImg from "../images/check.png";
import Portfolio from "../projects/PortFolio";
import ProjectColumn from "../projects/ProjectColumn";
import Questions from "../projects/Questions";
import ProjectUsers from "../projects/ProjectUsers";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import ProjectServices from "../Shared/Services/ProjectServices";
import { Spinner } from "react-bootstrap";
import NotFound from "../common/NotFound";

const Project = () => {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [fileid, setFileId] = useState("");
  // const [project_id, setProjectId] = useState(location.state !== null ? location.state.projectId : "");
  const [project_id, setProjectId] = useState("");
  const [projectDetail, setProjectDetail] = useState({});
  const navigate = useNavigate();
  let isNextClick = false;
  let noLoader = false;

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    let project_id = "";
    if (splitPath.length > 2) {
      project_id = splitPath[2];
      setProjectId(project_id);
      getProjectDetail(project_id);
    }
  }, []);

  const getProjectDetail = (project_id) => {
    let ProjectDetail = {};
    if (project_id !== "") {
      if (!isNextClick) {
        setLoading(true);
      }
      ProjectServices.getProjectDetail(project_id).then((response) => {
        if (response.data.code === 1) {
          setProjectDetail(response.data.data);
          if (isNextClick !== true && noLoader === false) {
            setStepForEdit(response.data.data);
          }
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setLoading(false);
      });
    }
    return ProjectDetail;
  }

  const setStepForEdit = (data) => {
    if (data.last_completed_step === 2) {
      setCurrentStep(3);
    }
    else if (data.last_completed_step === 3) {
      setCurrentStep(4);
    }
    else if (data.last_completed_step === 4) {
      setCurrentStep(5);
    }
    else if (data.last_completed_step >= 4) {
      setCurrentStep(5);
    }
  }

  const callBackImportSuccess = (data) => {
    setFileId(data.id)
    setCurrentStep(2);
  }

  const callBackPortFolioSuccess = (data) => {
    setProjectId(data.id);
    // isNextClick = true;
    noLoader = true;
    getProjectDetail(data.id);
    navigate("/edit-project/" + data.id, { replace: "" });
    setCurrentStep(3);
  }

  const callBackHeaderColumnSuccess = (data) => {
    isNextClick = true;
    noLoader = false;
    getProjectDetail(project_id);
    if (projectDetail.only_integrity !== 1) {
      setCurrentStep(4);
    }
    else {
      setCurrentStep(5);
    }
  }

  const callBackQuestionSuccess = (data) => {
    isNextClick = true;
    noLoader = false;
    getProjectDetail(project_id);
    setCurrentStep(5);
  }

  const callBackUsersSuccess = (data) => {
    navigate("/projects");
  }

  const callBackPreviousColumn = () => {
    setCurrentStep(2);
  }

  const callBackPreviousQuestion = () => {
    setCurrentStep(3);
  }

  const callBackPreviousUsers = () => {
    if(projectDetail.only_integrity !== 1) {
      setCurrentStep(4);
    }
    else {
      setCurrentStep(3);
    }
  }

  // console.log("project", projectDetail, location, loading);
  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="step-wrapper">

          <ul className="step-list">
            <li className={currentStep === 1 ? "process" : currentStep > 1 ? "finish" : ""}><Link to="#"><span>1</span><img alt="checkImg" src={checkImg} /></Link><span>Import</span></li>
            <li className={currentStep === 2 ? "process" : currentStep > 2 ? "finish" : ""}><Link to="#"><span>2</span><img alt="checkImg" src={checkImg} /></Link><span>Portfolio</span></li>
            <li className={currentStep === 3 ? "process" : currentStep > 3 ? "finish" : ""}><Link to="#"><span>3</span><img alt="checkImg" src={checkImg} /></Link><span>Data</span></li>
            {projectDetail && projectDetail.only_integrity !== 1 &&
              <li className={currentStep === 4 ? "process" : currentStep > 4 ? "finish" : ""}><Link to="#"><span>4</span><img alt="checkImg" src={checkImg} /></Link><span>Questions</span></li>
            }
            <li className={currentStep === 5 ? "process" : currentStep > 5 ? "finish" : ""}><Link to="#"><span>{projectDetail && projectDetail.only_integrity !== 1 ? 5 : 4}</span><img alt="checkImg" src={checkImg} /></Link><span>Users</span></li>
          </ul>
          {(loading === false && (Object.keys(projectDetail).length !== 0 || !location.pathname.includes("edit-project"))) &&
            <>
              <React.Fragment>
                {currentStep === 1 &&
                  <AddProjectImport
                    project_id={project_id}
                    currentStep={currentStep}
                    projectDetail={projectDetail}
                    callBackImportSuccess={callBackImportSuccess}
                  />
                }
                {currentStep === 2 &&
                  <Portfolio
                    callBackPortFolioSuccess={callBackPortFolioSuccess}
                    currentStep={currentStep}
                    projectDetail={projectDetail}
                    project_id={project_id}
                    fileid={fileid}
                  />
                }
                {currentStep === 3 &&
                  <ProjectColumn
                    project_id={project_id}
                    currentStep={currentStep}
                    projectDetail={projectDetail}
                    callBackPreviousColumn={callBackPreviousColumn}
                    callBackHeaderColumnSuccess={callBackHeaderColumnSuccess}
                  />
                }
                {currentStep === 4 &&
                  <Questions
                    projectDetail={projectDetail}
                    currentStep={currentStep}
                    project_id={project_id}
                    callBackPreviousQuestion={callBackPreviousQuestion}
                    callBackQuestionSuccess={callBackQuestionSuccess}
                  />
                }
                {currentStep === 5 &&
                  <ProjectUsers
                    projectDetail={projectDetail}
                    currentStep={currentStep}
                    project_id={project_id}
                    callBackPreviousUsers={callBackPreviousUsers}
                    callBackUsersSuccess={callBackUsersSuccess}
                  />
                }
              </React.Fragment>
            </>
          }
          {loading === false && Object.keys(projectDetail).length === 0 && location.pathname.includes("edit-project") &&
            <NotFound message="No Project Data Found" type="content" />
          }
          {loading === true &&
            <>
              <div className="card">
                <div className="p-5 text-center">
                  <Spinner animation="border" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </React.Fragment>
  )
}
export default Project;