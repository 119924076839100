import React from "react";
import { Button, ProgressBar, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import projectApp from "../Shared/Controller/projectApp";

class ProjectColumn extends projectApp {

  constructor(props) {
    super(props)
    this.state = {
      fileLoading: false,
      saveFileLoading: false,
      file_columns: [],
      headerIds: [],
      reviewHeaderIds: [],
      fileMessage: "",
      importStatus: "Success",
      isEdit: false,
      progress: 0,
      nextCall: true,
    }
  }


  componentDidMount = () => {
    this.getFileColumn(this.props.project_id);
    localStorage.setItem("nextCall", true);
  }

  componentWillUnmount = () => {
    localStorage.setItem("nextCall", false);
  }

  afterGetColumnSuccess = (data) => {
    if (this.props.currentStep === 3 && this.props.projectDetail.last_completed_step >= 2 && this.props.projectDetail.activeheaderids.length > 0) {
      this.setState({
        isEdit: true,
        headerIds: this.props.projectDetail.activeheaderids,
        reviewHeaderIds: this.props.projectDetail.activereviewheaderids
      });
    }
    else {
      this.setDefaultFileColumns(data);
    }
  }

  getFileProgress = () => {
    setTimeout(() => {
      let nextCall = localStorage.getItem("nextCall");
      if (this.state.importStatus === "Pending" && nextCall === "true") {
        this.GetFileProgress(this.props.project_id)
      }
    }, 3000);
  }

  setDefaultFileColumns = (data) => {
    let headerIds = [];
    let reviewHeaderIds = [];
    localStorage.setItem("nextCall", false);
    let lastThreeRecord = data.length - 3
    if (data && data.length > 0) {
      data.map((item, index) => {
        // if (item.column_heading === "Account_Reference" || item.column_heading === "Current_Balance" || item.column_heading === "Delinquency_Flag") {
        if (this.props.projectDetail.activeheaderids && this.props.projectDetail?.activeheaderids.length === 0) {
          if (this.isDefaultColumn(item.column_heading)) {
            headerIds.push(item.id);
          }
          else {
            if (index <= 2) {
              headerIds.push(item.id);
            }
            if (index >= lastThreeRecord) {
              headerIds.push(item.id);
            }
          }
        }
        /* Set Review HeaderIds */
        if (this.props.projectDetail.activereviewheaderids && this.props.projectDetail?.activereviewheaderids.length === 0) {
          reviewHeaderIds.push(item.id);
        }
        /* End Review HeaderIds */
        return null
        // }
      })
    }
    this.setState({ isEdit: true, headerIds: headerIds, reviewHeaderIds: reviewHeaderIds })
  }

  onHandleChange = (e) => {
    let headerids = [...this.state.headerIds];
    let columnindex = headerids.indexOf(parseInt(e.target.value));
    if (columnindex >= 0) {
      headerids.splice(columnindex, 1);
    }
    else {
      headerids.push(parseInt(e.target.value));
    }
    this.setState({
      headerIds: headerids
    })
  }

  onReviewHandleChange = (e) => {
    let reviewHeaderIds = [...this.state.reviewHeaderIds];
    let columnindex = reviewHeaderIds.indexOf(parseInt(e.target.value));
    if (columnindex >= 0) {
      reviewHeaderIds.splice(columnindex, 1);
    }
    else {
      reviewHeaderIds.push(parseInt(e.target.value));
    }
    this.setState({
      reviewHeaderIds: reviewHeaderIds
    });
  }

  renderIntegrityCheckboxItem = (citem) => {
    let value = "";
    if (this.props.projectDetail.activeheaderids && this.props.projectDetail?.activeheaderids.length > 0) {
      let element = this.props.projectDetail.activeheaderids && this.props.projectDetail.activeheaderids.find((item) => parseInt(citem.id) === item);
      if (this.isDefaultColumn(element ? citem.column_heading : "")) {
        value = <input type="checkbox" disabled="disabled"
          checked="checked"
          className="custom-chkbox"
          onClick={(e) => this.onHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
      else {
        value = <input type="checkbox"
          defaultChecked={citem.id === element ? "checked" : ""}
          className="custom-chkbox"
          onClick={(e) => this.onHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
    }
    else if (this.props.projectDetail.activeheaderids && this.props.projectDetail?.activeheaderids.length === 0) {
      if (this.isDefaultColumn(citem.column_heading)) {
        value = <input type="checkbox" disabled="disabled"
          checked="checked"
          className="custom-chkbox"
          onClick={(e) => this.onHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
      else {
        value = <input type="checkbox"
          defaultChecked={this.setSpacifiedColumn(citem.id)}
          // defaultChecked="checked"
          className="custom-chkbox"
          onClick={(e) => this.onHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
    }
    /* else {
      value = <input type="checkbox"
        defaultChecked="checked"
        onClick={(e) => this.onHandleChange(e)}
        value={citem.id}
        name={citem.column_heading} />
    } */
    return value;
  }

  renderReviewCheckboxItem = (citem) => {
    let value = "";
    if (this.props.projectDetail.activereviewheaderids && this.props.projectDetail?.activereviewheaderids.length > 0) {
      let element = this.props.projectDetail.activereviewheaderids.find((item) => parseInt(citem.id) === item);
      if (this.isDefaultColumn(element ? citem.column_heading : "")) {
        value = <input type="checkbox" disabled="disabled"
          checked="checked"
          className="custom-chkbox"
          onClick={(e) => this.onReviewHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
      else {
        value = <input type="checkbox"
          // defaultChecked={"checked"}
          defaultChecked={citem.id === element ? "checked" : ""}
          className="custom-chkbox"
          onClick={(e) => this.onReviewHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
    }
    else if (this.props.projectDetail.activereviewheaderids && this.props.projectDetail?.activereviewheaderids.length === 0) {
      if (this.isDefaultColumn(citem.column_heading)) {
        value = <input type="checkbox" disabled="disabled"
          checked="checked"
          className="custom-chkbox"
          onClick={(e) => this.onReviewHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
      else {
        value = <input type="checkbox"
          // defaultChecked={this.setSpacifiedColumn(citem.id)}
          defaultChecked="checked"
          className="custom-chkbox"
          onClick={(e) => this.onReviewHandleChange(e)}
          value={citem.id}
          name={citem.column_heading} />
      }
    }
    /* else {
      value = <input type="checkbox"
        defaultChecked="checked"
        onClick={(e) => this.onHandleChange(e)}
        value={citem.id}
        name={citem.column_heading} />
    } */
    return value;
  }

  isDefaultColumn = (item) => {
    let isDefault = false;
    if (item === "Account_Reference" || item === "Current_Balance" || item === "Delinquency_Flag") {
      isDefault = true;
    }
    return isDefault;
  }

  setSpacifiedColumn = (checkedid) => {
    let isFindChecked = false;
    let lastThreeRecord = this.state.file_columns.length - 3
    this.state.file_columns.map((item, index) => {
      if (index <= 2 && item.id === checkedid) {
        isFindChecked = true;
      }
      if (index >= lastThreeRecord && item.id === checkedid) {
        isFindChecked = true;
      }
    });
    return isFindChecked;
  }

  onSaveHeaderColumn = (e) => {
    e.preventDefault()
    let itemChecked = false;
    for (let findex = 0; findex < this.state.file_columns.length; findex++) {
      let fitem = this.state.file_columns[findex];
      if (!this.isDefaultColumn(fitem.column_heading)) {
        itemChecked = this.state.headerIds.find((hitem) => hitem === fitem.id);
        if (itemChecked) {
          break;
        }
      }
    }
    if (!itemChecked) {
      ShowToast("message", "error", "Please mark at least one integrity data column");
    }
    else {
      this.saveHeaderColumns();
    }
  }

  onColumnPreviousClick = (e) => {
    e.preventDefault();
    this.props.callBackPreviousColumn();
  }

  checkColumnsNoteMsg = () => {
    let msg = " ";
    if (this.props.projectDetail.only_review === 0 && this.props.projectDetail.only_integrity === 0) {
      msg = "Data Integrity and Data Review";
    }
    else if (this.props.projectDetail.only_review === 1 && this.props.projectDetail.only_integrity === 0) {
      msg = "Data Review";
    }
    else if (this.props.projectDetail.only_integrity === 1 && this.props.projectDetail.only_review === 0) {
      msg = "Data Integrity";
    }
    return msg;
  }

  render() {
    return (
      <React.Fragment>
        <div className="step-list-wrap">
          <h2 className="step-title">Data</h2>
          <div className="step-content">
            <form className="step-form">
              {this.state.fileLoading === false &&
                <>
                  {this.state.fileMessage === "" &&
                    <div className="uplod-inner">
                      <b>
                        <p>The following column data was loaded from the source file</p>
                        <p>Please use the checkbox to include the column within a {this.checkColumnsNoteMsg()} Workflow</p>
                      </b>
                    </div>
                  }
                  {this.state.fileMessage !== "" &&
                    <div className="uplod-inner">
                      <b>
                        <p>{this.state.fileMessage}</p>
                      </b>
                    </div>
                  }
                  {this.state.importStatus === "Pending" &&
                    <div className="uplod-inner mt-3">
                      <ProgressBar animated
                        now={this.state.progress}
                        label={this.state.progress + "%"} />
                    </div>
                  }
                  {this.state.file_columns && this.state.file_columns.length > 0 &&
                    <table className="col-md-12 mt-3">
                      <thead>
                        <tr>
                          <th>Data Columns</th>
                          {this.props.projectDetail.only_review === 0 &&
                            <th className="text-center">Integrity</th>
                          }
                          {this.props.projectDetail.only_integrity === 0 &&
                            <th className="text-center">Remove Columns from Review</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.file_columns.map((citem) => {
                          return (
                            <React.Fragment key={citem.column_heading}>
                              <tr>
                                <td>
                                  <div>
                                    <label>
                                      <span>{citem.column_heading}</span>
                                    </label>
                                  </div>
                                </td>
                                {this.props.projectDetail.only_review === 0 &&
                                  <td className="text-center">
                                    <label>
                                      {this.renderIntegrityCheckboxItem(citem)}
                                      <span></span>
                                    </label>
                                  </td>
                                }
                                {this.props.projectDetail.only_integrity === 0 &&
                                  <td className="text-center">
                                    <label>
                                      {this.renderReviewCheckboxItem(citem)}
                                      <span></span>
                                    </label>
                                  </td>
                                }
                              </tr>
                            </React.Fragment>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  }
                  {this.state.fileMessage !== "" &&
                    <div className="setp-btn-row">
                      <Link className="btn btn-blue btn-nex" to="/projects">Close</Link>
                    </div>
                  }
                  {this.state.fileMessage === "" &&
                    <div className="setp-btn-row">
                      <Button variant="secondary"
                        onClick={(e) => this.onColumnPreviousClick(e)}
                        className="btn btn-blue btn-pre">Previous</Button>
                      {this.state.saveFileLoading === false &&
                        <Button variant="secondary"
                          disabled={this.state.file_columns.length === 0 ? true : false}
                          onClick={(e) => this.onSaveHeaderColumn(e)}
                          className="btn btn-blue btn-nex">
                          Next
                        </Button>
                      }
                      {this.state.saveFileLoading === true &&
                        <Button variant="secondary"
                          className="btn btn-blue btn-nex">
                          <Spinner animation="border" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Button>
                      }
                    </div>
                  }
                </>
              }
              {this.state.fileLoading === true &&
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default ProjectColumn;