import React from "react"
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom"
import { CloseImg, DeleteIconProject, EditIconProject, ExportIconProject, EyeIconProjct } from "../common/ImageConstants";
import NotFound from "../common/NotFound";
import * as Constants from "../partials/CommonConstant"
import ConfirmModal from "../common/ConfirmModal";
import projectApp from "../Shared/Controller/projectApp";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { connect } from "react-redux";
import { ShowToast, getFormattedDate } from "../common/CommonController";
import { ArchiveProjectData } from "../Shared/Controller/filesApp";

class ProjectList extends projectApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      projectList: [],
      showModal: false,
      exportLoading: false,
      exportProjectIds: "",
      projectId: "",
      ShowLastRowModal:false,
      deleteLoading:false,
    }
  }

  componentDidMount = () => {
    this.getProjects();
  }
  onClickClose =(e,id)=>
  {
    this.setState({ShowLastRowModal: true,projectId:id});
  }
  archiveProject = () => {
    this.setState({deleteLoading:true})
    const project_id = this.state.projectId;
    ArchiveProjectData({project_id:project_id,is_archived:1}).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        this.getProjects();
        this.setState({ShowLastRowModal: false});
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({deleteLoading:false})
    }).catch(error => {
      this.setState({deleteLoading:false})
      ShowToast("message", "error", "" + error);
    });
  }

  getColumnData = (data) => {
    console.log("jayesh PII flag check ",data);
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon text-center">
          {this.state.exportLoading === true && this.state.exportProjectIds === data.item.id &&
            <React.Fragment key={"export" + data.item.id}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </React.Fragment>
          }
          {this.state.exportProjectIds !== data.item.id &&
            <React.Fragment>
                {
                  this.props.userDetail.role === 1 && (
                    (data.item.integrity_precentage_completed === 100 && data.item.percentage_completed === 100) ||
                    (data.item.integrity_precentage_completed === 100 && data.item.percentage_completed === 0 && data.item.only_integrity===1) ||
                    (data.item.integrity_precentage_completed !== 0 && data.item.percentage_completed === 100) ||
                    (data.item.integrity_precentage_completed === 100 && data.item.only_integrity === 0 && data.item.percentage_completed ===100) ||
                    (data.item.integrity_precentage_completed !== 0 && data.item.only_integrity === 0 && data.item.percentage_completed ===100) ||
                    (data.item.percentage_completed===100 && data.item.only_review===1)
                  ) && (
                    <Link to="" className="ms-2 edt-btn btn-danger" onClick={(e) => this.onClickClose(e, data.item.id)}>Close
                    </Link>
                  )
                }
                

              {data.item.status === 1 && data.item.only_review !== 1 &&
                <Link to={"/integrity-project/" + data.item.id}
                  className={this.state.exportLoading === false ? "ms-2 edt-btn orange" : "ms-2 disabled-action edt-btn orange"}
                >
                  Integrity 
                  {/* <img src={IntegrityReviewImg} alt="Integrity" /> */}
                </Link>
              }
              {data.item.status === 1 && data.item.only_integrity !== 1 &&
                <Link to={"/review-project/" + data.item.id}
                  className={this.state.exportLoading === false ? "ms-2 edt-btn purple" : "ms-2 disabled-action edt-btn purple"}
                >
                  Review
                
                  {/* <img src={ReviewImg} alt="Review" /> */}
                </Link>
              }
              <Link data-tooltip="View" to={"/view-project/" + data.item.id}
                className={this.state.exportLoading === false ? "ms-2" : "ms-2 disabled-action"}
              >
                <img src={EyeIconProjct} alt="View" />
              </Link>
              {data.item.import_status !== 0 && this.props.userDetail.role === 1 &&
                <Link data-tooltip="Edit" to={"/edit-project/" + data.item.id} state={{ projectId: data.item.id, isEdit: true }}
                  className={this.state.exportLoading === false ? "ms-2" : "ms-2 disabled-action"}>
                  <img src={EditIconProject} alt="Edit" />
                </Link>
              }
              {/* {data.item.status !== 3 && data.item.import_status === 1 && this.props.userDetail.role === 1 &&
            <Link to={"#"} state={{ projectId: data.item.id, isEdit: true }} onClick={() => this.exportProject()} className="ms-2">
              <img src={EditImg} alt="export" title="Export" />
            </Link>
          } */}
              {this.props.userDetail.role === 1 &&
                <Link data-tooltip="Delete" to="/"
                  className={this.state.exportLoading === false ? "ms-2" : "ms-2 disabled-action"}
                  onClick={(e) => this.onClickDelete(e, data.item.id)}>
                  <img src={DeleteIconProject} alt="Delete" />
                </Link>
              }
              {data.item.status !== 3 && // this.props.userDetail.role === 1 && 
                <React.Fragment>
                  <Link data-tooltip="Export" data-position="right" to="/"
                    className={this.state.exportLoading === false ? "ms-2" : "ms-2 disabled-action"}
                    onClick={(e) => this.onClickExport(e, data.item.id)}>
                    <img src={ExportIconProject} alt="Export" />
                  </Link>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </div>
      );
    }
    if (data.type === "status") {
      return (
        <>
          {data.item.status === 1 &&
            "Active"
          }{data.item.status === 2 &&
            "Completed"
          }{data.item.status === 3 &&
            "Incomplete"
          }
        </>
      );
    }

    if (data.type === "importstatus") {
      return (
        <>
          {data.item.import_status === 0 &&
            "Failed"
          }{data.item.import_status === 1 &&
            "Completed"
          }{data.item.import_status === 2 &&
            "In Progress"
          }
        </>
      );
    }
    return columnData;
  }

  exportProject = () => {

  }

  getImportStatus = (value) => {
    if (value === 0) {
      return "Failed";
    }
    if (value === 1) {
      return "Completed";
    }
    if (value === 2) {
      return "In Progress";
    }
  }

  shortImportStatus = (rowA, rowB) => {
    const a = this.getImportStatus(rowA.import_status);
    const b = this.getImportStatus(rowB.import_status);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  /* customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = selector(rowA)
      const bField = selector(rowB)

      let comparison = 0;

      console.log("sort func", aField, bField, selector[aField])
      if (selector == "import_status") {
        console.log("sort func11", aField, bField, rowA, selector, rowB, rows, direction)
        if (a === 1) {
      return 1;
    }
    else if (a === 2) {
      return -1;
    }
      }
      else {
        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
      }

      return direction === 'desc' ? comparison * -1 : comparison;
    });
  }; */

  getTableColumns = () => {
    let table_columns = [];
    if (this.props.userDetail.role === 1) {
      table_columns = [
        {
          name: "Project Name",
          selector: row => row.project_name,
          // cell: d => { return this.getProjectName({ ...d }) },
          wrap: true,
          width: "12%",
          sortable: true
        },
        {
          name: "Identifier",
          selector: row => row.identifier,
          width: "15%",
          // compact: true,
          wrap: true,
          sortable: true
        },
        {
          name: "# Tasks",
          compact: true,
          center: true,
          selector: row => row.imported_rows,
          sortable: true,
        },
        {
          name: "Integrity Progress",
          compact: true,
          center: true,
          selector: row => row.integrity_statustotal,
          cell: d => { return d.only_review !== 1 ? d.integrity_statustotal : "" },
          sortable: true,
        },
        {
          name: "Review Progress",
          selector: row => row.task_statustotal,
          compact: true,
          center: true,
          cell: d => { return d.only_integrity !== 1 ? d.task_statustotal : "" },
          sortable: true,
        },
        // {
        //   name: "Import Status",
        //   selector: row => row.import_status,
        //   sortField: "",
        //   // compact: true,
        //   sortable: true,
        //   width: "13%",
        //   sortFunction: this.shortImportStatus,
        //   cell: d => { return this.getColumnData({ type: "importstatus", item: d }) }
        // },
        // {
        //   name: "Project Status",
        //   // compact: true,
        //   selector: row => row.status,
        //   sortable: true,
        //   width: "14%",
        //   cell: d => { return this.getColumnData({ type: "status", item: d }) }
        // },

        {
          name: "Owner",
          // compact: true,
          wrap: true,
          selector: row => row.created_user,
          width: "10%",
          sortable: true,
        },
        {
          name: "Created On",
          selector: row => row.created_at,
          width: "14%",
          wrap: true,
          cell: d => { return getFormattedDate(d.created_at) },
          sortable: true,
        },
        {
          name: "PII Flag",
          selector: row => row.pll_flag,
          width: "7%",
          wrap: true,
          cell: d => { return d.pll_flag },
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "28%",
          cell: d => { return this.getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    else if (this.props.userDetail.role === 2) {
      table_columns = [
        {
          name: "Project Name",
          selector: row => row.project_name,
          // cell: d => { return this.getProjectName({ ...d }) },
          wrap: true,
          width: "25%",
          sortable: true
        },
        {
          name: "Identifier",
          selector: row => row.identifier,
          width: "15%",
          wrap: true,
          sortable: true
        },
        // {
        //   name: "Project Status",
        //   selector: row => row.status,
        //   sortable: true,
        //   cell: d => { return this.getColumnData({ type: "status", item: d }) }
        // },
        {
          name: "Owner",
          selector: row => row.created_user,
          width: "15%",
          sortable: true,
        },
        {
          name: "Created On",
          selector: row => row.created_at,
          width: "15%",
          cell: d => { return getFormattedDate(d.created_at) },
          sortable: true,
        },
        {
          name: "PII Flag",
          selector: row => row.pll_flag,
          width: "7%",
          wrap: true,
          cell: d => { return d.pll_flag },
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "28%",
          cell: d => { return this.getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    return table_columns;
  }

  render() {

    // Create table headers
    const data = this.props.project_list ? this.props.project_list : [];
    const columns = this.getTableColumns();

    const tableData = {
      columns,
      data
    };
    

    /* const paginationComponentOptions = {
      selectAllRowsItem: true,
      selectAllRowsItemText: "ALL"
    }; */

    return (
      <>
        <div className="dashboard-card-wrapper">

        <ConfirmModal show={this.state.ShowLastRowModal}
        customMessage={"Are you sure you want to archive this project?"}
        deleteLoading={this.state.deleteLoading}
        onCloseModal={() => this.onCloseArchiveModal()}
        confirmClick={() => this.archiveProject()} 
        confirmBtnText={"Confirm"}/>

          <ConfirmModal show={this.state.showModal}
            onCloseModal={() => this.onCloseModal()}
            module="project"
            confirmClick={() => this.handleDelete()} />
          <div className="row align-items-center">
            {(this.props.userDetail.role === 1) &&
              <div className="col-12 text-end">
                <Link to={Constants.addProject} className="btn btn-blue btn-nex me-2"> Add Project</Link>
                <Link to={Constants.archived} className="btn btn-blue btn-nex me-2"> Archived Projects</Link>
                <Link to={Constants.deletedProjects} className="btn btn-blue btn-nex">Deleted Projects</Link>
              </div>
            }
          </div>
          <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
            <div className="card-main p-0">
              <div className="dark-table-wrapper table-scroll proj_table">
                {this.props.project_list && this.props.project_list.length !== 0 && this.state.loading === false &&
                  <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                    <DataTable
                      // sortFunction={this.customSort}
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      paginationRowsPerPageOptions={[10, 20, 30, 100, 500]}
                      paginationPerPage={20}
                      // paginationComponentOptions={paginationComponentOptions}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                }
                {this.state.loading === true &&
                  <table className="mb-0 table">
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
                {/* Not found */}
                {this.state.loading === false && this.props.project_list && this.props.project_list.length === 0 &&
                  <NotFound message="No Data Found" type="content" />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default connect(projectApp.mapStateToProps, projectApp.mapDispatchToProps)(ProjectList)