import React, { useContext } from "react";
import { CheckmarkImg, CloseImg } from "../common/ImageConstants";
import $ from "jquery";
import { ReviewContext } from "../context/ReviewContext";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import NotFound from "../common/NotFound";

const IntegrityRow = () => {

  const RowProps = useContext(ReviewContext)
  let navigate = useNavigate();
  const onAccordian1Click = (e) => {
    e.preventDefault();
    $(".accord1").toggle();
    $(".btnacc1").parent().toggleClass('tclass');
  }

  const getDefaultValue = (item, answers) => {
    let selItem = "";
    if (answers.length > 0) {
      selItem = answers.find((aitem) => aitem.column_name === item);
    }
    return selItem ? selItem.status : "";
  }

  const setInputHiddenValue = (e) => {
    if (e.target.id === "btnIntegritySave") {
      document.getElementsByName("inputBtnHidden").value = "save";
    }
    else if (e.target.id === "btnIntegritySaveAndReview") {
      document.getElementsByName("inputBtnHidden").value = "save & review";
    }
    else {
      document.getElementsByName("inputBtnHidden").value = "save & next";
    }
  }

  /* Hide 3 field -> Account reference, current balance and delinquency flag */
  const checkFixedField = (item) => {
    let isChecked = true;
    if (item === "Account_Reference") {
      isChecked = false;
    }
    if (item === "Current_Balance") {
      isChecked = false;
    }
    if (item === "Delinquency_Flag") {
      isChecked = false;
    }
    return isChecked;
  }
  /* Hide 3 field -> Account reference, current balance and delinquency flag */

  // console.log("IntegrityRow", RowProps)
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mt-md-0">
          <h5>{RowProps.RowData.SingleRow.project_name}</h5>
        </div>
        <div className="col-md-12  mt-md-0">
          {RowProps.isReviewFinish === false &&
            <React.Fragment>
              {/* <h1 className="content-title">Data Integrity</h1> */}
              <div className="right-panel btn-integrity">
                <React.Fragment>
                  <div className="tagn">
                    {RowProps.isRowAnswerFilled === false &&
                      <img src={CloseImg} alt="close" />
                    }
                    {RowProps.isRowAnswerFilled === true &&
                      <img src={CheckmarkImg} alt="Checkmark" />
                    }
                    Questions
                  </div>
                </React.Fragment>
                <div className="btn-block">
                  {RowProps.saveRowLoading === false &&
                    <React.Fragment>
                      <Button id="btnIntegritySave"
                        onClick={setInputHiddenValue}
                        type="submit"
                        variant="secondary"
                        className={"btn btn-blue btn-nex me-1 mb-1"}>
                        Save & Close
                      </Button>
                      <Form.Control type="hidden" name="inputBtnHidden">

                      </Form.Control>
                    </React.Fragment>
                  }
                  {RowProps.saveRowLoading === false &&
                    <Button id="btnIntegritySaveNext"
                      type="submit"
                      onClick={setInputHiddenValue}
                      variant="secondary" className={"btn btn-blue btn-nex mb-1 me-1"}>
                      {parseInt(RowProps.RowData.SingleRow.integrity_completed_percentage) === 100 ? "Integrity Completed" : "Save & Next"}
                    </Button>
                  }
                  {RowProps.saveRowLoading === true &&
                    <Button variant="secondary" className="btn btn-blue btn-nex mb-1 me-1">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                  {(RowProps.nextRowLoading === false && parseInt(RowProps.RowData.SingleRow.integrity_completed_percentage) === 100) &&
                    <Button id="btnIntegrityNext"
                      type="button"
                      onClick={RowProps.checkNextRow}
                      variant="secondary" className={"btn btn-blue btn-nex mb-1 me-1"}>
                      Next
                    </Button>
                  }
                  {(RowProps.nextRowLoading === true && parseInt(RowProps.RowData.SingleRow.integrity_completed_percentage) === 100) &&
                    <Button variant="secondary" className="btn btn-blue btn-nex mb-1 me-1">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                  {(RowProps.RowData.SingleRow?.only_integrity !== 1 && RowProps.saveRowLoading === false) &&
                    <Button id="btnIntegritySaveAndReview"
                      type="submit"
                      onClick={setInputHiddenValue}
                      variant="secondary" className={"btn btn-blue btn-nex mb-1 me-1"}>
                      Save & Review
                    </Button>
                  }
                  {/* <Link to={Constants.projectList} className="btn btn-blue btn-nex mb-1">Close</Link> */}
                  <Button onClick={() => navigate(-1)} className="btn btn-blue btn-nex mb-1">Back</Button>
                </div>
              </div>
              <p>Investor Reference - {RowProps.RowData.SingleRow.investor_ref}, Owner - {RowProps.RowData.SingleRow.owner_name} <br />
                Supplemental Data</p>
            </React.Fragment>
          }
        </div>
      </div>
      {RowProps.isReviewFinish === false &&
        <React.Fragment>
          <div className="row">
            <div className="col-md-12 mt-3 mt-md-0 accordian-row">
              <button onClick={(e) => onAccordian1Click(e)} className="btnacc1"> </button>
            </div>
          </div>
          <div className="row accord1">
            <div className="col-md-12 col-sm-12 col-lg-12 mb-3">
              <div className="card-main">
                <table className="table mb-0 no-border">
                  <tbody>
                    {RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
                      return (
                        <>
                          {(!checkFixedField(item)) &&
                            <tr>
                              <td>
                                <span>{item}</span>
                              </td>
                              <td>
                                <span>{RowProps.RowData.SingleRow.row_details[item]}</span>
                              </td>
                            </tr>
                          }
                        </>
                      )
                    })
                    }
                    {RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
                      return (
                        <React.Fragment key={"rowheader" + item}>
                          {(checkFixedField(item)) &&
                            <tr>
                              <td>
                                <span>{item}</span>
                              </td>
                              <td>
                                <span>{RowProps.RowData.SingleRow.row_details[item]}</span>
                              </td>
                              <>
                                <td>
                                  <Form.Control
                                    as="select"
                                    name={"status|" + item}
                                    // required
                                    value={getDefaultValue(item, RowProps.answers)}
                                    defaultValue={getDefaultValue(item, RowProps.answers)}
                                    onChange={(e) => RowProps.onHandleChange(e)}
                                  >
                                    <option value="">Select Status</option>
                                    <option key={"match_" + item} value={"Match"}>Match</option>
                                    <option key={"nomatch_" + item} value={"No Match"}>No Match</option>
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">Please select status</Form.Control.Feedback>
                                </td>
                                {RowProps.answers.length > 0 && RowProps.answers.map((fitem) => {
                                  if (fitem.column_name === item && fitem.status === "No Match") {
                                    return (
                                      <React.Fragment key={"comment" + item}>
                                        <td>
                                          <Form.Control
                                            // required
                                            name={"comment|" + item}
                                            placeholder="Comment"
                                            value={fitem.comment}
                                            onChange={(e) => RowProps.onHandleChange(e)}
                                          >
                                          </Form.Control>
                                          <Form.Control.Feedback type="invalid">Please enter comment</Form.Control.Feedback>
                                        </td>
                                      </React.Fragment>
                                    )
                                  }
                                })
                                }
                              </>
                            </tr>
                          }
                        </React.Fragment>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      }
      {RowProps.isReviewFinish === true &&
        <div className="card mt-3">
          <NotFound type="content" message={"Integrity Finished"} />
        </div>
      }
    </React.Fragment>
  )
}
export default IntegrityRow;