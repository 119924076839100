import { Component } from "react"
import { ShowToast } from "../../common/CommonController"
import InvesterServices from "../Services/InvesterServices"

class investorApp extends Component {
  getInvestors = () => {
    this.setState({ loading: true })
    InvesterServices.getInvestors().then((response) => {
      if (response.data.code === 1) {
        this.setState({ investorList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  saveInvestor = (data) => {
    this.setState({ saveLoading: true })
    InvesterServices.saveInvestor(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onSaveSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ saveLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveLoading: false });
    });
  }

  getInvestor = (investorId) => {
    this.setState({ loading: true })
    InvesterServices.getInvestor(investorId).then((response) => {
      if (response.data.code === 1) {
        let data = {
          name: response.data.data.name,
        }
        this.setState({ loading: false, ...data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  deleteInvestor = (data) => {
    this.setState({ loading: true });
    InvesterServices.deleteInvestor(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onDeleteSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }


  handleDelete = (e) => {
    let newData = { investorId: this.state.investorId }
    this.setState({ showModal: false })
    this.deleteInvestor(newData);
  };

  onClickDelete = (e, investorId) => {
    e.preventDefault();
    this.setState({ showModal: true, investorId: investorId })
  }

  onCloseModal = () => {
    this.setState({ showModal: false })
  }

  onSaveSuccess = (data) => {
    // this.getInvestors();
    this.props.callBackSuccess(data);
  }

  onDeleteSuccess = (data) => {
    this.getInvestors();
  }

}
export default investorApp