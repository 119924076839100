import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProjectRecordRow, getProjectRow, getReviewRowView, saveReviewRow, setDefaultAnswerData, setFilledAnswer } from "../Shared/Controller/projectReviewApp";
import { ShowToast } from "../common/CommonController";
import ReviewRow from "../reviewproject/ReviewRow";
import { ReviewContext } from "../context/ReviewContext";
import { Form, Spinner } from "react-bootstrap";
import ConfirmModal from "../common/ConfirmModal";

let btnClicked = "save & next";

const ReviewProject = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let userDetail = useSelector((state) => state.common.db.user)
  let params = useParams();

  const [RowData, setRowData] = useState({ SingleRow: {}, AllQuestions: {} });
  const [RowLoading, setRowLoading] = useState(false);
  const [reviewAnswerErrors, setReviewAnswerErrors] = useState({});
  const [answers, setAnswers] = useState([]);
  const [grade, setGrade] = useState("");
  const [row_comment, setRowComment] = useState("");
  const [isRowAnswerFilled, setIsRowAnswerFilled] = useState(false);
  const [take_ownership, setTakeOwnership] = useState(0);
  const [projectId, setProjectId] = useState("");
  const [saveRowLoading, setSaveRowLoading] = useState(false);
  const [isReviewFinish, setIsReviewFinish] = useState(false);
  const [integrity_review] = useState(location.pathname.indexOf("review-project") !== -1 ? 0 : 1);
  const [showConfirmModal, setShowConfirmModal] = useState({ flag: false, btnClicked: "" });
  const [showLastRowModal, setShowLastRowModal] = useState(false);
  const [nextRowLoading, setNextRowLoading] = useState(false);

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    let project_id = "";
    if (splitPath.length > 2) {
      project_id = splitPath[2];
      setProjectId(project_id);
      if (location.hash === "") {
        GetProjectRow(project_id);
      }
      else {
        GetProjectRecordRow()
      }
    }
  }, []);

  const GetProjectRow = (project_id) => {
    let data = {
      userid: userDetail.id,
      projectid: project_id,
      integrity_review: integrity_review,
    }
    setRowLoading(true);
    getProjectRow(data).then((response) => {
      if (response.data.code === 1) {
        setRowData({ SingleRow: response.data.data.singel_row, AllQuestions: response.data.data.all_questions })
        setGrade(response.data.data.singel_row.grade !== null ? response.data.data.singel_row.grade : "");
        setRowComment(response.data.data.singel_row.row_comment !== null ? response.data.data.singel_row.row_comment : "");
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  const GetProjectRecordRow = () => {
    let data = {
      project_id: params.project_id,
      row_id: location.hash.replace("#", ""),
    }
    setRowLoading(true);
    getProjectRecordRow(data).then((response) => {
      if (response.data.code === 1) {
        setRowData({ SingleRow: response.data.data.singel_row, AllQuestions: response.data.data.all_questions })
        setGrade(response.data.data.singel_row.grade !== null ? response.data.data.singel_row.grade : "");
        setRowComment(response.data.data.singel_row.row_comment !== null ? response.data.data.singel_row.row_comment : "");
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  useEffect(() => {
    setReviewAnswerError();
    setAnswers(setDefaultAnswerData(RowData));
    setTakeOwnership(0);
  }, [RowData])

  useEffect(() => {
    setRowFieldStatus();
  }, [answers])

  const setRowFieldStatus = () => {
    let isFilledRowAnswer = true;
    answers.map((item) => {
      if (item.selected_choice === "") {
        isFilledRowAnswer = false;
      }
      if (item.comment === "" && item.cr === 1) {
        isFilledRowAnswer = false;
      }
      if (grade !== "") {
      }
      if (row_comment !== "") {
      }
      return null;
    });
    setIsRowAnswerFilled(isFilledRowAnswer);
  }

  const setReviewAnswerError = () => {
    let newErrors = {}
    let isFilledRowAnswer = true;
    answers.map((item) => {
      if (item.selected_choice === "" || item.selected_choice === null) {
        newErrors["choice_" + item.questionid] = "please select choice";
        isFilledRowAnswer = false;
      }
      if ((item.comment === "" || item.comment === null) && item.comment_required.toString() === "1") {
        newErrors["comment_" + item.questionid] = "please enter comment";
        isFilledRowAnswer = false;
      }
      if (grade === "") {
        newErrors["grade"] = "please enter row grade";
      }
      return null;
    });
    if(answers.length === 0) {
      if (grade === "") {
        newErrors["grade"] = "please enter row grade";
      }
    }
    setIsRowAnswerFilled(isFilledRowAnswer);
    return newErrors
  }

  const onSaveRowReview = (e) => {
    e.preventDefault();
    const newErrors = setReviewAnswerError()
    /* if (Object.keys(newErrors).length > 0) {
      if (integrity_review === 1 && take_ownership !== 0) {
        setReviewAnswerErrors({ ...newErrors })
      }
      else if (integrity_review === 0) {
        setReviewAnswerErrors({ ...newErrors })
      }
      if (userDetail.role === 1 && take_ownership === 0 && integrity_review === 1) {
        ShowToast("message", "info", "Please click on take ownership and edit details.")
        return;
      }
    }
    else { */
    // setReviewAnswerErrors({});
    if (userDetail.role === 1 && take_ownership === 0 && integrity_review === 1) {
      ShowToast("message", "info", "Please click on take ownership and edit details.")
      return;
    }
    btnClicked = "save & next";
    if (e.target.id === 'btnReviewSave') {
      btnClicked = "save";
    }
    if (e.target.id === 'btnReviewSaveIntegrity') {
      btnClicked = "save & integrity";
    }
    if (Object.keys(newErrors).length > 0) {
      setShowConfirmModal({ ...showConfirmModal, flag: true, btnClicked: btnClicked });
    }
    else {
      setReviewAnswerErrors({});
      SaveReviewRow(btnClicked, 0);
    }
    // }
  }

  const SaveReviewRow = (btnClicked, incomplete_status = 0) => {
    let data = {
      userid: userDetail.id,
      projectid: projectId,
      rowid: RowData.SingleRow.id,
      grade: grade,
      row_comment: row_comment,
      take_ownership: take_ownership,
      integrity_review: integrity_review,
      answers: answers,
      incomplete_status: incomplete_status,
      review_status: RowData.SingleRow.task_status,
    }
    let newAnswerArr = answers;
    // Un-comment 27-dec-2022 remove comment when optional
    // checking answer comment if required then blank comment pass 08-Sep-2022
    /* Comment 06-Dec-2022 for comment box changes always show */
    newAnswerArr.map((aitem) => {
      if (aitem.comment_required.toString() === "0") {
        aitem.comment = "";
      }
    })
    // End checking answer comment.
    data.answers = newAnswerArr;
    console.log("save review", newAnswerArr);
    setSaveRowLoading(true);
    saveReviewRow(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        if (btnClicked === "save & next") {
          if (RowData.SingleRow.id === RowData.SingleRow.last_row_id) {
            setShowLastRowModal(true);
          }
          else {
            GetProjectRow(projectId);
          }
        }
        else if (btnClicked === "save & integrity") {
          navigate("/integrity-project/" + RowData.SingleRow.project_id + "#" + RowData.SingleRow.id);
        }
        else {
          if (RowData.SingleRow.id === RowData.SingleRow.last_row_id) {
            setShowLastRowModal(true);
          }
          else {
            navigate(-1);
          }
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setSaveRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  const setQuestionChoice = (e) => {
    let data = {
      type: "choice",
      value: e.target.value,
      name: e.target.name,
      comment_required: e.comment_required,
    }
    setAnswers(setFilledAnswer({ ...data, answers: answers }));
    setReviewAnswerError();
  }

  const setQuestionComment = (e) => {
    let data = {
      type: "comment",
      value: e.target.value,
      name: e.target.name,
    }
    setAnswers(setFilledAnswer({ ...data, answers: answers }));
    setReviewAnswerError();
  }

  const SetRowGrade = (e) => {
    setGrade(e.target.value);
  }

  const SetRowComment = (e) => {
    setRowComment(e.target.value);
  }

  const onTakeOwnsership = (e) => {
    e.preventDefault();
    setTakeOwnership(1);
  }

  const onCloseConfirmModal = () => {
    setShowConfirmModal({ ...showConfirmModal, flag: false });
  }

  const onConfirmYesModal = () => {
    setShowConfirmModal({ ...showConfirmModal, flag: false });
    SaveReviewRow(showConfirmModal.btnClicked, 1);
  }

  const onConfirmLastRowModal = () => {
    setShowLastRowModal(false);
    if (btnClicked !== "save") {
      if (btnClicked === "next") {
        GetReviewRowView();
      }
      else {
        GetProjectRow(projectId);
      }
    }
    else {
      navigate("/projects");
    }
  }

  const checkNextRow = () => {
    if (RowData.SingleRow.id === RowData.SingleRow.last_row_id) {
      btnClicked = "next"
      setShowLastRowModal(true);
    }
    else {
      GetReviewRowView();
    }
  }

  const GetReviewRowView = () => {
    let data = {}
    data.projectid = params.project_id;
    data.nextid = RowData.SingleRow.id;

    setNextRowLoading(true);
    setRowLoading(true);
    getReviewRowView(data).then((response) => {
      if (response.data.code === 1) {
        setRowData({ SingleRow: response.data.data.singel_row, AllQuestions: response.data.data.all_questions })
        setGrade(response.data.data.singel_row.grade !== null ? response.data.data.singel_row.grade : "");
        setRowComment(response.data.data.singel_row.row_comment !== null ? response.data.data.singel_row.row_comment : "");
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setRowLoading(false);
      setNextRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper reviewpage">
        <ConfirmModal show={showConfirmModal.flag} type="permanentDelete"
          customMessage={"This is incomplete data, still you want to save?"}
          onCloseModal={() => onCloseConfirmModal()}
          confirmClick={() => onConfirmYesModal()} />
        <ConfirmModal show={showLastRowModal}
          customMessage={"You have reached end of the job"}
          showCancelButton={false}
          confirmBtnText="Ok"
          confirmClick={() => onConfirmLastRowModal()} />
        <ReviewContext.Provider value={{
          userDetail,
          RowData,
          answers,
          setQuestionChoice,
          setQuestionComment,
          reviewAnswerErrors,
          SetRowGrade,
          SetRowComment,
          isRowAnswerFilled,
          grade,
          row_comment,
          onTakeOwnsership,
          take_ownership,
          integrity_review,
          saveRowLoading,
          isReviewFinish,
          onSaveRowReview,
          checkNextRow,
          nextRowLoading,
        }}>
          {RowLoading === false &&
            <Form noValidate>
              <ReviewRow />
            </Form>
          }
          {RowLoading === true &&
            <>
              <div className="card">
                <div className="p-5 text-center">
                  <Spinner animation="border" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </>
          }
        </ReviewContext.Provider>
      </div>
    </React.Fragment>
  )
}
export default ReviewProject;