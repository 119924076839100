import { Component } from "react"
import { ShowToast } from "../../common/CommonController"
import PlatformServices from "../Services/PlatformServices"

class platformApp extends Component {
  getPlatforms = () => {
    this.setState({ loading: true })
    PlatformServices.getPlatforms().then((response) => {
      if (response.data.code === 1) {
        this.setState({ platformList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  savePlatform = (data) => {
    this.setState({ saveLoading: true })
    PlatformServices.savePlatform(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onSaveSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ saveLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveLoading: false });
    });
  }

  getPlatform = (platformId) => {
    this.setState({ loading: true })
    PlatformServices.getPlatform(platformId).then((response) => {
      if (response.data.code === 1) {
        let data = {
          name: response.data.data.name,
        }
        this.setState({ loading: false, ...data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  deletePlatform = (data) => {
    this.setState({ loading: true });
    PlatformServices.deletePlatform(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onDeleteSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  handleDelete = () => {
    let newData = { platformId: this.state.platformId }
    this.setState({ showModal: false })
    this.deletePlatform(newData);
  };

  onClickDelete = (e, platformId) => {
    e.preventDefault();
    this.setState({ showModal: true, platformId: platformId })
  }

  onCloseModal = () => {
    this.setState({ showModal: false })
  }

  onSaveSuccess = (data) => {
    this.props.callBackSuccess(data);
  }

  onDeleteSuccess = (data) => {
    this.getPlatforms();
  }

}
export default platformApp