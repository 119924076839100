import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ShowToast } from "../common/CommonController";
import ConfirmModal from "../common/ConfirmModal";
import { checkIsImage, DeleteMultipleDocument, getFilePathForView, getTableColumns } from "../Shared/Controller/filesApp";

const DocumentDataTable = (props) => {
  const columns = getTableColumns(props);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDocs, setDeleteDocs] = useState({ file: [], folder: [] });

  const onRowDoubleClickHandle = (row) => {
    console.log("duble clieck", row, props);
    if (row.document_type === "file") {
      if (checkIsImage(row.original_filename)) {
        getFilePathForView({}, row.id)
      }
      else {
        window.open("/fileviewer/" + row.id, "_blank");
      }
    }
    else if (row.document_type === "folder") {
      // window.open("/view-folder/" + row.id, "_self");
      props.navigate("/view-folder/" + row.id);
    }
  }

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
    let folderIds = [];
    let fileIds = [];
    state.selectedRows.map((item) => {
      if (item.document_type === "folder") {
        folderIds.push(item.id);
      }
      if (item.document_type === "file") {
        fileIds.push(item.id);
      }
    });
    setDeleteDocs({ ...deleteDocs, file: fileIds, folder: folderIds });
  };

  const deleteDocuments = () => {
    setDeleteLoading(true);
    DeleteMultipleDocument(deleteDocs).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        props.callBackDeleteDoc();
        setSelectedRows([]);
        setShowModal(false);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setDeleteLoading(false);
    }).catch(error => {
      setDeleteLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      /* if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
        setToggleCleared(!toggleCleared);
        // setData(differenceBy(data, selectedRows, 'title'));
      } */
      setShowModal(true);
    };
    if (selectedRows.length > 0) {
      return (
        <Button key="delete" className="btn btn-blue btn-nex" onClick={handleDelete} style={{ backgroundColor: '#ee7608' }} icon>
          Delete
        </Button>
      );
    }
    else {
      return null;
    }
  }, [selectedRows]);

  const customStyles = {
    columns: {
      style: {
        padding: "10px",
      }
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal show={showModal}
        type="permanentDelete"
        module={"documents"}
        deleteLoading={deleteLoading}
        onCloseModal={() => setShowModal(false)}
        confirmClick={() => deleteDocuments()} />
      <DataTable
        columns={columns}
        actions={contextActions}
        data={props.data}
        defaultSortField="filename"
        defaultSortAsc={true}
        onRowDoubleClicked={onRowDoubleClickHandle}
        pagination
        highlightOnHover
        selectableRows
        selectableRowsHighlight={true}
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        customStyles={customStyles}
      />
    </React.Fragment>
  )
}
export default DocumentDataTable;