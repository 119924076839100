import React from "react";
import { Button } from "react-bootstrap";
import { LogOut } from "./CommonController";
import { LogoutImg } from "./ImageConstants";

const Logout = (props) => {

  const onLogout = () => {
    LogOut();
  }

  return (
    <React.Fragment>
      {props.logoutType && props.logoutType === 'sidemenu' &&
        <li onClick={() => onLogout()} className="c-menu__item has-submenu" data-toggle="tooltip" title="Logout">
          <div className="c-menu__item__inner">
            <span className="menu-icon-block">
              <img src={LogoutImg} alt="Logout" />
            </span>
            <div className="c-menu-item__title">
              <span>Logout</span>
            </div>
          </div>
        </li>
      }
      {props.logoutType && props.logoutType === "topmenu" &&
        <Button className="dropdown-item" onClick={() => onLogout()}>
          Logout
        </Button>
      }
    </React.Fragment>
  )
}
export default Logout;