import * as Constant from "../../partials/CommonConstant";

export const AppServices = {
  GET_USERS: () => { return `${Constant.GET_USERS}` },
  SAVE_USER: () => { return `${Constant.SAVE_USER}` },
  UPDATE_USER: (userid) => { return `${Constant.UPDATE_USER(userid)}` },
  GET_USER: (userid) => { return `${Constant.GET_USER(userid)}` },
  DELETE_USER: (userId) => { return `${Constant.DELETE_USER(userId)}` },
  RESTORE_USER: (userId) => { return `${Constant.RESTORE_USER(userId)}` },
  RESEND_VERIFY_EMAIL: () => { return `${Constant.RESEND_VERIFY_EMAIL}` },
  SAVE_INVESTOR: () => { return `${Constant.SAVE_INVESTOR}` },
  UPDATE_INVESTOR: (investorid) => { return `${Constant.UPDATE_INVESTOR(investorid)}` },
  GET_INVESTORES: () => { return `${Constant.GET_INVESTORES}` },
  GET_INVESTOR: (investorid) => { return `${Constant.GET_INVESTOR(investorid)}` },
  DELETE_INVESTOR: (investorid) => { return `${Constant.DELETE_INVESTOR(investorid)}` },
  RESTORE_INVESTOR: (investorid) => { return `${Constant.RESTORE_INVESTOR(investorid)}` },
  GET_PLATFORMS: () => { return `${Constant.GET_PLATFORMS}` },
  GET_PROFILE: (userid) => { return `${Constant.GET_PROFILE(userid)}` },
  SAVE_PLATFORM: () => { return `${Constant.SAVE_PLATFORM}` },
  UPDATE_PLATFORM: (platformid) => { return `${Constant.UPDATE_PLATFORM(platformid)}` },
  GET_PLATFORM: (platformid) => { return `${Constant.GET_PLATFORM(platformid)}` },
  DELETE_PLATFORM: (platformid) => { return `${Constant.DELETE_PLATFORM(platformid)}` },
  RESTORE_PLATFORM: (platformid) => { return `${Constant.RESTORE_PLATFORM(platformid)}` },
  GET_PROJECTS: () => { return `${Constant.GET_PROJECTS}` },
  GET_ARCHIVE: () => { return `${Constant.GET_ARCHIVE}` },
  IMPORT_FILE: () => { return `${Constant.IMPORT_FILE}` },
  GET_TEMPLATES: () => { return `${Constant.GET_TEMPLATES}` },
  SAVE_TEMPLATE: () => { return `${Constant.SAVE_TEMPLATE}` },
  UPDATE_TEMPLATE: (templateId) => { return `${Constant.UPDATE_TEMPLATE(templateId)}` },
  GET_TEMPLATE: (templateId) => { return `${Constant.GET_TEMPLATE(templateId)}` },
  DELETE_TEMPLATE: (templateId) => { return `${Constant.DELETE_TEMPLATE(templateId)}` },
  RESTORE_TEMPLATE: (templateId) => { return `${Constant.RESTORE_TEMPLATE(templateId)}` },
  SAVE_PROJECT: () => { return `${Constant.SAVE_PROJECT}` },
  GET_FILECOLUMNS: () => { return `${Constant.GET_FILE_COLUMNS}` },
  SAVE_HEADER_COLUMNS: () => { return `${Constant.SAVE_HEADER_COLUMNS}` },
  DELETE_PROJECT: (projectId) => { return `${Constant.DELETE_PROJECT(projectId)}` },
  POST_SAVE_QUESTION: () => { return `${Constant.POST_SAVE_QUESTION}` },
  SAVE_QUESTIONS: () => { return `${Constant.SAVE_QUESTIONS}` },
  SAVE_PROJECT_USERS: () => { return `${Constant.SAVE_PROJECT_USERS}` },
  GET_PROJECT_DETAIL: (projectId) => { return `${Constant.GET_PROJECT_DETAIL(projectId)}` },
  GET_FILE_PROGRESS: () => { return `${Constant.GET_FILE_PROGRESS}` },
  GET_QUESTIONS: () => { return `${Constant.GET_QUESTIONS}` },
  GET_QUESTION: (questionId) => { return `${Constant.GET_QUESTION(questionId)}` },
  DELETE_QUESTION: (questionId) => { return `${Constant.DELETE_QUESTION(questionId)}` },
  UPDATE_QUESTION: (questionId) => { return `${Constant.UPDATE_QUESTION(questionId)}` },
  RESTORE_QUESTION: (questionId) => { return `${Constant.RESTORE_QUESTION(questionId)}` },
  GET_PROJECT_ROW: () => { return `${Constant.GET_PROJECT_ROW}` },
  SAVE_REVIEW_ROW: () => { return `${Constant.SAVE_REVIEW_ROW}` },
  GET_ALL_FILES: () => { return `${Constant.GET_ALL_FILES}` },
  DOWNLOAD_FILE: () => { return `${Constant.DOWNLOAD_FILE}` },
  GET_FILE: (fileId) => { return `${Constant.GET_FILE(fileId)}` },
  ADD_FILE: () => { return `${Constant.ADD_FILE}` },
  DELETE_FILE: (fileid) => { return `${Constant.DELETE_FILE(fileid)}` },
  UPDATE_FILE: () => { return `${Constant.UPDATE_FILE()}` },
  GET_INTEGRITY_PROJECT_ROW: () => { return `${Constant.GET_INTEGRITY_PROJECT_ROW}` },
  SAVE_INTEGRITY_ROW: () => { return `${Constant.SAVE_INTEGRITY_ROW}` },
  GET_DOWNLOAD_LOGS: () => { return `${Constant.GET_DOWNLOAD_LOGS}` },
  GET_SESSION_LOG: () => { return `${Constant.GET_SESSION_LOG}` },
  GET_SITE_LOG: () => { return `${Constant.GET_SITE_LOG}` },
  GET_DASHBOARD: () => { return `${Constant.GET_DASHBOARD}` },
  DELETE_SITE_LOGS: (noofmonths) => { return `${Constant.DELETE_SITE_LOGS(noofmonths)}` },
  DELETE_SESSION_LOGS: (noofmonths) => { return `${Constant.DELETE_SESSION_LOGS(noofmonths)}` },
  GET_EXPORT_DATA: () => { return `${Constant.GET_EXPORT_DATA()}` },
  VERIFY_CONFIRM_DELETE: () => { return `${Constant.VERIFY_CONFIRM_DELETE()}` },
  SAVE_FOLDER: () => { return `${Constant.SAVE_FOLDER()}` },
  GET_FOLDER: (folderid) => { return `${Constant.GET_FOLDER(folderid)}` },
  UPDATE_FOLDER: () => { return `${Constant.UPDATE_FOLDER()}` },
  DELETE_FOLDER: (id) => { return `${Constant.DELETE_FOLDER(id)}` },
  GET_SHORTCUT_URL: () => { return `${Constant.GET_SHORTCUT_URL()}` },
  GET_PROJECT_RECORD: () => { return `${Constant.GET_PROJECT_RECORD()}` },
  GET_PROJECT_RECORD_ROW: () => { return `${Constant.GET_PROJECT_RECORD_ROW}` },
  GET_INTEGRITY_PROJECT_RECORD_ROW: () => { return `${Constant.GET_INTEGRITY_PROJECT_RECORD_ROW}` },
  RESTORE_PROJECT: (projectId) => { return `${Constant.RESTORE_PROJECT(projectId)}` },
  UPLOAD_FOLDER_FILE: () => { return `${Constant.UPLOAD_FOLDER_FILE()}` },
  VERIFY_FILE_TOKEN: (token) => { return `${Constant.VERIFY_FILE_TOKEN(token)}` },
  DELETE_MULTIPLE_DOCUMENT: () => { return `${Constant.DELETE_MULTIPLE_DOCUMENT()}` },
  ARCHIVE_PROJECT: () => { return `${Constant.ARCHIVE_PROJECT()}` },
  UNARCHIVE_PROJECT: () => { return `${Constant.UNARCHIVE_PROJECT()}` },
  SEND_CRON_JOB_MAIL_DELETE_FILE: () => { return `${Constant.SEND_CRON_JOB_MAIL_DELETE_FILE()}`},
  GET_INTEGRITY_ROW_VIEW: () => { return `${Constant.GET_INTEGRITY_ROW_VIEW}` },
  GET_REVIEW_ROW_VIEW: () => { return `${Constant.GET_REVIEW_ROW_VIEW}` },
  POST_MULTIPLE_TEMPLATE_DELETE: () => { return `${Constant.POST_MULTIPLE_TEMPLATE_DELETE}` },
  POST_QUESTION_REORDER: () => { return `${Constant.POST_QUESTION_REORDER}` },
};

export const HttpHeaders = () => {

  const token = localStorage.getItem('authtoken');
  return {
    headers: {
      'authtoken': `${token}`
    }
  }
}