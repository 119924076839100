import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import QuestionServices from "../Shared/Services/QuestionServices";
import { ShowToast } from "./CommonController";
import { CheckedImg, UncheckedImg } from "./ImageConstants";

const AddQuestionModal = (props) => {
  const [show, setShow] = useState(props.show);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [export_heading, setExportHeading] = useState("");
  const [question, setQuestion] = useState("");
  const [comment_required, isCommentRequired] = useState(false);
  const [choices, setChoices] = useState([]);
  const [ChoiceName, setChoiceName] = useState("");
  const [status, setStatus] = useState(false);
  const [questionFormErrors, setQuestionFormErrors] = useState({});
  const [choice_error, setChoiceError] = useState("");

  const setUpdateValues = () => {
    if (props.questionDetails && props.questionDetails.length !== 0) {
      setCategory(props.questionDetails.category);
      setExportHeading(props.questionDetails.export_heading);
      setQuestion(props.questionDetails.question);
      isCommentRequired(props.questionDetails.comment_required === 1 ? true : false);
      let choice = [];
      if (props.questionDetails.choices && props.questionDetails.choices.length > 0) {
        props.questionDetails.choices.map((item) => {
          let randomID = Math.floor((Math.random() * 100) + 1);
          choice.push({ choice: item.choice, status: parseInt(item.status), id: randomID })
          return null;
        });
      }
      setChoices(choice);
    }
  }


  useEffect(() => {
    setShow(props.show)
    setUpdateValues()
  }, [props.show]);

  const CloseModal = () => {
    resetForm();
    props.onCloseModal();
  }

  const resetForm = () => {
    setCategory("");
    setExportHeading("");
    setQuestion("");
    setChoices([]);
    isCommentRequired(false);
    setQuestionFormErrors({});
    setChoiceError("");
    setChoiceName("")
    setStatus(false);
  }

  const onClickAddChoice = () => {
    let choice = choices;
    let randomID = Math.floor((Math.random() * 100) + 1);
    if (!ChoiceName || ChoiceName === "") {
      setChoiceError("Please enter choice");
    }
    else {
      choice.push({ choice: ChoiceName, status: status === true ? 1 : 0, id: randomID })
      setChoices(choice);
      setChoiceError("");
      setChoiceName("")
      // setStatus(true);
      setStatus(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = setQuestionFormError()
    if (Object.keys(newErrors).length > 0) {
      setQuestionFormErrors({ ...newErrors })
    }
    else {
      let newData = {
        category: category,
        export_heading: export_heading,
        comment_required: comment_required === true ? 1 : 0,
        question: question,
        choices: choices,
      }
      if (props.templateId !== '')
        newData.templateid = props.templateId;
      if (props.questionDetails && props.questionDetails.length !== 0)
        newData.questionId = props.questionDetails.id;

      setQuestionLoading(true);
      QuestionServices.saveQuestion(newData).then((response) => {
        if (response.data.code === 1) {
          props.callBackAddQuestion(response.data.data);
          ShowToast("message", "success", response.data.message)
          CloseModal();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setQuestionLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setQuestionLoading(false);
      });
    }
  }

  const setQuestionFormError = () => {
    // const { project_name, identifier, platform_id, investor_id } = questionFormErrors
    const newErrors = {}
    if (!category || category === '') {
      newErrors.category_error = 'Please select category'
    }
    if (!question || question === '') {
      newErrors.question_error = 'Please enter question';
    }
    if (!export_heading || export_heading === '') {
      newErrors.export_heading_error = 'Please enter export heading';
    }
    if (!choices || choices.length === 0) {
      newErrors.choices_error = 'Please enter choices';
    }
    return newErrors
  }

  const removeChoice = (e) => {
    let choice = choices;
    let choiceArray = choice.filter((item) => item.id !== parseInt(e.target.value));
    setChoices(choiceArray);
  }

  const onChangeCommentRequired = (e) => {
    isCommentRequired(e.target.checked);
    if (!e.target.checked) {
      setStatus(e.target.checked)
    }
  }

  return (
    <>
      <Modal show={show} className="question-modal" onHide={CloseModal}>
        <Modal.Body>
          <span className=""></span>
          <Form className="question-form" noValidate onSubmit={handleSubmit}>
            <div className="popup-container">
              <div className="popup-add-question">
                <div className="popup-form">
                  <h3>{props.questionDetails && props.questionDetails.length !== 0 ? "Edit Question" : "Add Question"}</h3>
                  <div className="popup-field">
                    <span>Category</span>
                    <div className="form-input-block">
                      <Form.Control
                        as="select"
                        name="category"
                        required
                        value={category}
                        isInvalid={!!questionFormErrors.category_error}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">Select Category</option>
                        <option value="1">File</option>
                        <option value="2">Loan</option>
                        <option value="3">Delinquency</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{questionFormErrors.category_error}</Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="popup-field">
                    <span>Excel Export Heading</span>
                    <div className="form-input-block">
                      <Form.Control
                        required
                        name="export_heading"
                        isInvalid={!!questionFormErrors.export_heading_error}
                        onChange={(e) => setExportHeading(e.target.value)}
                        value={export_heading}
                      />
                      <Form.Control.Feedback type="invalid">{questionFormErrors.export_heading_error}</Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="custom-checkbox">
                    <label>
                      <Form.Check
                        as="input"
                        required={false}
                        name="comment_required"
                        id={"comment_required-checkbox"}
                        label={"Exception Comment Required?"}
                        onChange={(e) => onChangeCommentRequired(e)}
                        defaultChecked={comment_required}
                        value={comment_required}
                      />
                      <span></span>
                    </label>
                  </div>
                  <div className="popup-field align-items-start">
                    <span>Question</span>
                    <div className="form-input-block">
                      <Form.Control
                        required
                        as="textarea"
                        name="question"
                        isInvalid={!!questionFormErrors.question_error}
                        onChange={(e) => setQuestion(e.target.value)}
                        value={question}
                      />
                      <Form.Control.Feedback type="invalid">{questionFormErrors.question_error}</Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup-choices">
                <h3 className="inner-step-block mb-2">Choices</h3>
                <div className="added-question-list">
                  {choices && choices.length > 0 && choices.map((item) => {
                    return (
                      <React.Fragment key={"choice" + item.id}>
                        {item.status === 1 &&
                          <div className="choice-content">
                            <div className="added-question q-checked">
                              <span>{item.choice}</span>
                              <span title="Show Comment" className="checked"><img alt="check_Img" src={CheckedImg} /></span>
                            </div>
                            <Button onClick={(e) => removeChoice(e)}
                              value={item.id}>
                              <i className="material-icon">remove</i>
                            </Button>
                          </div>
                        }
                        {item.status === 0 &&
                          <div className="choice-content">
                            <div className="added-question q-unchecked">
                              <span>{item.choice}</span>
                              <span title="Hide Comment" className="unchecked"><img alt="uncheck_img" src={UncheckedImg} /></span>
                            </div>
                            <Button onClick={(e) => removeChoice(e)}
                              value={item.id}>
                              <i className="material-icon">remove</i>
                            </Button>
                          </div>
                        }
                      </React.Fragment>
                    )
                  })
                  }
                  {choices && choices.length === 0 &&
                    <>
                      <Form.Control
                        as="input"
                        type="hidden"
                        name="choices"
                        required
                        isInvalid={!!questionFormErrors.choices_error}
                        value={choices}
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{questionFormErrors.choices_error}</Form.Control.Feedback>
                    </>
                  }
                </div>
              </div>
              <div className="popup-new-question">
                <h3 className="inner-step-block mb-2">New</h3>
                <div className="new-question-form">
                  <div className="form-input-block">
                    <Form.Control
                      required
                      name="choice_name"
                      isInvalid={!!choice_error}
                      placeholder="Choice"
                      value={ChoiceName}
                      onChange={(e) => setChoiceName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">{choice_error}</Form.Control.Feedback>
                  </div>
                  {comment_required &&
                    <div className="custom-checkbox">
                      <label data-tooltip="Is Required">
                        <Form.Check
                          as="input"
                          name="choice_status"
                          checked={status}
                          id={"choice-status-checkbox"}
                          label=""
                          // title="Is Comment"
                          onChange={(e) => setStatus(e.target.checked)}
                        />
                        <span></span>
                      </label>
                    </div>
                  }
                  <Button onClick={onClickAddChoice} >+</Button>
                </div>
              </div>
              <div className="setp-btn-row">
                {questionLoading === false &&
                  <Button type="submit" variant="secondary" className="btn btn-blue btn-nex">Ok</Button>
                }
                {questionLoading === true &&
                  <Button variant="secondary" className="btn btn-blue btn-nex">
                    <div className="text-center">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  </Button>
                }
                <Button className="btn btn-blue  btn-pre" onClick={CloseModal}>Cancel</Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default AddQuestionModal;