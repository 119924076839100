import { Component } from "react"
import { ShowToast } from "../../common/CommonController"
import { addUser } from "../Redux/Actions/CommonAction"
import ProfileServices from "../Services/ProfileServices"

class profileApp extends Component {
  getProfile = (id) => {
    ProfileServices.getProfile(id).then((response) => {
      if (response.data.code === 1) {
        this.props.onAddUser(response.data.data)
        this.setState({ profileData: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  SendCronJob = () => {
    let data = {};
    this.setState({ MailLoading: true })
    ProfileServices.SendCronJob(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
      this.setState({ MailLoading: false })
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false, MailLoading: false });
    });
  }

  updateProfile = (data) => {
    this.setState({ loading: true })
    ProfileServices.updateProfile(data).then((response) => {
      if (response.data.code === 1) {
        this.props.onAddUser(response.data.data)
        this.onSuccess(response.data)
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  static mapDispatchToProps = (dispatch) => {
    return {
      onAddUser: (data) => dispatch(addUser(data))
    }
  }

  static mapStateToProps = (state) => {

    return {
      profileData: state.common.db.user,
    }
  }
}
export default profileApp