import React, { useEffect, useState } from "react";
import { ShowToast } from "../common/CommonController";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetProjectRecords } from "../Shared/Controller/ProjectRecordApp";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const ProjectRecords = () => {

  const [projectRecordList, setProjectRecordList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let params = useParams();

  useEffect(() => {
    if (params.projectid) {
      getProjectRecord(params.projectid);
    }
  }, []);

  const getProjectRecord = (projectId = "") => {
    setLoading(true);
    let data = {
      project_id: projectId,
      no_of_rows: 2000,
    }
    GetProjectRecords(data).then((response) => {
      if (response.data.code === 1) {
        setProjectRecordList(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const checkRowStatusAll = () => {
    let isOmit = false;
    for (let i = 0; i < projectRecordList.length; i++) {
      if (projectRecordList[i].project_status !== 1) {
        isOmit = true;
      }
      else if (projectRecordList[i].project_status === 1) {
        isOmit = false;
        break;
      }
      if (projectRecordList[i].task_status !== 2) {
        isOmit = true;
      }
      else {
        isOmit = false
        break
      }
      if (projectRecordList[i].integrity_status !== 2) {
        isOmit = true;
      }
      else {
        isOmit = false
        break
      }
    }
    return isOmit;
  }

  const getTableColumns = () => {
    let table_columns = [];
    if (projectRecordList.every((item) => item.only_integrity === 0 && item.only_review === 0)) {
      table_columns = [
        {
          name: "Account Reference",
          wrap: true,
          width: "10%",
          selector: row => row.row_details.Account_Reference,
          sortable: true,
        },
        {
          name: "Integrity Status",
          width: "11%",
          selector: row => getStatusText(row.integrity_status),
          sortFunction: shortIntegrityStatus,
          sortable: true,
        },
        {
          name: "Integrity Owner By",
          wrap: true,
          width: "12%",
          selector: row => row.integrity_record_owner,
          sortable: true,
        },
        {
          name: "Integrity Modified By",
          wrap: true,
          width: "13%",
          selector: row => row.last_integrity_done_by,
          sortable: true,
        },
        {
          name: "Review Status",
          width: "11%",
          selector: row => getStatusText(row.task_status),
          sortFunction: shortReviewStatus,
          // omit: projectRecordList && projectRecordList.every((item) => item.only_integrity === 1),
          sortable: true,
        },
        {
          name: "Review Owner By",
          wrap: true,
          width: "12%",
          selector: row => row.review_record_owner,
          sortable: true,
        },
        {
          name: "Review Modified By",
          width: "13%",
          wrap: true,
          selector: row => row.last_review_done_by,
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "30%",
          omit: checkRowStatusAll(),
          cell: d => { return getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    else if (projectRecordList.every((item) => item.only_review === 1)) {
      table_columns = [
        {
          name: "Account Reference",
          wrap: true,
          selector: row => row.row_details.Account_Reference,
          sortable: true,
        },
        {
          name: "Review Status",
          selector: row => getStatusText(row.task_status),
          sortFunction: shortReviewStatus,
          sortable: true,
        },
        {
          name: "Review Owner By",
          wrap: true,
          selector: row => row.review_record_owner,
          sortable: true,
        },
        {
          name: "Review Modified By",
          wrap: true,
          selector: row => row.last_review_done_by,
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "20%",
          omit: checkRowStatusAll(),
          cell: d => { return getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    else {
      table_columns = [
        {
          name: "Account Reference",
          selector: row => row.row_details.Account_Reference,
          sortable: true,
        },
        {
          name: "Integrity Status",
          selector: row => getStatusText(row.integrity_status),
          sortFunction: shortIntegrityStatus,
          sortable: true,
        },
        {
          name: "Integrity Owner By",
          selector: row => row.integrity_record_owner,
          sortable: true,
        },
        {
          name: "Integrity Modified By",
          selector: row => row.last_integrity_done_by,
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "20%",
          omit: checkRowStatusAll(),
          cell: d => { return getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    return table_columns;
  }

  const getColumnData = (data) => {
    if (data.type === "action") {
      return (
        <React.Fragment>
          <div className="edit-icon text-center">
            {data.item.project_status !== 2 &&
              <>
                {data.item.only_review !== 1 &&
                  <Link to={{ pathname: "/integrity-project/" + params.projectid, hash: "#" + data.item.id }}
                    className={"ms-2 edt-btn orange"}
                  >
                    Integrity
                  </Link>
                }
                {data.item.only_integrity !== 1 &&
                  <Link params={{ row_id: data.item.id }}
                    to={{ pathname: "/review-project/" + params.projectid, hash: "#" + data.item.id }}
                    className={"ms-2 edt-btn purple"}
                  >
                    Review
                  </Link>
                }
              </>
            }
            {data.item.only_review !== 1 && data.item.integrity_status === 2 && data.item.project_status === 2 &&
              <Link params={{ row_id: data.item.id }}
                to={{ pathname: "/integrity-view/" + params.projectid, hash: "#" + data.item.id }}
                className={"ms-2 edt-btn orange"}
              >
                View Integrity
              </Link>
            }
            {data.item.only_integrity !== 1 && data.item.task_status === 2 && data.item.project_status === 2 &&
              <Link params={{ row_id: data.item.id }}
                to={{ pathname: "/review-view/" + params.projectid, hash: "#" + data.item.id }}
                className={"ms-2 edt-btn purple"}
              >
                View Review
              </Link>
            }
          </div>
        </React.Fragment>
      )
    }
  }

  const getStatusText = (value) => {
    if (value === 0) {
      return "Pending";
    }
    if (value === 1) {
      return "In Progress";
    }
    if (value === 2) {
      return "Completed";
    }
    if (value === 3) {
      return "Incomplete";
    }
  }

  const shortIntegrityStatus = (rowA, rowB) => {
    const a = getStatusText(rowA.integrity_status);
    const b = getStatusText(rowB.integrity_status);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const shortReviewStatus = (rowA, rowB) => {
    const a = getStatusText(rowA.task_status);
    const b = getStatusText(rowB.task_status);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  const data = projectRecordList;
  const columns = getTableColumns();

  const tableData = {
    columns,
    data
  };

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end">
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
          <div className="card-main p-0">
            <div className="dark-table-wrapper table-scroll proj_table">
              {loading === false &&
                <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                  <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={true}
                    pagination
                    paginationRowsPerPageOptions={[10, 20, 30, 100, 500]}
                    paginationPerPage={500}
                    highlightOnHover
                  />
                </DataTableExtensions>
              }
              {loading === true &&
                <table className="mb-0 table">
                  <tbody>
                    <tr>
                      <td colSpan={10} className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ProjectRecords;