import React from "react";

const NotFound = (props) => {
  const NotFoundDataTable = () => {
    return (
      <>
        <tr>
          <td colSpan={10}>
            {props.message}
          </td>
        </tr>
      </>
    )
  }

  const NotFoundinContent = () => {
    return (
      <>
          <h2 className="text-center p-5">{props.message}</h2>
        {/* <div className="card-body no-data-found">
          <div> */}
            {/* <img src={ImgConstants.NoDataImg} alt={props.message} title={props.message} /> */}
          {/* </div>
        </div> */}
      </>
    )
  }

  return (
    <React.Fragment>
      {props.type === "table" &&
        NotFoundDataTable()
      }
      {props.type === "content" &&
        NotFoundinContent()
      }
    </React.Fragment>
  )
}

export default NotFound;