import axios from "axios"
import { AppServices, HttpHeaders } from "../Services/AppServices";

const ProjectServices = {
  getProjects: (data = {}) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_PROJECTS(), {
      params: data,
      headers: header.headers,
    })
  },
  getArchive: (data = {}) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_ARCHIVE(), {
      params: data,
      headers: header.headers,
    })
  },

  getFileColumn: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_FILECOLUMNS(), {
      params: data,
      headers: header.headers,
    })
  },
  saveHeaderColumns: (data) => {
    return axios.post(AppServices.SAVE_HEADER_COLUMNS(), data, HttpHeaders())
  },
  importFile: (data) => {
    return axios.post(AppServices.IMPORT_FILE(), data, HttpHeaders())
  },
  saveProject: (data) => {
    return axios.post(AppServices.SAVE_PROJECT(), data, HttpHeaders())
  },
  saveQuestions: (data) => {
    return axios.post(AppServices.SAVE_QUESTIONS(), data, HttpHeaders())
  },
  deleteProject: (data) => {
    return axios.post(AppServices.DELETE_PROJECT(data.projectId), data, HttpHeaders())
  },
  saveProjectUsers: (data) => {
    return axios.post(AppServices.SAVE_PROJECT_USERS(), data, HttpHeaders())
  },
  getProjectDetail: (project_id = {}) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_PROJECT_DETAIL(project_id), {
      params: {},
      headers: header.headers,
    })
  },
  getFileProgress: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_FILE_PROGRESS(), {
      params: data,
      headers: header.headers,
    })
  },
  exportData: (data = {}) => {
    return axios.post(AppServices.GET_EXPORT_DATA(), data, HttpHeaders())
  },
  GetProjectRecords: (data) => {
    return axios.post(AppServices.GET_PROJECT_RECORD(), data, HttpHeaders())
  },
  restoreProject: (data) => {
      return axios.post(AppServices.RESTORE_PROJECT(data.projectid), data, HttpHeaders())
  },
}

export default ProjectServices;
