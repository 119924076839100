import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const DownloadLogList = (props) => {
  return (
    <React.Fragment>
      {props.downloadLogList.length !== 0 && props.loading === false &&
        <DataTableExtensions {...props.tableData} print={false} export={false} filterPlaceholder="Search">
          <DataTable
            noHeader
            defaultSortField="downloaded_byuser"
            defaultSortAsc={true}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      }
    </React.Fragment>
  )
}
export default DownloadLogList;