import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import templateApp from "../Shared/Controller/templateApp";
import * as Constants from "../partials/CommonConstant";

class ViewTemplate extends templateApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }

  }

  componentDidMount = () => {
    let splitPath = window.location.pathname.split("/")
    let templateId = "";
    if (splitPath.length > 2) {
      templateId = splitPath[2];
    }
    if (templateId) {
      this.getTemplate(templateId);
    }
  }


  render() {
    const fieldLayout = {
      sm: "12",
      md: "12",
      lg: "6",
      xl: "6",
    }
    return (
      <React.Fragment>
        <div className="dashboard-card-wrapper">
          <div className="row align-items-center">
            <div className="col-12 text-end">
              <Link to={Constants.templates} className="btn btn-blue btn-nex">Back</Link>
            </div>
          </div>
          <div className="profile-block mt-3">
            <div className="card-main">
              <div className="profile-text-inner">
                {this.state.loading === false &&
                  <>
                    <Row>
                      <Col {...fieldLayout}>
                        <div className="profile-card">
                          <div className="profile-label">
                            <label>Template Name</label>
                          </div>
                          <div className="profile-value">
                            <p>{this.state.name}</p>
                          </div>
                        </div>
                      </Col>
                      {this.state.projectdetails &&
                        <Col {...fieldLayout}>
                          <div className="profile-card">
                            <div className="profile-label">
                              <label>Project Name</label>
                            </div>
                            <div className="profile-value">
                              <p>{this.state.projectdetails.project_name}</p>
                            </div>
                          </div>
                        </Col>
                      }
                    </Row>
                    <div>
                      <div className="card-header mt-5"><h5>Questions</h5></div>
                    </div>
                  </>

                }



                {this.state.loading &&
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}
export default ViewTemplate;