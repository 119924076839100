import React from "react";
import ViewProfile from "../profile/ViewProfile";
import profileApp from "../Shared/Controller/profileApp";
import * as Constants from "../partials/CommonConstant";
import { EditIcon } from "../common/ImageConstants";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

class Profile extends profileApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      MailLoading: false,
      profileData: {},
    }
  }

  componentDidMount = () => {
    if (!this.props.profileData) {
      this.setState({ loading: true })
    }
    this.setState({ profileData: this.props.profileData })
    if (this.props.profileData) {
      this.getProfile(this.props.profileData.id);
    }
  }

  sendCronJob = () => {
    this.SendCronJob();
  }


  render() {
    return (
      <React.Fragment>
        <div className="dashboard-card-wrapper">
          <div className="row align-items-center">
            <div className="col-12 text-end">
              {/* {this.props.profileData.role === 1 &&
                <>
                  {this.state.MailLoading &&
                    <Link to={"#"} className="btn btn-blue btn-nex me-2">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Link>
                  }
                  {!this.state.MailLoading &&
                    <Link to={"#"} onClick={() => this.sendCronJob()} className="btn btn-blue btn-nex me-2">
                      Send Confirm File Delete Mail
                    </Link>
                  }
                </>
              } */}
              <Link to={Constants.editProfile} className="btn btn-blue btn-nex me-2"><img src={EditIcon} />Edit</Link>
              {this.props.profileData.role === 1 &&
                <React.Fragment>
                  <Link to={Constants.siteLogs} className="btn btn-blue btn-nex me-2">Site Logs</Link>
                  <Link to={Constants.sessionLogs} className="btn btn-blue btn-nex">Session Logs</Link>
                </React.Fragment>
              }
            </div>
          </div>
          <div className="profile-block mt-3">
            <ViewProfile parentState={this.state} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default connect(profileApp.mapStateToProps, profileApp.mapDispatchToProps)(Profile);