import axios from "axios"
import { AppServices, HttpHeaders } from "./AppServices";

const QuestionServices = {
    getQuestions: (data = {}) => {
        const header = HttpHeaders();
        return axios.get(AppServices.GET_QUESTIONS(), {
            params: data,
            headers: header.headers,
        })
    },
    saveQuestion: (data) => {
        if (data.questionId) {
            return axios.post(AppServices.UPDATE_QUESTION(data.questionId), data, HttpHeaders())
        }
        else {
            return axios.post(AppServices.POST_SAVE_QUESTION(), data, HttpHeaders());
        }
    },
    deleteQuestion: (data) => {
        return axios.post(AppServices.DELETE_QUESTION(data.questionId), data, HttpHeaders())
    },
    getQuestion: (questionId) => {
        return axios.get(AppServices.GET_QUESTION(questionId), HttpHeaders())
    },
    restoreQuestion: (data) => {
        return axios.post(AppServices.RESTORE_QUESTION(data.questionId), data, HttpHeaders())
    },

}
export default QuestionServices;
