import React from "react";
import { Row, Table } from "react-bootstrap";
import ProjectAssignUser from "./ProjectAssignUser";
import ProjectColsDetails from "./ProjectColsDetails";

const ProjectReviewOverview = (props) => {

  const ShowChoices = (choices) => {
    let choiceStr = "";
    choices && choices.map((c) => {
      choiceStr += c.choice + ", ";
      return null;
    })
    return choiceStr.substring(0, choiceStr.length - 2);
  }

  return (
    <React.Fragment>
      {props.projectDetail.status !== 3 &&
        <>
          <Row>
            <ProjectColsDetails Col_Type="template" Col_Lable="Template" projectDetail={props.projectDetail} />
            <ProjectColsDetails Col_Type="total_task" Col_Lable="Total Task" projectDetail={props.projectDetail} />
            <ProjectColsDetails Col_Type="review_completed" Col_Lable="Review Completed"
              Col_Value={props.projectDetail.completed_rows} />
            <ProjectColsDetails Col_Type="review_completed_by_you" Col_Lable="Review Completed By You"
              Col_Value={props.projectDetail.completed_by_currentuser} />
          </Row>
        </>
      }
      {props.projectDetail.relatedquestions.length !== 0 &&
        <Row>
          <h5 className="mt-2">Questions</h5>
          <div>
            <Table className="tbl_view_project" size="sm">
              <thead className="rdt_TableHead">
                <tr className="rdt_TableHeadRow">
                  <th style={{ width: "33%" }} className="rdt_TableCol_Sortable">Category</th>
                  <th style={{ width: "33%" }} className="rdt_TableCol_Sortable">Question</th>
                  <th style={{ width: "33%", paddingLeft: "18px" }} className="rdt_TableCol_Sortable">Choices</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3} style={{ padding: "0px" }}>
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <table style={{ width: "100%" }}>
                        {props.projectDetail.relatedquestions.map((item) => {
                          return (
                            <tr>
                              <td style={{ width: "33%" }}>
                                {item.category === 1 &&
                                  "File"
                                }
                                {item.category === 2 &&
                                  "Loan"
                                }{item.category === 3 &&
                                  "Delinquency"
                                }
                              </td>
                              <td style={{ width: "33%", wordBreak: "break-all" }}>{item.question}</td>
                              <td style={{ width: "33%" }}>{ShowChoices(item.choices)}</td>
                            </tr>
                          )
                        })
                        }
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Row>
      }
      {props.projectDetail.status === 3 &&
        <>
          <span>No review data found</span>
        </>
      }
      {props.projectDetail.assignedusers.length !== 0 &&
        <ProjectAssignUser projectDetail={props.projectDetail} showType="review" />
      }
    </React.Fragment>
  )
}
export default ProjectReviewOverview;