import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { ValidateCharacter } from "../common/CommonController";
import ConfirmModal from "../common/ConfirmModal";
import projectApp from "../Shared/Controller/projectApp";
import randomWords from "random-words";

class Portfolio extends projectApp {

  constructor(props) {
    super(props)
    this.state = {
      projectfile: {},
      investorValidated: false,
      platformValidated: false,
      projectValidate: false,
      investorLoading: false,
      platformLoading: false,
      addInvestorloading: false,
      addPlatformloading: false,
      projectloading: false,
      investorList: [],
      platformList: [],
      fileid: "",
      mark_complete: false,
      file_completeness: false,
      showConfirmModal: false,
      isProjectNameChange: false,
      pll_flag: false
    }
  }

  componentDidMount = () => {
    this.getInvestors()
    this.getPlatforms();
    // console.log(randomWords({ exactly: 1, wordsPerString: 2 })[0]);
    if (this.props.currentStep === 2 && this.props.projectDetail.last_completed_step >= 2) {
      this.setPortFolioDetail();
    }
    else {
      this.setState({
        fileid: this.props.fileid,
        // identifier: randomWords({ exactly: 1, wordsPerString: 2 })[0]
        identifier: Math.floor(Math.random() * 90000) + 10000
      })
    }
    /* else if (this.props.isStep !== 2 && this.props.projectDetail.last_completed_step >= 2) {
      this.props.callBackPortFolioSuccess({ id: this.props.projectDetail.id });
      this.setPortFolioDetail();
    } */
  }

  setPortFolioDetail = () => {
    let projectData = this.props.projectDetail;
    let mark_complete = projectData.status === 2 ? true : false;
    this.setState({
      fileid: projectData.projectfile.id,
      platform_id: projectData.platform_id,
      investor_id: projectData.investor_id,
      project_name: projectData.project_name,
      mark_complete: mark_complete,
      file_completeness: projectData.file_completeness === 1 ? true : false,
      identifier: projectData.identifier,
      only_integrity: projectData.only_integrity !== 0 ? projectData.only_integrity.toString() : projectData.only_review !== 0 ? "2" : "0",
      pll_flag: projectData?.pll_flag === 1 ? true : false
    })
  }

  handleInvestorSubmit = (e) => {
    e.preventDefault();
    const newErrors = this.investorFormErrors()
    if (Object.keys(newErrors).length > 0) {
      this.setState({ ...newErrors })
    }
    else {
      let newData = { name: this.state.investor_name }
      newData.status = 1;
      this.saveInvestor(newData);
    }
  }

  handlePlatformSubmit = (e) => {
    e.preventDefault();
    const newErrors = this.platForm_FormErrors()
    if (Object.keys(newErrors).length > 0) {
      this.setState({ ...newErrors })
    }
    else {
      let newData = { name: this.state.platform_name }
      newData.status = 1;
      this.savePlatform(newData);
    }
  }

  handleProjectSubmit = (e) => {
    e.preventDefault();
    const newErrors = this.projectFormErrors()
    if (Object.keys(newErrors).length > 0) {
      this.setState({ ...newErrors })
    }
    else {
      if (this.state.isProjectNameChange) {
        this.setState({ showConfirmModal: true });
      }
      else {
        this.saveProject()
      }
    }
  }

  setMarkCompelete = (checked) => {
    this.setState({ mark_complete: checked });
  }

  setFileCompleteness = (checked) => {
    this.setState({ file_completeness: checked });
  }

  setPllFlag = (checked) => {
    this.setState({ pll_flag: checked });
  }

  onCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  }

  onConfirmYesModal = () => {
    this.setState({ showConfirmModal: false });
    this.saveProject()
  }

  checkProjectType = () => {
    let projectType = "";
    if (this.state.only_integrity === "0") {
      projectType = "0"
    }
    else if (this.state.only_integrity === "1") {
      projectType = "1";
    }
    else if (this.state.only_integrity === "2") {
      projectType = "2";
    }
    return projectType;
  }

  render() {
    return (
      <React.Fragment>
        <div className="step-list-wrap">
          <ConfirmModal show={this.state.showConfirmModal} type="permanentDelete"
            customMessage={"Please confirm the project name is correct?"}
            onCloseModal={() => this.onCloseConfirmModal()}
            confirmClick={() => this.onConfirmYesModal()} />
          <h2 className="step-title">Portfolio</h2>
          <Form noValidate
            // validated={this.state.projectValidate}
            name="project_form" onSubmit={this.handleProjectSubmit}>
            <div className="step-content">
              <div className="row grid-row-gap-30">
                <div className="col-md-6">
                  <div className="investor-box">
                    <h3 className="inner-step-block">Investor</h3>
                    <div className="add-project">
                      <Form noValidate>
                        <Form.Label>New</Form.Label>
                        <div className="form-input-block">
                          <Form.Control
                            as="input"
                            required
                            name="investor_name"
                            isInvalid={!!this.state.investor_name_error}
                            // value={name}
                            onChange={this.onChangeInvestor}
                          />
                          <Form.Control.Feedback type="invalid">{this.state.investor_name_error}</Form.Control.Feedback>
                        </div>
                        {this.state.addInvestorloading === false &&
                          <Button onClick={this.handleInvestorSubmit} value="+">
                            +
                          </Button>
                        }
                        {this.state.addInvestorloading === true &&
                          <Button value="+">
                            <div className="text-center">
                              <Spinner animation="border" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </Button>
                        }
                      </Form>
                    </div>
                    <div className="project-list-box">
                      {this.state.investorList.length !== 0 &&
                        <span>Or Select</span>
                      }
                      <ul className="project-list">
                        {this.state.investorLoading === false && this.state.investorList && this.state.investorList.map((Iitem, i) => {
                          return (
                            // <React.Fragment key={Iitem + "_" + i}>
                            //   <li>{Iitem.name}</li>
                            // </React.Fragment>
                            <React.Fragment key={Iitem + "_" + i}>
                              <Form.Control
                                as="button"
                                type="button"
                                required
                                name="investor_id"
                                className={Iitem.id === parseInt(this.state.investor_id) ? "active" : ""}
                                isInvalid={!!this.state.investor_id_error}
                                value={Iitem.id}
                                onClick={(e) => this.onChangeName(e)}
                              >
                                {Iitem.name}
                              </Form.Control>
                            </React.Fragment>
                          )
                        })
                        }
                        {this.state.investorLoading === false && this.state.investorList.length === 0 &&
                          <>
                            <div className="text-center me-4 mt-2">
                              <span>No Investor Found</span>
                            </div>
                          </>
                        }
                        {this.state.investorLoading === true &&
                          <>
                            <div className="text-center">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </>
                        }
                      </ul>
                      <Form.Control.Feedback className="invalid_list_msg" type="invalid">{this.state.investor_id_error}</Form.Control.Feedback>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="investor-box">
                    <h3 className="inner-step-block">Platform</h3>
                    <div className="add-project">
                      <Form noValidate>
                        <Form.Label>New</Form.Label>
                        <div className="form-input-block">
                          <Form.Control
                            as="input"
                            required
                            name="platform_name"
                            isInvalid={!!this.state.platform_name_error}
                            // value={name}
                            onChange={this.onChangePlatform}
                          />
                          <Form.Control.Feedback type="invalid">{this.state.platform_name_error}</Form.Control.Feedback>
                        </div>
                        {this.state.addPlatformloading === false &&
                          <Button onClick={this.handlePlatformSubmit} value="+">
                            +
                          </Button>
                        }
                        {this.state.addPlatformloading === true &&
                          <Button value="+">
                            <div className="text-center">
                              <Spinner animation="border" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </Button>
                        }
                      </Form>
                    </div>
                    <div className="project-list-box">
                      {this.state.platformList.length !== 0 &&
                        <span>Or Select</span>
                      }
                      <ul className="project-list">
                        {this.state.platformLoading === false && this.state.platformList && this.state.platformList.map((PItem, i) => {
                          return (
                            <React.Fragment key={PItem + "_" + i}>
                              <Form.Control
                                as="button"
                                type="button"
                                required
                                name="platform_id"
                                className={PItem.id === parseInt(this.state.platform_id) ? "active" : ""}
                                isInvalid={!!this.state.platform_id_error}
                                value={PItem.id}
                                onClick={(e) => this.onChangeName(e)}
                              >
                                {PItem.name}
                              </Form.Control>
                            </React.Fragment>
                          )
                        })
                        }
                        {this.state.investorLoading === false && this.state.platformList.length === 0 &&
                          <>
                            <div className="text-center me-4 mt-2">
                              <span>No Platform Found</span>
                            </div>
                          </>
                        }
                        {this.state.platformLoading === true &&
                          <>
                            <div className="text-center">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </>
                        }
                      </ul>
                      <Form.Control.Feedback className={!!this.state.platform_id_error ? "invalid_list_msg" : ""} type="invalid">{this.state.platform_id_error}</Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12">
                  <h3 className="inner-step-block">Project</h3>
                </div>
              </div>
              <div className="row grid-row-gap-30">
                <div className="col-md-6">
                  <div className="investor-box">
                    <div className="input-full">
                      <Form.Group className="add-project" controlId="project_name">
                        <Form.Label>Name</Form.Label>
                        <div className="form-input-block">
                          <Form.Control
                            as="input"
                            required
                            name="project_name"
                            isInvalid={!!this.state.project_name_error}
                            value={this.state.project_name}
                            onChange={(e) => this.onChangeName(e)}
                            onKeyPress={(e) => {
                              if (!ValidateCharacter({ key: e.key, type: "special_character" })) {  // Restrict to enter special character.
                                e.preventDefault()
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">{this.state.project_name_error}</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </div>
                    {this.props.project_id !== "" && this.props.projectDetail.status !== 3 &&
                      <div className="custom-checkbox">
                        <label>
                          <Form.Check
                            as="input"
                            required={false}
                            name="mark_complete"
                            id={"mark_complete"}
                            label={"Mark As Complete"}
                            onChange={(e) => this.setMarkCompelete(e.target.checked)}
                            value={this.state.mark_complete}
                            checked={this.state.mark_complete}
                          />
                          <span></span>
                        </label>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="investor-box">
                    <div className="input-full">
                      <Form.Group className="add-project" controlId="identifier">
                        <Form.Label>Identifier</Form.Label>
                        <div className="form-input-block">
                          <Form.Control
                            as="input"
                            required
                            name="identifier"
                            isInvalid={!!this.state.identifier_error}
                            value={this.state.identifier}
                            onChange={(e) => this.onChangeName(e)}
                          />
                          <Form.Control.Feedback type="invalid">{this.state.identifier_error}</Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="row">
                    <div class="col">
                      {this.props.project_id !== "" &&
                        <div className="custom-checkbox">
                          <label>
                            <Form.Check
                              as="input"
                              required
                              name="file_completeness"
                              id="file_completeness"
                              label={"File Completeness"}
                              onChange={(e) => this.setFileCompleteness(e.target.checked)}
                              value={this.state.file_completeness}
                              checked={this.state.file_completeness}
                            />
                            <span></span>
                          </label>
                        </div>
                      }
                    </div>
                    <div class="col">
                      {this.props.project_id !== "" &&
                        <div className="custom-checkbox">
                          <label>
                            <Form.Check
                              as="input"
                              required
                              name="pll_flag"
                              id="pll_flag"
                              label={"PII Flag"}
                              onChange={(e) => this.setPllFlag(e.target.checked)}
                              value={this.state.pll_flag}
                              checked={this.state.pll_flag}
                            />
                            <span></span>
                          </label>
                        </div>
                      }
                      </div>
                    </div>
                    {/* <div className="custom-checkbox">
                                        <label>
                                            <input type="checkbox" />
                                            <span>Delete Portfolio</span>
                                        </label>
                                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row grid-row-gap-30 data-integrity mt-3">
                <div className="col-md-6">
                  <div className="input-full">
                    <Form.Group className="add-project" controlId="identifier">
                      <Form.Label className="lbl-project-type">Type</Form.Label>
                      <div className="form-input-block">
                        <Form.Control
                          as="select"
                          required
                          name="only_integrity"
                          isInvalid={!!this.state.only_integrity_error}
                          value={this.checkProjectType()}
                          disabled={this.props.projectDetail.only_integrity === undefined ? false : true}
                          onChange={(e) => this.onChangeName(e)}
                        >
                          <option value="">Select Project Type</option>
                          <option value="0">Integrity + Review</option>
                          <option value="1">Only Integrity</option>
                          <option value="2">Only Review</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{this.state.only_integrity_error}</Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                {this.props.project_id === "" &&
                  <div className="col-md-6 btn_set">
                    <div className="custom-checkbox">
                      <div className="form-input-block">
                              <label>
                                <Form.Check
                                  as="input"
                                  required
                                  name="pll_flag"
                                  id="pll_flag"
                                  label={"PII Flag"}
                                  onChange={(e) => this.setPllFlag(e.target.checked)}
                                  value={this.state.pll_flag}
                                  checked={this.state.pll_flag}
                                />
                                <span></span>
                              </label>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="setp-btn-row">
                {/* <button type="button" className="btn btn-blue btn-pre">Previous</button> */}
                {this.state.projectloading === false &&
                  <Button className="btn btn-blue btn-nex" variant="secondary" type="submit">
                    Next
                  </Button>
                }
                {this.state.projectloading === true &&
                  <Button className="btn btn-blue btn-nex" variant="secondary">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Button>
                }
              </div>
              <div className="clear"></div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    )
  }
}
export default Portfolio;