import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import { downloadFile, GetFolderDetail } from "../Shared/Controller/filesApp";
import DocumentDataTable from "./DocumentDataTable";


const ViewFolder = () => {
  const [loading, setLoading] = useState(false);
  const [folderDetail, setFolderDetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getFolderDetail();
    // getShortCut();
  }, [window.location.pathname])

  const getFolderDetail = () => {
    let splitPath = window.location.pathname.split("/")
    let folderid = "";
    if (splitPath.length > 2) {
      folderid = splitPath[2];
    }
    if (folderid) {
      setLoading(true)
      let data = {
        folderid: folderid,
      }
      GetFolderDetail(data).then((response) => {
        if (response.data.code === 1) {
          setFolderDetails(response.data.data);
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
      })
    }
  }

  const onClickDeleteFile = (e) => {
  }

  const callBackDeleteDoc = () => {
    getFolderDetail();
  }

  const fieldLayout = {
    sm: "12",
    md: "12",
    lg: "6",
    xl: "6",
  }

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end btn-block">
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        <div className="profile-block mt-3">
          <div className="card-main">
            <div className="profile-text-inner">
              {loading === false &&
                <>
                  <Row>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Folder Name</label>
                        </div>
                        <div className="profile-value">
                          <p>{folderDetail.foldername}</p>
                        </div>
                      </div>
                    </Col>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Owner</label>
                        </div>
                        <div className="profile-value">
                          <p>{folderDetail.owner_name}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {Object.keys(folderDetail).length !== 0 && folderDetail?.shared_with?.length !== 0 &&
                    <Row>
                      <h5 className="mt-2">Shared Users</h5>
                      <div>
                        <Table className="tbl_view_project" size="sm">
                          <thead className="rdt_TableHead">
                            <tr className="rdt_TableHeadRow">
                              <th className="rdt_TableCol_Sortable">Name</th>
                              <th className="rdt_TableCol_Sortable">Email</th>
                              <th className="rdt_TableCol_Sortable">Username</th>
                            </tr>
                          </thead>
                          <tbody>
                            {folderDetail.shared_with.map((item) => {
                              return (
                                <React.Fragment key={"user" + item.id}>
                                  <tr>
                                    <td>
                                      {item.name}
                                    </td>
                                    <td>
                                      {item.email}
                                    </td>
                                    <td>
                                      {item.username}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )
                            })
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  }
                  {Object.keys(folderDetail).length !== 0 && folderDetail?.files_and_folders?.length !== 0 &&
                    <Row>
                      <h5 className="mt-2">Folder & Files</h5>
                      <div>
                        <div className="dark-table-wrapper table-scroll mt-3">
                          <DocumentDataTable
                            downloadFile={downloadFile}
                            sharedOnly={0}
                            type="addfolder"
                            mineOnly={0}
                            data={folderDetail?.files_and_folders}
                            onClickDeleteFile={onClickDeleteFile}
                            navigate={navigate}
                            callBackDeleteDoc={callBackDeleteDoc}
                          />
                        </div>
                      </div>
                    </Row>
                  }
                </>
              }
              {loading &&
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ViewFolder;