import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetUser, ShowToast } from "../common/CommonController";
import { GetAllUsers, GetFileDetail, GetParentFiles, SaveFile, UpdateFile } from "../Shared/Controller/filesApp";
import AddEditFileForm from "./AddEditFileForm";

const AddEditFile = (props) => {
  const location = useLocation();
  const navigateTo = useNavigate();
  let params = useParams();
  const userDetail = GetUser();
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [parentFiles, setParentFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [fileDetail, setFileDetails] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    if (splitPath.find((item) => item === "edit-file") === "edit-file") {
      if (params.fileid) {
        setFileId(params.fileid);
        setIsEdit(true);
      }
    }
    else if (params.folderid && splitPath.find((item) => item === "add-folder-file") === "add-folder-file") {
      setFileDetails({
        folder_id: params.folderid,
        parent_fileid: "0",
        shared_with: [],
        note: "",
      })
    }
    else if (params.fileid) {
      setFileDetails({
        parent_fileid: params.fileid,
        shared_with: [],
        note: "",
      })
    }
    setLoading(true);
    getParentFiles();
    getAllUser();
  }, []);

  /* useEffect(() => {
    if (fileId !== "") {
      getFileDetail();
    }
  }, [fileId]) */

  const getParentFiles = () => {
    let data = {
      // parent_only: 1,
    }
    GetParentFiles(data).then((response) => {
      if (response.data.code === 1) {
        if (typeof (response.data.data) === "object" && Object.keys(response.data.data).length > 0) {
          setParentFiles(response.data.data.general);
        }
        else {
          setParentFiles([]);
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        setLoading(false);
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }

  const getAllUser = () => {
    let data = {
      status: 1,
      exclude_userid: userDetail.id,
    }
    GetAllUsers(data).then((response) => {
      if (response.data.code === 1) {
        setUsers(response.data.data);
        let splitPath = location.pathname.split("/")
        if (params.fileid && splitPath.find((item) => item === "edit-file") === "edit-file") {
          getFileDetail();
        }
        if (isEdit === false) {
          setLoading(false);
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        setLoading(false);
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }

  const getFileDetail = () => {
    if (params.fileid) {
      GetFileDetail(params.fileid).then((response) => {
        if (response.data.code === 1) {
          setFileDetails(response.data.data);
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
      })
    }
  }

  const saveFile = (data) => {
    setSaveLoading(true);
    SaveFile(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        // redirectToList();
        navigateTo(-1);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setSaveLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      setSaveLoading(false);
    });
  }

  const updateFile = (data) => {
    setSaveLoading(true);
    UpdateFile(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        // redirectToList();
        navigateTo(-1);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setSaveLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      setSaveLoading(false);
    });
  }

  const redirectToList = () => {
    navigateTo("/files");
  }

  return (
    <div className="dashboard-card-wrapper">
      <div className="row">
        {loading === false &&
          <AddEditFileForm
            fileId={fileId}
            folderid={params.folderid}
            parentFiles={parentFiles}
            fileDetail={fileDetail}
            saveFile={saveFile}
            updateFile={updateFile}
            users={users}
            isEdit={isEdit}
            saveLoading={saveLoading}
          />
        }
        {loading === true &&
          <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
            <div className="card-main">
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default AddEditFile;