import React from "react";
import { Row } from "react-bootstrap";
import ProjectAssignUser from "./ProjectAssignUser";
import ProjectColsDetails from "./ProjectColsDetails";

const ProjectIntegrityOverView = (props) => {
  return (
    <React.Fragment>
      {props.projectDetail.status !== 3 &&
        <>
          <Row>
            <ProjectColsDetails Col_Type="total_task" Col_Lable="Total Task" projectDetail={props.projectDetail} />
            <ProjectColsDetails Col_Type="integrity_completed" Col_Lable="Integrity Completed"
              Col_Value={props.projectDetail.completed_integrity} />
            <ProjectColsDetails Col_Type="integriry_completed_by_you" Col_Lable="Integrity Completed By You"
              Col_Value={props.projectDetail.integrity_completed_by_current_user} />
          </Row>
        </>
      }
      {props.projectDetail.status === 3 &&
        <>
          <span>No integrity data found</span>
        </>
      }
      {props.projectDetail.assignedusers.length !== 0 &&
        <ProjectAssignUser projectDetail={props.projectDetail} showType="integrity" />
      }
    </React.Fragment>
  )
}
export default ProjectIntegrityOverView;