import axios from "axios"
import { AppServices, HttpHeaders } from "../Services/AppServices";

const TemplateServices = {
    getTemplates: (data = {}) => {
        const header = HttpHeaders();
        return axios.get(AppServices.GET_TEMPLATES(), {
            params: data,
            headers: header.headers,
        })
    },
    saveTemplate: (data) => {
        if (data.templateId) {
            return axios.post(AppServices.UPDATE_TEMPLATE(data.templateId), data, HttpHeaders())
        }
        else {
            return axios.post(AppServices.SAVE_TEMPLATE(), data, HttpHeaders())
        }
    },
    getTemplate: (templateId) => {
        return axios.get(AppServices.GET_TEMPLATE(templateId), HttpHeaders())
    },
    deleteTemplate: (data) => {
        return axios.post(AppServices.DELETE_TEMPLATE(data.templateId), data, HttpHeaders())
    },
    restoreTemplate: (data) => {
        return axios.post(AppServices.RESTORE_TEMPLATE(data.templateId), data, HttpHeaders())
    },
}
export default TemplateServices;
