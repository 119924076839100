import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import AddQuestionModal from "../common/AddQuestionModal";
import { GetQuestionCategory } from "../common/CommonController";
import ConfirmModal from "../common/ConfirmModal";
import { QuestionImg } from "../common/ImageConstants";
import projectApp from "../Shared/Controller/projectApp";

class Questions extends projectApp {

  constructor(props) {
    super(props)
    this.state = {
      templateLoading: false,
      questionsLoading: false,
      getTemplateLoading: false,
      templateId: "",
      templateList: [],
      templateDetail: {},
      selectedQuestions: [],
      modalShow: false,
      isEdit: false,
      template_name: "",
      confirmModalData: { flag: false, btnClicked: "" },
      isTemplateChange: false,
    }
  }

  componentDidMount = () => {
    if (this.props.currentStep === 4 && this.props.projectDetail.last_completed_step >= 3) {
      this.editCallQuestions();
    }
    if (this.props.currentStep === 4 && this.props.projectDetail.last_completed_step >= 3 && !(this.props.projectDetail.template_id)) {
      this.setState({
        template_name: this.props.projectDetail.project_name + " " + this.props.projectDetail.identifier
      });
    }
    this.getTamplates();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props && this.props.currentStep === 4 && this.props.projectDetail.last_completed_step >= 3 && !(this.props.projectDetail.template_id)) {
      this.setState({
        template_name: this.props.projectDetail.project_name + " " + this.props.projectDetail.identifier
      });
    }
  }

  editCallQuestions = () => {
    // let template = templateList.find((item) => item.id === this.props.projectDetail.template_id);
    if (this.props.projectDetail.template_id) {
      this.setState({
        isEdit: true,
        templateId: this.props.projectDetail.template_id,
        selectedQuestions: this.props.projectDetail.relatedquestions,
        template_name: this.props.projectDetail.templatedetails?.name,
      });
    }
    else {
      this.setState({
        isEdit: true,
      });
    }
  }

  onChangeTemplate = (e) => {
    if (e.target.value !== "") {
      this.getTemplate(e.target.value)
    }
  }

  onChangeTemplateName = (event) => {
    this.setState({ [event.target.name]: event.target.value, isTemplateChange: true })
    // Check and see if errors exist, and remove them from the error object:
    this.clearErrors(event.target.name);
  }

  onTemplateClick = (e) => {
    e.preventDefault();
    // const form = e.currentTarget;
    const newErrors = this.tamplateFormErrors()
    if (Object.keys(newErrors).length > 0) {
      this.setState({ ...newErrors })
    }
    else {
      if (this.state.isTemplateChange) {
        this.setState({ showConfirmModal: true, btnClicked: "template" });
      }
      else {
        this.saveTamplate();
      }
    }
  }

  tamplateFormErrors = () => {
    const { template_name } = this.state
    const newErrors = {}
    if (!template_name || template_name === '') {
      newErrors.template_name_error = 'Please enter tamplate name'
    }
    return newErrors
  }

  setTamplateReset = () => {
    Array.from(document.getElementsByName("template_name")).forEach(
      input => (input.value = "")
    );
  };

  checkExistsSelQue = (data) => {
    let isExists = false;
    this.state.selectedQuestions.map((item) => {
      if (parseInt(data.id) === item.id) {
        isExists = true;
      }
      return null;
    });
    return isExists;
  }

  onSelectQuestion = (e) => {
    e.preventDefault();
    let selectedQuestion = this.state.selectedQuestions;
    this.state.templateDetail.related_questions.map((item) => {
      if (item.id === parseInt(e.target.value) && !this.checkExistsSelQue(item)) {
        selectedQuestion.push(item);
      }
      return null;
    });
    this.setState({ selectedQuestions: selectedQuestion })
  }

  deleteSelectedQuestion = (e) => {
    e.preventDefault();
    let selArray = this.state.selectedQuestions.filter((item) => item.id !== parseInt(e.target.value));
    this.setState({ selectedQuestions: selArray })
  }

  onQuestionModalShow = () => {
    this.setState({ modalShow: true });
  }

  onQuestionCloseModal = () => {
    this.setState({ modalShow: false });
  }

  callBackAddQuestion = (data) => {
    let selectedQuestion = this.state.selectedQuestions;
    selectedQuestion.push(data);
    this.setState({ selectedQuestions: selectedQuestion })
  }

  onSaveQuestionClick = (e) => {
    e.preventDefault();
    const newErrors = this.questionsFormErrors()
    if (Object.keys(newErrors).length > 0) {
      this.setState({ ...newErrors })
    }
    else {
      if (this.state.isTemplateChange) {
        this.setState({ showConfirmModal: true, btnClicked: "step_finish" });
      }
      else {
        this.saveQuestions();
      }
    }
  }

  questionsFormErrors = () => {
    const { template_name, selectedQuestions } = this.state
    const newErrors = {}
    if (!template_name || template_name === '') {
      newErrors.template_name_error = 'Please enter tamplate name'
    }
    if (!selectedQuestions || selectedQuestions.length === 0) {
      newErrors.selected_question_error = 'Please add question';
    }
    return newErrors
  }

  onQuestionPreviousClick = (e) => {
    e.preventDefault();
    this.props.callBackPreviousQuestion();
  }

  onCloseConfirmModal = () => {
    this.setState({ showConfirmModal: false, btnClicked: "" });
  }

  onConfirmYesModal = () => {
    if (this.state.btnClicked === "step_finish") {
      this.setState({ showConfirmModal: false, btnClicked: "" }, () => {
        this.saveQuestions();
      });
    }
    else {
      this.setState({ showConfirmModal: false, btnClicked: "" }, () => {
        this.saveTamplate();
      });
    }
  }

  render() {
    // console.log("question props", this.state, this.props)
    return (
      <React.Fragment>
        <div className="step-list-wrap">
          <ConfirmModal show={this.state.showConfirmModal} type="permanentDelete"
            customMessage={"Please confirm the template name is correct?"}
            onCloseModal={() => this.onCloseConfirmModal()}
            confirmClick={() => this.onConfirmYesModal()} />
          <h2 className="step-title">Questions</h2>
          <Form className="questions-form" noValidate>
            <div className="step-content">
              <div className="popup-btn-row">
                <div className="uplod-inner">
                  <p>Please choose the questions you wish to include in this portfolio workflow. Click a question to add in selected question list.</p>
                </div>
                <Button title="Add New Question" onClick={() => this.onQuestionModalShow()} className="popup-add-btn">+</Button>
                <AddQuestionModal show={this.state.modalShow}
                  onCloseModal={this.onQuestionCloseModal}
                  callBackAddQuestion={(data) => this.callBackAddQuestion(data)}
                />
              </div>
              <div className="row grid-row-gap-30">
                <div className="col-md-6">
                  <div className="investor-box">
                    <h3 className="inner-step-block mb-2">Templates</h3>
                    <div className="uplod-inner mb-3">
                      <p>Select questions from previously used template.{/*  [Questions already selected will be exclu] */}</p>
                    </div>
                    <div className="add-project">
                      <Form.Select aria-label="Default select example"
                        onChange={this.onChangeTemplate}
                      >
                        <option value="">Please Select</option>
                        {this.state.templateList.length > 0 && this.state.templateList.map((item) => {
                          return (
                            <React.Fragment key={"temp" + item.id}>
                              <option value={item.id}>{item.name}</option>
                            </React.Fragment>
                          )
                        })
                        }
                      </Form.Select>
                    </div>
                    <div className="available-list">
                      <h3 className="inner-step-block">Available</h3>
                      {this.state.templateDetail.related_questions && this.state.templateDetail.related_questions.map((item) => {
                        return (
                          <React.Fragment key={"que" + item.id}>
                            <Form.Control as="button"
                              onClick={(e) => this.onSelectQuestion(e)}
                              value={item.id}
                              className="available-box">
                              <div className="available-box-icon"><img alt="QuestionImg" src={QuestionImg} /></div>
                              <div className="available-box-content">
                                <h6>{GetQuestionCategory(item.category, "single")}</h6>
                                <p>{item.question}</p>
                              </div>
                            </Form.Control>
                          </React.Fragment>
                        )
                      })
                      }
                      {(!this.state.templateDetail.related_questions || this.state.templateDetail.related_questions.length === 0) &&
                        <>
                          <span>No Question Available</span>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="investor-box">
                    <h3 className="inner-step-block mb-2">Save</h3>
                    <div className="uplod-inner mb-3">
                      <p>Save the selected questions as a template.</p>
                    </div>
                    <Form noValidate>
                      <div className="add-project save-field">
                        <span>Save as</span>
                        <div className="form-input-block">
                          <Form.Control
                            as="input"
                            required
                            name="template_name"
                            value={this.state.template_name}
                            isInvalid={!!this.state.template_name_error}
                            onChange={this.onChangeTemplateName}
                          />
                          <Form.Control.Feedback type="invalid">{this.state.template_name_error}</Form.Control.Feedback>
                        </div>
                        {/* <input type="text" /> */}
                        {this.state.templateLoading === false &&
                          <Button className={(this.state.templateId !== "" && this.state.templateId !== null) ? "save-button disabled" : "save-button"}
                            onClick={this.onTemplateClick}>Save</Button>
                        }
                        {this.state.templateLoading === true &&
                          <Button className="save-button">
                            <div className="text-center">
                              <Spinner animation="border" size="sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          </Button>
                        }
                        {/* <input type="button" value="save" /> */}
                      </div>
                    </Form>
                    <div className="selected-list">
                      <h3 className="inner-step-block">Selected</h3>
                      {this.state.selectedQuestions.map((item) => {
                        return (
                          <React.Fragment key={"select_que" + item.id}>
                            <div className="selected-box">
                              <div className="selected-box-icon"><img alt="QuestionImg" src={QuestionImg} /></div>
                              <div className="selected-box-content">
                                <h6>{GetQuestionCategory(item.category, "single")}</h6>
                                <p>{item.question}</p>
                                <Button onClick={(e) => this.deleteSelectedQuestion(e)}
                                  value={item.id}>
                                  <i className="material-icon">close</i>
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })
                      }
                      {this.state.selectedQuestions.length === 0 &&
                        <>
                          <span>No Selected Question</span>
                        </>
                      }
                      {this.state.selectedQuestions.length === 0 &&
                        <>
                          <Form.Control
                            as="input"
                            type="hidden"
                            name="selected_questions"
                            required
                            isInvalid={!!this.state.selected_question_error}
                          >
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">{this.state.selected_question_error}</Form.Control.Feedback>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="setp-btn-row">
                <Button variant="secondary"
                  onClick={(e) => this.onQuestionPreviousClick(e)}
                  className="btn btn-blue btn-pre">Previous</Button>
                {this.state.questionsLoading === false &&
                  <Button type="submit"
                    onClick={(e) => this.onSaveQuestionClick(e)}
                    variant="secondary" className="btn btn-blue btn-nex">Next</Button>
                }
                {this.state.questionsLoading === true &&
                  <Button
                    variant="secondary" className="btn btn-blue btn-nex">
                    <div className="text-center">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  </Button>
                }
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    )
  }
}
export default Questions;