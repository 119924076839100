import { useState } from 'react';
import { getToken } from "./CommonController"

function ManageToken() {

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('authtoken', userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
export default ManageToken;