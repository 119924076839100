import { Component } from "react"
import { ShowToast } from "../../common/CommonController"
import InvesterServices from "../Services/InvesterServices"
import PlatformServices from "../Services/PlatformServices"
import UserServices from "../Services/UserServices"
import TemplateServices from "../Services/TemplateServices"
import QuestionServices from "../Services/QuestionServices"
import ProjectServices from "../Services/ProjectServices"

class deleteddataApp extends Component {
  //#region - Investors
  getInvestors = () => {
    this.setState({ loading: true })
    let params = {
      status: 0
    }
    InvesterServices.getInvestors(params).then((response) => {
      if (response.data.code === 1) {
        this.setState({ investorList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  restoreInvestor = (data) => {
    this.setState({ loading: true });
    InvesterServices.restoreInvestor(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onRestoreSuccess(response.data, 'Investor')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  permanentDeleteInvestor = (data) => {
    this.setState({ loading: true });
    InvesterServices.deleteInvestor(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onPermanentDeleteSuccess(response.data, 'Investor')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  onClickInvestoreRestore = (e, investorId) => {
    e.preventDefault();
    this.setState({ showRestoreModal: true, investorId: investorId })
  }

  onClickPermenantDeleteInvestore = (e, investorId) => {
    e.preventDefault();
    this.setState({ showDeleteModal: true, investorId: investorId })
  }

  //#endregion - Investors

  //#region - Platforms
  getPlatforms = () => {
    this.setState({ loading: true })
    let params = {
      status: 0
    }
    PlatformServices.getPlatforms(params).then((response) => {
      if (response.data.code === 1) {
        this.setState({ platformList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  restorePlatform = (data) => {
    this.setState({ loading: true });
    PlatformServices.restorePlatform(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onRestoreSuccess(response.data, 'Platform')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  permanentDeletePlatform = (data) => {
    this.setState({ loading: true });
    PlatformServices.deletePlatform(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onPermanentDeleteSuccess(response.data, 'Platform')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  onClickPlatformRestore = (e, platformId) => {
    e.preventDefault();
    this.setState({ showRestoreModal: true, platformId: platformId })
  }

  onClickPermenantDeletePlatform = (e, platformId) => {
    e.preventDefault();
    this.setState({ showDeleteModal: true, platformId: platformId })
  }

  //#endregion - Platforms

  //#region - Users
  getUsers = () => {
    this.setState({ loading: true })
    let params = {
      status: 0
    }
    UserServices.getUsers(params).then((response) => {
      if (response.data.code === 1) {
        let dataList = response.data.data;
        dataList.map((item) => {
          item.fullname = item.firstname + " " + item.lastname;
        })
        this.setState({ userList: dataList, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  restoreUser = (data) => {
    this.setState({ loading: true });
    UserServices.restoreUser(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onRestoreSuccess(response.data, 'User')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  permanentDeleteUser = (data) => {
    this.setState({ loading: true });
    UserServices.deleteUser(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onPermanentDeleteSuccess(response.data, 'User')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  onClickUserRestore = (e, userId) => {
    e.preventDefault();
    this.setState({ showRestoreModal: true, userId: userId })
  }

  onClickPermenantDeleteUser = (e, userId) => {
    e.preventDefault();
    this.setState({ showDeleteModal: true, userId: userId })
  }

  //#endregion - Users

  //#region Projects
  getProjects = () => {
    this.setState({ loading: true })
    let params = {
      status: 0
    }
    ProjectServices.getProjects(params).then((response) => {
      if (response.data.code === 1) {
        this.setState({ project_list: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  restoreProject = (data) => {
    this.setState({ loading: true });
    ProjectServices.restoreProject(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onRestoreSuccess(response.data, 'Project')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  permanentDeleteProject = (data) => {
    this.setState({ loading: true });
    ProjectServices.deleteProject(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onPermanentDeleteSuccess(response.data, 'Project')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  onClickProjectRestore = (e, projectId) => {
    e.preventDefault();
    this.setState({ showRestoreModal: true, projectId: projectId })
  }

  onClickPermenantDeleteProject = (e, projectId) => {
    e.preventDefault();
    this.setState({ showDeleteModal: true, projectId: projectId })
  }
  //#endregion Projects

  //#region - Templates
  getTemplates = () => {
    this.setState({ loading: true })
    let params = {
      status: 0
    }
    TemplateServices.getTemplates(params).then((response) => {
      if (response.data.code === 1) {
        this.setState({ templateList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  restoreTemplate = (data) => {
    this.setState({ loading: true });
    TemplateServices.restoreTemplate(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onRestoreSuccess(response.data, 'Template')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  permanentDeleteTemplate = (data) => {
    this.setState({ loading: true });
    TemplateServices.deleteTemplate(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onPermanentDeleteSuccess(response.data, 'Template')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  onClickTemplateRestore = (e, templateId) => {
    e.preventDefault();
    this.setState({ showRestoreModal: true, templateId: templateId })
  }

  onClickPermenantDeleteTemplate = (e, templateId) => {
    e.preventDefault();
    this.setState({ showDeleteModal: true, templateId: templateId })
  }
  //#endregion - Templates

  //#region - Questions
  getQuestions = () => {
    this.setState({ loading: true })
    let params = {
      status: 0
    }
    QuestionServices.getQuestions(params).then((response) => {
      if (response.data.code === 1) {
        this.setState({ questionList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  restoreQuestion = (data) => {
    this.setState({ loading: true });
    QuestionServices.restoreQuestion(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onRestoreSuccess(response.data, 'Question')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  permanentDeleteQuestion = (data) => {
    this.setState({ loading: true });
    QuestionServices.deleteQuestion(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onPermanentDeleteSuccess(response.data, 'Question')
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  onClickQuestionRestore = (e, questionId) => {
    e.preventDefault();
    this.setState({ showRestoreModal: true, questionId: questionId })
  }

  onClickPermenantDeleteQuestion = (e, questionId) => {
    e.preventDefault();
    this.setState({ showDeleteModal: true, questionId: questionId })
  }
  //#endregion - Questions

  //#region - Common
  handleRestore = (e, module) => {
    if (module === 'Investor') {
      let newData = { investorId: this.state.investorId }
      this.setState({ showRestoreModal: false })
      this.restoreInvestor(newData);
    } else if (module === 'Platform') {
      let newData = { platformId: this.state.platformId }
      this.setState({ showRestoreModal: false })
      this.restorePlatform(newData);
    } else if (module === 'User') {
      let newData = { userId: this.state.userId }
      this.setState({ showRestoreModal: false })
      this.restoreUser(newData);
    } else if (module === 'Template') {
      let newData = { templateId: this.state.templateId }
      this.setState({ showRestoreModal: false })
      this.restoreTemplate(newData);
    } else if (module === 'Question') {
      let newData = { questionId: this.state.questionId }
      this.setState({ showRestoreModal: false })
      this.restoreQuestion(newData);
    } else if (module === 'Project') {
      let newData = { projectid: this.state.projectId }
      this.setState({ showRestoreModal: false })
      this.restoreProject(newData);
    }
  };

  handlePermanentDelete = (e, module) => {
    if (module === 'Investor') {
      let newData = { investorId: this.state.investorId, permanentDelete: 1 }
      this.setState({ showDeleteModal: false })
      this.permanentDeleteInvestor(newData);
    } else if (module === 'Platform') {
      let newData = { platformId: this.state.platformId, permanentDelete: 1 }
      this.setState({ showDeleteModal: false })
      this.permanentDeletePlatform(newData);
    } else if (module === 'User') {
      let newData = { userId: this.state.userId, permanentDelete: 1 }
      this.setState({ showDeleteModal: false })
      this.permanentDeleteUser(newData);
    } else if (module === 'Template') {
      let newData = { templateId: this.state.templateId, permanentDelete: 1 }
      this.setState({ showDeleteModal: false })
      this.permanentDeleteTemplate(newData);
    } else if (module === 'Question') {
      let newData = { questionId: this.state.questionId, permanentDelete: 1 }
      this.setState({ showDeleteModal: false })
      this.permanentDeleteQuestion(newData);
    } else if (module === 'Project') {
      let newData = { project_id: this.state.projectId, permanentDelete: 1 }
      this.setState({ showDeleteModal: false })
      this.permanentDeleteProject(newData);
    }

  };

  onRestoreSuccess = (data, module) => {
    if (module === 'Investor') {
      this.getInvestors();
    } else if (module === 'Platform') {
      this.getPlatforms();
    } else if (module === 'User') {
      this.getUsers();
    } else if (module === 'Template') {
      this.getTemplates();
    } else if (module === 'Question') {
      this.getQuestions();
    } else if (module === 'Project') {
      this.getProjects();
    }


  }

  onPermanentDeleteSuccess = (data, module) => {
    if (module === 'Investor') {
      this.getInvestors();
    } else if (module === 'Platform') {
      this.getPlatforms();
    } else if (module === 'User') {
      this.getUsers();
    } else if (module === 'Template') {
      this.getTemplates();
    } else if (module === 'Question') {
      this.getQuestions();
    } else if (module === 'Project') {
      this.getProjects();
    }
  }

  onRestoreCloseModal = () => {
    this.setState({ showRestoreModal: false })
  }

  onDeleteCloseModal = () => {
    this.setState({ showDeleteModal: false })
  }
  //#endregion - Common



}
export default deleteddataApp