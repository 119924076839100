import React, { useState } from 'react'
import Axios from 'axios'
import * as Constants from "./partials/CommonConstant";
import * as ImgConstants from "./common/ImageConstants";
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import LoginLeftBlock from './LoginLeftBlock';
import { useDispatch } from 'react-redux';
import { addUser } from "./Shared/Redux/Actions/CommonAction";
import { hasErrorFor, renderErrorFor, renderSuccessMessage, renderErrorMessage } from "./Shared/Controller/authApp";
// import { toast, ToastContainer } from 'react-toastify';

const Login = (props) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [twofacode, setTowFaCode] = useState("");
  const [success_message, setSuccessMsg] = useState("");
  const [error_message, setErrorMsg] = useState("");
  const [showtwofa, setShowTwoFa] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false)
  const dispatch = useDispatch();

  const onChangeUserName = (event) => {
    setUserName(event.target.value)
  }

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const onChangeTwoFACode = (event) => {
    setTowFaCode(event.target.value)
  }

  const renderTwofacodefield = () => {
    if (showtwofa === true) {
      return (
        <div className='mb-3 form-block'>
          <label htmlFor='twofacode' className='form-label'>Enter Code</label>
          <input type='text' name='twofacode'
            className={`form-control ${hasErrorFor({ field: 'twofacode', errors: errors }) ? 'is-invalid' : ''}`}
            id='twofacode' placeholder='Enter the Code'
            value={twofacode}
            maxLength="6"
            onChange={onChangeTwoFACode} />
          {renderErrorFor({ field: 'twofacode', errors: errors })}
        </div>

      )
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()

    let send_data = {
      username: username,
      password: password,
    }
    if (showtwofa === true) {
      send_data['twofacode'] = twofacode
    }
    setLoading(true)
    setErrorMsg("")
    setErrors([])
    Axios.post(Constants.UserLogin, send_data)
      .then(response => {
        if (response.data.code === 1) {
          // For success
          if (showtwofa === true) {
            props.setToken(response.data.data.authtoken)
            dispatch(addUser(response.data.data));
            setSuccessMsg(response.data.message);
          }
          else {
            // Show success message
            setShowTwoFa(true)
            setSuccessMsg(response.data.message)
          }
        }
        else {
          //For errors
          if (response.data.message.constructor === Object) {
            setErrors(response.data.message)
            hasErrorFor({ errors })
            setSuccessMsg("");
          } else {
            setErrorMsg(response.data.message);
            setSuccessMsg("");
          }
        }
        setLoading(false)
      })
      .catch(error => {
        setErrors(error.response ? error.response.data.errors : [])
      })
  }

  return (
    <>
      <div className='main'>

        {/* <ToastContainer autoClose={3000} /> */}
        <div className='login-form-block'>
          <div className='mobile-logo'>
            <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} className='' />
            <div className='heading'>
              <h2>Qflow</h2>
            </div>
          </div>

          <div className='login-banner-block'>
            <img src={ImgConstants.LoginBanner} alt={Constants.ImageALT} title={Constants.ImageTitle} />
          </div>
          <div className='login-form-box-main'>
            <LoginLeftBlock />
            <div className='login-form-right-block'>
              <div className='login-form-logo'>
                <img src={ImgConstants.FormLogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} />
              </div>
              <form onSubmit={handleFormSubmit}>
                <h4>Sign In</h4>
                {renderSuccessMessage(success_message)}
                {renderErrorMessage(error_message)}
                {showtwofa === false &&
                  <>
                    <div className='mb-3 form-block'>
                      <label htmlFor='username' className='form-label'>Email or Username</label>
                      <input type='text' name='username'
                        autoComplete="nofill"
                        className={`username-icon form-control ${hasErrorFor({ field: 'username', errors: errors }) ? 'is-invalid' : ''}`}
                        id='username' placeholder='Enter Your Email or Username'
                        value={username}
                        onChange={(e) => onChangeUserName(e)} />
                      {renderErrorFor({ field: 'username', errors: errors })}
                    </div>
                    <div className='mb-3 form-block'>
                      <label htmlFor='password' className='form-label'>Password</label>
                      <input type={isShowPassword === true ? 'text' : 'password'}
                        name='password'
                        className={`password-icon form-control ${hasErrorFor({ field: 'password', errors: errors }) ? 'is-invalid' : ''}`}
                        id='password' placeholder='Enter Your Password'
                        value={password}
                        autoComplete="nofill"
                        onChange={(e) => onChangePassword(e)} />
                      <span onClick={() => setIsShowPassword(!isShowPassword)} className='password-show' style=
                        {isShowPassword === true ? { backgroundImage: `url(${ImgConstants.PassswordShowImg})` } : { backgroundImage: `url(${ImgConstants.PassswordHideImg})` }}
                      ></span>
                      {renderErrorFor({ field: 'password', errors: errors })}
                    </div>
                  </>
                }
                {renderTwofacodefield()}
                <div className='form-btn'>
                  <Link to={Constants.ForgotPassword}>Forgot password?</Link>
                  {loading === false &&
                    <button type='submit' className='btn btn-primary'>
                      Sign In
                    </button>
                  }
                  {loading === true &&
                    <button type='button' className='btn btn-primary'>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login