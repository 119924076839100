import React, { useEffect, useState } from "react";
import { ShowToast } from "../common/CommonController";
import { Spinner } from "react-bootstrap";
import { deleteSessionLogs, GetSessionLogs } from "../Shared/Controller/LogsApp";
import NotFound from "../common/NotFound";
import LogList from "../Logs/LogList";
import DeleteLog from "../Logs/DeleteLog";
import { useNavigate } from "react-router-dom";

const SessionLogs = () => {

  const [sessionLogList, setSessionLogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [validated, setValidate] = useState(false);
  const [deleteTimeLog, setDeleteTimeLog] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getSessionLogs();
  }, []);

  const getSessionLogs = () => {
    setLoading(true);
    getSessionLogsApi();
  }

  const getSessionLogsApi = (data = {}) => {
    setValidate(false);
    GetSessionLogs(data).then((response) => {
      if (response.data.code === 1) {
        setSessionLogList(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const setTimeLog = (e) => {
    setDeleteTimeLog(e.target.value);
  }

  const deleteLogSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidate(true);
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      setDeleteLoading(true);
      deleteSessionLogs(deleteTimeLog).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message)
          getSessionLogs();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setDeleteLoading(false);
      }).catch(error => {
        setDeleteLoading(false);
        ShowToast("message", "error", "" + error);
      });
    }
    setValidate(true);
  }

  const getTableColumns = () => {
    let table_columns = [];
    table_columns = [
      {
        name: "Username",
        selector: row => row.username,
        sortable: true,
      },
      {
        name: "Login",
        selector: row => row.login_time ? row.login_time : "",
        cell: row => { return row.login_time ? row.login_time : "" },
        sortable: true,
      },
      {
        name: "Logout",
        selector: row => row.logout_time ? row.logout_time : "",
        cell: row => { return row.login_time ? row.login_time : "" },
        sortable: true,
      },
      {
        name: "IP Address",
        selector: row => row.ipaddress,
        sortable: true,
      },
    ]
    return table_columns;
  }

  const data = sessionLogList;
  const columns = getTableColumns();

  const tableData = {
    columns,
    data
  };

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end">
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        {sessionLogList.length !== 0 &&
          <DeleteLog deleteLoading={deleteLoading}
            deleteLogSubmit={deleteLogSubmit}
            validated={validated}
            setTimeLog={setTimeLog}
          />
        }
        <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
          <div className="card-main p-0">
            <div className="dark-table-wrapper table-scroll">
              <LogList LogListData={sessionLogList} loading={loading} tableData={tableData} />
              {loading === false && sessionLogList.length === 0 &&
                <NotFound message="No Data Found" type="content" />
              }
              {loading === true &&
                <table className="mb-0 table">
                  <tbody>
                    <tr>
                      <td colSpan={10} className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default SessionLogs;