import axios from "axios"
import { AppServices, HttpHeaders } from "./AppServices";

const DownloadService = {
  GetDownloadLogs: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_DOWNLOAD_LOGS(), {
      params: data,
      headers: header.headers,
    })
  },
}
export default DownloadService;
