import React, { useContext } from "react";
import $ from "jquery";
import { ReviewContext } from "../context/ReviewContext";
import { Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import NotFound from "../common/NotFound";
import ViewFileQuestion from "./ViewFileQuestion";
import ViewLoanQuestion from "./ViewLoanQuestion";
import ViewDelinquencyQuestion from "./ViewDelinquencyQuestion";

const ViewReviewRow = () => {

  const RowProps = useContext(ReviewContext)
  let navigate = useNavigate();
  const onAccordian1Click = (e) => {
    e.preventDefault();
    $(".accord1").toggle();
    $(".btnacc1").parent().toggleClass('tclass');
  }
  const onAccordian2Click = (e) => {
    e.preventDefault();
    $(".accord2").toggle();
    $(".btnacc2").parent().toggleClass('tclass');
  }

  // console.log("ViewReviewRow", RowProps)
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mt-md-0">
          <h5>{RowProps.RowData.SingleRow.project_name}</h5>
        </div>
        <div className="col-md-12  mt-md-0">
          {RowProps.isReviewFinish === false &&
            <React.Fragment>
              <div className="right-panel">
                <div className="btn-block">
                  {RowProps.getRowLoading === false &&
                    <Button id="btnReviewSaveNext" onClick={() => RowProps.checkLastRow("next")} variant="secondary"
                      className={"btn btn-blue btn-nex mb-1"}>
                      Next
                    </Button>
                  }
                  {RowProps.getRowLoading === true &&
                    <Button variant="secondary" className="btn btn-blue btn-nex me-1 mb-1">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                  &nbsp;
                  {/* <Link to={Constants.projectList} className="btn btn-blue btn-nex mb-1">Close</Link> */}
                  <Button onClick={() => navigate(-1)} className="btn btn-blue btn-nex mb-1">Back</Button>
                </div>
              </div>
              <p>Investor Reference - {RowProps.RowData.SingleRow.Account_Reference}, Owner - {RowProps.RowData.SingleRow.owner_name} {(RowProps.take_ownership === 0 && RowProps.userDetail.role === 1 && RowProps.integrity_review === 1) ? <Link to="#" onClick={RowProps.onTakeOwnsership}>[Take Ownership]</Link> : null} <br />
                Supplemental Data</p>
            </React.Fragment>
          }
        </div>
      </div>
      {RowProps.isReviewFinish === false &&
        <React.Fragment>
          <div className="row">
            <div className="col-md-12 mt-3 mt-md-0 mb-3">
              <div className="card-main p-0">
                <div className="dark-table-wrapper table-scroll smtb">
                  {RowProps.RowData &&
                    <table className="table mb-0">
                      <thead className="thead-dark">
                        <tr>
                          {RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
                            return (
                              <React.Fragment key={"rowheader" + item}>
                                <th>{item}</th>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {RowProps.RowData.SingleRow.row_details && Object.keys(RowProps.RowData.SingleRow.row_details).map((item, i) => {
                            return (
                              <React.Fragment key={"rowValue" + item + RowProps.RowData.SingleRow.row_details[item]}>
                                <td>{RowProps.RowData.SingleRow.row_details[item]}</td>
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3 mt-md-0 accordian-row">
              <button onClick={(e) => onAccordian1Click(e)} className="btnacc1"> </button>
            </div>
          </div>
          <div className="row accord1">
            <ViewFileQuestion />
            <ViewLoanQuestion />
            {/* 10-Mar-2023 check delinquency Flag 1 or 0 to hide/unhide question */}
            {RowProps.RowData?.SingleRow?.row_details?.Delinquency_Flag === 1 &&
              <ViewDelinquencyQuestion />
            }
          </div>
          <div className="row">
            <div className="col-md-12  accordian-row">
              <button onClick={(e) => onAccordian2Click(e)} className="btnacc2"> </button>
            </div>
          </div>

          <div className="row accord2">
            <div className="col-md-12  mt-md-0 mb-3">
              <div className="card-main">
                <div className="row g-4">
                  <div className="col-md-4">
                    <div className="m-1">
                      <b>Grade: </b>
                      <span>{RowProps.grade}</span>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="m-1">
                      <b>Comment: </b>
                      <span>{RowProps.row_comment}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      }
      {RowProps.isReviewFinish === true &&
        <div className="card mt-3">
          <NotFound type="content" message={RowProps.integrity_review === 1 ? "Integrity Finished" : "Review Finished"} />
        </div>
      }
    </React.Fragment>
  )
}
export default ViewReviewRow;