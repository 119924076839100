import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { LogOut } from './CommonController';

const SessionTimeout = (props) => {
  const [events, setEvents] = useState(['click', 'load', 'scroll', 'mouseover', 'mousemove', 'change']);
  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  const timePeriod = 7200000;   // Atik Tag Time period will be 30 Min. // Atik Tag 120 min change

  // start inactive check
  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = localStorage.getItem('logoutTimeStamp');
      warningInactive(storedTimeStamp);
    }, 30000);
  };

  // warning timer
  const warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);
    warningInactiveInterval.current = setInterval(() => {
      const maxTime = timePeriod;
      // const diff = moment.duration(moment().diff(moment(timeString)));
      const diff = (new Date().getTime() - new Date(timeString).getTime());
      // console.log("warning time", maxTime, diff);

      if (diff >= maxTime) {
        clearInterval(warningInactiveInterval.current);
        localStorage.removeItem('logoutTimeStamp');
        LogOut();
      }
    }, 1000);
  };

  // reset interval timer
  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (props.isAuthenticated) {
      timeStamp = new Date();
      localStorage.setItem('logoutTimeStamp', timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      localStorage.removeItem('logoutTimeStamp');
    }
    timeChecker();
  }, [props.isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  // change fragment to modal and handleclose func to close
  return <Fragment />;
};

export default SessionTimeout;
