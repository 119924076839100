import React from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";

const DeleteLog = (props) => {
  return (
    <React.Fragment>
      <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
        <div className="card-main">
          <div>
            <Form noValidate validated={props.validated} onSubmit={props.deleteLogSubmit}>
              <Row className="row g-4">
                <Form.Group className="col-md-4" controlId="validationCustom01">
                  <Form.Label>Delete logs older then</Form.Label>
                  <Form.Control
                    as="select"
                    name="delete_log"
                    required
                    onChange={(e) => props.setTimeLog(e)}
                  >
                    <option value="">Select Time</option>
                    <option value="2">2 Month</option>
                    <option value="4">4 Month</option>
                    <option value="6">6 Month</option>
                    <option value="8">8 Month</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Please select time</Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-4 btn-delete-content">
                  {props.deleteLoading === false &&
                    <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">Delete</Button>
                  }
                  {props.deleteLoading === true &&
                    <Button className="btn btn-sm btn-blue" variant="secondary" >
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default DeleteLog;