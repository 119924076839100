import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { GetRole } from "../common/CommonController";
import DownlodUserLog from "../downloalog/DownlodUserLog";

const ViewProfile = (props) => {
  const fieldLayout = {
    sm: "12",
    md: "12",
    lg: "6",
    xl: "6",
  }

  return (
    <React.Fragment>
      <div className="card-main">
        <div className="profile-text-inner">
          {props.parentState.loading === false && props.parentState.profileData &&
            <Row>
              <Col {...fieldLayout}>
                <div className="profile-card">
                  <div className="profile-label">
                    <label>First Name</label>
                  </div>
                  <div className="profile-value">
                    <p>{props.parentState.profileData.firstname}</p>
                  </div>
                </div>
              </Col>
              <Col {...fieldLayout}>
                <div className="profile-card">
                  <div className="profile-label">
                    <label>Last Name</label>
                  </div>
                  <div className="profile-value">
                    <p>{props.parentState.profileData.lastname}</p>
                  </div>
                </div>
              </Col>
              <Col {...fieldLayout}>
                <div className="profile-card">
                  <div className="profile-label">
                    <label>Email</label>
                  </div>
                  <div className="profile-value">
                    <p>{props.parentState.profileData.email}</p>
                  </div>
                </div>
              </Col>
              <Col {...fieldLayout}>
                <div className="profile-card">
                  <div className="profile-label">
                    <label>Username</label>
                  </div>
                  <div className="profile-value">
                    <p>{props.parentState.profileData.username}</p>
                  </div>
                </div>
              </Col>
              <Col {...fieldLayout}>
                <div className="profile-card">
                  <div className="profile-label">
                    <label>Role</label>
                  </div>
                  <div className="profile-value">
                    <p>{GetRole(props.parentState.profileData.role)}</p>
                  </div>
                </div>
              </Col>
              {props.parentState.profileData.id &&
                <DownlodUserLog userid={props.parentState.profileData.id} />
              }
            </Row>
          }
          {props.parentState.loading &&
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  )
}
export default ViewProfile;