import React from "react";

const ViewRowQuestions = (props) => {

  return (
    <React.Fragment>
      <div className={props.index === 0 ? "row g-4" : "row g-4 mt-1"}>
        <p className="mb-0 small-text">{props.item.question}</p>
        <div className="col-md-5 mt-0">
          <div className="m-1">
            <b>Answer: </b>
            {props.item.selected_choice}
          </div>
        </div>
        {(props.item.comment !== "" && props.item.comment_required !== 0) &&
          <div className="col-md-7 mt-0">
            <div className="m-1">
              <b>Comment: </b>
              {props.item.comment}
            </div>
          </div>
        }
        {/* } */}
      </div>
    </React.Fragment>
  )
}
export default ViewRowQuestions;