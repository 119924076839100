import React, { useEffect, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getFileSize, getFileType, GetUser, ShowToast } from "../common/CommonController";
import { GetAllUsers, GetUploadFolderFiles } from "../Shared/Controller/filesApp";
import * as Constants from "../partials/CommonConstant";
import { Scrollbars } from 'react-custom-scrollbars';
import UserPermissionModal from "./UserPermissionModal";
import $ from "jquery";

const UploadFolder = () => {
  const [Validated, setValidated] = useState(false);
  const [folderFiles, setFolderUpload] = useState({});
  const [folderField, setFolderField] = useState({ shared_with: [] });
  const navigate = useNavigate();
  const userDetail = GetUser();
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showPopupModal, setPopupShowModal] = useState(false);

  useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    setDefaultPermission();
  }, [users])

  const resetFiles = () => {
    setFolderUpload({});
    document.getElementById("projectfileupload").value = null;
  }

  const setDefaultPermission = () => {
    let data = {
      shared_with: []
    }
    let userList = [];
    users.map((item) => {
      userList.push({ user_id: item.id.toString(), permission: "1" });
      return null;
    });
    setFolderField({
      ...data,
      shared_with: userList,
    });
  }

  const getAllUser = () => {
    let data = {
      status: 1,
      exclude_userid: userDetail.id,
    }
    setLoading(true);
    GetAllUsers(data).then((response) => {
      if (response.data.code === 1) {
        setUsers(response.data.data);
        // if (isEdit === false) {
        setLoading(false);
        // }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        setLoading(false);
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }

  const handlePermission = (data) => {
    let shared_with = folderField.shared_with;
    shared_with.map((item) => {
      if (item.user_id === data.id.toString()) {
        item.permission = data.permission;
      }
    })
    setFolderField({ ...folderField, shared_with: shared_with })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      setUploadLoading(true);
      let uploadFolderData = new FormData();
      const blob = new Blob([JSON.stringify(folderFiles)], { type: 'application/json' });
      Object.keys(folderFiles).map((item, i) => {
        uploadFolderData.append("folder[" + i + "]", folderFiles[item]);
        uploadFolderData.append("path[" + i + "]", folderFiles[item].webkitRelativePath);
        return null;
      });
      folderField.shared_with.map((item, i) => {
        uploadFolderData.append("shared_with[" + [i] + "][" + ["user_id"] + "]", parseInt(item["user_id"]));
        uploadFolderData.append("shared_with[" + [i] + "][" + ["permission"] + "]", parseInt(item["permission"]));
        return null;
      })
      // uploadFolderData.append("folder", blob);
      GetUploadFolderFiles(uploadFolderData).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message);
          navigate(Constants.allFiles)
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setUploadLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setUploadLoading(false);
      });
    }
    setValidated(true);
  }

  const handleFolderChange = (e) => {
    e.preventDefault()
    setValidated(false);
    if (Object.keys(e.target.files).length > 0) {
      Object.keys(e.target.files).map((item) => {
        let randomID = Math.floor((Math.random() * 1000) + 1);
        e.target.files[item].id = randomID;
      })
    }
    setFolderUpload(e.target.files);
    /* setTimeout(() => {
      window.setScrollBarForFiles();
    }, 1000); */
  }

  const handleChange = (e) => {
    e.preventDefault()
    let shared_with = folderField.shared_with;
    if (e.target.name === "shared_with") {
      if (shared_with.length > 0) {
        if (shared_with.indexOf(e.target.value) === -1) {
          shared_with.push(e.target.value)
        }
        else {
          shared_with = folderField.shared_with.filter((item) => item !== e.target.value)
        }
      }
      else {
        shared_with.push(e.target.value)
      }
      setFolderField({ ...folderField, [e.target.name]: shared_with })
    }
  }


  const cancelFolder = (e) => {
    e.preventDefault()
    let id = e.target.value;
    let newFolderFiles = [];
    Object.keys(folderFiles).map((item) => {
      if (folderFiles[item].id !== parseInt(id)) {
        newFolderFiles.push(folderFiles[item]);
      }
    });
    setFolderUpload(newFolderFiles);
  }

  const getFilePathFormat = (item) => {
    let filePath = item.webkitRelativePath.replace(item.name, "")
    return "/" + filePath.substring(0, filePath.length - 1);
  }

  const resetAllPermission = () => {
    let newSharedWith = [];
    let tempFormField = folderField;
    tempFormField.shared_with.map((item) => {
      newSharedWith.push({ ...item, permission: "1" });
      return null;
    });
    setFolderField({
      ...tempFormField,
      shared_with: newSharedWith,
    });
  }

  const onCloseModal = () => {
    // resetAllPermission();
    setPopupShowModal(false);
  }

  const confirmClick = () => {
    setPopupShowModal(false);
  }

  const handleMouseOver = () => {
    $(".uplod-file").addClass("hover-file");
  }

  const handleDragExit = () => {
    $(".uplod-file").removeClass("hover-file");
  }

  // console.log("folder form", folderFiles, folderField)
  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper user-upload-page">
        <div className="row">
        </div>
        {loading === false &&
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
              <div className="card-main">
                <Form noValidate validated={Validated} onSubmit={handleSubmit} className="step-form">
                  <Row>
                    {/* <Form.Label>Upload Folder</Form.Label> */}
                    <div className="uplod-file col-6">
                      <Form.Group controlId="projectfileupload">
                        <Form.Control
                          as="input"
                          className="file mb-2"
                          required
                          type="file"
                          title="Choose Folder"
                          placeholder="Please Upload Folder"
                          name="folder"
                          bsPrefix="file"
                          directory=""
                          webkitdirectory=""
                          onClick={resetFiles}
                          onChange={handleFolderChange}
                          onDragEnter={handleMouseOver}
                          onDragLeave={handleDragExit}
                        />
                        <Form.Control.Feedback type="invalid">Please upload folder</Form.Control.Feedback>
                      </Form.Group>
                      {/* <button>+</button> */}
                      <span className="mx-2 mt-1">You can also drag & drop into the above</span>
                    </div>
                    <Form.Group className="col-6 share-usr-lst">
                      <Button variant="secondary" className="btn btn-blue btn-nex mt-1" onClick={() => setPopupShowModal(true)}>
                        Show Permissions
                      </Button>
                    </Form.Group>
                    <UserPermissionModal
                      show={showPopupModal}
                      formField={folderField}
                      users={users}
                      handlePermission={handlePermission}
                      onCloseModal={onCloseModal}
                      confirmClick={confirmClick}
                      modalFor="folder"
                    />
                    <div className="col-md-12 col-sm-12 col-lg-12 mt-3 mb-3 fold-list-main">
                      <Scrollbars className="custom-scroll" style={{ height: 450 }}>
                        {Object.keys(folderFiles).length > 0 && Object.keys(folderFiles).map((item) => {
                          return (
                            <React.Fragment key={"Folder_" + folderFiles[item].id}>
                              <div className="selected-list folder-file-list">
                                <div className="selected-box">
                                  <div className="selected-box-content">
                                    <span>{getFileType(folderFiles[item].type)}</span>
                                    <p className="up-title"><a href="#" title={folderFiles[item].name}>{folderFiles[item].name}</a></p>
                                    <p className="up-size">Size: {getFileSize(folderFiles[item].size)}</p>
                                    <p className="up-path"><a href="#" title={getFilePathFormat(folderFiles[item])}>{getFilePathFormat(folderFiles[item])}</a></p>
                                    <Button onClick={(e) => cancelFolder(e)}
                                      value={folderFiles[item].id}>
                                      <i className="material-icon">close</i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        })
                        }
                        {Object.keys(folderFiles).length === 0 &&
                          <React.Fragment key={"noData File" + 1}>
                            <div className="text-center mt-3 no-file-content">
                              <span>No files added</span>
                            </div>
                          </React.Fragment>
                        }
                      </Scrollbars>
                    </div>
                    {/* <Form.Group className="col-md-6 col-sm-12 col-lg-3 mt-3 mb-3 share-usr-lst">
                      <Form.Label>Shared Users</Form.Label>
                      <ul className="project-list file-user-list">
                        <Scrollbars className="custom-scroll" style={{ height: 600 }}>
                          {users.length > 0 && users.map((item) => {
                            return (
                              <React.Fragment key={"shared_with" + item.id}>
                                <Form.Control as="button"
                                  onClick={handleChange}
                                  value={item.id}
                                  required
                                  name="shared_with"
                                  className={folderField.shared_with.indexOf(item.id.toString()) !== -1 ? "active" : ""}
                                >
                                  {item.firstname + " " + item.lastname}
                                </Form.Control>
                              </React.Fragment>
                            )
                          })
                          }
                        </Scrollbars>
                      </ul>
                      <Form.Control.Feedback type="invalid">Please select user</Form.Control.Feedback>
                    </Form.Group> */}
                    <div className="col-12 text-end">
                      {uploadLoading === false &&
                        <>
                          <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex me-2">Cancel</button>
                          <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                            Upload
                          </Button>
                        </>
                      }
                      {uploadLoading === true &&
                        <Button className="btn btn-sm btn-blue" variant="secondary">
                          <Spinner animation="border" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Button>
                      }
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        }
        {loading === true &&
          <div className="col-md-12 col-sm-12 col-lg-128 mb-3 mt-3">
            <div className="card-main">
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}
export default UploadFolder;