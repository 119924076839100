import React from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import userApp from "../Shared/Controller/userApp";
import * as Constants from "../partials/CommonConstant";

class AddUser extends userApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      validated: false,
      saveLoading: false,
    }
  }

  handleChange = (event) => {
    if ([event.target.name] === "status") {
      this.setState({ [event.target.name]: event.target.value })
    }
    else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      let newData = { ...this.state }
      delete newData.loading
      delete newData.validated
      if (this.props.isEdit) {
        newData.userId = this.props.userId
      }
      this.saveUser(newData);
    }
    this.setState({ validated: true });
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.getUser(this.props.userId)
    }
  }

  render() {
    // console.log("user add", this.state, this.props)
    return (
      <div className="dashboard-card-wrapper">
        <div className="row">
          {/* <div className="col-md-12 mt-3 mt-md-0">
            <h1 className="content-title">{this.props.isEdit ? "Edit User" : "Add User"}</h1>
          </div> */}
          <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
            <div className="card-main">
              {this.state.loading === false &&
                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                  <Row className="row g-4">
                    <Form.Group md="4" className="col-md-4" controlId="validationCustom01">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        required
                        name="firstname"
                        value={this.state.firstname}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group md="4" className="col-md-4">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        required
                        name="lastname"
                        value={this.state.lastname}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
                    </Form.Group>
                    {!this.props.isEdit &&
                      <Form.Group md="4" className="col-md-4">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          required
                          name="username"
                          value={this.state.username}
                          onChange={this.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">Please enter username</Form.Control.Feedback>
                      </Form.Group>
                    }
                    <Form.Group md="4" className="col-md-4">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        required
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
                    </Form.Group>
                    {this.props.isEdit &&
                      <Form.Group md="4" className="col-md-4">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="********"
                          autoComplete="nofill"
                          onChange={this.handleChange}
                          value={this.state.password}
                          name="password"
                        />
                        <Form.Control.Feedback type="invalid">Please enter password</Form.Control.Feedback>
                      </Form.Group>
                    }
                    <Form.Group md="4" className="col-md-4">
                      <Form.Label>Role</Form.Label>
                      <Form.Select
                        name="role"
                        required
                        value={this.state.role}
                        onChange={this.handleChange}
                      >
                        <option value="">Select Role</option>
                        <option value="1">Admin</option>
                        <option value="2">Standard User</option>
                        {/* <option value="3">Client</option> */}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">Please select role</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group md="4" className="col-md-4">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        name="status"
                        required
                        value={this.state.status}
                        onChange={this.handleChange}
                      >
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                      {/* <Form.Check
                                            type="checkbox"
                                            id="custom-switch"
                                            label="active"
                                            name="status"
                                            value={this.state.status}
                                            onChange={(event) => this.handleChange(event)}
                                            checked={this.state.status}
                                        /> */}
                      <Form.Control.Feedback type="invalid">Please select role</Form.Control.Feedback>
                    </Form.Group>
                    <div className="col-12 text-end">
                      {this.state.saveLoading === false &&
                        <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                          Save
                        </Button>
                      }
                      {this.state.saveLoading &&
                        <Button className="btn btn-sm btn-blue" variant="secondary" type="button">
                          <Spinner animation="border" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Button>
                      }
                      <Link to={Constants.users} className="btn btn-sm btn-blue ms-2" variant="secondary" >Cancel</Link>
                    </div>
                  </Row>
                </Form>
              }
              {this.state.loading &&
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AddUser;