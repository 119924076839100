import React from "react"
import { Link, NavLink } from "react-router-dom";
import { DashboardImg, ProjectImg, SideBarLogoImg, SidebarReLogoImg, UserDocImg, ProfileImg, InvestorsImg, PlatformImg, TemplateImg, UsersImg } from "./ImageConstants";
import Logout from "./Logout";
import * as Constants from "../partials/CommonConstant"
import { GetUser } from "./CommonController";

const SideMenu = (props) => {
  const userDetail = GetUser();

  const checkRole = () => {
    if (userDetail.role === 1) {
      return true;
    }
    return false;
  }

  const checkRoleForClient = () => {
    if (userDetail.role !== 3) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="l-sidebar">
        <div className="logo">
        </div>
        <div className="l-sidebar__content">
          <Link to={Constants.dashboard} className="logo-info">
            <img src={SideBarLogoImg} alt="sidebar-logo" className="expaned-img" />
            <img src={SidebarReLogoImg} alt="sidebar-logo" className="reduced-img" />
          </Link>
          <nav className="c-menu js-menu">
            <ul className="u-list">
              {checkRoleForClient() &&
                <NavLink to={Constants.dashboard} className={(menu) => {
                  return (
                    menu.isActive ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                  )
                }} data-toggle="tooltip" title="Dashboard">
                  <div className="c-menu__item__inner">
                    <span className="menu-icon-block">
                      <img src={DashboardImg} alt="Dashboard" />
                    </span>
                    <div className="c-menu-item__title"><span>Dashboard</span></div>
                  </div>
                </NavLink>
              }
              {checkRoleForClient() &&
                <NavLink className={(menu) => {
                  return (
                    props.location.pathname.includes("project") ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                  )
                }}
                  to={Constants.projectList} data-toggle="tooltip" title="Projects">
                  <div className="c-menu__item__inner">
                    <span className="menu-icon-block">
                      <img src={ProjectImg} alt="Projects" />
                    </span>
                    <div className="c-menu-item__title"><span>Projects</span></div>
                  </div>
                </NavLink>
              }
              {checkRole() &&
                <NavLink className={(menu) => {
                  return (
                    props.location.pathname.includes("user") ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                  )
                }}
                  to={Constants.users} data-toggle="tooltip" title="Users">
                  <div className="c-menu__item__inner">
                    <span className="menu-icon-block">
                      <img src={UsersImg} alt="Users" />
                    </span>
                    <div className="c-menu-item__title"><span>Users</span></div>
                  </div>
                </NavLink>
              }
              {/* <NavLink className={(menu) => {
                return (
                  (props.location.pathname.includes("files") || props.location.pathname.includes("add-file")
                    || props.location.pathname.includes("edit-file") || props.location.pathname.includes("view-file")
                    || props.location.pathname.includes("download-logs") || props.location.pathname.includes("add-folder")
                    || props.location.pathname.includes("view-folder")
                    || props.location.pathname.includes("edit-folder") || props.location.pathname.includes("upload-folder")) ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                )
              }}
                to={"#"} onClick={() => window.open("http://docman.quadringroup.com", "_blank")} data-toggle="tooltip" title="Docman">
                <div className="c-menu__item__inner">
                  <span className="menu-icon-block">
                    <img src={UserDocImg} alt="Users Documents" />
                  </span>
                  <div className="c-menu-item__title"><span>Docman</span></div>
                </div>
              </NavLink> */}
              {checkRole() &&
                <NavLink className={(menu) => {
                  return (
                    props.location.pathname.includes("investor") ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                  )
                }}
                  to={Constants.investors} data-toggle="tooltip" title="Investors">
                  <div className="c-menu__item__inner">
                    <span className="menu-icon-block">
                      <img src={InvestorsImg} alt="Investors" />
                    </span>
                    <div className="c-menu-item__title"><span>Investors</span></div>
                  </div>
                </NavLink>
              }
              {checkRole() &&
                <NavLink className={(menu) => {
                  return (
                    props.location.pathname.includes("platform") ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                  )
                }}
                  to={Constants.platforms} data-toggle="tooltip" title="Platforms">
                  <div className="c-menu__item__inner">
                    <span className="menu-icon-block">
                      <img src={PlatformImg} alt="Platforms" />
                    </span>
                    <div className="c-menu-item__title"><span>Platforms</span></div>
                  </div>
                </NavLink>
              }
              {checkRole() &&
                <NavLink className={(menu) => {
                  return (
                    props.location.pathname.includes("template") || props.location.pathname.includes("questions") ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                  )
                }}
                  to={Constants.templates} data-toggle="tooltip" title="Templates">
                  <div className="c-menu__item__inner">
                    <span className="menu-icon-block">
                      <img src={TemplateImg} alt="Templates" />
                    </span>
                    <div className="c-menu-item__title"><span>Templates</span></div>
                  </div>
                </NavLink>
              }
              <NavLink to={Constants.profile} className={(menu) => {
                return (
                  (props.location.pathname.includes("profile") || props.location.pathname.includes("site-logs") || props.location.pathname.includes("session-logs")) ? "c-menu__item has-submenu is-active" : "c-menu__item has-submenu"
                )
              }} data-toggle="tooltip" title="Profile">
                <div className="c-menu__item__inner">
                  <span className="menu-icon-block">
                    <img src={ProfileImg} alt="Profile" />
                  </span>
                  <div className="c-menu-item__title"><span>Profile</span></div>
                </div>
              </NavLink>
              <Logout logoutType="sidemenu" />
            </ul>
          </nav>

          <div className="sidebar-img-wrap">
            <div className="heading">
              <h2>Qflow</h2>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default SideMenu