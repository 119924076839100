
import React from 'react'
import Layout from './common/Layout';
import './css/bootstrap.min.css';
import './css/style.css';
import './css/devstyle.css';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';

function App(props) {

  // const state = useSelector((state) => state)
  axios.interceptors.response.use(
    (response) => {
      if (response.data.message === "Unauthorized Access") {
        window.location.pathname = "/login";
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.data !== undefined) {
        if (error.response.data.message === "Unauthorized Access") {
          window.location.pathname = "/login";
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      <div>
        <Layout />
      </div>
    </div>
  );
}

export default App;
