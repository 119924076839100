import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Forgotpassword from "../Forgotpassword"
import Login from "../Login"
import * as Constants from "../partials/CommonConstant"
import Resetpassword from "../Resetpassword"
import ManageToken from "./ManageToken"
import SideMenu from "./SideMenu"
import Header from "../partials/Header"
import VerfiyEmail from "../VerifyEmail"
import React from "react"
import RouteList from "./RouteList"
import { GetUser } from "./CommonController"
import ThankYou from "../pages/ThankYou"
import SessionTimeout from "./SessionTimeout"
import VerifyShortcut from "./VerifyShortcut"

const Layout = () => {
  const userDetail = GetUser();
  const { token, setToken } = ManageToken();
  let location = useLocation()
  // console.log("layout", token, location)

  /* useEffect(() => {
      if (getToken() === null || getToken() === "") {
          setIsToken(false)
      }
      else {
          setIsToken(true)
      }
  }, [getToken()]) */

  return (
    <>
      {(!token) &&
        <>
          <Routes>
            <Route path='/' element={<Login setToken={setToken} />} />
            {/* <Route exact path='/*' element={<Login setToken={setToken} />} /> */}
            <Route exact path={Constants.Login} element={<Login setToken={setToken} />} />
            <Route exact path={Constants.ForgotPassword} element={<Forgotpassword />} />
            <Route path={Constants.ResetPassword} element={<Resetpassword />} />
            <Route path={Constants.VerifyUser} element={<VerfiyEmail />} />
            <Route path={"/redirect-url/*"} location={location} setToken={setToken} element={<VerifyShortcut location={location} />} />
            <Route path='/*' element={<Navigate to="/" />} />
            <Route path='/confirm-delete' exact element={<ThankYou setToken={setToken} />} />
          </Routes>
        </>
      }
      {token &&
        <>
          {!location.pathname.includes("fileviewer") &&
            <>
              <Header location={location} />
              <SideMenu location={location} />
            </>
          }
          <main className={location.pathname.includes("fileviewer") ? "" : "l-main"}>
            <div className={location.pathname.includes("fileviewer") ? "" : "content-wrapper content-wrapper--with-bg"}>
              <RouteList roleid={userDetail.role} />
            </div>
            <SessionTimeout isAuthenticated={true} />
          </main>
        </>
      }
    </>
  )
}
export default Layout