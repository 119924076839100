import React from "react"
import { Spinner } from "react-bootstrap"
import deleteddataApp from "../Shared/Controller/deleteddataApp"
import { RestoreImg, DeleteImg } from "../common/ImageConstants";
import { Link } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import ConfirmModal from "../common/ConfirmModal";
import NotFound from "../common/NotFound";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import TempalateDataTable from "../template/TempalateDataTable";

class DeletedTemplates extends deleteddataApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      templateList: [],
      showDeleteModal: false,
      showRestoreModal: false,
      templateId: "",
    }
  }
  componentDidMount = () => {
    this.getTemplates();
  }

  callbackDeleteTemplate = () => {
    this.getTemplates();
  }

  getColumnData = (data) => {
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon text-center">
          <Link to="/" className="ms-2" onClick={(e) => this.onClickTemplateRestore(e, data.item.id)}>
            <img src={RestoreImg} alt="Restore" title="Restore" />
          </Link>
          <Link to="/" className="ms-2" onClick={(e) => this.onClickPermenantDeleteTemplate(e, data.item.id)}>
            <img src={DeleteImg} alt="Permenant Delete" title="Permenant Delete" />
          </Link>
        </div>
      );
    }
    return columnData;
  }

  render() {
    // Create table headers
    const data = this.state.templateList;

    const columns = [
      {
        name: "Template Name",
        selector: row => row.name,
        sortable: true
      },
      {
        name: "Project Name",
        selector: row => row.projectname,
        sortable: true,
      },
      {
        name: "# Of Questions",
        selector: row => row.totalquestions,
        sortable: true
      },
      {
        name: "Action",
        center: true,
        cell: d => { return this.getColumnData({ type: "action", item: d }) }
      }
    ];

    const tableData = {
      columns,
      data
    };
    return (
      <>
        <div className="dashboard-card-wrapper deleted-page-data">
          <ConfirmModal show={this.state.showDeleteModal} type="permanentDelete"
            onCloseModal={() => this.onDeleteCloseModal()}
            module="template"
            confirmClick={(e) => this.handlePermanentDelete(e, 'Template')} />
          <ConfirmModal show={this.state.showRestoreModal} type="restore"
            onCloseModal={() => this.onRestoreCloseModal()}
            module="template"
            confirmClick={(e) => this.handleRestore(e, 'Template')} />
          <div className="row align-items-center">
            <div className="col-12 text-end">
              <Link to={Constants.templates} className="btn btn-blue btn-nex">Active Templates</Link>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
            <div className="card-main p-0">
              <div className="dark-table-wrapper table-scroll data-table">
                {this.state.templateList && this.state.templateList.length !== 0 && this.state.loading === false &&
                  <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                    {/* <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                    /> */}
                    <TempalateDataTable
                      {...tableData}
                      columns={columns}
                      permanentDelete={1}
                      callbackDeleteTemplate={this.callbackDeleteTemplate}
                    />
                  </DataTableExtensions>
                }
                {this.state.loading === true &&
                  <table className="mb-0 table">
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }

                {/* Not found */}
                {this.state.loading === false && this.state.templateList && this.state.templateList.length === 0 &&
                  <NotFound message="No Data Found" type="content" />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default DeletedTemplates