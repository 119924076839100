import React, { useContext } from "react";
import { ReviewContext } from "../context/ReviewContext";
import { getQuestionsCols } from "../Shared/Controller/projectReviewApp";
import ViewRowQuestions from "./ViewRowQuestions";

const ViewDelinquencyQuestion = () => {
  const DelinquencyProps = useContext(ReviewContext);

  return (
    <React.Fragment>
      {DelinquencyProps.RowData.AllQuestions.delinquency_questions &&
        <div className={getQuestionsCols({ typesOfQuestion: Object.keys(DelinquencyProps.RowData.AllQuestions).length, type: "delinquency" }) + " mb-3"}>
          <div className="card-main">
            <h3>Delinquency</h3>
            {DelinquencyProps.RowData.AllQuestions.delinquency_questions.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <ViewRowQuestions item={item}
                    index={index}
                  />
                </React.Fragment>
              )
            })
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}
export default ViewDelinquencyQuestion;