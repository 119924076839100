import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import * as Constants from "../partials/CommonConstant"
import ManageToken from "./ManageToken"
import Home from "../pages/Home"
import Users from "../pages/Users"
import AddEditUser from "../user/AddEditUser"
import Investores from "../pages/Investores"
import AddEditInvestor from "../investor/AddEditInvestor"
import Platforms from "../pages/Platforms"
import AddEditPlatform from "../platform/AddEditPlatform"
import EditProfile from "../pages/EditProfile"
import Profile from "../pages/Profile"
import ViewUser from "../user/ViewUser"
import ProjectList from "../pages/ProjectList"
import ArchivedList from "../pages/ArchivedList"
import Project from "../pages/Project"
import Templates from "../pages/Templates"
import AddEditTemplate from "../template/AddEditTemplate"
import ViewTemplate from "../template/ViewTemplate"
import DeletedInvestores from "../deleteddata/DeletedInvestores"
import DeletedPlatforms from "../deleteddata/DeletedPlatforms"
import DeletedUsers from "../deleteddata/DeletedUsers"
import DeletedTemplates from "../deleteddata/DeletedTemplates"
import DeletedQuestions from "../deleteddata/DeletedQuestions"
import ViewProject from "../projects/ViewProject";
import ReviewProject from "../pages/ReviewProject";
import AllFiles from "../pages/AllFiles";
import ViewFile from "../files/ViewFile";
import AddEditFile from "../files/AddEditFile";
import IntegrityProject from "../pages/IntegrityProject";
import DownloadLogs from "../pages/DownloadLogs";
import SessionLogs from "../pages/SessionLogs";
import SiteLogs from "../pages/SiteLogs";
import ThankYou from "../pages/ThankYou";
import AddEditFolder from "../files/AddEditFolder";
import ViewFolder from "../files/ViewFolder";
import UploadFolder from "../files/UploadFolder";
import ProjectRecords from "../pages/ProjectRecords";
import DeletedProjects from "../deleteddata/DeletedProjects";
import DocumentViewer from "../files/DocumentViewer";
import VerifyShortcut from "./VerifyShortcut";
import IntegrityView from "../pages/IntegrityView";
import ViewReview from "../pages/ViewReview";


const RouteList = (props) => {
    const { token, setToken } = ManageToken();
    let location = useLocation()


    return (
        <Routes>
            {/* Admin Routes */}
            {props.roleid === 1 &&
                <React.Fragment>
                    <Route path='/' element={<Home location={location} setToken={setToken} />} />
                    <Route path={Constants.home} element={<Home location={location} setToken={setToken} />} />
                    <Route path={Constants.users} element={<Users location={location} />} />
                    <Route path={Constants.addUser} element={<AddEditUser location={location} {...props} />} />
                    <Route path={Constants.editUser} element={<AddEditUser location={location} {...props} />} />
                    <Route path={Constants.investors} element={<Investores />} />
                    <Route path={Constants.addInvestores} element={<AddEditInvestor location={location} {...props} />} />
                    <Route path={Constants.editInvestores} element={<AddEditInvestor location={location} {...props} />} />
                    <Route path={Constants.platforms} element={<Platforms />} />
                    <Route path={Constants.addPlatform} element={<AddEditPlatform location={location} {...props} />} />
                    <Route path={Constants.editPlatform} element={<AddEditPlatform location={location} {...props} />} />
                    <Route path={Constants.editProfile} element={<EditProfile location={location} {...props} />} />
                    <Route path={Constants.profile} element={<Profile location={location} {...props} />} />
                    <Route path={Constants.viewUser} element={<ViewUser location={location} {...props} />} />
                    <Route path={Constants.projectList} element={<ProjectList location={location} {...props} />} />
                    <Route path={Constants.archived} element={<ArchivedList location={location} {...props} />} />
                    <Route path={Constants.addProject} element={<Project location={location} {...props} />} />
                    <Route path={Constants.templates} element={<Templates />} />
                    <Route path={Constants.viewTemplate} element={<ViewTemplate location={location} {...props} />} />
                    <Route path={Constants.addTemplate} element={<AddEditTemplate location={location} {...props} />} />
                    <Route path={Constants.editTemplate} element={<AddEditTemplate location={location} {...props} />} />
                    <Route path={Constants.deletedInvestores} element={<DeletedInvestores location={location} {...props} />} />
                    <Route path={Constants.deletedPlatforms} element={<DeletedPlatforms location={location} {...props} />} />
                    <Route path={Constants.deletedUsers} element={<DeletedUsers location={location} {...props} />} />
                    <Route path={Constants.deletedTemplates} element={<DeletedTemplates location={location} {...props} />} />
                    <Route path={Constants.deletedQuestions} element={<DeletedQuestions location={location} {...props} />} />
                    <Route path={Constants.editProject} element={<Project location={location} {...props} />} />
                    <Route path={Constants.viewProject} element={<ViewProject location={location} {...props} />} />
                    <Route path={Constants.reviewProject} element={<ReviewProject location={location} {...props} />} />
                    {/* <Route path={Constants.integrityProject} element={<ReviewProject location={location} {...props} />} /> */}
                    <Route path={Constants.allFiles} element={<AllFiles location={location} {...props} />} />
                    <Route path={Constants.viewFile} element={<ViewFile location={location} {...props} />} />
                    <Route path={Constants.addFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addFileParent} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.editFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.integrityProject} element={<IntegrityProject location={location} {...props} />} />
                    <Route path={Constants.downlodLogs} element={<DownloadLogs location={location} {...props} />} />
                    <Route path={Constants.sessionLogs} element={<SessionLogs location={location} {...props} />} />
                    <Route path={Constants.siteLogs} element={<SiteLogs location={location} {...props} />} />
                    <Route path={Constants.addFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.editFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.editFolderFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addSubFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.addFolderFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.viewFolder} element={<ViewFolder location={location} {...props} />} />
                    <Route path={Constants.uploadFolder} element={<UploadFolder location={location} {...props} />} />
                    <Route path={Constants.projectRecords} element={<ProjectRecords location={location} {...props} />} />
                    <Route path={Constants.deletedProjects} element={<DeletedProjects location={location} {...props} />} />
                    <Route path={Constants.docViewer} location={location} setToken={setToken} element={<DocumentViewer />} />
                    <Route path={"/redirect-url/*"} location={location} setToken={setToken} element={<VerifyShortcut location={location} {...props} />} />
                    <Route path='/confirm-delete' exact element={<ThankYou setToken={setToken} />} />
                    <Route path='/integrity-view/:project_id' exact element={<IntegrityView location={location} {...props} />} />
                    <Route path='/review-view/:project_id' exact element={<ViewReview location={location} {...props} />} />
                    {/* <Route path="/workflow-laravel/public/files/imports/*" element="" /> */}
                </React.Fragment>
            }
            {/* Standard user Routes */}
            {props.roleid === 2 &&
                <React.Fragment>
                    <Route path='/' element={<Home location={location} setToken={setToken} />} />
                    <Route path={Constants.home} element={<Home location={location} setToken={setToken} />} />
                    <Route path={Constants.editProfile} element={<EditProfile location={location} {...props} />} />
                    <Route path={Constants.profile} element={<Profile location={location} {...props} />} />
                    <Route path={Constants.reviewProject} element={<ReviewProject location={location} {...props} />} />
                    <Route path={Constants.projectList} element={<ProjectList location={location} {...props} />} />
                    <Route path={Constants.viewProject} element={<ViewProject location={location} {...props} />} />
                    <Route path={Constants.allFiles} element={<AllFiles location={location} {...props} />} />
                    <Route path={Constants.viewFile} element={<ViewFile location={location} {...props} />} />
                    <Route path={Constants.addFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.editFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addFileParent} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.integrityProject} element={<IntegrityProject location={location} {...props} />} />
                    <Route path={Constants.addFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.editFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.editFolderFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addSubFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.addFolderFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.viewFolder} element={<ViewFolder location={location} {...props} />} />
                    <Route path={Constants.projectRecords} element={<ProjectRecords location={location} {...props} />} />
                    <Route path={Constants.uploadFolder} element={<UploadFolder location={location} {...props} />} />
                    <Route path={Constants.docViewer} location={location} setToken={setToken} element={<DocumentViewer />} />
                    <Route path={"/redirect-url/*"} location={location} setToken={setToken} element={<VerifyShortcut location={location} {...props} />} />
                    <Route path='/confirm-delete' exact element={<ThankYou setToken={setToken} />} />
                    <Route path='/integrity-view/:project_id' exact element={<IntegrityView location={location} {...props} />} />
                    <Route path='/review-view/:project_id' exact element={<ViewReview location={location} {...props} />} />
                </React.Fragment>
            }
            {props.roleid === 3 &&
                <React.Fragment>
                    <Route path='/' element={<AllFiles location={location} setToken={setToken} />} />
                    <Route path={Constants.home} element={<AllFiles location={location} setToken={setToken} />} />
                    <Route path={Constants.editProfile} element={<EditProfile location={location} {...props} />} />
                    <Route path={Constants.profile} element={<Profile location={location} {...props} />} />
                    <Route path={Constants.allFiles} element={<AllFiles location={location} {...props} />} />
                    <Route path={Constants.viewFile} element={<ViewFile location={location} {...props} />} />
                    <Route path={Constants.addFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.editFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addFileParent} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.editFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.editFolderFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.addSubFolder} element={<AddEditFolder location={location} {...props} />} />
                    <Route path={Constants.addFolderFile} element={<AddEditFile location={location} {...props} />} />
                    <Route path={Constants.viewFolder} element={<ViewFolder location={location} {...props} />} />
                    <Route path={Constants.uploadFolder} element={<UploadFolder location={location} {...props} />} />
                    <Route path={Constants.docViewer} location={location} setToken={setToken} element={<DocumentViewer />} />
                    <Route path={"/redirect-url/*"} location={location} setToken={setToken} element={<VerifyShortcut location={location} {...props} />} />
                    <Route path='/confirm-delete' exact element={<ThankYou setToken={setToken} />} />
                </React.Fragment>
            }
            <Route path='/*' element={<Navigate to="/" />} />
        </Routes>
    )
}

export default RouteList;