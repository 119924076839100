import React, { useState } from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import { getFormattedDate } from "../common/CommonController";
import ProjectColsDetails from "./ProjectColsDetails";
import ProjectIntegrityOverView from "./ProjectIntegrityOverView";
import ProjectReviewOverview from "./ProjectReviewOverview";

const ProjectViewAdmin = (props) => {
  const [key, setKey] = useState('project_detail');

  return (
    <React.Fragment>
      <Tabs
        id="project_overview-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="project_detail" title="Detail">
          <Row>
            <ProjectColsDetails Col_Type="project_name" Col_Lable="Project Name" Col_Value={props.projectDetail.project_name} />
            <ProjectColsDetails Col_Type="identifier" Col_Lable="Identifier" Col_Value={props.projectDetail.identifier} />
            <ProjectColsDetails Col_Type="investor" Col_Lable="Investor" projectDetail={props.projectDetail} />
            <ProjectColsDetails Col_Type="platform" Col_Lable="Platform" projectDetail={props.projectDetail} />
            <ProjectColsDetails Col_Type="created_by" Col_Lable="Owner" Col_Value={props.projectDetail.created_user} />
            <ProjectColsDetails Col_Type="created_date" Col_Lable="Created Date" Col_Value={getFormattedDate(props.projectDetail.created_at)} />
            <ProjectColsDetails Col_Type="status" Col_Lable="Status" projectDetail={props.projectDetail} />
          </Row>
        </Tab>
        {props.projectDetail.only_review !== 1 &&
          <Tab eventKey="integrity" title="Integrity">
            <ProjectIntegrityOverView projectDetail={props.projectDetail} />
          </Tab>
        }
        {props.projectDetail.only_integrity !== 1 &&
          <Tab eventKey="review" title="Review">
            <ProjectReviewOverview projectDetail={props.projectDetail} />
          </Tab>
        }
      </Tabs>
    </React.Fragment>
  )
}
export default ProjectViewAdmin;