import React, { useState } from 'react'
import axios from 'axios'
import * as Constants from "./partials/CommonConstant";
import * as ImgConstants from "./common/ImageConstants";
import { Link } from 'react-router-dom';
import LoginLeftBlock from './LoginLeftBlock';
import { Spinner } from 'react-bootstrap';
import { hasErrorFor, renderErrorFor, renderSuccessMessage, renderErrorMessage } from "./Shared/Controller/authApp";

const Forgotpassword = (props) => {
  const [username, setUserName] = useState("");
  const [success_message, setSuccessMsg] = useState("");
  const [error_message, setErrorMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (event) => {
    var allerrors = errors
    delete allerrors[event.target.name];
    setUserName(event.target.value);
    setErrors(allerrors);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    let send_data = {
      username: username,
    }
    setErrors([]);
    setSuccessMsg("");
    setErrorMsg("");
    setLoading(true);
    axios.post(Constants.UserForgotPassword, send_data)
      .then(response => {
        if (response.data.code == 1) {
          // For success
          setSuccessMsg(response.data.message);
          setUserName("");
        } else {
          // console.log(response.data.message.constructor);
          if (response.data.message.constructor === Object) {
            setErrors(response.data.message);
          } else {
            setErrorMsg(response.data.message);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setErrors(error.response.data.errors);
      })
  }

  return (
    <div className='main'>
      <div className='login-form-block'>
        <div className='mobile-logo'>
          <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} className='' />
          <div className='heading'>
            <h2>Qflow</h2>
          </div>
        </div>

        <div className='login-banner-block'>
          <img src={ImgConstants.LoginBanner} alt={Constants.ImageALT} title={Constants.ImageTitle} />
        </div>
        <div className='login-form-box-main'>
          <LoginLeftBlock />
          <div className='login-form-right-block'>
            <div className='login-form-logo'>
              <img src={ImgConstants.FormLogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} />
            </div>
            <form onSubmit={handleFormSubmit}>
              <h4>Forgot Password</h4>
              {renderSuccessMessage(success_message)}
              {renderErrorMessage(error_message)}
              <div className='mb-3 form-block'>
                <label htmlFor='username' className='form-label'>Email or Username</label>
                <input type='text' name='username' className={`username-icon form-control ${hasErrorFor({ field: 'username', errors: errors }) ? 'is-invalid' : ''}`} id='username' placeholder='Enter Your Email or Username' value={username} onChange={handleFieldChange} />
                {renderErrorFor({ field: 'username', errors: errors })}
              </div>
              <div className='form-btn'>
                <Link to={Constants.Login}>Login</Link>
                {loading === false &&
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                }
                {loading === true &&
                  <button type='button' className='btn btn-primary'>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </button>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Forgotpassword