import axios from "axios";
import { AppServices, HttpHeaders } from "../Services/AppServices";
import CommonServices from "../Services/CommonServices";

export const GetDashboadDetail = async () => {
  let response = CommonServices.GetDashboadDetail();
  return response;
}

export const getChartData = (chartData) => {
  let cdata = {
    data: {
      type: 'doughnut',
      labels: ['Completed', 'Active', 'Incomplete'],
      datasets: [{
        label: '# of Projects',
        data: [chartData.project_summary.projects_completed, chartData.project_summary.projects_in_progress, chartData.project_summary.projects_incomplete_creation],
        backgroundColor: [
          '#2b7c22',
          '#edb925',
          '#a93d0a',
        ],
        borderColor: [
          '#cbfdc5',
          '#efce70',
          '#ff9c6d',
        ],
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true
        }
      },
    },
    redraw: true,
  }
  return cdata;
}

export const VerifyConfirmDelete = async (data) => {
  let response = CommonServices.VerifyConfirmDelete(data);
  return response;
}

export const DeleteProject = async (data) => {
  return axios.post(AppServices.DELETE_PROJECT(data.projectId), data, HttpHeaders())
}

export const ExportData = async (data) => {
  return axios.post(AppServices.GET_EXPORT_DATA(), data, HttpHeaders());
}

export const GetShortcutURL = async (data) => {
  let response = CommonServices.GetShortcutURL(data);
  return response;
}

export const shortImportStatus = (rowA, rowB) => {
  let sort = 0;
  const a = getImportStatus(rowA.import_status);
  const b = getImportStatus(rowB.import_status);

  if (a > b) {
    sort = 1;
  }
  if (b > a) {
    sort = -1;
  }
  return sort;
};

export const getImportStatus = (value) => {
  if (value === 0) {
    return "Failed";
  }
  if (value === 1) {
    return "Completed";
  }
  if (value === 2) {
    return "In Progress";
  }
}

export const VerifyFileToken = async (data) => {
  let response = CommonServices.VerifyFileToken(data);
  return response;
}

export const MultipleDeleteTemplate = async (data) => {
  return axios.post(AppServices.POST_MULTIPLE_TEMPLATE_DELETE(), data, HttpHeaders());
}