import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getFileType, GetUser, ShowToast } from "../../common/CommonController";
import { DeleteImg, DocViewImage, DownloadImg, EditImg, EyeImg } from "../../common/ImageConstants";
import FilesServices from "../Services/FilesServices";
import UserServices from "../Services/UserServices";

export const getAllFiles = async (data) => {
  let response = FilesServices.GetAllFiles(data);
  return response;
}

export const DownloadFile = async (data) => {
  let response = FilesServices.downloadFile(data);
  return response;
}

export const GetFileDetail = async (fileId) => {
  let response = FilesServices.getFile(fileId);
  return response;
}

export const GetParentFiles = async (data) => {
  let response = FilesServices.GetAllFiles(data);
  return response;
}

export const GetAllUsers = async (data) => {
  let response = UserServices.getUsers(data);
  return response;
}

export const SaveFile = async (data) => {
  let response = FilesServices.AddFile(data);
  return response;
}

export const UpdateFile = async (data) => {
  let response = FilesServices.UpdateFile(data);
  return response;
}

export const DeleteFile = async (fileId) => {
  let response = FilesServices.DeleteFile(fileId);
  return response;
}

export const SaveFolder = async (data) => {
  let response = FilesServices.SaveFolder(data);
  return response;
}

export const UpdateFolder = async (data) => {
  let response = FilesServices.UpdateFolder(data);
  return response;
}

export const GetFolderDetail = async (folderid) => {
  let response = FilesServices.getFolder(folderid);
  return response;
}

export const DeleteFolder = async (id) => {
  let response = FilesServices.DeleteFolder(id);
  return response;
}

export const GetUploadFolderFiles = async (data) => {
  let response = FilesServices.GetUploadFolderFiles(data);
  return response;
}

export const DeleteMultipleDocument = async (data) => {
  let response = FilesServices.DeleteMultipleDocument(data);
  return response;
}
export const ArchiveProjectData = async (data) => {
  let response = FilesServices.ArchiveProjectData(data);
  return response;
}
export const UnarchiveProjectData = async (data) => {
  let response = FilesServices.UnarchiveProjectData(data);
  return response;
}




export const downloadFile = (e, id, filename) => {
  e.preventDefault()
  if (id) {
    let data = {
      fileid: id,
    }
    DownloadFile(data).then(response => {
      if (response.data.code === 1) {
        let r = response.data.data.download_url;
        const a = document.createElement('a');
        a.setAttribute('href', r);
        a.setAttribute("download", filename);
        a.target = "_blank";
        a.click();
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }
}

export const add3Dots = (string, limit) => {
  var dots = "...";
  if (string.length > limit) {
    // you can also use substr instead of substring
    string = string.substring(0, limit) + dots;
  }
  return string;
}

export const onDubleClickhandle = (data) => {
  console.log("onDubleClickhandle", data);
  if (data.document_type === "file") {
    if (checkIsImage(data.original_filename)) {
      getFilePathForView({}, data.id)
    }
    else {
      window.open("/fileviewer/" + data.id, "_blank");
    }
  }
  else {
    data.props.navigate("/view-folder/" + data.id);
  }
}

export const getFileName = (item) => {
  return (
    <>
      <div onDoubleClick={() => onDubleClickhandle(item)} className="up-icon">{getFileType(item.document_type === "folder" ? "folder" : item.original_filename)}</div>
      <p onDoubleClick={() => onDubleClickhandle(item)} className="up-filename" title={item.document_type === "folder" ? item.foldername : item.original_filename}>
        {item.document_type === "folder" ? item.foldername : item.original_filename}
        <span className="up-auth" title={item.owner_name}>Owner: {item.owner_name}</span>
      </p>
    </>

  )
}

export const getTableColumns = (props) => {
  let table_columns = [];
  if (props && props.type === "root") {
    table_columns = [
      {
        name: "Folder/File Name",
        width: "45%",
        selector: row => row.document_type === "folder" ? row.foldername : row.original_filename,
        cell: d => { return getFileName({ ...d, props }) },
      },
      {
        name: "Document Type",
        selector: row => row.document_type,
        cell: d => { return d.document_type.charAt(0).toUpperCase() + d.document_type.slice(1) },
        sortable: true,
      },
      /* {
        name: "Type",
        selector: row => row.type,
        sortable: true,
      }, */
      {
        name: "Project",
        selector: row => row.project_name,
        sortable: true,
      },
      /* {
        name: "Parent File",
        selector: row => row.parent_filename,
        sortable: true,
      }, */
      {
        name: "Action",
        className: "text-center",
        width: "25%",
        cell: d => { return getColumnData({ type: "action", item: d }, props) },
      }
    ]
  }
  else {
    table_columns = [
      {
        name: "Folder/File Name",
        selector: row => row.original_filename,
        cell: d => { return d.document_type === "folder" ? d.foldername : d.original_filename },
        // sortable: true.valueOf,
      },
      {
        name: "Document Type",
        selector: row => row.document_type,
        cell: d => { return d.document_type.charAt(0).toUpperCase() + d.document_type.slice(1) },
        sortable: true,
      },
      {
        name: "Type",
        selector: row => row.type,
        sortable: true,
      },
      {
        name: "Project",
        selector: row => row.project_name,
        sortable: true,
      },
      {
        name: "Parent File",
        selector: row => row.parent_filename,
        sortable: true,
      },
      {
        name: "Owner",
        selector: row => row.owner_name,
        sortable: true,
        omit: (props.mineOnly === 1) ? true : false,

      },
      {
        name: "Action",
        className: "text-center",
        width: "25%",
        cell: d => { return getColumnData({ type: "action", item: d }, props) },
      }
    ]
  }
  return table_columns;
}

export const checkIsImage = (filename) => {
  let isImage = false;
  if (filename.includes(".png") || filename.includes(".jpeg") || filename.includes(".jpg") || filename.includes(".gif") || filename.includes(".txt")) {
    isImage = true;
  }
  return isImage;
}

export const getFilePathForView = (e, fileId) => {
  // e.preventDefault()
  DownloadFile({ fileid: fileId }).then(response => {
    if (response.data.code === 1) {
      let r = response.data.data.download_url;
      window.open(r);
      /* const a = document.createElement('a');
      a.setAttribute('href', r);
      // a.setAttribute("download", filename);
      a.target = "_blank";
      a.click(); */
    }
    else {
      if (response.data.message.constructor === Object) {
        ShowToast("object", "error", response.data.message)
      }
      else {
        ShowToast("message", "error", response.data.message)
      }
    }
  }).catch(error => {
    ShowToast("message", "error", "" + error);
  });
}

export const getColumnData = (data, props) => {
  let userDetail = GetUser();
  let columnData = "";
  if (data.type === "action") {
    return (
      <div className="edit-icon text-center">
        {(data.item.permission === "3") && data.item.document_type !== "folder" &&
          <Link to="#" onClick={(e) => props.downloadFile(e, data.item.id, data.item.filename)} name={data.item.id} className="ms-2">
            <img src={DownloadImg} alt="Download" title="Download" />
          </Link>
        }
        {(data.item.permission !== "1") && data.item.document_type !== "folder" &&
          <React.Fragment>
            {!checkIsImage(data.item.original_filename) &&
              <Link to={"/fileviewer/" + data.item.id}
                // onClick={(e) => props.downloadFile(e, data.item.id, data.item.filename)} 
                name={data.item.id}
                target="_blank"
                className="ms-2 view-file-link">
                <img src={DocViewImage} alt="View Document" title="View Document" />
              </Link>
            }
            {checkIsImage(data.item.original_filename) &&
              <Link to={"#"}
                onClick={(e) => getFilePathForView(e, data.item.id)}
                name={data.item.id}
                className="ms-2">
                <img src={DocViewImage} alt="View Document" title="View Document" />
              </Link>
            }
          </React.Fragment>
        }
        {(((data.item.permission !== "1") || (userDetail.role === 1))) && // !window.location.pathname.includes("view-folder")) &&
          <Link to={data.item.document_type === "file" ? "/view-file/" + data.item.id : "/view-folder/" + data.item.id} className="ms-2">
            <img src={EyeImg} alt="View" title="View" />
          </Link>
        }
        {(((data.item.permission === "3") || (userDetail.role === 1))) && // !window.location.pathname.includes("view-folder")) &&
          <>
            <Link to={data.item.document_type === "file" ? "/edit-file/" + data.item.id : "/edit-folder/" + data.item.id} className="ms-2">
              <img src={EditImg} alt="Edit" title="Edit" />
            </Link>
            <Link to="/" className="ms-2" onClick={(e) => props.onClickDeleteFile(e, data.item)}>
              <img src={DeleteImg} alt="Delete" title="Delete" />
            </Link>
          </>
        }
      </div>
    );
  }
  return columnData;
}