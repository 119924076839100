import axios from "axios"
import { AppServices, HttpHeaders } from "../Services/AppServices";

const UserServices = {
    getUsers: (data = {}) => {
        const header = HttpHeaders();
        return axios.get(AppServices.GET_USERS(), {
            params: data,
            headers: header.headers,
        })
    },
    saveUser: (data) => {
        if (data.userId) {
            return axios.post(AppServices.UPDATE_USER(data.userId), data, HttpHeaders())
        }
        else {
            return axios.post(AppServices.SAVE_USER(), data, HttpHeaders())
        }
    },
    getUser: (userId) => {
        return axios.get(AppServices.GET_USER(userId), HttpHeaders())
    },
    deleteUser: (data) => {
        return axios.post(AppServices.DELETE_USER(data.userId), data, HttpHeaders())
    },
    resendVerifyEmail: (userId) => {
        return axios.post(AppServices.RESEND_VERIFY_EMAIL(), { userid: userId }, HttpHeaders())
    },
    restoreUser: (data) => {
        return axios.post(AppServices.RESTORE_USER(data.userId), data, HttpHeaders())
    },
}

export default UserServices;
