import React from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import profileApp from "../Shared/Controller/profileApp";

class Edit extends profileApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      validated: false,
    }
  }

  handleChange = (event) => {
    if ([event.target.name] === "status") {
      this.setState({ [event.target.name]: event.target.value })
    }
    else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      let newData = { ...this.state }
      delete newData.loading
      delete newData.validated
      newData.userId = this.state.id
      this.updateProfile(newData);
    }
    this.setState({ validated: true });
  };

  onSuccess = (data) => {
    // localStorage.setItem('user', JSON.stringify(data.data))
    this.props.callBackSuccess(data)
  }

  componentDidMount = () => {
    this.setState({ ...this.props.profileData })
    // this.getUser(this.props.userId)
  }

  render() {
    return (
      <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
        <div className="card-main">
          <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
            <Row className="row g-4">
              <Form.Group md="4" className="col-md-4" controlId="validationCustom01">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  name="firstname"
                  value={this.state.firstname}
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
              </Form.Group>
              <Form.Group md="4" className="col-md-4">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  name="lastname"
                  value={this.state.lastname}
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group md="4" className="col-md-4">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  required
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Please enter user name</Form.Control.Feedback>
              </Form.Group> */}
              <Form.Group md="4" className="col-md-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
              </Form.Group>
              <Form.Group md="4" className="col-md-4">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  // required
                  type="password"
                  placeholder="********"
                  autoComplete="nofill"
                  onChange={this.handleChange}
                  value={this.state.password}
                  name="password"
                />
                <Form.Control.Feedback type="invalid">Please enter password</Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group md="4" className="col-md-4">
                    <Form.Label>Role</Form.Label>
                    <Form.Select
                      name="role"
                      required
                      value={this.state.role}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Role</option>
                      <option value="1">Admin</option>
                      <option value="2">Standard User</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Please select role</Form.Control.Feedback>
                  </Form.Group> */}
              <div className="col-12 text-end">
                {this.state.loading === false &&
                  <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                    Update
                  </Button>
                }
                {this.state.loading === true &&
                  <Button className="btn btn-sm btn-blue" variant="secondary" type="button">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Button>
                }
                <Link to={Constants.profile} className="btn btn-sm btn-blue ms-2" variant="secondary" >Cancel</Link>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    )
  }
}
export default connect(profileApp.mapStateToProps, profileApp.mapDispatchToProps)(Edit);