import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import ProjectServices from "../Shared/Services/ProjectServices";
import ProjectViewAdmin from "./ProjectViewAdmin";
import { GetUser } from "../common/CommonController";
import ProjectViewStdUser from "./ProjectViewStdUser";

const ViewProject = () => {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [project_id, setProjectId] = useState("");
  const [projectDetail, setProjectDetail] = useState({});
  let userDetail = GetUser();
  const navigate = useNavigate();

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    let project_id = "";
    if (splitPath.length > 2) {
      project_id = splitPath[2];
      setProjectId(project_id);
      getProjectDetail(project_id);
    }
  }, []);

  const getProjectDetail = (project_id) => {
    let ProjectDetail = {};
    if (project_id !== "") {
      setLoading(true);
      ProjectServices.getProjectDetail(project_id).then((response) => {
        if (response.data.code === 1) {
          setProjectDetail(response.data.data);
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setLoading(false);
      });
    }
    return ProjectDetail;
  }

  return (
    <>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end">
            {(projectDetail.status === 1 || projectDetail.status === 2) &&
              <Link to={"/project-records/" + project_id} className="btn btn-blue btn-nex me-2">View Project Records</Link>
            }
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        <div className="profile-block mt-3">
          <div className="card-main">
            <div className="profile-text-inner">
              {loading === false && Object.keys(projectDetail).length !== 0 &&
                <React.Fragment>
                  {userDetail.role === 1 &&
                    <ProjectViewAdmin
                      projectDetail={projectDetail}
                    />
                  }
                  {userDetail.role === 2 &&
                    <ProjectViewStdUser
                      projectDetail={projectDetail}
                    />
                  }
                </React.Fragment>
              }
              {loading &&
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ViewProject;