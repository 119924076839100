import axios from "axios"
import { AppServices, HttpHeaders } from "./AppServices";

const FilesServices = {
  GetAllFiles: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_ALL_FILES(), {
      params: data,
      headers: header.headers,
    })
  },
  downloadFile: (data) => {
    return axios.post(AppServices.DOWNLOAD_FILE(), data, HttpHeaders())
  },
  getFile: (fileId) => {
    return axios.get(AppServices.GET_FILE(fileId), HttpHeaders())
  },
  AddFile: (data) => {
    return axios.post(AppServices.ADD_FILE(), data, HttpHeaders())
  },
  DeleteFile: (fileId) => {
    return axios.post(AppServices.DELETE_FILE(fileId), {}, HttpHeaders())
  },
  UpdateFile: (data) => {
    return axios.post(AppServices.UPDATE_FILE(), data, HttpHeaders())
  },
  SaveFolder: (data) => {
    return axios.post(AppServices.SAVE_FOLDER(), data, HttpHeaders())
  },
  UpdateFolder: (data) => {
    return axios.post(AppServices.UPDATE_FOLDER(), data, HttpHeaders())
  },
  getFolder: (data) => {
    // return axios.get(AppServices.GET_FOLDER(folderid), HttpHeaders())
    return axios.get(AppServices.GET_FOLDER(data.folderid), {
      params: data,
      headers: HttpHeaders().headers,
    })
  },
  DeleteFolder: (id) => {
    return axios.get(AppServices.DELETE_FOLDER(id), HttpHeaders())
  },
  GetUploadFolderFiles: (data) => {
    return axios.post(AppServices.UPLOAD_FOLDER_FILE(), data, HttpHeaders())
  },
  DeleteMultipleDocument: (data) => {
    return axios.post(AppServices.DELETE_MULTIPLE_DOCUMENT(), data, HttpHeaders())
  },
  ArchiveProjectData: (data) => {
    return axios.post(AppServices.ARCHIVE_PROJECT(), data, HttpHeaders())
  },
  UnarchiveProjectData: (data) => {
    return axios.post(AppServices.UNARCHIVE_PROJECT(), data, HttpHeaders())
  }
}
export default FilesServices;
