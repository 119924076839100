import React, { useContext } from "react";
import { ReviewContext } from "../context/ReviewContext";
import { getQuestionsCols } from "../Shared/Controller/projectReviewApp";
import RowQuestion from "./RowQuestion";

const LoanQuestion = () => {
  const LoanProps = useContext(ReviewContext);

  return (
    <React.Fragment>
      {LoanProps.RowData.AllQuestions.loan_questions &&
        <div className={getQuestionsCols({ typesOfQuestion: Object.keys(LoanProps.RowData.AllQuestions).length, type: "loan" }) + " mb-3"}>
          <div className="card-main">
            <h3>Loan</h3>
            {LoanProps.RowData.AllQuestions.loan_questions.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <RowQuestion item={item}
                    answers={LoanProps.answers}
                    setQuestionChoice={LoanProps.setQuestionChoice}
                    setQuestionComment={LoanProps.setQuestionComment}
                    reviewAnswerErrors={LoanProps.reviewAnswerErrors}
                    userDetail={LoanProps.userDetail}
                    take_ownership={LoanProps.take_ownership}
                    integrity_review={LoanProps.integrity_review}
                    index={index}
                  />
                </React.Fragment>
              )
            })
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}
export default LoanQuestion;