import axios from "axios"
import { AppServices, HttpHeaders } from "../Services/AppServices";

const InvesterServices = {
    getInvestors: (data = {}) => {
        const header = HttpHeaders();
        return axios.get(AppServices.GET_INVESTORES(), {
            params: data,
            headers: header.headers,
        })
    },
    saveInvestor: (data) => {
        if (data.investorId) {
            return axios.post(AppServices.UPDATE_INVESTOR(data.investorId), data, HttpHeaders())
        }
        else {
            return axios.post(AppServices.SAVE_INVESTOR(), data, HttpHeaders())
        }
    },
    getInvestor: (investorId) => {
        return axios.get(AppServices.GET_INVESTOR(investorId), HttpHeaders())
    },
    deleteInvestor: (data) => {
        return axios.post(AppServices.DELETE_INVESTOR(data.investorId), data, HttpHeaders())
    },
    restoreInvestor: (data) => {
        return axios.post(AppServices.RESTORE_INVESTOR(data.investorId), data, HttpHeaders())
    },
}
export default InvesterServices;
