import React from "react";
import { useNavigate } from "react-router-dom";
import Edit from "../profile/Edit";
import * as Constants from "../partials/CommonConstant";

const EditProfile = () => {
  const navigate = useNavigate();

  const callBackSuccess = (data) => {
    navigate(Constants.profile);
  }

  return (
    <div className="dashboard-card-wrapper">
      <div className="row">
        <Edit callBackSuccess={(data) => callBackSuccess(data)}
        />
      </div>
    </div>
  )
}
export default EditProfile;