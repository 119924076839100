import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { GetUser, ShowToast } from "../common/CommonController";
import { GetAllUsers, GetFolderDetail, SaveFolder, UpdateFolder } from "../Shared/Controller/filesApp";
import AddEditFolderForm from "./AddEditFolderForm";

const AddEditFolder = () => {
  const location = useLocation();
  const navigateTo = useNavigate();
  let params = useParams();
  const userDetail = GetUser();
  const [loading, setLoading] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [folderDetail, setFolderDetails] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    setIsEdit(false);
    if (splitPath.find((item) => item === "edit-folder") === "edit-folder") {
      if (params.folderid) {
        setFolderId(params.folderid);
        setIsEdit(true);
      }
    }
    else if (params.folderid && splitPath.find((item) => item === "add-folder") === "add-folder") {
      setFolderDetails({
        folder_id: params.folderid,
        shared_with: [],
        note: "",
      })
    }
    setLoading(true);
    getAllUser();
  }, [location]);

  /* useEffect(() => {
    if (folderId !== "") {
      getFolderDetail();
    }
  }, [folderId]) */

  const getAllUser = () => {
    let data = {
      status: 1,
      exclude_userid: userDetail.id,
    }
    GetAllUsers(data).then((response) => {
      if (response.data.code === 1) {
        setUsers(response.data.data);
        let splitPath = location.pathname.split("/");
        if (params.folderid && splitPath.find((item) => item === "edit-folder") === "edit-folder") {
          getFolderDetail();
        }
        else {
          setLoading(false);
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        setLoading(false);
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }

  const getFolderDetail = () => {
    if (params.folderid) {
      let param = {
        folderid: params.folderid,
      }
      setLoading(true);
      GetFolderDetail(param).then((response) => {
        if (response.data.code === 1) {
          setFolderDetails(response.data.data);
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setLoading(false);
      })
    }
  }

  const saveFolder = (data) => {
    setSaveLoading(true);
    SaveFolder(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        // redirectToList();
        navigateTo(-1);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setSaveLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      setSaveLoading(false);
    });
  }

  const updateFolder = (data) => {
    setSaveLoading(true);
    UpdateFolder(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        navigateTo(-1);
        // redirectToList();
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setSaveLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      setSaveLoading(false);
    });
  }

  const callbackAfterDelete = () => {
    getFolderDetail();
  }

  const redirectToList = () => {
    navigateTo("/files");
  }

  return (
    <div className="dashboard-card-wrapper">
      <div className="row align-items-center">
        <div className="col-12">
          <div className="file-radio-list mb-2">
            {isEdit === true &&
              <>
                <Link to={"/add-folder/" + folderId} className="btn btn-blue btn-nex me-2">Create Folder</Link>
                <Link to={"/add-folder-file/" + folderId} className="btn btn-blue btn-nex me-2">Upload File</Link>
              </>
            }
          </div>
        </div>
        {loading === false &&
          <AddEditFolderForm
            folderId={folderId}
            parentFolderId={params.parentfolderid}
            folderDetail={folderDetail}
            saveFolder={saveFolder}
            updateFolder={updateFolder}
            callbackAfterDelete={callbackAfterDelete}
            users={users}
            isEdit={isEdit}
            saveLoading={saveLoading}
          />
        }
        {loading === true &&
          <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
            <div className="card-main">
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default AddEditFolder;