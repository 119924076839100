import ProjectReviewService from "../Services/ProjectReviewService"

export const getProjectRow = async (data) => {
  let response = ProjectReviewService.getProjectRow(data);
  return response;
}

export const getProjectRecordRow = async (data) => {
  let response = ProjectReviewService.getProjectRecordRow(data);
  return response;
}

export const getIntegrityProjectRow = async (data) => {
  let response = ProjectReviewService.getIntegrityProjectRow(data);
  return response;
}

export const getIntegrityProjectRecordRow = async (data) => {
  let response = ProjectReviewService.getIntegrityProjectRecordRow(data);
  return response;
}

export const saveReviewRow = (data) => {
  let response = ProjectReviewService.saveReviewRow(data);
  return response;
}

export const saveIntegrityRow = (data) => {
  let response = ProjectReviewService.saveIntegrityRow(data);
  return response;
}

export const setDefaultAnswerData = (RowData) => {
  let defaultData = [];
  Object.keys(RowData.AllQuestions).map((aitem) => {
    if (aitem === "file_questions") {
      RowData.AllQuestions[aitem].map((item) => {
        let objData = {}
        objData.questionid = item.id;
        objData.selected_choice = item.selected_choice;
        objData.comment_required = item.choices ? (item.choices.find((citem) => citem.status.toString() === "1" && citem.choice === item.selected_choice && item.comment_required === 1) ? 1 : 0) : 0;
        objData.comment = item.comment;
        objData.cr = item.comment_required;
        defaultData.push(objData);
      });
    }
    else if (aitem === "loan_questions") {
      RowData.AllQuestions[aitem].map((item) => {
        let objData = {}
        objData.questionid = item.id;
        objData.selected_choice = item.selected_choice;
        objData.comment_required = item.choices ? (item.choices.find((citem) => citem.status.toString() === "1" && citem.choice === item.selected_choice && item.comment_required === 1) ? 1 : 0) : 0;
        objData.comment = item.comment;
        objData.cr = item.comment_required;
        defaultData.push(objData);
      });
    }
    /* 10-Mar-2023 check delinquency Flag 1 or 0 to remove question on param */
    else if (aitem === "delinquency_questions" && RowData.SingleRow?.row_details.Delinquency_Flag === 1) {
      RowData.AllQuestions[aitem].map((item) => {
        let objData = {}
        objData.questionid = item.id;
        objData.selected_choice = item.selected_choice;
        objData.comment_required = item.choices ? (item.choices.find((citem) => citem.status.toString() === "1" && citem.choice === item.selected_choice && item.comment_required === 1) ? 1 : 0) : 0;
        objData.comment = item.comment;
        objData.cr = item.comment_required;
        defaultData.push(objData);
      });
    }
  })
  return defaultData;
}

export const setFilledAnswer = (data) => {
  let newAnswerArray = [...data.answers];
  let answer = {};
  let isNotFound = false;
  if (newAnswerArray.length > 0) {
    for (let i = 0; i < newAnswerArray.length; i++) {
      if (parseInt(newAnswerArray[i].questionid) === parseInt(data.name)) {
        if (data.type === "choice") {
          newAnswerArray[i].selected_choice = data.value;
          newAnswerArray[i].comment_required = data.comment_required;
          // newAnswerArray[i].comment = data.comment_required ? newAnswerArray[i].comment : "";
        }
        else if (data.type === "comment") {
          newAnswerArray[i].comment = data.value;
        }
        isNotFound = false;
        break;
      }
      else {
        isNotFound = true;
      }
    }
  }
  else {
    if (data.type === "choice") {
      newAnswerArray.push({
        questionid: data.name,
        selected_choice: data.value,
        comment_required: data.comment_required,
      });
    }
    else if (data.type === "comment") {
      newAnswerArray.push({
        questionid: data.name,
        comment: data.value,
      });
    }
  }
  if (isNotFound) {
    answer.questionid = data.name;
    if (data.type === "choice") {
      answer.selected_choice = data.value;
      answer.comment_required = data.comment_required;
    }
    else if (data.type === "comment") {
      answer.comment = data.value;

    }
    newAnswerArray.push(answer);
  }
  return newAnswerArray;
}

export const getQuestionsCols = (data) => {
  let cols = "";
  if (data.typesOfQuestion === 1) {
    cols = "col-md-12 col-sm-12 col-lg-12";
  }
  if (data.typesOfQuestion === 2) {
    cols = "col-md-12 col-sm-12 col-lg-6";
  }
  if (data.typesOfQuestion === 3 && data.type === "delinquency") {
    cols = "col-md-12 col-sm-12 col-lg-12";
  }
  if (data.typesOfQuestion === 3 && data.type !== "delinquency") {
    cols = "col-md-12 col-sm-12 col-lg-6";
  }
  return cols;
}

export const getIntegrityRowView = async (data) => {
  let response = ProjectReviewService.getIntegrityRowView(data);
  return response;
}

export const getReviewRowView = async (data) => {
  let response = ProjectReviewService.getReviewRowView(data);
  return response;
}