import LogsServices from "../Services/LogsServices";

export const GetSessionLogs = async (data) => {
  let response = LogsServices.GetSessionLogs(data);
  return response;
}

export const GetSiteLogs = async (data) => {
  let response = LogsServices.GetSiteLogs(data);
  return response;
}

export const deleteSiteLogs = async (data) => {
  let response = LogsServices.DeleteSiteLogs(data);
  return response;
}

export const deleteSessionLogs = async (data) => {
  let response = LogsServices.DeleteSessionLogs(data);
  return response;
}