import dashboardImg from "../images/dashboard-1.png"
import editImg from '../images/edit.png';
import deleteImg from '../images/delete.png';
import refreshImg from '../images/refrash.png';
import formLogoImg from '../images/form-logo.png';
import graphBoxImg from '../images/graph-box.png';
import leftIconWhite from '../images/left-icon-white.png';
import rightIconWhiteImg from '../images/right-icon-white.png';
import profileImg from '../images/profile.png';
import projectImg from '../images/projects.png';
import quadrinGroupImg from '../images/quadrin-group.png';
import logoImg from '../images/logo.png';
import logoutImg from '../images/logout.png';
import sideBarLogoImg from '../images/sidebar-logo.png';
import sidebarReLogoImg from '../images/sidebar-re-logo.png';
import userDocImg from '../images/user-document.png';
import searchIconImg from '../images/search-icon.png';
import settingsImg from '../images/settings.png';
import uploadIconImg from '../images/upload-icon.png';
import downloadIconImg from '../images/download-icon.png';
import documentShareIconImg from '../images/document-share-icon.png';
import eyeImg from '../images/eye.png';
import profilePicImg from '../images/profile-pic.png';
import loginLeftImg from '../images/login_left_img.png';
import editIcon from '../images/edit-wh.png';
import investorsImg from '../images/investors.png';
import platformImg from '../images/platform.png';
import templateImg from '../images/template.png';
import logobanner from '../images/logo-banner-img1.jpg';
import passwordshowimg from '../images/password-show-view.png';
import passwordhideimg from '../images/password-icon.png';
import nodatafoundimg from '../images/no-data-found.png';
import resendImg from '../images/resend-email.png';
import restoreImg from '../images/restore.png';
import questionImg from '../images/question.png';
import checkedImg from '../images/checked.png';
import uncheckedImg from '../images/unchecked.png';
import userImg from '../images/user.png';
import closeImg from '../images/icon-close.png';
import checkmarkImg from '../images/icon-checkmark.png';
import integrityReviewImg from '../images/integrity_review.png';
import reviewImg from '../images/review.png';
import downloadImg from '../images/download.png';
import usersImg from '../images/users.png';
import exportImg from '../images/export.png';
import docImage from '../images/icon-doc.png';
import pptImage from '../images/icon-pptx.png';
import jpgImage from '../images/icon-jpg.png';
import pdfImage from '../images/icon-pdf.png';
import pngImage from '../images/icon-png.png';
import redCloseImage from '../images/icon-redclose.png';
import txtImage from '../images/icon-txt.png';
import xlsImage from '../images/icon-xls.png';
import shareImage from '../images/share-icon.png';
import unknownImage from '../images/icon-unknown.png';
import folderImage from '../images/folder-icon.png';
import iconFolderImage from '../images/icon-folder.png';
import docViewImage from '../images/doc_view_icon.ico';
import deleteIconProject from '../images/delete_icon_project.png';
import editIconProject from '../images/edit_icon_project.png';
import exportIconProject from '../images/export_icon_project.png';
import eyeIconProject from '../images/eye_icon_project.png';


export const DashboardImg = dashboardImg;
export const EditImg = editImg;
export const DeleteImg = deleteImg;
export const RefreshImg = refreshImg;
export const FormLogoImg = formLogoImg;
export const GraphBoxImg = graphBoxImg;
export const LeftIconWhite = leftIconWhite;
export const RightIconWhiteImg = rightIconWhiteImg;
export const ProfileImg = profileImg;
export const ProjectImg = projectImg;
export const QuadrinGroupImg = quadrinGroupImg;
export const LogoImg = logoImg;
export const LogoutImg = logoutImg;
export const SideBarLogoImg = sideBarLogoImg;
export const SidebarReLogoImg = sidebarReLogoImg;
export const SearchIconImg = searchIconImg;
export const SettingsImg = settingsImg;
export const DownloadIconImg = downloadIconImg;
export const DocumentShareIconImg = documentShareIconImg;
export const UploadIconImg = uploadIconImg;
export const EyeImg = eyeImg;
export const UserDocImg = userDocImg;
export const ProfilePicImg = profilePicImg;
export const LoginLeftImg = loginLeftImg;
export const EditIcon = editIcon;
export const InvestorsImg = investorsImg;
export const PlatformImg = platformImg;
export const TemplateImg = templateImg;
export const LoginBanner = logobanner;
export const PassswordShowImg = passwordshowimg;
export const PassswordHideImg = passwordhideimg;
export const NoDataImg = nodatafoundimg;
export const ResendImg = resendImg;
export const RestoreImg = restoreImg;
export const QuestionImg = questionImg;
export const CheckedImg = checkedImg;
export const UncheckedImg = uncheckedImg;
export const UserImg = userImg;
export const CloseImg = closeImg;
export const CheckmarkImg = checkmarkImg;
export const IntegrityReviewImg = integrityReviewImg;
export const ReviewImg = reviewImg;
export const DownloadImg = downloadImg;
export const UsersImg = usersImg;
export const ExportImg = exportImg;
export const DocImage = docImage;
export const PPTImage = pptImage;
export const JpgImage = jpgImage;
export const PdfImage = pdfImage;
export const PngImage = pngImage;
export const RedCloseImage = redCloseImage;
export const TxtImage = txtImage;
export const XlsImage = xlsImage;
export const ShareImage = shareImage;
export const UnKnownImage = unknownImage;
export const FolderImage = folderImage;
export const IconFolderImage = iconFolderImage;
export const DocViewImage = docViewImage;
export const DeleteIconProject = deleteIconProject;
export const EditIconProject = editIconProject;
export const ExportIconProject = exportIconProject;
export const EyeIconProjct = eyeIconProject;