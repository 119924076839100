import React, { useEffect, useState } from "react";
import { GetUser, ShowToast } from "../common/CommonController";
import DataTableExtensions from "react-data-table-component-extensions";
import { DeleteFile, DeleteFolder, downloadFile, getAllFiles, GetFolderDetail, getTableColumns } from "../Shared/Controller/filesApp";
import { Col, Form, NavLink, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import ConfirmModal from "../common/ConfirmModal";
import DocumentDataTable from "../files/DocumentDataTable";
import { Scrollbars } from 'react-custom-scrollbars';
import { DeleteImg, EditImg, EyeImg, FolderImage } from "../common/ImageConstants";

const AllFiles = () => {
  let userDetail = GetUser();

  const [FileList, setFilesList] = useState([]);
  const [FolderList, setFolderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mineOnly, setMineOnly] = useState(0);
  const [sharedOnly, setSharedOnly] = useState(0);
  const [allFiles, setAllFiles] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState("");
  const [deleteFolderId, setDeleteFolderId] = useState("");
  const [selectedFolder, setSelectFolder] = useState("");
  const [selectedFolderDetail, setSelectedFolderDetail] = useState({});
  const [docListLoading, setDocListLoading] = useState(false);
  // const [selectFolderLoading, setSelectFolderLoading] = useState(false);
  const [generalList, setGeneralList] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    GetAllFiles();
  }, []);

  useEffect(() => {
    if (mineOnly === 1) {
      GetAllFiles();
    }
    else if (sharedOnly === 1) {
      GetAllFiles();
    }
    else if (allFiles === 1) {
      GetAllFiles();
    }
  }, [mineOnly, sharedOnly, allFiles])

  const GetAllFiles = () => {
    let data = {
      mine_only: mineOnly,
      shared_only: sharedOnly,
    }
    setFolderList([]);
    setSelectedFolderDetail({});
    setLoading(true);
    getAllFiles(data).then((response) => {
      if (response.data.code === 1) {
        if (typeof (response.data.data) === "object" && Object.keys(response.data.data).length > 0) {
          let folderList = [];
          setFilesList(response.data.data.general);
          setGeneralList(response.data.data.general);
          folderList.push({
            id: '0',
            foldername: "General"
          })
          response.data.data.folder_list.map((item) => {
            folderList.push(item);
          })
          setSelectFolder("0");
          setFolderList(folderList);
        }
        else {
          setFilesList([]);
          setFolderList([]);
          setGeneralList([]);
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    })
  }

  const onChangeAllFiles = () => {
    setAllFiles(1)
    setSharedOnly(0);
    setMineOnly(0);
  }

  const onChangeMineOnly = () => {
    setMineOnly(1);
    setSharedOnly(0);
    setAllFiles(0)
  }

  const onChangeSharedOnly = () => {
    setSharedOnly(1);
    setMineOnly(0);
    setAllFiles(0)
  }

  const onClickDeleteFile = (e, data) => {
    e.preventDefault();
    setShowModal(true);
    if (data.document_type === "file") {
      setDeleteFileId(data.id);
      setDeleteFolderId("");
    }
    else {
      setDeleteFolderId(data.id);
      setDeleteFileId("");
    }
  }

  const deleteFile = () => {
    setDeleteLoading(true);
    if (deleteFileId !== "") {
      DeleteFile(deleteFileId).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message);
          setShowModal(false);
          setDeleteFileId("");
          GetAllFiles();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setDeleteLoading(false);
      }).catch(error => {
        setDeleteLoading(false);
        ShowToast("message", "error", "" + error);
      });
    }
    else {
      DeleteFolder(deleteFolderId).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message);
          setShowModal(false);
          setDeleteFolderId("");
          GetAllFiles();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setDeleteLoading(false);
      }).catch(error => {
        setDeleteLoading(false);
        ShowToast("message", "error", "" + error);
      });
    }
  }

  const onSelectFolder = (id) => {
    setSelectFolder(id);
    if (id !== "0") {
      setSelectedFolderDetail({});
      getSelectedFolderDetail(id);
    }
    else {
      setSelectedFolderDetail({});
      setFilesList(generalList);
    }
  }

  const getSelectedFolderDetail = (folderid) => {
    setDocListLoading(true)
    let data = {
      folderid: folderid,
      shared_only: sharedOnly,
      mine_only: mineOnly,
    }
    GetFolderDetail(data).then((response) => {
      if (response.data.code === 1) {
        setSelectedFolderDetail(response.data.data);
        setFilesList(response.data.data.files_and_folders);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setDocListLoading(false)
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      setDocListLoading(false)
    })
  }

  const getClassOfDiv = () => {
    let classStr = "col-md-12 col-sm-12 col-lg-9 mt-3";
    if (FileList.length === 0 && FolderList.length === 0) {
      classStr = "col-md-12 col-sm-12 col-lg-12 mt-3";
    }
    else if (FileList.length === 0 && Object.keys(selectedFolderDetail).length === 0 && FolderList.length === 0) {
      classStr = "col-md-12 col-sm-12 col-lg-12 mt-3";
    }
    return classStr;
  }

  const callBackDeleteDoc = () => {
    GetAllFiles();
  }

  const data = FileList;
  const tableProps = {
    downloadFile: downloadFile,
    sharedOnly: sharedOnly,
    mineOnly: mineOnly,
    onClickDeleteFile: onClickDeleteFile,
  }
  const columns = getTableColumns({ data: data, ...tableProps });

  const tableData = {
    columns,
    data
  };

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <ConfirmModal show={showModal}
          type="permanentDelete"
          module={deleteFileId !== "" ? "file" : deleteFolderId !== "" ? "folder" : "record"}
          deleteLoading={deleteLoading}
          onCloseModal={() => setShowModal(false)}
          confirmClick={() => deleteFile()} />
        <div className="row align-items-center">
          <div className="col-12">
            <div className="file-radio-list mb-4">
              {userDetail.role === 1 &&
                <Link to={Constants.downlodLogs} className="btn btn-blue btn-nex">Download Logs</Link>
              }
              <Link to={Constants.addFolder} className="btn btn-blue btn-nex me-2">Create Folder</Link>
              <Link to={Constants.addFile} className="btn btn-blue btn-nex me-2">Upload File</Link>
              <Link to={Constants.uploadFolder} className="btn btn-blue btn-nex me-2">Upload Folder</Link>
              <Form.Check
                type="radio"
                name="fileList"
                id="radioMine"
                label="Shared By Me"
                defaultChecked={userDetail.role !== 1 ? true : false}
                onChange={() => onChangeMineOnly()}
              />
              <Form.Check
                type="radio"
                name="fileList"
                id="radioShared"
                label="Shared With Me"
                onChange={() => onChangeSharedOnly()}
              />
              {/* {userDetail.role === 1 && */}
              <Form.Check
                type="radio"
                name="fileList"
                id="radioAllFiles"
                label="All Files"
                defaultChecked={true}
                onChange={() => onChangeAllFiles()}
              />
              {/* } */}
            </div>
            {/* <Button onClick={() => setMineOnly(1)} className="btn btn-blue btn-nex">Mine Only</Button> */}
          </div>
        </div>
        <div className="card-main p-0">
          <div className="row">
            {FolderList.length > 0 &&
              <div className="col-md-6 col-sm-12 col-lg-3 mt-3 mb-3 folder-explore">
                <Scrollbars className="custom-scroll" style={{ height: 600 }}>
                  <ul>
                    {FolderList.map((fitem) => {
                      return (
                        <React.Fragment key={"folder-" + fitem.id}>
                          <li className={selectedFolder === fitem.id ? "active" : ""}><NavLink onClick={() => onSelectFolder(fitem.id)}><img src={FolderImage} alt="Folder Name" />{fitem.foldername}</NavLink></li>
                        </React.Fragment>
                      )
                    })
                    }
                  </ul>
                </Scrollbars>
              </div>
            }
            {loading === false &&
              <div className={getClassOfDiv()}>
                {Object.keys(selectedFolderDetail).length > 0 && docListLoading === false &&
                  <div className="container p-0">
                    {/* {selectFolderLoading === false && */}
                    <Row className="folder-path-action">
                      <div className="inner-folder-action">
                        <Col sm="12" md="12" lg="6" xl="6">
                          <label>Folder Path: /{selectedFolderDetail.bread_crumbs}</label>
                          <label>Owner: {selectedFolderDetail.owner_name}</label>
                        </Col>
                        <Col sm="12" md="12" lg="6" xl="6">
                          <div className="folder-action">
                            <span className="me-3">Action: </span>
                            {selectedFolderDetail.permission !== "1" &&
                              <Link to={"/view-folder/" + selectedFolderDetail.id} className="ms-2">
                                <img src={EyeImg} alt="View" title="View" />
                              </Link>
                            }
                            {(((selectedFolderDetail.permission === "3" && userDetail.role !== 1) || (userDetail.role === 1))) &&
                              <React.Fragment>
                                <Link to={"/edit-folder/" + selectedFolderDetail.id} className="ms-2">
                                  <img src={EditImg} alt="Edit" title="Edit" />
                                </Link>
                                <Link to="/" onClick={(e) => onClickDeleteFile(e, selectedFolderDetail)} className="ms-2">
                                  <img src={DeleteImg} alt="Delete" title="Delete" />
                                </Link>
                              </React.Fragment>
                            }
                          </div>
                        </Col>
                      </div>
                    </Row>
                    {/* } */}
                  </div>
                }
                {/* {selectFolderLoading === true &&
                <Row>
                  <div className="text-center">
                    <Spinner animation="border" size="md" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </Row>
              } */}
                {docListLoading === false &&
                  <div className="dark-table-wrapper table-scroll user-list-main document-table">
                    <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                      <DocumentDataTable
                        {...tableData}
                        downloadFile={downloadFile}
                        sharedOnly={sharedOnly}
                        type="root"
                        mineOnly={mineOnly}
                        tableData={tableData}
                        onClickDeleteFile={onClickDeleteFile}
                        navigate={navigate}
                        callBackDeleteDoc={callBackDeleteDoc}
                      />
                      {/* <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="filename"
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                  /> */}
                    </DataTableExtensions>
                    {/* Not found */}
                  </div>
                }
                {docListLoading === true &&
                  <table className="mb-0 table">
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            }
            {loading === true &&
              <table className="mb-0 table">
                <tbody>
                  <tr>
                    <td colSpan={10} className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default AllFiles;