import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getIntegrityProjectRecordRow, getIntegrityProjectRow, getIntegrityRowView, saveIntegrityRow } from "../Shared/Controller/projectReviewApp";
import { ShowToast } from "../common/CommonController";
import { ReviewContext } from "../context/ReviewContext";
import { Form, Spinner } from "react-bootstrap";
import IntegrityRow from "../projects/IntegrityRow";
import ConfirmModal from "../common/ConfirmModal";

let btnClicked = "save & next";

const IntegrityProject = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let isEdit = false;
  let params = useParams();

  const [RowData, setRowData] = useState({ SingleRow: {}, AllQuestions: {} });
  const [RowLoading, setRowLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isRowAnswerFilled, setIsRowAnswerFilled] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [saveRowLoading, setSaveRowLoading] = useState(false);
  const [isReviewFinish, setIsReviewFinish] = useState(false);
  const [formValidate, setFormValidate] = useState(false);
  const [showLastRowModal, setShowLastRowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState({ flag: false, btnClicked: "" });
  const [nextRowLoading, setNextRowLoading] = useState(false);

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    let project_id = "";
    if (splitPath.length > 2) {
      project_id = splitPath[2];
      setProjectId(project_id);
      isEdit = true;
      if (location.hash === "") {
        GetProjectRow(project_id);
      }
      else {
        GetProjectRecordRow()
      }
    }
  }, []);

  useEffect(() => {
    setAnswers([]);
    // setAnswers(setDefaultAnswerData(RowData));
    setDefaultIntegrityData();
  }, [RowData])

  useEffect(() => {
    setRowFieldStatus();
  }, [answers])

  const GetProjectRow = (project_id) => {
    let data = {
      projectid: project_id,
    }
    setRowLoading(true);
    setFormValidate(false);
    getIntegrityProjectRow(data).then((response) => {
      if (response.data.code === 1) {
        /* Hide 3 field -> Account reference, current balance and delinquency flag */
        /* Comment because show three column with data only not dropdown or edit anything. */
        // let newData = { ...response.data.data };
        // newData.row_details && Object.keys(newData.row_details).map((item) => {
        //   if (!checkFixedField(item)) {
        //     delete newData.row_details["" + item + ""];
        //   }
        // });
        /* End Hide 3 field -> Account reference, current balance and delinquency flag */
        setRowData({ SingleRow: response.data.data })
        // setGrade(response.data.data.singel_row.grade !== null ? response.data.data.singel_row.grade : "");
        // setRowComment(response.data.data.singel_row.row_comment !== null ? response.data.data.singel_row.row_comment : "");
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  const checkFixedField = (item) => {
    let isChecked = true;
    if (item === "Account_Reference") {
      isChecked = false;
    }
    if (item === "Current_Balance") {
      isChecked = false;
    }
    if (item === "Delinquency_Flag") {
      isChecked = false;
    }
    return isChecked;
  }

  const GetProjectRecordRow = (project_id) => {
    let data = {
      project_id: params.project_id,
      row_id: location.hash.replace("#", ""),
    }
    setRowLoading(true);
    setFormValidate(false);
    getIntegrityProjectRecordRow(data).then((response) => {
      if (response.data.code === 1) {
        setRowData({ SingleRow: response.data.data })
        // setGrade(response.data.data.singel_row.grade !== null ? response.data.data.singel_row.grade : "");
        // setRowComment(response.data.data.singel_row.row_comment !== null ? response.data.data.singel_row.row_comment : "");
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  const setDefaultIntegrityData = () => {
    let answerData = [];
    if (Object.keys(RowData.SingleRow).length > 0 && RowData.SingleRow.row_details && RowData.SingleRow.prev_answers) {
      Object.keys(RowData.SingleRow.row_details).map((item) => {
        let prevItem = RowData.SingleRow.prev_answers.find((fitem) => fitem.column_name === item.trim());
        if (checkFixedField(item)) {
          if (prevItem) {
            answerData.push({
              column_name: item,
              status: prevItem.status,
              comment: prevItem.comment,
            });
          }
          else {
            answerData.push({
              column_name: item,
              status: "",
              comment: "",
            })
          }
        }
      });
    }
    else if (Object.keys(RowData.SingleRow).length > 0 && RowData.SingleRow.row_details && !RowData.SingleRow.prev_answers) {
      Object.keys(RowData.SingleRow.row_details).map((item) => {
        if (checkFixedField(item)) {
          answerData.push({
            column_name: item,
            status: "",
            comment: "",
          });
        }
        return null;
      });
    }
    setAnswers(answerData);
  }

  const setRowFieldStatus = () => {
    let isFilledRowAnswer = true;
    if (answers.length > 0) {
      answers.map((item) => {
        if (item.status === "") {
          isFilledRowAnswer = false;
        }
        if (item.comment === "" && item.status === "No Match") {
          isFilledRowAnswer = false;
        }
        return null;
      });
    }
    else {
      isFilledRowAnswer = false;
    }
    setIsRowAnswerFilled(isFilledRowAnswer);
  }

  const onHandleChange = (e) => {
    // e.preventDefault();
    let finalDataArr = [...answers];
    if (e.target.name.split("|")[0] === "status") {
      let isAdded = false;
      if (finalDataArr.length !== 0) {
        for (let i = 0; i < finalDataArr.length; i++) {
          if (finalDataArr[i].column_name === e.target.name.split("|")[1]) {
            finalDataArr[i] = {
              column_name: finalDataArr[i].column_name,
              status: e.target.value,
              comment: "",
            }
            isAdded = true;
            break;
          }
          else {
            isAdded = false;
          }
        }
      }
      if (isAdded === false) {
        finalDataArr.push({
          column_name: e.target.name.split("|")[1],
          status: e.target.value,
          comment: "",
        })
      }
    }
    else if (e.target.name.split("|")[0] === "comment") {
      finalDataArr.map((item, index) => {
        if (item.column_name === e.target.name.split("|")[1]) {
          finalDataArr[index] = {
            column_name: item.column_name,
            status: item.status,
            comment: e.target.value,
          }
        }
        return null;
      })
    }
    setAnswers(finalDataArr);
  }

  const onSaveRowReview = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setFormValidate(true);
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      btnClicked = "save & next";
      if (document.getElementsByName("inputBtnHidden").value === 'save') {
        btnClicked = "save";
      }
      else if (document.getElementsByName("inputBtnHidden").value === 'save & review') {
        btnClicked = "save & review";
      }
      let incompleteStatus = 0;
      for (let i = 0; i < answers.length; i++) {
        let item = answers[i];
        if ((!item.comment || !item.status)) {
          if (item.status?.toLowerCase() !== "match" && !item.comment) {
            incompleteStatus = 1;
            break;
          }
          else {
            incompleteStatus = 0;
          }
        }
        else {
          incompleteStatus = 0;
        }
      }
      if (incompleteStatus) {
        setShowConfirmModal({ ...showConfirmModal, flag: true, btnClicked: btnClicked });
      }
      else {
        SaveIntigrityRow(btnClicked);
      }
    }
  }

  const SaveIntigrityRow = (btnClicked, incomplete_status = 0) => {
    let data = {
      // userid: userDetail.id,
      projectid: projectId,
      rowid: RowData.SingleRow.id,
      answers: answers,
      incomplete_status: incomplete_status,
      integrity_status: RowData.SingleRow.integrity_status,
    }

    setSaveRowLoading(true);
    saveIntegrityRow(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        if (btnClicked === "save & next") {
          if (RowData.SingleRow.id === RowData.SingleRow.last_row_id) {
            setShowLastRowModal(true);
          }
          else {
            GetProjectRow(projectId);
          }
        }
        else if (btnClicked === "save & review") {
          navigate("/review-project/" + RowData.SingleRow.project_id + "#" + RowData.SingleRow.id);
        }
        else {
          if (RowData.SingleRow.id === RowData.SingleRow.last_row_id) {
            setShowLastRowModal(true);
          }
          else {
            navigate(-1);
          }
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setSaveRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  const onCloseConfirmModal = () => {
    setShowConfirmModal({ ...showConfirmModal, flag: false });
  }

  const onConfirmYesModal = () => {
    setShowLastRowModal(false);
    if (btnClicked !== "save") {
      if (btnClicked === "next") {
        GetIntegrityRowView();
      }
      else {
        GetProjectRow(projectId);
      }
    }
    else {
      navigate("/projects");
    }
  }

  const onConfirmIncompleteYesModal = () => {
    setShowConfirmModal({ ...showConfirmModal, flag: false });
    SaveIntigrityRow(showConfirmModal.btnClicked, 1);
  }

  const checkNextRow = (e) => {
    e.preventDefault();
    if (RowData.SingleRow.id === RowData.SingleRow.last_row_id) {
      btnClicked = "next";
      setShowLastRowModal(true);
    }
    else {
      GetIntegrityRowView();
    }
  }

  const GetIntegrityRowView = () => {
    let data = {}
    data.projectid = params.project_id;
    data.nextid = RowData.SingleRow.id;
    setNextRowLoading(true);
    getIntegrityRowView(data).then((response) => {
      if (response.data.code === 1) {
        setRowData({ SingleRow: response.data.data });
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message);
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setNextRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper reviewpage">
        <ConfirmModal show={showLastRowModal}
          customMessage={"You have reached end of the job"}
          showCancelButton={false}
          confirmBtnText="Ok"
          confirmClick={() => onConfirmYesModal()} />
        <ConfirmModal show={showConfirmModal.flag} type="permanentDelete"
          customMessage={"This is incomplete data, still you want to save?"}
          onCloseModal={() => onCloseConfirmModal()}
          confirmClick={() => onConfirmIncompleteYesModal()} />
        <ReviewContext.Provider value={{
          RowData,
          answers,
          isRowAnswerFilled,
          saveRowLoading,
          isReviewFinish,
          onHandleChange,
          onSaveRowReview,
          checkNextRow,
          nextRowLoading,
        }}>
          {RowLoading === false &&
            <Form noValidate validated={formValidate} onSubmit={onSaveRowReview}>
              <IntegrityRow />
            </Form>
          }
          {RowLoading === true &&
            <>
              <div className="card">
                <div className="p-5 text-center">
                  <Spinner animation="border" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </>
          }
        </ReviewContext.Provider>
      </div>
    </React.Fragment>
  )
}
export default IntegrityProject;