import React, { useEffect, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import ConfirmModal from "../common/ConfirmModal";
import { DeleteFile, DeleteFolder, downloadFile } from "../Shared/Controller/filesApp";
import DocumentDataTable from "./DocumentDataTable";
import UserPermissionModal from "./UserPermissionModal";

const AddEditFolderForm = (props) => {
  const [Validated, setValidated] = useState(false);
  const [folderField, setFolderField] = useState({ folder_name: "", shared_with: [] });
  const [showModal, setShowModal] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState("");
  const [deleteFolderId, setDeleteFolderId] = useState("");
  const [showPopupModal, setPopupShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setDefaultPermission();
  }, []);

  useEffect(() => {
    if (Object.keys(props.folderDetail).length > 0) {
      setDefaultFileField();
    }
  }, [props.folderDetail]);

  const setDefaultFileField = () => {
    let data = {
      folder_name: props.folderDetail.foldername, shared_with: []
    }
    let users = [];
    if (props.folderDetail.shared_with.length !== 0) {
      props.users.map((item) => {
        let shared_user = props.folderDetail.shared_with.find((sitem) => sitem.id === item.id);
        if (shared_user) {
          users.push({ user_id: shared_user.id.toString(), permission: shared_user.permission.toString() });
        }
        else {
          users.push({ user_id: item.id.toString(), permission: "1" });
        }
      });
    }
    else {
      props.users.map((item) => {
        users.push({ user_id: item.id.toString(), permission: "1" });
        return null;
      });
    }
    setFolderField({
      ...data,
      shared_with: users,
      // note: props.folderDetail.note !== null ? props.folderDetail.note : "",
    });
  }

  const setDefaultPermission = () => {
    let data = {
      folder_name: "", shared_with: []
    }
    let users = [];
    if (Object.keys(props.folderDetail).length === 0) {
      props.users.map((item) => {
        users.push({ user_id: item.id.toString(), permission: "1" });
        return null;
      });
    }
    setFolderField({
      ...data,
      shared_with: users,
    });
  }

  const handleChange = (e) => {
    e.preventDefault()
    setFolderField({ ...folderField, [e.target.name]: e.target.value })
  }

  const handlePermission = (data) => {
    let shared_with = folderField.shared_with;
    shared_with.map((item) => {
      if (item.user_id === data.id.toString()) {
        item.permission = data.permission;
      }
    })
    setFolderField({ ...folderField, shared_with: shared_with })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      let addFolderData = { ...folderField };
      if (props.isEdit === false) {
        addFolderData.parent_folder_id = props.folderDetail.folder_id ? props.folderDetail.folder_id : 0;
        props.saveFolder({ ...addFolderData });
      }
      else {
        props.updateFolder({ ...addFolderData, folder_id: props.folderDetail.id, parent_folder_id: props.folderDetail.parent_folder_id });
      }
    }
    setValidated(true);
  }

  const onClickDeleteFile = (e, data) => {
    e.preventDefault();
    setShowModal(true);
    if (data.document_type === "file") {
      setDeleteFileId(data.id);
      setDeleteFolderId("");
    }
    else {
      setDeleteFolderId(data.id);
      setDeleteFileId("");
    }
  }

  const deleteFile = () => {
    setDeleteLoading(true);
    if (deleteFileId !== "") {
      DeleteFile(deleteFileId).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message);
          setShowModal(false);
          setDeleteFileId("");
          props.callbackAfterDelete();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setDeleteLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setDeleteLoading(false);
      });
    }
    else {
      DeleteFolder(deleteFolderId).then((response) => {
        if (response.data.code === 1) {
          ShowToast("message", "success", response.data.message);
          setShowModal(false);
          setDeleteFolderId("");
          props.callbackAfterDelete();
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setDeleteLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        setDeleteLoading(false);
      });
    }
  }

  const resetAllPermission = () => {
    let newSharedWith = [];
    let tempFormField = folderField;
    tempFormField.shared_with.map((item) => {
      newSharedWith.push({ ...item, permission: "1" });
      return null;
    });
    setFolderField({
      ...tempFormField,
      shared_with: newSharedWith,
    });
  }

  const onCloseModal = () => {
    // resetAllPermission();
    setPopupShowModal(false);
  }

  const confirmClick = () => {
    setPopupShowModal(false);
  }

  const data = props.folderDetail.files_and_folders;
  // const tableProps = {
  //   downloadFile: downloadFile,
  //   sharedOnly: 0,
  //   mineOnly: 0,
  //   onClickDeleteFile: onClickDeleteFile,
  // }
  // const columns = getTableColumns(data, tableProps);

  /* const tableData = {
    columns,
    data
  }; */
  // console.log("folder form", folderField, props)

  return (
    <React.Fragment>
      <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
        <ConfirmModal show={showModal}
          type="permanentDelete"
          module={deleteFileId !== "" ? "file" : deleteFolderId !== "" ? "folder" : "record"}
          deleteLoading={deleteLoading}
          onCloseModal={() => setShowModal(false)}
          confirmClick={() => deleteFile()} />
        <div className="card-main">
          <Form noValidate validated={Validated} onSubmit={handleSubmit}>
            <Row className="row g-4">
              <Form.Group className="col-md-4">
                <Form.Label>Folder Name</Form.Label>
                <Form.Control
                  name="folder_name"
                  required
                  value={folderField.folder_name}
                  onChange={handleChange}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please enter folder name</Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group className="col-md-4">
                <Form.Label>Shared Users</Form.Label>
                <ul className="project-list file-user-list">
                  <Scrollbars className="custom-scroll" autoHeight={true}>
                    {props.users.length > 0 && props.users.map((item) => {
                      return (
                        <React.Fragment key={"shared_with" + item.id}>
                          <Form.Control as="button"
                            onClick={handleChange}
                            value={item.id}
                            required
                            name="shared_with"
                            className={folderField.shared_with.indexOf(item.id.toString()) !== -1 ? "active" : ""}
                          >
                            {item.firstname + " " + item.lastname}
                          </Form.Control>
                        </React.Fragment>
                      )
                    })
                    }
                  </Scrollbars>
                </ul>
                <Form.Control.Feedback type="invalid">Please select user</Form.Control.Feedback>
              </Form.Group> */}
              <UserPermissionModal
                show={showPopupModal}
                formField={folderField}
                users={props.users}
                handlePermission={handlePermission}
                onCloseModal={onCloseModal}
                confirmClick={confirmClick}
                isEdit={props.isEdit}
                modalFor="folder"
              />
              <Form.Group className="col-md-4">
                <Button variant="secondary" className="btn btn-blue btn-nex permission-btn" onClick={() => setPopupShowModal(true)}>
                  Show Permissions
                </Button>
              </Form.Group>
              <div className="col-12 text-end">
                {props.saveLoading === false &&
                  <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                    Save
                  </Button>
                }
                {props.saveLoading &&
                  <Button className="btn btn-sm btn-blue" variant="secondary">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Button>
                }
                <button onClick={() => navigate(-1)} className="btn btn-sm btn-blue ms-2" type="button" variant="secondary" >Cancel</button>
              </div>
            </Row>
          </Form>
          {props.isEdit === true &&
            <div className="dark-table-wrapper table-scroll mt-3">
              <DocumentDataTable
                downloadFile={downloadFile}
                sharedOnly={0}
                type="addfolder"
                mineOnly={0}
                data={data}
                onClickDeleteFile={onClickDeleteFile}
                navigate={navigate}
                callBackDeleteDoc={props.callbackAfterDelete}
                />
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  )

}
export default AddEditFolderForm;