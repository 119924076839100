import React, { useEffect, useState } from "react";
import * as Constants from "../partials/CommonConstant";
import * as ImgConstants from "../common/ImageConstants";
import ManageToken from "../common/ManageToken";
import { VerifyConfirmDelete } from "../Shared/Controller/commonApp";
import { ShowToast } from "../common/CommonController";
import { Spinner } from "react-bootstrap";

const ThankYou = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const { token, setToken } = ManageToken();

  useEffect(() => {
    setLoading(true);
    verifyConfirmDelete();
  }, [])

  const verifyConfirmDelete = () => {
    let token = queryParams.get('token');
    let send_data = {
      token: token,
    }
    VerifyConfirmDelete(send_data).then((response) => {
      if (response.data.code === 1) {
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  return (
    <React.Fragment>
      {(!token) &&
        <div className="content-wrapper content-wrapper--with-bg">
          <div className='main'>
            <div className='login-form-block'>
              {loading === false &&
                <div className='mobile-logo'>
                  <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} className='' />
                  <div className='heading'>
                    <h2>Qflow</h2>
                  </div>
                </div>
              }
              <div className='login-form-box-main'>
                <div className='login-form-right-block'>
                  <div className='login-form-logo'>
                    <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} className='' />
                    <div className='heading'>
                      <h2>Qflow</h2>
                    </div>
                  </div>
                  {loading &&
                    <div className='text-center mt-1'>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  {loading === false &&
                    <div className="text-center">
                      <h3>Thanks You, this is your confirmation that files/folders have been removed in accordance with our GDPR policies</h3>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {token &&
        <div className='card'>
          {loading &&
            <div className='text-center mt-1 p-5'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          }
          {loading === false &&
            <div className='login-form-right-block'>
              <div className="text-center">
                <h3>Thank you for your confirmation.</h3>
              </div>
            </div>
          }
        </div>
      }
    </React.Fragment>
  )
}
export default ThankYou;