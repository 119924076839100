import React, { useEffect, useState } from 'react'
import { SearchIconImg, RefreshImg } from "../common/ImageConstants";
import Logout from '../common/Logout';
import $ from "jquery";
import * as Constants from "../partials/CommonConstant";
import { Link, useLocation } from 'react-router-dom';
import { GetUser } from '../common/CommonController';
import CreateShortcut from '../common/CreateShortcut';

const Header = (props) => {
   const userDetail = GetUser();
   const [headerTitle, setTitle] = useState("Dashboard");
   const location = useLocation();

   useEffect(() => {
      window.addEventListener('resize', handleMenu);
   }, [])

   useEffect(() => {
      setHeaderTitle();
   }, [props.location])

   const handleMenu = () => {
      if ($(window).width() < 1200) {
         $('body').removeClass('sidebar-is-expanded').addClass('sidebar-is-reduced');
      }
      else {
         $('body').removeClass('sidebar-is-reduced').addClass('sidebar-is-expanded');
      }
   }

   const setOpenCloseMenu = () => {
      if ($('body').hasClass('sidebar-is-expanded')) {
         $('body').removeClass('sidebar-is-expanded').addClass('sidebar-is-reduced');
      }
      else {
         $('body').removeClass('sidebar-is-reduced').addClass('sidebar-is-expanded');
      }
   }

   const setHeaderTitle = () => {
      if (props.location) {
         if (props.location.pathname === Constants.home) {
            setTitle("Dashboard");
         }
         else if (props.location.pathname === Constants.users) {
            setTitle("Users");
         }
         else if (props.location.pathname === Constants.addUser) {
            setTitle("Add User");
         }
         else if (props.location.pathname.includes("edit-user")) {
            setTitle("Edit User");
         }
         else if (props.location.pathname.includes(Constants.profile)) {
            setTitle("Profile");
         }
         else if (props.location.pathname.includes("edit-profile")) {
            setTitle("Edit Profile");
         }
         else if (props.location.pathname === Constants.investors) {
            setTitle("Investors");
         }
         else if (props.location.pathname === Constants.addInvestores) {
            setTitle("Add Investor");
         }
         else if (props.location.pathname === Constants.addPlatform) {
            setTitle("Add Platform");
         }
         else if (props.location.pathname.includes("edit-investor")) {
            setTitle("Edit Investor");
         }
         else if (props.location.pathname.includes("edit-platform")) {
            setTitle("Edit Platform");
         }
         else if (props.location.pathname === Constants.platforms) {
            setTitle("Platforms");
         }
         else if (props.location.pathname.includes("view-user")) {
            setTitle("View User");
         }
         else if (props.location.pathname === Constants.templates) {
            setTitle("Templates");
         }
         else if (props.location.pathname === Constants.addTemplate) {
            setTitle("Add Template");
         }
         else if (props.location.pathname.includes("edit-template")) {
            setTitle("Edit Template");
         }
         else if (props.location.pathname === Constants.projectList) {
            setTitle("Projects");
         }
         else if (props.location.pathname === Constants.archived) {
            setTitle("Archived Projects");
         }
         else if (props.location.pathname.includes("add-project")) {
            setTitle("Create Project");
         }
         else if (props.location.pathname === Constants.deletedInvestores) {
            setTitle("Deleted Investors");
         }
         else if (props.location.pathname === Constants.deletedPlatforms) {
            setTitle("Deleted Platforms");
         }
         else if (props.location.pathname === Constants.deletedUsers) {
            setTitle("Deleted Users");
         }
         else if (props.location.pathname === Constants.deletedTemplates) {
            setTitle("Deleted Templates");
         }
         else if (props.location.pathname === Constants.deletedQuestions) {
            setTitle("Deleted Questions");
         }
         else if (props.location.pathname.includes("edit-project")) {
            setTitle("Edit Project");
         }
         else if (props.location.pathname.includes("review-project")) {
            setTitle("Project Review");
         }
         else if (props.location.pathname.includes("integrity-project")) {
            setTitle("Project Integrity");
         }
         else if (props.location.pathname.includes("view-project")) {
            setTitle("Project Overview");
         }
         else if (props.location.pathname.includes("files")) {
            setTitle("Docman");
         }
         else if (props.location.pathname.includes("view-file")) {
            setTitle("View File");
         }
         else if (props.location.pathname.includes("add-file")) {
            setTitle("Upload File");
         }
         else if (props.location.pathname.includes("edit-file")) {
            setTitle("Edit File");
         }
         else if (props.location.pathname.includes("download-logs")) {
            setTitle("Download Logs");
         }
         else if (props.location.pathname.includes("session-logs")) {
            setTitle("Session Logs");
         }
         else if (props.location.pathname.includes("site-logs")) {
            setTitle("Site Logs");
         }
         else if (props.location.pathname.includes("add-folder-file")) {
            setTitle("Upload File");
         }
         else if (props.location.pathname.includes("add-folder")) {
            setTitle("Create Folder");
         }
         else if (props.location.pathname.includes("edit-folder")) {
            setTitle("Edit Folder");
         }
         else if (props.location.pathname.includes("view-folder")) {
            setTitle("View Folder");
         }
         else if (props.location.pathname.includes("upload-folder")) {
            setTitle("Upload Folder");
         }
         else if (props.location.pathname.includes("site-logs")) {
            setTitle("Site Logs");
         }
         else if (props.location.pathname.includes("deleted-projects")) {
            setTitle("Deleted Projects");
         }
         else if (props.location.pathname.includes("project-records")) {
            setTitle("Project Records");
         }
         else if (props.location.pathname.includes("integrity-view")) {
            setTitle("View Integrity");
         }
         else if (props.location.pathname.includes("review-view")) {
            setTitle("View Review");
         }
         else {
            setTitle("Dashboard");
         }
      }
   }

   return (
      <div className="l-header">
         <div className="l-header__inner clearfix">
            <div className="c-header-icon js-hamburger">
               <Link to="#" onClick={() => setOpenCloseMenu()} className="hamburger-toggle">
                  <span className="bar-top"></span>
                  <span className="bar-mid"></span>
                  <span className="bar-bot"></span>
               </Link>
            </div>
            <div className="right-content">
               <h1 className="main-heading">{headerTitle}</h1>
            </div>
            <div className="header-info-block-main">
               <div className="header-notification-icon">
                  {/* {location.pathname === "/files" && */}
                  {/* <CreateShortcut /> */}
                  {/* } */}
                  {/* <Link to="#"><img alt='searchImage' src={SearchIconImg} /></Link> */}
                  <Link to="#" title='Refresh' onClick={() => window.location.reload()}><img alt="refreshImage" src={RefreshImg} /></Link>
                  {/* <a href="#"><img src={SettingsImg} /></a> */}
               </div>
               {userDetail.id &&
                  <div className="profile-pic-block-main">
                     <Link to={Constants.profile} className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <strong className='user-shortname'>{userDetail.firstname.substring(0, 1)}{userDetail.lastname.substring(0, 1)}</strong>
                     </Link>
                     <ul className="dropdown-menu dropdown-menu-end">
                        <li><Link to="/profile" className="dropdown-item" type="button">My Profile</Link></li>
                        <li><Logout logoutType="topmenu" /></li>
                     </ul>
                  </div>
               }
            </div>

         </div>
      </div>
   )
}
export default Header