import React from "react"
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom"
import NotFound from "../common/NotFound";
import * as Constants from "../partials/CommonConstant"
import ConfirmModal from "../common/ConfirmModal";
import projectApp from "../Shared/Controller/projectApp";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { connect } from "react-redux";
import { ShowToast, getFormattedDate } from "../common/CommonController";
import ProjectServices from "../Shared/Services/ProjectServices";
import { UnarchiveProjectData } from "../Shared/Controller/filesApp";

class ArchivedList extends projectApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      archiveList: [],
      showModal: false,
      exportLoading: false,
      exportProjectIds: "",
      deleteLoading:false,
      ShowLastRowModal:false,
      projectId: "",
    }
  }

  componentDidMount = () => {
    if (this.state.archiveList.length === 0) {
      this.setState({ loading: true })
    }
    ProjectServices.getArchive().then((response) => {
      if (response.data.code === 1) {
        if (response.data.data) {
          this.setState({ archiveList: response.data.data });
        }
        else {
          this.setState({ archiveList: [] })
        }
      } 
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }
  onClickRestore =(e,project_id)=>
  {
    this.setState({ShowLastRowModal: true,projectId:project_id});
  }

  restoreProject =()=>
  {
    this.setState({deleteLoading:true})
    const project_id = this.state.projectId;
    if(project_id)
    {
        UnarchiveProjectData({project_id:project_id,is_archived:0}).then((response) => {
          if (response.data.code === 1) {
            ShowToast("message", "success", response.data.message);
            this.componentDidMount();
            this.setState({ShowLastRowModal: false});
          }
          else {
            if (response.data.message.constructor === Object) {
              ShowToast("object", "error", response.data.message)
            }
            else {
              ShowToast("message", "error", response.data.message)
            }
          }
        
          this.setState({deleteLoading:false})
        }).catch(error => {
          this.setState({deleteLoading:false})
          ShowToast("message", "error", "" + error);
        });
    }
  }

  getColumnData = (data) => {
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon text-center">
          {this.state.exportLoading === true && this.state.exportProjectIds === data.item.id &&
            <React.Fragment key={"export" + data.item.id}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </React.Fragment>
          }
          {this.state.exportProjectIds !== data.item.id &&
            <React.Fragment>
                 <Link to="" className="ms-2 edt-btn btn-danger" onClick={(e) => this.onClickRestore(e, data.item.id)}>Restore</Link>
            </React.Fragment>
          }
        </div>
      );
    }
    if (data.type === "status") {
      return (
        <>
          {data.item.status === 1 &&
            "Active"
          }{data.item.status === 2 &&
            "Completed"
          }{data.item.status === 3 &&
            "Incomplete"
          }
        </>
      );
    }

    if (data.type === "importstatus") {
      return (
        <>
          {data.item.import_status === 0 &&
            "Failed"
          }{data.item.import_status === 1 &&
            "Completed"
          }{data.item.import_status === 2 &&
            "In Progress"
          }
        </>
      );
    }
    return columnData;
  }

  exportProject = () => {
  }

  getImportStatus = (value) => {
    if (value === 0) {
      return "Failed";
    }
    if (value === 1) {
      return "Completed";
    }
    if (value === 2) {
      return "In Progress";
    }
  }

  shortImportStatus = (rowA, rowB) => {
    const a = this.getImportStatus(rowA.import_status);
    const b = this.getImportStatus(rowB.import_status);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };


  getTableColumns = () => {
    let table_columns = [];
    if (this.props.userDetail.role === 1) {
      table_columns = [
        {
          name: "Project Name",
          selector: row => row.project_name,
          // cell: d => { return this.getProjectName({ ...d }) },
          wrap: true,
          width: "20%",
          sortable: true
        },
        {
          name: "Identifier",
          selector: row => row.identifier,
          width: "15%",
          // compact: true,
          wrap: true,
          sortable: true
        },
        {
          name: "# Tasks",
          compact: true,
          center: true,
          selector: row => row.imported_rows,
          sortable: true,
        },
        {
          name: "Integrity Progress",
          compact: true,
          center: true,
          selector: row => row.integrity_statustotal,
          cell: d => { return d.only_review !== 1 ? d.integrity_statustotal : "" },
          sortable: true,
        },
        {
          name: "Review Progress",
          selector: row => row.task_statustotal,
          compact: true,
          center: true,
          cell: d => { return d.only_integrity !== 1 ? d.task_statustotal : "" },
          sortable: true,
        },
        {
          name: "Owner",
          // compact: true,
          wrap: true,
          selector: row => row.created_user,
          width: "10%",
          sortable: true,
        },
        {
          name: "Created On",
          selector: row => row.created_at,
          width: "15%",
          wrap: true,
          cell: d => { return getFormattedDate(d.created_at) },
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "26%",
          cell: d => { return this.getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    else if (this.props.userDetail.role === 2) {
      table_columns = [
        {
          name: "Project Name",
          selector: row => row.project_name,
          // cell: d => { return this.getProjectName({ ...d }) },
          wrap: true,
          width: "30%",
          sortable: true
        },
        {
          name: "Identifier",
          selector: row => row.identifier,
          width: "15%",
          wrap: true,
          sortable: true
        },
        // {
        //   name: "Project Status",
        //   selector: row => row.status,
        //   sortable: true,
        //   cell: d => { return this.getColumnData({ type: "status", item: d }) }
        // },
        {
          name: "Owner",
          selector: row => row.created_user,
          width: "15%",
          sortable: true,
        },
        {
          name: "Created On",
          selector: row => row.created_at,
          width: "15%",
          cell: d => { return getFormattedDate(d.created_at) },
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "30%",
          cell: d => { return this.getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    return table_columns;
  }

  render() {
    // Create table headers
    const data = this.state.archiveList ? this.state.archiveList : [];
    const columns = this.getTableColumns();

    const tableData = {
      columns,
      data
    };
    

    /* const paginationComponentOptions = {
      selectAllRowsItem: true,
      selectAllRowsItemText: "ALL"
    }; */

    return (
      <>
        <div className="dashboard-card-wrapper">
          <ConfirmModal show={this.state.ShowLastRowModal}
          customMessage={"Are you sure you want to restore this project?"}
          deleteLoading={this.state.deleteLoading}
          onCloseModal={() => this.onCloseArchiveModal()}
          confirmClick={() => this.restoreProject()} />
          <div className="row align-items-center">
          {
            (this.props.userDetail.role === 1) &&
              <div className="col-12 text-end">
                <Link to={Constants.projectList} className="btn btn-blue btn-nex me-2">Back</Link>
              </div>
          }
          </div>
          <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
            <div className="card-main p-0">
              <div className="dark-table-wrapper table-scroll proj_table">
                {this.props.project_list && this.props.project_list.length !== 0 && this.state.loading === false &&
                  <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                    <DataTable
                      // sortFunction={this.customSort}
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      paginationRowsPerPageOptions={[10, 20, 30, 100, 500]}
                      paginationPerPage={20}
                      // paginationComponentOptions={paginationComponentOptions}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                }
                {this.state.loading === true &&
                  <table className="mb-0 table">
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
                {/* Not found */}
                {this.state.loading === false && this.props.project_list && this.props.project_list.length === 0 &&
                  <NotFound message="No Data Found" type="content" />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default connect(projectApp.mapStateToProps, projectApp.mapDispatchToProps)(ArchivedList)