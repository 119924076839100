import axios from "axios"
import { Component } from "react"
import $ from "jquery";
import { ShowToast } from "../../common/CommonController"
import { addProjectList } from "../Redux/Actions/CommonAction"
import { AppServices } from "../Services/AppServices"
import InvesterServices from "../Services/InvesterServices"
import PlatformServices from "../Services/PlatformServices"
import ProjectServices from "../Services/ProjectServices"
import TemplateServices from "../Services/TemplateServices"
import UserServices from "../Services/UserServices"
import { Interweave } from "interweave";

class projectApp extends Component {
  static mapStateToProps = (state) => {
    return {
      userDetail: state.common.db.user,
      project_list: state.common.db.project_list ? state.common.db.project_list : [],
    }
  }

  static mapDispatchToProps = (dispatch) => {
    return {
      onAddProjectList: (data) => dispatch(addProjectList(data))
    }
  }

  //#region Column Headers `Methods`
  getFileColumn = (project_id) => {
    this.setState({ fileLoading: true })
    let values = {
      projectid: project_id
    }
        ProjectServices.getFileColumn(values).then((response) => {
      if (response.data.code === 1) {
        if (response.data.data) {
          /* if (this.state.isEdit === false) {
            this.setDefaultFileColumns(response.data.data)
          } */
          this.setState({ file_columns: response.data.data },
            () => {
              this.afterGetColumnSuccess(response.data.data);
            })
        }
        else {
          this.setState({ file_columns: [] })
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          // ShowToast("object", "error", response.data.message)
        }
        else {
          // ShowToast("message", "error", response.data.message);
          this.setState({ fileMessage: response.data.message, importStatus: response.data.import_status, progress: parseInt(response.data.extra_details.percentage) },
            this.getFileProgress(this.props.project_id)
          );
        }
      }
      this.setState({ fileLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ fileLoading: false });
    });
  }

  saveHeaderColumns = () => {
    this.setState({ saveFileLoading: true })
    let data = {
      projectid: this.props.project_id,
      headerids: this.state.headerIds,
      review_header_ids: this.state.reviewHeaderIds,
    }
    if (this.props.projectDetail.only_integrity.toString() === "1") {
      data.only_integrity = this.props.projectDetail.only_integrity;
      data.only_review = 0
    }
    else if (this.props.projectDetail.only_integrity.toString() === "2") {
      data.only_review = 1
      data.only_integrity = 0
    }
    else {
      data.only_integrity = this.props.projectDetail.only_integrity;
      data.only_review = 0
    }

    ProjectServices.saveHeaderColumns(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.props.callBackHeaderColumnSuccess(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ saveFileLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveFileLoading: false });
    });
  }

  GetFileProgress = (project_id) => {
    let values = {
      projectid: project_id
    }
    // this.setState({ nextCall: false });
    localStorage.setItem("nextCall", false);

    ProjectServices.getFileProgress(values).then((response) => {
      if (response.data.code === 1) {
        if (response.data.import_status === "Success") {
          this.setState({
            file_columns: response.data.data,
            importStatus: response.data.import_status,
            fileMessage: "",
            // nextCall: true,
          })
          this.setDefaultFileColumns(response.data.data)
        }
        else {
          this.setState({
            progress: parseInt(response.data.data.percentage),
            // nextCall: true,
          })
        }
        localStorage.setItem("nextCall", true);
        this.getFileProgress();
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message);
          this.setState({ fileMessage: response.data.message });
        }
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }
  //#endregion Columns Headers

  //#region Import File
  ShowMsg = (msg) => {
    return (
      <Interweave content={msg} />
    )
  }

  importFile = (data) => {
    this.setState({ importLoading: true })
    // const [type, messageType, message, showMsg ] = ToastMsg();
    const token = localStorage.getItem('authtoken');
    axios
      .post(AppServices.IMPORT_FILE(), data, {
        headers: {
          'authtoken': `${token}`
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          if (Math.round((100 * data.loaded) / data.total) <= 99) {
            this.setState({ importProgress: Math.round((100 * data.loaded) / data.total) })
          }
        },
      }).then((response) => {
        if (response.data.code === 1) {
          this.props.callBackImportSuccess(response.data.data)
          ShowToast("message", "success", response.data.message)
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", this.ShowMsg(response.data.message))
          }
        }
        $("#projectfile").val("");
        this.setState({ importLoading: false, importProgress: 0, validated: false, numberofrows: "" });
      }).catch(error => {
        ShowToast("message", "error", "" + error);
        this.setState({ importLoading: false });
      })
  }
  //#endregion Import File

  //#region Start Save Project data
  getInvestors = () => {
    this.setState({ investorLoading: true })
    InvesterServices.getInvestors().then((response) => {
      if (response.data.code === 1) {
        this.setState({ investorList: response.data.data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ investorLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ investorLoading: false });
    });
  }

  getPlatforms = () => {
    this.setState({ platformLoading: true })
    PlatformServices.getPlatforms().then((response) => {
      if (response.data.code === 1) {
        this.setState({ platformList: response.data.data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ platformLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ platformLoading: false });
    });
  }

  saveInvestor = (data) => {
    this.setState({ addInvestorloading: true })
    InvesterServices.saveInvestor(data).then((response) => {
      if (response.data.code === 1) {
        this.getInvestors();
        this.handleInvestorReset();
        this.setState({ investorValidated: false });
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ addInvestorloading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ addInvestorloading: false });
    });
  }

  handleInvestorReset = () => {
    Array.from(document.getElementsByName("investor_name")).forEach(
      input => (input.value = "")
    );
  };

  handlePlatformReset = () => {
    Array.from(document.getElementsByName("platform_name")).forEach(
      input => (input.value = "")
    );
  };

  savePlatform = (data) => {
    this.setState({ addPlatformloading: true })
    PlatformServices.savePlatform(data).then((response) => {
      if (response.data.code === 1) {
        this.getPlatforms();
        this.handlePlatformReset();
        this.setState({ platformValidated: false });
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ addPlatformloading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ addPlatformloading: false });
    });
  }

  saveProject = () => {
    this.setState({ projectloading: true })
    let newData = {};
    newData.project_name = this.state.project_name;
    newData.identifier = this.state.identifier;
    newData.investor_id = this.state.investor_id;
    newData.platform_id = this.state.platform_id;
    newData.mark_complete = this.state.mark_complete === true ? 1 : 0;
    newData.file_completeness = this.state.file_completeness === true ? 1 : 0;
    newData.pll_flag = this.state.pll_flag === true ? 1 : 0;
    
    if (this.state.only_integrity.toString() === "1") {
      newData.only_integrity = this.state.only_integrity;
      newData.only_review = 0
    }
    else if (this.state.only_integrity.toString() === "2") {
      newData.only_review = 1
      newData.only_integrity = 0
    }
    else {
      newData.only_integrity = this.state.only_integrity;
      newData.only_review = 0
    }
    // newData.only_integrity = this.state.only_integrity;
    newData.fileid = this.state.fileid;
    if (this.props.projectDetail.id) {
      newData.projectid = this.props.projectDetail.id;
    }
    ProjectServices.saveProject(newData).then((response) => {
      if (response.data.code === 1) {
        this.props.callBackPortFolioSuccess(response.data.data);
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ projectloading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ projectloading: false });
    });
  }

  getTemplate = (templateId) => {
    this.setState({ getTemplateLoading: true })
    TemplateServices.getTemplate(templateId).then((response) => {
      if (response.data.code === 1) {
        this.setState({ templateDetail: response.data.data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ getTemplateLoading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ getTemplateLoading: false });
    });

  }

  platForm_FormErrors = () => {
    const { platform_name } = this.state
    const newErrors = {}
    if (!platform_name || platform_name === '') {
      newErrors.platform_name_error = 'Please enter platform name'
    }
    return newErrors
  }

  projectFormErrors = () => {
    const { project_name, identifier, platform_id, investor_id, only_integrity } = this.state
    const newErrors = {}
    if (!project_name || project_name === '') {
      newErrors.project_name_error = 'Please enter project name'
    }
    if (!identifier || identifier === '') {
      newErrors.identifier_error = 'Please enter identifier name';
    }
    if (!investor_id || investor_id === '') {
      newErrors.investor_id_error = 'Please select investor';
    }
    if (!platform_id || platform_id === '') {
      newErrors.platform_id_error = 'Please select platform';
    }
    if (!only_integrity || only_integrity === '') {
      newErrors.only_integrity_error = 'Please select project type';
    }
    return newErrors
  }

  onChangePlatform = (event) => {
    this.setState({ [event.target.name]: event.target.value })
    // Check and see if errors exist, and remove them from the error object:
    this.clearErrors(event.target.name);
  }

  onChangeName = (event) => {
    if (event.target.name === "project_name") {
      this.setState({ [event.target.name]: event.target.value, isProjectNameChange: true })
    }
    else {
      this.setState({ [event.target.name]: event.target.value });
    }
    // Check and see if errors exist, and remove them from the error object:
    this.clearErrors(event.target.name);
  }

  clearErrors = (name) => {
    let error_name = name + "_error"
    if (!!this.state[error_name]) {
      this.setState({
        [error_name]: ""
      })
    }
  }

  investorFormErrors = () => {
    const { investor_name } = this.state
    const newErrors = {}
    if (!investor_name || investor_name === '') {
      newErrors.investor_name_error = 'Please enter investor name'
    }
    return newErrors
  }

  onChangeInvestor = (event) => {
    this.setState({ [event.target.name]: event.target.value })
    // Check and see if errors exist, and remove them from the error object:
    this.clearErrors(event.target.name);
  }
  //#endregion Save Project data

  //#region Questions Methods
  getTamplates = () => {
    this.setState({ listTamplateLoading: true })
    TemplateServices.getTemplates().then((response) => {
      if (response.data.code === 1) {
        this.setState({ templateList: response.data.data });
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ listTamplateLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ listTamplateLoading: false });
    });
  }

  saveTamplate = () => {
    this.setState({ templateLoading: true })
    let newData = { name: this.state.template_name }
    newData.status = 1;
    TemplateServices.saveTemplate(newData).then((response) => {
      if (response.data.code === 1) {
        // this.setTamplateReset();
        this.setState({ templateId: response.data.data.id })
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ templateLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ templateLoading: false });
    });
  }

  saveQuestions = () => {
    this.setState({ questionsLoading: true })
    let questionids = [];
    this.state.selectedQuestions.map((item) => {
      questionids.push(item.id);
      return null;
    })
    let newData = {};
    newData.projectid = this.props.project_id;
    newData.template_name = this.state.template_name;
    newData.questionids = questionids;
    if (this.state.templateId !== "" && this.state.templateId !== null) {
      newData.templateid = this.state.templateId;
    }
    newData.status = 1;
    ProjectServices.saveQuestions(newData).then((response) => {
      if (response.data.code === 1) {
        this.props.callBackQuestionSuccess(response.data.data);
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ questionsLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ questionsLoading: false });
    });
  }
  //#endregion Questions

  //#region Start Project list methods 
  getProjects = () => {
    if (this.props.project_list.length === 0) {
      this.setState({ loading: true })
    }
    ProjectServices.getProjects().then((response) => {
      if (response.data.code === 1) {
        if (response.data.data) {
          this.props.onAddProjectList(response.data.data);
          this.setState({ projectList: response.data.data }); // Currently no use of state
        }
        else {
          this.setState({ projectList: [] })
        }
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  deleteProject = (data) => {
    this.setState({ loading: true });
    ProjectServices.deleteProject(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onDeleteSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  handleDelete = (e) => {
    let newData = { project_id: this.state.projectId }
    this.setState({ showModal: false })
    this.deleteProject(newData);
  };

  onClickDelete = (e, projectId) => {
    e.preventDefault();
    this.setState({ showModal: true, projectId: projectId })
  }

  onCloseModal = () => {
    this.setState({ showModal: false })
  }
  onCloseArchiveModal= ()=>
  {
    this.setState({ ShowLastRowModal: false })
  }

  onDeleteSuccess = (data) => {
    this.getProjects();
  }
  onClickExport = (e, projectId) => {
    e.preventDefault();
    this.setState({ exportLoading: true, exportProjectIds: projectId })
    let data = {
      projectid: projectId,
    }
    ProjectServices.exportData(data).then((response) => {
      if (response.data.code === 1) {
        let r = response.data.data;
        const a = document.createElement('a');
        a.setAttribute('href', r);
        a.setAttribute("download", r.split("exports/")[1]);
        a.target = "_blank";
        a.click();
        this.exportSuccess();
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ exportLoading: false, exportProjectIds: "" })
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ exportLoading: false, exportProjectIds: "" })
    });
  }

  exportSuccess = () => {
    this.setState({ exportLoading: false, exportProjectIds: "" })
  }
  //#endregion Project list methods

  //#region Users methods 
  getUsers = () => {
    this.setState({ usersLoading: true })
    UserServices.getUsers().then((response) => {
      if (response.data.code === 1) {
        let dataList = response.data.data.filter((item) => (item.role !== 1 && item.role !== 3));
        dataList.map((item) => {
          item.fullname = item.firstname + " " + item.lastname;
          return null;
        })
        let SelectedUserList = response.data.data.filter((item) => (item.role !== 2 && item.role !== 3));
        SelectedUserList.map((item) => {
          item.fullname = item.firstname + " " + item.lastname;
          return null;
        })
        if (this.state.isEdit) {
          this.setState({ usersList: dataList, usersLoading: false });
          this.editCallBack(SelectedUserList);
        }
        else {
          this.setState({ authorizedUserList: SelectedUserList, usersList: dataList, usersLoading: false })
          this.afterSuccess({ SelectedUsers: SelectedUserList, type: "remove" })
        }
        // this.setState({ usersList: dataList, usersLoading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ usersLoading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ usersLoading: false });
    });
  }

  saveProjectUsers = (data) => {
    this.setState({ saveUsersLoading: true })
    ProjectServices.saveProjectUsers(data).then((response) => {
      if (response.data.code === 1) {
        this.props.callBackUsersSuccess(response.data.data);
        ShowToast("message", "success", response.data.message)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ saveUsersLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveUsersLoading: false });
    });
  }
  //#endregion Users methods 
}
export default projectApp