import * as ReduxConstant from '../ReduxConstant';

export function addToken(token) {
  return {
    type: ReduxConstant.ADD_TOKEN,
    token: token
  };
};
export function addUser(user) {
  return {
    type: ReduxConstant.ADD_USER,
    user
  };
};
export function addProjectList(project_list) {
  return {
    type: ReduxConstant.ADD_PROJECTLIST,
    project_list
  };
};