import React from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddQuestionModal from "../common/AddQuestionModal";
import * as Constants from "../partials/CommonConstant"
import templateApp from "../Shared/Controller/templateApp";
import { EditImg, DeleteImg } from "../common/ImageConstants";
import ConfirmModal from "../common/ConfirmModal";
import NotFound from "../common/NotFound";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import QuestionDataTable from "./QuestionDataTable";

class AddTemplate extends templateApp {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            validated: false,
            saveLoading: false,
            modalShow: false,
            questionId: "",
            editQuestionLoading: false,
            editquestionId: "",
            questionDetails: [],
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onQuestionModalShow = (e, editquestionId = "") => {
        if (editquestionId !== "") {
            e.preventDefault();
            this.getQuestion(editquestionId)
            // this.setState({ modalShow: true, editquestionId: editquestionId });
        } else {
            this.setState({ modalShow: true, editquestionId: "", questionDetails: [] });
        }
    }

    onQuestionCloseModal = () => {
        this.setState({ modalShow: false, editquestionId: "", questionDetails: [] });

    }

    callBackAddQuestion = (data) => {
        /* let relatedQuestions = this.state.related_questions;
         relatedQuestions.push(data);
         this.setState({ related_questions: relatedQuestions })
         */
        if (this.props.isEdit) {
            this.getTemplate(this.props.templateId)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        else {
            let newData = { ...this.state }
            newData.status = 1;
            delete newData.loading
            delete newData.validated
            console.log("submit New template", this.state, newData);
            if (this.props.isEdit) {
                newData.templateId = this.props.templateId
            }
            this.saveTemplate(newData);
        }
        this.setState({ validated: true });
    };

    componentDidMount = () => {
        if (this.props.isEdit) {
            this.getTemplate(this.props.templateId)
            this.setState({ templateId: this.props.templateId });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.templateId !== this.props.templateId) {
            this.getTemplate(this.props.templateId)
            this.setState({ templateId: this.props.templateId });
        }
    }

    getColumnData = (data) => {
        let columnData = "";
        if (data.type === "action") {
            return (
                <div className="edit-icon text-center">
                    {/* <Link to={`${Constants.editTemplate}${data.item.id}`} className="ms-2"><img src={EditImg} alt="Edit" title="Edit" /></Link> */}
                    <Link to="/" className="ms-2" onClick={(e) => this.onQuestionModalShow(e, data.item.id)}>
                        <img src={EditImg} alt="Edit" title="Edit" />
                    </Link>
                    <Link to="/" className="ms-2" onClick={(e) => this.onClickQuestionDelete(e, data.item.id)}>
                        <img src={DeleteImg} alt="Delete" title="Delete" />
                    </Link>
                </div>
            );
        }
        if (data.type === "category") {
            return (
                <>
                    {data.item.category === 1 ? "File" : (data.item.category === 2 ? "Loan" : "Delinquency")}
                </>
            );
        }
        return columnData;
    }

    render() {
        // Create table headers
        const data = this.state.related_questions;
        const columns = [
            {
                name: "Question",
                selector: row => row.question,
                wrap: true,
                sortable: true
            },
            {
                name: "Export Heading",
                selector: row => row.export_heading,
                wrap: true,
                sortable: true,
            },
            {
                name: "Category",
                selector: row => row.category,
                sortable: true,
                cell: d => { return this.getColumnData({ type: "category", item: d }) }
            },
            {
                name: "Action",
                center: true,
                cell: d => { return this.getColumnData({ type: "action", item: d }) }
            }
        ];

        const tableData = {
            columns,
            data
        };

        return (
            <div className="dashboard-card-wrapper addedit-template">
                <ConfirmModal show={this.state.showModal}
                    onCloseModal={() => this.onCloseModal()}
                    module="question"
                    confirmClick={() => this.handleQuestionDelete()} />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
                        <div className="card-main">
                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <Row className="row g-4">
                                    <Form.Group md="4" className="col-md-6" controlId="validationCustom01">
                                        <Form.Label>Template Name</Form.Label>
                                        <Form.Control
                                            required
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter template name</Form.Control.Feedback>
                                    </Form.Group>

                                    <div className="col-12">
                                        {this.state.saveLoading === false &&
                                            <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                                                Save
                                            </Button>
                                        }
                                        {this.state.saveLoading === true &&
                                            <Button className="btn btn-sm btn-blue" variant="secondary" type="button">
                                                <Spinner animation="border" size="sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </Button>
                                        }
                                        <Link to={Constants.templates} className="btn btn-sm btn-blue ms-2" variant="secondary" >Cancel</Link>
                                    </div>
                                </Row>
                            </Form>
                            <div className="col-12 text-end">
                                {this.props.isEdit &&
                                    <Button onClick={() => this.onQuestionModalShow()} variant="secondary" className="btn btn-blue btn-nex">Add Question</Button>
                                }
                                <AddQuestionModal show={this.state.modalShow}
                                    onCloseModal={this.onQuestionCloseModal}
                                    callBackAddQuestion={(data) => this.callBackAddQuestion(data)}
                                    templateId={(this.props.isEdit) ? this.props.templateId : ''}
                                    questionDetails={(this.state.questionDetails.length !== 0) ? this.state.questionDetails : []}
                                />
                            </div>
                            <div className="dark-table-wrapper table-scroll data-table">
                                {this.state.related_questions && this.state.related_questions.length !== 0 && this.state.loading === false &&
                                    <>
                                        {/* <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortField="name"
                                            defaultSortAsc={true}
                                            pagination
                                            highlightOnHover
                                        />
                                    </DataTableExtensions> */}
                                        <QuestionDataTable
                                            data={data}
                                            templateId={this.props.templateId}
                                            onQuestionModalShow={this.onQuestionModalShow}
                                            onClickQuestionDelete={this.onClickQuestionDelete}
                                        />
                                    </>
                                }
                                {this.state.loading === true &&
                                    <table className="mb-0 table">
                                        <tbody>
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                {/* Not found */}
                                {this.state.loading === false && this.state.related_questions && this.state.related_questions.length === 0 &&
                                    <NotFound message="No Questions Added." type="content" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default AddTemplate;