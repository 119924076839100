import { Component } from "react"
import TemplateServices from "../Services/TemplateServices"
import QuestionServices from "../Services/QuestionServices"
import { ShowToast } from "../../common/CommonController"

class templateApp extends Component {
  getTemplates = () => {
    this.setState({ loading: true })
    TemplateServices.getTemplates().then((response) => {
      if (response.data.code === 1) {
        this.setState({ templateList: response.data.data, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  saveTemplate = (data) => {
    this.setState({ saveLoading: true })
    TemplateServices.saveTemplate(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onSaveSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ saveLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveLoading: false });
    });
  }

  getTemplate = (templateId) => {
    this.setState({ loading: true })
    TemplateServices.getTemplate(templateId).then((response) => {
      if (response.data.code === 1) {
        let data = {
          loading: false,
          ...response.data.data
        }
        this.setState({ ...data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });

  }

  deleteTemplate = (data) => {
    this.setState({ loading: true });
    TemplateServices.deleteTemplate(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onDeleteSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  deleteQuestion = (data) => {
    this.setState({ loading: true });
    QuestionServices.deleteQuestion(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onDeleteQuestionSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  getQuestion = (questionId) => {
    this.setState({ editQuestionLoading: true })
    QuestionServices.getQuestion(questionId).then((response) => {
      if (response.data.code === 1) {
        let data = {
          editQuestionLoading: false,
          questionDetails: response.data.data,
          modalShow: true,
          editquestionId: questionId,
        }
        this.setState({ ...data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ editQuestionLoading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ editQuestionLoading: false });
    });

  }


  onSaveSuccess = (data) => {
    // this.getTemplates();
    this.props.callBackSuccess(data)
  }


  handleDelete = (e) => {
    let newData = { templateId: this.state.templateId }
    this.setState({ showModal: false })
    this.deleteTemplate(newData);
  };

  onClickDelete = (e, templateId) => {
    e.preventDefault();
    this.setState({ showModal: true, templateId: templateId })
  }

  onCloseModal = () => {
    this.setState({ showModal: false })
  }

  onDeleteSuccess = (data) => {
    this.getTemplates();
  }


  handleQuestionDelete = (e) => {
    let newData = { questionId: this.state.questionId }
    this.setState({ showModal: false })
    this.deleteQuestion(newData);
  };

  onClickQuestionDelete = (e, questionId) => {
    e.preventDefault();
    this.setState({ showModal: true, questionId: questionId })
  }

  onDeleteQuestionSuccess = (data) => {
    if (this.state.templateId !== '')
      this.getTemplate(this.state.templateId);
  }


}
export default templateApp