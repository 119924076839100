import React from 'react'
import * as Constants from "./partials/CommonConstant";
import * as ImgConstants from "./common/ImageConstants";

const LoginLeftBlock = () => {
    return (
        <div className='login-form-left-block'>
            <div className='login-left-inner-content text-center'>
                <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} />
                <div className='heading'>
                    <h2>Qflow</h2>
                </div>
                <p>Please use your credentials to login.</p>
            </div>
            <img src={ImgConstants.LoginLeftImg} alt={Constants.ImageALT} title={Constants.ImageTitle} />
        </div>
    )
}
export default LoginLeftBlock;