import { getEnviromentApiUrl } from '../common/CommonController';


// Common vars
export const ImageALT = 'Quadrin Group';
export const ImageTitle = 'Quadrin Group';
export const QuestionCategory = ['File', 'Loan', 'Delinquency'];


//Site urls
export const Login = '/login';
export const ForgotPassword = '/forgot-password';
export const ResetPassword = '/reset-password';
export const VerifyUser = '/verify-user';
export const dashboard = '/';
export const home = '/home';
export const users = '/users';
export const addUser = '/add-user';
export const editUser = '/edit-user/:user_id';
export const investors = '/investors';
export const platforms = '/platforms';
export const editProfile = '/edit-profile';
export const profile = '/profile';
export const addInvestores = '/add-investor';
export const editInvestores = '/edit-investor/:investorId';
export const addPlatform = '/add-platform';
export const editPlatform = '/edit-platform/:platformId';
export const viewUser = '/view-user/:user_id';
export const projectList = '/projects';
export const addProject = '/add-project';
export const archived = '/archived';

export const templates = '/templates';
export const addTemplate = '/add-template';
export const editTemplate = '/edit-template/:templateId';
export const viewTemplate = '/view-template/:templateId';
export const deletedInvestores = '/deleted-investors';
export const deletedPlatforms = '/deleted-platforms';
export const deletedUsers = '/deleted-users';
export const deletedTemplates = '/deleted-templates';
export const deletedQuestions = '/deleted-questions';
export const editProject = '/edit-project/:project_id';
export const viewProject = '/view-project/:project_id';
export const reviewProject = "/review-project/:project_id";
export const integrityProject = "/integrity-project/:project_id";
export const allFiles = "/files"
export const viewFile = '/view-file/:fileid';
export const addFile = "/add-file/";
export const addFileParent = "/add-file/:fileid";
export const editFile = "/edit-file/:fileid";
export const downlodLogs = "/download-logs";
export const sessionLogs = "/session-logs";
export const siteLogs = "/site-logs";
export const addFolder = "/add-folder/";
export const addSubFolder = "/add-folder/:folderid";
export const addFolderFile = "/add-folder-file/:folderid";
export const editFolder = "/edit-folder/:folderid";
export const editFolderFile = "/edit-file/:folderid/:fileid";
export const viewFolder = '/view-folder/:folderid';
export const uploadFolder = '/upload-folder';
export const projectRecords = '/project-records/:projectid';
export const deletedProjects = '/deleted-projects';
export const docViewer = '/fileviewer/:fileid';

// API Urls
export const apiurl = getEnviromentApiUrl();
export const UserLogin = apiurl + 'user/login';
export const UserForgotPassword = apiurl + 'user/forgot-password';
export const UserResetPassword = apiurl + 'user/reset-password';
export const VerifyUserEmail = apiurl + 'user/verifyemail';
export const VerifiedUsersetPassword = apiurl + 'user/setpassword';
export const GET_USERS = apiurl + 'allusers';
export const SAVE_USER = apiurl + 'user/add';
export const UPDATE_USER = (userId) => { return apiurl + 'user/update/' + userId };
export const GET_USER = (userId) => { return apiurl + 'user/view/' + userId };
export const DELETE_USER = (userId) => { return apiurl + 'user/delete/' + userId };
export const RESTORE_USER = (userId) => { return apiurl + 'user/restore/' + userId };
export const RESEND_VERIFY_EMAIL = apiurl + 'user/resend-verification';
export const GET_INVESTORES = apiurl + 'allinvestors';
export const SAVE_INVESTOR = apiurl + 'investor/add';
export const GET_PLATFORMS = apiurl + 'allplatforms';
export const UPDATE_INVESTOR = (investorId) => { return apiurl + 'investor/update/' + investorId };
export const GET_INVESTOR = (investorId) => { return apiurl + 'investor/view/' + investorId };
export const DELETE_INVESTOR = (investorId) => { return apiurl + 'investor/delete/' + investorId };
export const RESTORE_INVESTOR = (investorId) => { return apiurl + 'investor/restore/' + investorId };
export const SAVE_PLATFORM = apiurl + 'platform/add';
export const UPDATE_PLATFORM = (platformId) => { return apiurl + 'platform/update/' + platformId };
export const GET_PLATFORM = (platformId) => { return apiurl + 'platform/view/' + platformId };
export const DELETE_PLATFORM = (platformId) => { return apiurl + 'platform/delete/' + platformId };
export const RESTORE_PLATFORM = (platformId) => { return apiurl + 'platform/restore/' + platformId };
export const GET_PROFILE = (userId) => { return apiurl + 'user/view/' + userId };
export const GET_PROJECTS = apiurl + 'allprojects';
export const GET_ARCHIVE = apiurl + 'all-archives';
export const IMPORT_FILE = apiurl + 'project/importsheet';
export const GET_TEMPLATES = apiurl + 'alltemplates';
export const SAVE_TEMPLATE = apiurl + 'template/add';
export const UPDATE_TEMPLATE = (templateId) => { return apiurl + 'template/update/' + templateId };
export const GET_TEMPLATE = (templateId) => { return apiurl + 'template/view/' + templateId };
export const DELETE_TEMPLATE = (templateId) => { return apiurl + 'template/delete/' + templateId };
export const RESTORE_TEMPLATE = (templateId) => { return apiurl + 'template/restore/' + templateId };
export const SAVE_PROJECT = apiurl + 'project/addedit';
export const GET_FILE_COLUMNS = apiurl + 'project/sheetheaders';
export const SAVE_HEADER_COLUMNS = apiurl + 'project/managesheetheaders';
export const DELETE_PROJECT = () => { return apiurl + 'project/delete' };
export const POST_SAVE_QUESTION = apiurl + 'question/add';
export const SAVE_QUESTIONS = apiurl + "project/add-templatequestions";
export const SAVE_PROJECT_USERS = apiurl + "project/assign-users";
export const GET_PROJECT_DETAIL = (project_id) => { return apiurl + 'project/view/' + project_id };
export const GET_FILE_PROGRESS = apiurl + "project/import-progress";
export const GET_QUESTIONS = apiurl + 'allquestions';
export const GET_QUESTION = (questionId) => { return apiurl + 'question/view/' + questionId };
export const DELETE_QUESTION = (questionId) => { return apiurl + 'question/delete/' + questionId };
export const UPDATE_QUESTION = (questionId) => { return apiurl + 'question/update/' + questionId };
export const RESTORE_QUESTION = (questionId) => { return apiurl + 'question/restore/' + questionId };
export const GET_PROJECT_ROW = apiurl + 'review/getrow';
export const SAVE_REVIEW_ROW = apiurl + "review/saverow";
export const GET_ALL_FILES = apiurl + "folder/view-all";
export const DOWNLOAD_FILE = apiurl + "file/download";
export const GET_FILE = (fileId) => { return apiurl + 'file/view/' + fileId };
export const ADD_FILE = apiurl + 'file/add';
export const DELETE_FILE = (fileId) => { return apiurl + 'file/delete/' + fileId };
export const UPDATE_FILE = () => { return apiurl + 'file/update' };
export const GET_INTEGRITY_PROJECT_ROW = apiurl + 'integrity/getrow';
export const SAVE_INTEGRITY_ROW = apiurl + "integrity/saverow";
export const GET_DOWNLOAD_LOGS = apiurl + "alldownloadlogs";
export const GET_SESSION_LOG = apiurl + "logs/get-session-logs";
export const GET_SITE_LOG = apiurl + "logs/get-site-logs";
export const GET_DASHBOARD = apiurl + "dashboard/dashboard-details";
export const DELETE_SITE_LOGS = (noofmonths) => { return apiurl + "logs/delete-site-logs/" + noofmonths };
export const DELETE_SESSION_LOGS = (noofmonths) => { return apiurl + "logs/delete-session-logs/" + noofmonths };
export const GET_EXPORT_DATA = () => { return apiurl + 'project/generate-report' };
export const VERIFY_CONFIRM_DELETE = () => { return apiurl + 'user/email-delete-confirmation' };
export const SAVE_FOLDER = () => { return apiurl + 'folder/add-folder' };
export const GET_FOLDER = (folderid) => { return apiurl + 'folder/view/' + folderid };
export const UPDATE_FOLDER = () => { return apiurl + 'folder/edit-folder' };
export const DELETE_FOLDER = (id) => { return apiurl + 'folder/delete/' + id };
export const GET_SHORTCUT_URL = () => { return apiurl + 'dashboard/get-url-shortcut' };
export const GET_PROJECT_RECORD = () => { return apiurl + 'task/view-project-tasks' };
export const GET_PROJECT_RECORD_ROW = apiurl + 'task/get-review-details';
export const GET_INTEGRITY_PROJECT_RECORD_ROW = apiurl + 'task/get-integrity-details';
export const RESTORE_PROJECT = (projectid) => { return apiurl + 'project/restore/' + projectid };
export const UPLOAD_FOLDER_FILE = () => { return apiurl + 'folder/add-nested-folders' };
export const VERIFY_FILE_TOKEN = (token) => { return apiurl + 'redirect-url/' + token };
export const DELETE_MULTIPLE_DOCUMENT = () => { return apiurl + 'file/delete-files' };
export const ARCHIVE_PROJECT = ()=>{ return apiurl + 'project/archive'}
export const UNARCHIVE_PROJECT = ()=>{ return apiurl + 'project/unarchive'}
export const SEND_CRON_JOB_MAIL_DELETE_FILE = () => { return apiurl + 'dashboard/mail-delete-downloaded-files' };
export const GET_INTEGRITY_ROW_VIEW = apiurl + 'integrity/getrowview';
export const GET_REVIEW_ROW_VIEW = apiurl + 'task/getreviewdetailsview';
export const POST_MULTIPLE_TEMPLATE_DELETE = apiurl + 'template/deletemultiple';
export const POST_QUESTION_REORDER = apiurl + 'question/reorder';
