import React, { useState } from 'react'
import axios from 'axios'
import * as Constants from "./partials/CommonConstant";
import * as ImgConstants from "./common/ImageConstants";
import { Link, useNavigate } from 'react-router-dom';
import LoginLeftBlock from './LoginLeftBlock';
import { Spinner } from 'react-bootstrap';
import { hasErrorFor, renderErrorFor, renderSuccessMessage, renderErrorMessage } from "./Shared/Controller/authApp";

const Resetpassword = (props) => {

  const queryParams = new URLSearchParams(window.location.search);
  const [token] = useState(queryParams.get('token'));
  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [success_message, setSuccessMsg] = useState('');
  const [error_message, setErrorMsg] = useState('');
  const [errors, setErrors] = useState([]);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate()

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const onChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value)
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    let send_data = {
      token: token,
      password: password,
      password_confirmation: password_confirmation,
    }
    setErrors([]);
    setErrorMsg("");
    setLoading(true);
    axios.post(Constants.UserResetPassword, send_data)
      .then(response => {
        if (response.data.code === 1) {
          // For success
          setSuccessMsg(response.data.message)
          navigate(Constants.Login, response.data.message)
        } else {
          // console.log(response.data.message.constructor);
          if (response.data.message.constructor === Object) {
            setSuccessMsg("");
            setErrors(response.data.message)
          } else {
            setSuccessMsg("");
            setErrorMsg(response.data.message);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setErrors(error.response.data.errors);
      })
  }

  return (
    <div className='main'>
      <div className='login-form-block'>
        <div className='mobile-logo'>
          <img src={ImgConstants.LogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} className='' />
          <div className='heading'>
            <h2>Qflow</h2>
          </div>
        </div>

        <div className='login-banner-block'>
          <img src={ImgConstants.LoginBanner} alt={Constants.ImageALT} title={Constants.ImageTitle} />
        </div>
        <div className='login-form-box-main'>
          <LoginLeftBlock />
          <div className='login-form-right-block'>
            <div className='login-form-logo'>
              <img src={ImgConstants.FormLogoImg} alt={Constants.ImageALT} title={Constants.ImageTitle} />
            </div>
            <form onSubmit={handleFormSubmit}>
              <h4>Reset Password</h4>
              {renderSuccessMessage(success_message)}
              {renderErrorMessage(error_message)}
              <div className='mb-3 form-block'>
                <label htmlFor='password' className='form-label'>Password</label>
                <input type={isShowPassword === true ? 'text' : 'password'}
                  name='password'
                  className={`password-icon form-control ${hasErrorFor({ field: 'password', errors: errors }) ? 'is-invalid' : ''}`}
                  id='password' placeholder='Password'
                  value={password}
                  onChange={onChangePassword} />
                <span
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  className='password-show' style=
                  {isShowPassword === true ? { backgroundImage: `url(${ImgConstants.PassswordShowImg})` } : { backgroundImage: `url(${ImgConstants.PassswordHideImg})` }}>
                </span>
                {renderErrorFor({ field: 'password', errors: errors })}
              </div>
              <div className='mb-3 form-block'>
                <label htmlFor='password_confirmation' className='form-label'>Confirm Password</label>
                <input type={isShowConfirmPassword === true ? 'text' : 'password'}
                  name='password_confirmation'
                  className={`password-icon form-control ${hasErrorFor({ field: 'password_confirmation', errors: errors }) ? 'is-invalid' : ''}`}
                  id='password_confirmation'
                  placeholder='Confirm Password'
                  value={password_confirmation}
                  onChange={onChangeConfirmPassword} />
                <span
                  onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                  className='password-show' style=
                  {isShowConfirmPassword === true ? { backgroundImage: `url(${ImgConstants.PassswordShowImg})` } : { backgroundImage: `url(${ImgConstants.PassswordHideImg})` }}>
                </span>
                {renderErrorFor({ field: 'password_confirmation', errors: errors })}
              </div>
              <div className='form-btn'>
                <Link to={Constants.Login}>Login</Link>
                {loading === false &&
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                }
                {loading === true &&
                  <button type='button' className='btn btn-primary'>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </button>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Resetpassword