import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import FileViewer from "react-file-viewer";
import { useParams } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import { DownloadFile } from "../Shared/Controller/filesApp";
import FileError from "./FileError";

const DocumentViewer = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [fileExtenstion, setExtension] = useState("");
  const file = url;
  const type = fileExtenstion;

  useEffect(() => {
    if (params.fileid) {
      setLoading(true);
      DownloadFile({ fileid: params.fileid }).then(response => {
        if (response.data.code === 1) {
          let url = response.data.data.download_url;
          // let fileext = url.split("/")[url.split("/").length - 1].split(".");
          if(response.data.data.extension === "xls") {
            response.data.data.extension = "xlsx";
          }
          if(response.data.data.extension === "doc") {
            response.data.data.extension = "docx";
          }
          setExtension(response.data.data.extension);
          setUrl(response.data.data.download_url);
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        setLoading(false);
        ShowToast("message", "error", "" + error);
      });
    }
  }, [])

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  // console.log("filevier", url)

  return (
    <React.Fragment>
      {loading === false && url !== "" &&
        <div className="col-12 p-5">
          <FileViewer
            fileType={type}
            filePath={file}
            onError={onError}
            errorComponent={FileError}
            height={"100%"}
          />
        </div>
      }
      {loading &&
        <div className="text-center p-5">
          <Spinner animation="border" size="lg" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }
    </React.Fragment>
  )
}
export default DocumentViewer;