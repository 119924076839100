import React from "react"
import { Col } from "react-bootstrap"

const ProjectColsDetails = (props) => {

  const fieldLayout = {
    sm: "12",
    md: "12",
    lg: "6",
    xl: "6",
  }

  const setProjLable = () => {
    let lable = "";
    if (props.Col_Type === "total_task") {
      lable = <label className={props.projectDetail?.projectfile ? "" : "alert-red"}>{props.projectDetail?.projectfile ? props.Col_Lable : "File Note"}</label>
    }
    else if (props.Col_Type === "investor") {
      lable = <label className={props.projectDetail?.investordetails ? "" : "alert-red"}>{props.Col_Lable}</label>
    }
    else if (props.Col_Type === "platform") {
      lable = <label className={props.projectDetail?.platform_id ? "" : "alert-red"}>{props.Col_Lable}</label>
    }
    else if (props.Col_Type === "template") {
      lable = <label className={props.projectDetail?.templatedetails !== null ? "" : "alert-red"}>{props.Col_Lable}</label>
    }
    else {
      lable = <label>{props.Col_Lable}</label>
    }
    return lable;
  }

  const setProjLblValue = () => {
    let lblValue = "";
    if (props.Col_Type === "total_task") {
      lblValue = <p className={props.projectDetail?.projectfile ? "" : "alert-red"}>
        {props.projectDetail?.projectfile ? props.projectDetail?.projectfile.total_rows : props.projectDetail?.file_msg}
      </p>
    }
    else if (props.Col_Type === "investor") {
      lblValue = <p className={props.projectDetail?.investordetails ? "" : "alert-red"}>
        {props.projectDetail?.investordetails ? props.projectDetail?.investordetails.name : "Investor Deleted"}
      </p>
    }
    else if (props.Col_Type === "platform") {
      lblValue = <p className={props.projectDetail?.platformdetails ? "" : "alert-red"}>
        {props.projectDetail?.platformdetails ? props.projectDetail?.platformdetails.name : "Platform Deleted"}
      </p>
    }
    else if (props.Col_Type === "template") {
      lblValue = <p className={props.projectDetail?.templatedetails !== null ? "" : "alert-red"}>
        {props.projectDetail?.templatedetails ? props.projectDetail?.templatedetails.name : "Template Deleted"}
      </p>
    }
    else if (props.Col_Type === "status") {
      lblValue = <p>
        {props.projectDetail?.status === 1 &&
          "Active"
        }
        {props.projectDetail?.status === 2 &&
          "Completed"
        }{props.projectDetail?.status === 3 &&
          "Incomplete"
        }</p>
    }
    else {
      lblValue = <p>{props.Col_Value}</p>
    }
    return lblValue;
  }

  return (
    <React.Fragment>
      <Col {...fieldLayout}>
        <div className="profile-card">
          <div className="profile-label pe-2">
            {setProjLable()}
          </div>
          <div className="profile-value">
            {setProjLblValue()}
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ProjectColsDetails;