import axios from "axios"
import { AppServices, HttpHeaders } from "./AppServices";

const LogsServices = {
  GetSessionLogs: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_SESSION_LOG(), {
      params: data,
      headers: header.headers,
    })
  },
  GetSiteLogs: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_SITE_LOG(), {
      params: data,
      headers: header.headers,
    })
  },
  DeleteSiteLogs: (data) => {
    return axios.post(AppServices.DELETE_SITE_LOGS(data), data, HttpHeaders())
  },
  DeleteSessionLogs: (data) => {
    return axios.post(AppServices.DELETE_SESSION_LOGS(data), data, HttpHeaders())
  },
}
export default LogsServices;
