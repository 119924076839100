import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Constants from "../partials/CommonConstant"
import { DocImage, IconFolderImage, JpgImage, PdfImage, PngImage, PPTImage, TxtImage, UnKnownImage, XlsImage } from "./ImageConstants";

export const GetToken = () => {
  let token = useSelector((state) => state.common.db.token)
  return token
}

export const GetUser = () => {
  let user = useSelector((state) => state.common.db.user)
  return user
}

export const getToken = () => {
  return localStorage.getItem('authtoken');
}

export const LogOut = () => {
  localStorage.clear();
  window.location.href = "/";
}

export const GetRole = (role_id) => {
  // role id if 1 then 'Admin' else 'Standard User'
  if (parseInt(role_id) === 1) {
    return "Admin";
  }
  else if (parseInt(role_id) === 2) {
    return "Standard User";
  }
  else {
    return "Client";
  }
}

export const getEnviromentApiUrl = () => {
  if (window.location.host.includes("localhost")) {
    return process.env.REACT_APP_DEV_APIURL;
  }
  else if (window.location.host.includes("stagingqflow")) {
    return process.env.REACT_APP_STAGE_LIVE_APIURL;
  }
  else if (window.location.host.includes("qflow.quadringroup")) {
    return process.env.REACT_APP_LIVE_APIURL;
  }
}

// Get difference between passed date and current time in days
export const GetTimeDifference = (time, format) => {
  let newStartDate = new Date(time);
  let newEndDate = new Date();
  let diffInMs = Math.abs(newStartDate - newEndDate);
  let diff;
  if (format == 'd') {
    diff = (diffInMs / (1000 * 60 * 60 * 24)).toFixed(2);
  }
  return diff;

}

export const ShowToast = (messageType, type, message) => {
  toast.configure({
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
  if (message === "You are logged out. Please login.") {
    LogOut();
  }
  if (messageType === "object") {
    let i = 1;
    Object.keys(message).forEach(key => {
      if (i == 1) {
        return toastMessageShow(type, message[key][0])
      }
      i++;
    })
  }
  else {
    return toastMessageShow(type, message)
  }
}

export const toastMessageShow = (type, message) => {
  if (type === "info") {
    return toast.info(message);
  }
  else if (type === "success") {
    return toast.success(message);
  }
  else if (type === "error") {
    return toast.error(message);
  }
  else if (type === "warning") {
    return toast.warn(message);
  }
  else {
    return toast(message);
  }
}

export const GetQuestionCategory = (category_id, type = "single") => {
  // get question cateogry base on type
  if (type === "single") {
    if (parseInt(category_id) === 1) {
      return Constants.QuestionCategory[0];
    }
    else if (parseInt(category_id) === 2) {
      return Constants.QuestionCategory[1];
    }
    else if (parseInt(category_id) === 3) {
      return Constants.QuestionCategory[2];
    }
  }
  else {
    return Constants.QuestionCategory;
  }
}

export const ValidateCharacter = (data) => {
  let isInvalid = false;
  if (data.key && data.type === "special_character") {  // Restrict to enter special character and exclude space.
    if (/^[A-Za-z0-9 ]+$/.test(data.key)) {
      isInvalid = true;
    }
  }
  else if (data.key && data.type === "numbers_only") {   // Only allow numbers. 
    if (/[0-9]/.test(data.key)) {
      isInvalid = true;
    }
  }
  return isInvalid;
}

export const setDateFormat = (date) => {
  let dateFormat = new Date(date);
  dateFormat = dateFormat.toLocaleDateString();
  return dateFormat;
}


export const getFileSize = (fileSizeBytes) => {
  let fileSize = "";
  if (parseFloat((fileSizeBytes / (1000 * 1000)).toFixed(2)) > 0.9) {
    fileSize = (fileSizeBytes / (1000 * 1000)).toFixed(2) + " MB";
  }
  else {
    fileSize = (fileSizeBytes / (1000)).toFixed(2) + " KB";
  }
  return fileSize;
}

export const getFileType = (fileType) => {
  let FileType = "";
  if (fileType === "image/png" || fileType.includes(".png")) {
    FileType = <img src={PngImage} alt="PNG file" />
  }
  else if (fileType === "image/jpeg" || fileType.includes(".jpg") || fileType.includes(".jpeg")) {
    FileType = <img src={JpgImage} alt="JPEG file" />
  }
  else if (fileType === "application/pdf" || fileType.includes(".pdf")) {
    FileType = <img src={PdfImage} alt="PDF file" />
  }
  else if (fileType === "text/plain" || fileType.includes(".txt")) {
    FileType = <img src={TxtImage} alt="TXT file" />
  }
  else if (fileType.includes(".xlsx") || fileType.includes(".xls")) {
    FileType = <img src={XlsImage} alt="XLSX file" />
  }
  else if (fileType.includes(".doc") || fileType.includes(".docx")) {
    FileType = <img src={DocImage} alt="Doc file" />
  }
  else if (fileType.includes(".ppt") || fileType.includes(".pptx")) {
    FileType = <img src={PPTImage} alt="PPT file" />
  }
  else if (fileType === "folder") {
    FileType = <img src={IconFolderImage} alt="Folder" />
  }
  else {
    FileType = <img src={UnKnownImage} alt="Unknown file" />
  }
  return FileType;
}

export const getFormattedDate = (created_at) => {
  let columnData = "";
  let tempDateFormat = new Date(created_at);
  const yyyy = tempDateFormat.getFullYear();
  let mm = tempDateFormat.getMonth() + 1; // Months start at 0!
  let dd = tempDateFormat.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedDate = dd + '/' + mm + '/' + yyyy;
  return columnData = formattedDate;
}