import React, { useEffect, useState } from "react";
import { ShowToast } from "../common/CommonController";
import { Spinner } from "react-bootstrap";
import { GetDownloadLogs } from "../Shared/Controller/downloadLogApp";
import DownloadLogList from "../downloalog/DownloadLogList";
import NotFound from "../common/NotFound";
import { useNavigate } from "react-router-dom";

const DownloadLogs = () => {

  const [downloadLogList, setDownloadLogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDownloadLogs();
  }, []);

  const getDownloadLogs = () => {
    setLoading(true);
    getDownlaodLogApiCall();
  }

  const getDownlaodLogApiCall = (data = {}) => {
    GetDownloadLogs(data).then((response) => {
      if (response.data.code === 1) {
        setDownloadLogList(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const getTableColumns = () => {
    let table_columns = [];
    table_columns = [
      {
        name: "Downloaded By",
        selector: row => row.downloaded_byuser,
        sortable: true,
      },
      {
        name: "Date",
        selector: row => { return row.download_date },
        sortable: true,
      },
      {
        name: "File Name",
        selector: row => { return getColumnData({ type: "file_name", item: row }) },
        sortable: true,
      },
      {
        name: "Owner",
        selector: row => { return getColumnData({ type: "owner", item: row }) },
        sortable: true,
      },
    ]
    return table_columns;
  }

  const getColumnData = (data) => {
    let columnData = "";
    if (data.type === "file_name") {
      columnData = data.item.file_details !== null ? data.item.file_details.original_filename : "";
    }
    if (data.type === "owner") {
      columnData = data.item.file_details !== null ? data.item.file_details.owner_name : "";
    }
    return columnData;
  }

  const data = downloadLogList;
  const columns = getTableColumns();

  const tableData = {
    columns,
    data
  };

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end">
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
          <div className="card-main p-0">
            <div className="dark-table-wrapper table-scroll">
              <DownloadLogList downloadLogList={downloadLogList} loading={loading} tableData={tableData} />
              {loading === false && downloadLogList.length === 0 &&
                <NotFound message="No Data Found" type="content" />
              }
              {loading === true &&
                <table className="mb-0 table">
                  <tbody>
                    <tr>
                      <td colSpan={10} className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default DownloadLogs;