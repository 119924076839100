import React, { useLayoutEffect, useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserPermissionModal from "./UserPermissionModal";
import $ from "jquery";

const AddEditFileForm = (props) => {
  const [Validated, setValidated] = useState(false);
  const [fileField, setFileField] = useState({ file: {}, parent_fileid: 0, shared_with: [], note: "" });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setDefaultPermission();
  }, []);

  useLayoutEffect(() => {
    if (Object.keys(props.fileDetail).length > 0) {
      setDefaultFileField();
    }
  }, [props.fileDetail]);

  const setDefaultFileField = () => {
    let data = {
      file: {}, parent_fileid: "0", shared_with: [], note: ""
    }
    let users = [];
    /* props.fileDetail.shared_with.map((item) => {
      users.push({ user_id: item.id.toString(), permission: "1" });
      return null;
    }); */
    if (props.fileDetail.shared_with.length !== 0) {
      props.users.map((item) => {
        let shared_user = props.fileDetail.shared_with.find((sitem) => sitem.id === item.id);
        if (shared_user) {
          users.push({ user_id: shared_user.id.toString(), permission: shared_user.permission.toString() });
        }
        else {
          users.push({ user_id: item.id.toString(), permission: "1" });
        }
      });
    }
    else {
      props.users.map((item) => {
        users.push({ user_id: item.id.toString(), permission: "1" });
        return null;
      });
    }
    setFileField({
      ...data,
      parent_fileid: props.fileDetail.parent_fileid,
      shared_with: users,
      folder_id: props.folderid ? props.folderid : "",
      note: props.fileDetail.note !== null ? props.fileDetail.note : "",
    });
  }

  const setDefaultPermission = () => {
    let data = {
      file: {}, parent_fileid: "0", shared_with: [], note: ""
    }
    let users = [];
    if (Object.keys(props.fileDetail).length === 0) {
      props.users.map((item) => {
        users.push({ user_id: item.id.toString(), permission: "1" });
        return null;
      });
    }
    setFileField({
      ...data,
      shared_with: users,
    });
  }

  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.name === "file") {
      setFileField({ ...fileField, [e.target.name]: e.target.files[0] })
    }
    else {
      setFileField({ ...fileField, [e.target.name]: e.target.value })
    }
  }

  const handlePermission = (data) => {
    let shared_with = fileField.shared_with;
    shared_with.map((item) => {
      if (item.user_id === data.id.toString()) {
        item.permission = data.permission;
      }
    })
    setFileField({ ...fileField, shared_with: shared_with })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      let addFileFormData = new FormData();
      if (props.isEdit === false) {
        addFileFormData.append("file", fileField.file);
        addFileFormData.append("parent_fileid", fileField.parent_fileid);
        fileField.shared_with.map((item, index) => {
          addFileFormData.append("shared_with[" + [index] + "][" + ["user_id"] + "]", parseInt(item["user_id"]));
          addFileFormData.append("shared_with[" + [index] + "][" + ["permission"] + "]", parseInt(item["permission"]));
          return null;
        })
        addFileFormData.append("folder_id", fileField.folder_id ? fileField.folder_id : "");
        addFileFormData.append("note", fileField.note);
        props.saveFile(addFileFormData);
      }
      else {
        let fileFormData = new FormData();
        fileFormData.append("parent_fileid", fileField.parent_fileid);
        fileField.shared_with.map((item, index) => {
          fileFormData.append("shared_with[" + [index] + "][" + ["user_id"] + "]", parseInt(item["user_id"]));
          fileFormData.append("shared_with[" + [index] + "][" + ["permission"] + "]", parseInt(item["permission"]));
          return null;
        })
        fileFormData.append("folder_id", props.fileDetail.folder_id);
        fileFormData.append("id", props.fileDetail.id);
        fileFormData.append("note", fileField.note);
        props.updateFile(fileFormData);
      }
    }
    setValidated(true);
  }

  const resetAllPermission = () => {
    let newSharedWith = [];
    let tempFormField = fileField;
    tempFormField.shared_with.map((item) => {
      newSharedWith.push({ ...item, permission: "1" });
      return null;
    });
    setFileField({
      ...tempFormField,
      shared_with: newSharedWith,
    });
  }

  const onCloseModal = () => {
    // resetAllPermission();
    setShowModal(false);
  }

  const confirmClick = () => {
    setShowModal(false);
  }

  const handleMouseOver = () => {
    $(".uplod-file").addClass("hover-file");
  }

  const handleDragExit = () => {
    $(".uplod-file").removeClass("hover-file");
  }

  return (
    <React.Fragment>
      <div className="col-md-12 col-sm-12 col-lg-128 mb-3">
        <div className="card-main">
          <div className="m-3">
            <li>Note: If you select parent file, Users who have access to parent file will also have access to this file.</li>
          </div>
          <Form noValidate validated={Validated} onSubmit={handleSubmit}>
            <Row className="row g-4">
              {props.isEdit === false &&
                <div className="uplod-file">
                  <Form.Group className="col-md-12" controlId="fileUpload">
                    <Form.Control
                      as="input"
                      required
                      type="file"
                      name="file"
                      bsPrefix="file"
                      className="mb-2"
                      onChange={handleChange}
                      onDragEnter={handleMouseOver}
                      onDragLeave={handleDragExit}
                    />
                    <Form.Control.Feedback type="invalid">Please upload file</Form.Control.Feedback>
                  </Form.Group>
                  <button>+</button>
                  <span className="mx-2">You can also drag & drop into the above</span>
                </div>
              }
              <Form.Group className="col-md-4">
                <Form.Label>Parent File</Form.Label>
                <Form.Select
                  name="parent_fileid"
                  // required
                  value={fileField.parent_fileid}
                  onChange={handleChange}
                >
                  <option value="0">Select Parent File</option>
                  {props.parentFiles.length > 0 && props.parentFiles.map((item) => {
                    return (
                      <React.Fragment key={"pfile" + item.id}>
                        <option value={item.id}>{item.original_filename}</option>
                      </React.Fragment>
                    )
                  })
                  }
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please select parent file</Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group className="col-md-4">
                <Form.Label>Shared Users</Form.Label>
                <ul className="project-list file-user-list">
                  <Scrollbars className="custom-scroll" autoHeight={true}>
                    {props.users.length > 0 && props.users.map((item) => {
                      return (
                        <React.Fragment key={"shared_with" + item.id}>
                          <Form.Control as="button"
                            onClick={handleChange}
                            value={item.id}
                            name="shared_with"
                            className={fileField.shared_with.indexOf(item.id.toString()) !== -1 ? "active" : ""}
                          >
                            {item.firstname + " " + item.lastname}
                          </Form.Control>
                        </React.Fragment>
                      )
                    })
                    }
                  </Scrollbars>
                </ul>
                <Form.Control.Feedback type="invalid">Please select user</Form.Control.Feedback>
              </Form.Group> */}
              <UserPermissionModal
                show={showModal}
                formField={fileField}
                users={props.users}
                handlePermission={handlePermission}
                onCloseModal={onCloseModal}
                confirmClick={confirmClick}
                modalFor="file"
              />
              <Form.Group className="col-md-4">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  name="note"
                  value={fileField.note}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">Please enter note</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-md-4">
                <Button variant="secondary" className="btn btn-blue btn-nex permission-btn" onClick={() => setShowModal(true)}>
                  Show Permissions
                </Button>
              </Form.Group>
              <div className="col-12 text-end">
                {props.saveLoading === false &&
                  <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                    Save
                  </Button>
                }
                {props.saveLoading &&
                  <Button className="btn btn-sm btn-blue" variant="secondary">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Button>
                }
                <button onClick={() => navigate(-1)} className="btn btn-sm btn-blue ms-2" type="button" variant="secondary" >Cancel</button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )

}
export default AddEditFileForm;