import React from "react";

const FileError = (props) => {
  console.log("props FIleerorr", props)
  return (
    <React.Fragment>
      <span style={{ color: "red" }}>File have some problem, please try another file.</span>
    </React.Fragment>
  )
}
export default FileError;