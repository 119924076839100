import * as ReduxConstant from '../ReduxConstant';
import { workflowstore } from '../WorkFlowStore';

export default function Common(state = workflowstore, action) {
  let oldDB = state.db;
  switch (action.type) {
    case ReduxConstant.ADD_TOKEN:
      return { db: { ...oldDB, token: action.token } };
    case ReduxConstant.ADD_USER:
      return { db: { ...oldDB, user: action.user } };
    case ReduxConstant.ADD_PROJECTLIST:
      return { db: { ...oldDB, project_list: action.project_list } };
    default:
      return state;
  }
}