import axios from "axios"
import { AppServices, HttpHeaders } from "../Services/AppServices";

const PlatformServices = {
    getPlatforms: (data = {}) => {
        const header = HttpHeaders();
        return axios.get(AppServices.GET_PLATFORMS(), {
            params: data,
            headers: header.headers,
        })
    },
    savePlatform: (data) => {
        if (data.platformId) {
            return axios.post(AppServices.UPDATE_PLATFORM(data.platformId), data, HttpHeaders())
        }
        else {
            return axios.post(AppServices.SAVE_PLATFORM(), data, HttpHeaders())
        }
    },
    getPlatform: (platformId) => {
        return axios.get(AppServices.GET_PLATFORM(platformId), HttpHeaders())
    },
    deletePlatform: (data) => {
        return axios.post(AppServices.DELETE_PLATFORM(data.platformId), data, HttpHeaders())
    },
    restorePlatform: (data) => {
        return axios.post(AppServices.RESTORE_PLATFORM(data.platformId), data, HttpHeaders())
    },
}
export default PlatformServices;
