import React from "react"
import { Spinner } from "react-bootstrap"
import deleteddataApp from "../Shared/Controller/deleteddataApp"
import { RestoreImg, DeleteImg } from "../common/ImageConstants";
import { Link } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import ConfirmModal from "../common/ConfirmModal";
import NotFound from "../common/NotFound";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

class DeletedProjects extends deleteddataApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      project_list: [],
      showDeleteModal: false,
      showRestoreModal: false,
      projectId: "",
    }
  }
  componentDidMount = () => {
    this.getProjects();
  }

  getColumnData = (data) => {
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon text-center">
          {data.item.import_status !== 0 &&
            <Link to="/" className="ms-2" onClick={(e) => this.onClickProjectRestore(e, data.item.id)}>
              <img src={RestoreImg} alt="Restore" title="Restore" />
            </Link>
          }
          <Link to="/" className="ms-2" onClick={(e) => this.onClickPermenantDeleteProject(e, data.item.id)}>
            <img src={DeleteImg} alt="Permenant Delete" title="Permenant Delete" />
          </Link>
        </div>
      );
    }
    if (data.type === "importstatus") {
      return (
        <>
          {data.item.import_status === 0 &&
            "Failed"
          }{data.item.import_status === 1 &&
            "Completed"
          }{data.item.import_status === 2 &&
            "In Progress"
          }
        </>
      );
    }
    return columnData;
  }

  getImportStatus = (value) => {
    if (value === 0) {
      return "Failed";
    }
    if (value === 1) {
      return "Completed";
    }
    if (value === 2) {
      return "In Progress";
    }
  }

  shortImportStatus = (rowA, rowB) => {
    const a = this.getImportStatus(rowA.import_status);
    const b = this.getImportStatus(rowB.import_status);

    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  render() {
    // Create table headers
    const data = this.state.project_list ? this.state.project_list : [];
    const columns = [
      {
        name: "Project Name",
        selector: row => row.project_name,
        sortable: true
      },
      {
        name: "# Tasks",
        selector: row => row.imported_rows,
        sortable: true,
      },
      {
        name: "Progress",
        selector: row => row.percentage_completed,
        cell: d => { return d.percentage_completed + '%' },
        sortable: true,
      },
      // {
      //   name: "Import Status",
      //   selector: row => row.import_status,
      //   sortField: "",
      //   sortable: true,
      //   sortFunction: this.shortImportStatus,
      //   cell: d => { return this.getColumnData({ type: "importstatus", item: d }) }
      // },
      {
        name: "Owner",
        selector: row => row.created_user,
        sortable: true,
      },
      {
        name: "Action",
        center: true,
        cell: d => { return this.getColumnData({ type: "action", item: d }) }
      }
    ];

    const tableData = {
      columns,
      data
    };

    return (
      <>
        <div className="dashboard-card-wrapper deleted-page-data">
          <ConfirmModal show={this.state.showDeleteModal} type="permanentDelete"
            onCloseModal={() => this.onDeleteCloseModal()}
            module="project"
            confirmClick={(e) => this.handlePermanentDelete(e, 'Project')} />
          <ConfirmModal show={this.state.showRestoreModal} type="restore"
            onCloseModal={() => this.onRestoreCloseModal()}
            module="project"
            confirmClick={(e) => this.handleRestore(e, 'Project')} />
          <div className="row align-items-center">
            <div className="col-12 text-end">
              <Link to={Constants.projectList} className="btn btn-blue btn-nex">Active Projects</Link>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
            <div className="card-main p-0">
              <div className="dark-table-wrapper table-scroll data-table">
                {this.state.project_list && this.state.project_list.length !== 0 && this.state.loading === false &&
                  <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                }
                {this.state.loading === true &&
                  <table className="mb-0 table">
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }

                {/* Not found */}
                {this.state.loading === false && this.state.project_list && this.state.project_list.length === 0 &&
                  <NotFound message="No Data Found" type="content" />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default DeletedProjects