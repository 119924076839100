import axios from "axios"
import { AppServices, HttpHeaders } from "./AppServices";

const CommonServices = {
  GetDashboadDetail: () => {
    return axios.get(AppServices.GET_DASHBOARD(), HttpHeaders())
  },
  VerifyConfirmDelete: (data) => {
    return axios.post(AppServices.VERIFY_CONFIRM_DELETE(), data, HttpHeaders())
  },
  GetShortcutURL: (data) => {
    return axios.post(AppServices.GET_SHORTCUT_URL(), data, HttpHeaders())
  },
  VerifyFileToken: (token) => {
    return axios.get(AppServices.VERIFY_FILE_TOKEN(token), HttpHeaders())
  },
}
export default CommonServices;
