import Logout from "../common/Logout";
import { DownloadIconImg, DocumentShareIconImg, UploadIconImg, EditIconProject, EyeIconProjct, DeleteIconProject, ExportIconProject } from "../common/ImageConstants";
import { Link } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { getFormattedDate, GetUser, ShowToast } from "../common/CommonController";
import { DeleteProject, ExportData, getChartData, GetDashboadDetail, shortImportStatus } from "../Shared/Controller/commonApp";
import { Chart as ChartJs, ArcElement, Tooltip } from "chart.js";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Card, Placeholder, Spinner } from "react-bootstrap";
import NotFound from "../common/NotFound";
import ConfirmModal from "../common/ConfirmModal";

const Home = () => {
  const DashboardChart = lazy(() => import("../dashboard/DashboardChart"));
  const userDetail = GetUser();
  const [loading, setLoading] = useState(false);
  const [dashboardDetail, setDashBoardDetail] = useState({});
  const [chartData, setChartData] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [exportProjectIds, setExportProjectIds] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [projectId, setProjectId] = useState("");

  ChartJs.register(ArcElement, Tooltip);

  useEffect(() => {
    getDashboardDetail();
  }, []);

  useEffect(() => {
    let ChartData = {};
    if (Object.keys(dashboardDetail).length !== 0) {
      ChartData = getChartData(dashboardDetail);
      setChartData(ChartData);
    }
  }, [dashboardDetail]);

  const getDashboardDetail = () => {
    setLoading(true);
    GetDashboadDetail().then((response) => {
      if (response.data.code === 1) {
        setDashBoardDetail(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const getTableColumns = () => {
    let table_columns = [];
    if (userDetail.role === 1) {
      table_columns = [
        {
          name: "Project Name",
          selector: row => row.project_name,
          wrap: true,
          // cell: d => { return getProjectName({ ...d }) },
          width: "12%",
          sortable: true
        },
        {
          name: "Identifier",
          selector: row => row.identifier,
          wrap: true,
          // cell: d => { return getProjectName({ ...d }) },
          width: "15%",
          sortable: true
        },
        {
          name: "# Tasks",
          compact: true,
          center: true,
          selector: row => row.imported_rows,
          sortable: true,
        },
        {
          name: "Integrity Progress",
          compact: true,
          center: true,
          selector: row => row.integrity_statustotal,
          cell: d => { return d.only_review !== 1 ? d.integrity_statustotal : "" },
          sortable: true,
        },
        {
          name: "Review Progress",
          compact: true,
          center: true,
          selector: row => row.task_statustotal,
          cell: d => { return d.only_integrity !== 1 ? d.task_statustotal : "" },
          sortable: true,
        },
        // {
        //   name: "Import Status",
        //   selector: row => row.import_status,
        //   sortable: true,
        //   width: "15%",
        //   sortFunction: shortImportStatus,
        //   cell: d => { return getColumnData({ type: "importstatus", item: d }) }
        // },
        // {
        //   name: "Project Status",
        //   selector: row => row.status,
        //   // compact: true,
        //   width: "15%",
        //   sortable: true,
        //   cell: d => { return getColumnData({ type: "status", item: d }) }
        // },
        {
          name: "Owner",
          selector: row => row.created_user,
          width: "10%",
          wrap: true,
          sortable: true,
        },
        {
          name: "Created On",
          selector: row => row.created_at,
          width: "13%",
          wrap: true,
          cell: d => { return getFormattedDate(d.created_at) },
          sortable: true,
        },
        {
          name: "PII Flag",
          selector: row => row.pll_flag,
          width: "7%",
          wrap: true,
          cell: d => { return d.pll_flag },
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "28%",
          cell: d => { return getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    else if (userDetail.role === 2) {
      table_columns = [
        {
          name: "Project Name",
          selector: row => row.project_name,
          // cell: d => { return getProjectName({ ...d }) },
          wrap: true,
          width: "19%",
          sortable: true
        },
        {
          name: "Identifier",
          selector: row => row.identifier,
          wrap: true,
          // cell: d => { return getProjectName({ ...d }) },
          width: "15%",
          sortable: true
        },
        // {
        //   name: "Project Status",
        //   selector: row => row.status,
        //   // width: "20%",
        //   sortable: true,
        //   cell: d => { return getColumnData({ type: "status", item: d }) }
        // },
        {
          name: "Owner",
          selector: row => row.created_user,
          width: "19%",
          wrap: true,
          sortable: true,
        },
        {
          name: "Created On",
          selector: row => row.created_at,
          width: "15%",
          wrap: true,
          cell: d => { return getFormattedDate(d.created_at) },
          sortable: true,
        },
        {
          name: "PII Flag",
          selector: row => row.pll_flag,
          width: "7%",
          wrap: true,
          cell: d => { return d.pll_flag },
          sortable: true,
        },
        {
          name: "Action",
          center: true,
          width: "30%",
          cell: d => { return getColumnData({ type: "action", item: d }) }
        }
      ]
    }
    return table_columns;
  }

  const getColumnData = (data) => {
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon text-center">
          {exportLoading === true && exportProjectIds === data.item.id &&
            <React.Fragment key={"export" + data.item.id}>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </React.Fragment>
          }
          {exportProjectIds !== data.item.id &&
            <React.Fragment>
              {data.item.status === 1 && data.item.only_review !== 1 &&
                <Link to={"/integrity-project/" + data.item.id}
                  className={exportLoading === false ? "ms-2 edt-btn orange" : "ms-2 disabled-action edt-btn orange"}>
                  Integrity
                </Link>
              }
              {data.item.status === 1 && data.item.only_integrity !== 1 &&
                <Link to={"/review-project/" + data.item.id}
                  className={exportLoading === false ? "ms-2 edt-btn purple" : "ms-2 disabled-action edt-btn purple"}>
                  Review
                </Link>
              }
              <Link data-tooltip="View" to={"/view-project/" + data.item.id}
                className={exportLoading === false ? "ms-2" : "ms-2 disabled-action"}>
                <img src={EyeIconProjct} alt="View" title="View" />
              </Link>
              {data.item.import_status !== 0 && userDetail.role === 1 &&
                <Link to={"/edit-project/" + data.item.id} state={{ projectId: data.item.id, isEdit: true }}
                  data-tooltip="Edit"
                  className={exportLoading === false ? "ms-2" : "ms-2 disabled-action"}>
                  <img src={EditIconProject} alt="Edit" title="Edit" />
                </Link>
              }
              {userDetail.role === 1 &&
                <Link to="/" className={exportLoading === false ? "ms-2" : "ms-2 disabled-action"}
                  data-tooltip="Delete"
                  onClick={(e) => onClickDelete(e, data.item.id)}>
                  <img src={DeleteIconProject} alt="Delete" title="Delete" />
                </Link>
              }
              {data.item.status !== 3 &&  // userDetail.role === 1 &&
                <React.Fragment>
                  <Link to="/"
                    data-tooltip="Export" data-position="right"
                    className={exportLoading === false ? "ms-2" : "ms-2 disabled-action"}
                    onClick={(e) => onClickExport(e, data.item.id)}>
                    <img src={ExportIconProject} alt="Export" title="Export" />
                  </Link>
                </React.Fragment>
              }
            </React.Fragment>
          }
        </div>
      );
    }
    if (data.type === "status") {
      return (
        <>
          {data.item.status === 1 &&
            "Active"
          }{data.item.status === 2 &&
            "Completed"
          }{data.item.status === 3 &&
            "Incomplete"
          }
        </>
      );
    }

    if (data.type === "importstatus") {
      return (
        <>
          {data.item.import_status === 0 &&
            "Failed"
          }{data.item.import_status === 1 &&
            "Completed"
          }{data.item.import_status === 2 &&
            "In Progress"
          }
        </>
      );
    }
    return columnData;
  }

  const onClickExport = (e, projectId) => {
    e.preventDefault();
    setExportLoading(true);
    setExportProjectIds(projectId)

    let data = {
      projectid: projectId,
    }
    ExportData(data).then((response) => {
      if (response.data.code === 1) {
        let r = response.data.data;
        const a = document.createElement('a');
        a.setAttribute('href', r);
        a.setAttribute("download", r.split("exports/")[1]);
        a.target = "_blank";
        a.click();
        setExportLoading(false);
        setExportProjectIds("");
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        setExportLoading(false);
        setExportProjectIds("");
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      setExportLoading(false);
      setExportProjectIds("");
    });
  }


  const handleDelete = (e) => {
    let newData = { project_id: projectId }
    setShowModal(false);
    deleteProject(newData);
  };


  const deleteProject = (data) => {
    setLoading(true);
    DeleteProject(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        getDashboardDetail();
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    });
  }

  const onClickDelete = (e, projectId) => {
    e.preventDefault();
    setShowModal(true);
    setProjectId(projectId);
  }

  const onCloseModal = () => {
    setShowModal(false);
  }

  const data = dashboardDetail.projects_list ? dashboardDetail.projects_list : [];
  const columns = getTableColumns();
  const tableData = {
    columns,
    data
  };

  return (
    <>
      <div className="dashboard-card-wrapper">
        <ConfirmModal show={showModal}
          onCloseModal={() => onCloseModal()}
          module="project"
          confirmClick={() => handleDelete()} />
        {dashboardDetail.project_summary && loading === false &&
          <React.Fragment>
            {/* <div className="row">
              <div className="col-md-4 col-sm-6 mb-2 mb-lg-4">
                <div className="dashboard-card orange">
                  <h2>Uploaded Documents</h2>
                  <div className="dashboard-card-inner">
                    <div className="dashboard-digits">
                      <span>{dashboardDetail.project_summary.uploaded_documents}</span>
                    </div>
                    <div className="dashboard-img">
                      <img src={UploadIconImg} alt="upload-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-2 mb-lg-4">
                <div className="dashboard-card red">
                  <h2>Downloaded Documents</h2>
                  <div className="dashboard-card-inner">
                    <div className="dashboard-digits">
                      <span>{dashboardDetail.project_summary.download_documents}</span>
                    </div>
                    <div className="dashboard-img">
                      <img src={DownloadIconImg} alt="download-icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 mb-2 mb-lg-4">
                <div className="dashboard-card green">
                  <h2>Document Shared</h2>
                  <div className="dashboard-card-inner">
                    <div className="dashboard-digits">
                      <span>{dashboardDetail.project_summary.files_shared}</span>
                    </div>
                    <div className="dashboard-img">
                      <img src={DocumentShareIconImg} alt="document-share-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12 mt-3 mt-md-0">
                <h1 className="content-title">Project Summary</h1>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-12 mb-3">
                <div className="card-main p-0">
                  <div className="dark-table-wrapper table-scroll proj_table">
                    {/* <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      highlightOnHover
                    /> */}
                    {data && data.length !== 0 &&
                  <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                    <DataTable
                      // sortFunction={this.customSort}
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      paginationRowsPerPageOptions={[10, 20, 30, 100, 500]}
                      paginationPerPage={20}
                      // paginationComponentOptions={paginationComponentOptions}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                }









                  </div>
                </div>
              </div>
              {/* <Suspense fallback={
                <Placeholder as={Card} className="col-md-6 col-sm-12 col-lg-4 p-5 text-center" animation="wave">
                  <div className="graph-box-wrapper text-center chart-content">
                  </div>
                </Placeholder>
              }>
                <DashboardChart
                  dashboardDetail={dashboardDetail}
                  chartData={chartData}
                  userDetail={userDetail}
                />
              </Suspense> */}
            </div>
          </React.Fragment>
        }
        {!dashboardDetail.project_summary && loading === false &&
          <React.Fragment>
            <div className="card-main p-5">
              <NotFound type="content" message="No Data Found" />
            </div>
          </React.Fragment>
        }
        {loading === true &&
          <div className="card-main p-5 text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      </div>
      <Logout />
    </>
  )
}
export default Home;