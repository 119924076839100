import React, { useState } from 'react'
import axios from 'axios'
import * as ImgConstants from "../common/ImageConstants";
import * as Constants from "../partials/CommonConstant";
import { Link, useNavigate } from 'react-router-dom';
import Successmsg from '../partials/Successmsg'
import Errormsg from '../partials/Errormsg'
import InlineErrormsg from '../partials/InlineErrormsg'

const VerifiedProfileForm = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [token] = useState(queryParams.get('token'));
  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [success_message, setSuccessMsg] = useState('');
  const [error_message, setErrorMsg] = useState('');
  const [errors, setErrors] = useState([]);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  let navigate = useNavigate()

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const onChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value)
  }

  const hasErrorFor = (field) => {
    if (errors && Object.keys(errors).length > 0) {
      return errors[field]
    }
  }
  const renderErrorFor = (field) => {
    if (hasErrorFor(field)) {
      return (<InlineErrormsg message={errors[field][0]} />)
    }
  }
  const renderSuccessMessage = () => {
    if (success_message !== '') {
      return (<Successmsg message={success_message} />)
    }
  }
  const renderErrorMessage = () => {
    if (error_message !== '') {
      return (<Errormsg message={error_message} />)
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    let send_data = {
      userid: props.varifiedUser.id,
      password: password,
      password_confirmation: password_confirmation,
    }
    axios.post(Constants.VerifiedUsersetPassword, send_data)
      .then(response => {
        if (response.data.code === 1) {
          // For success
          setSuccessMsg(response.data.message)
          setErrors([]);
          setErrorMsg("");
          navigate(Constants.Login, response.data.message)
        } else {
          // console.log(response.data.message.constructor);
          if (response.data.message.constructor === Object) {
            setSuccessMsg("");
            setErrors(response.data.message)
          } else {
            setSuccessMsg("");
            setErrorMsg(response.data.message);
          }
        }
      })
      .catch(error => {
        setErrors(error.response.data.errors);
      })
  }

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <h4>Reset Password</h4>
        {renderSuccessMessage()}
        {renderErrorMessage()}
        <div className='mb-3 form-block'>
          <label htmlFor='password' className='form-label'>Password</label>
          <input type={isShowPassword === true ? 'text' : 'password'}
            name='password'
            className={`password-icon form-control ${hasErrorFor('password') ? 'is-invalid' : ''}`}
            id='password' placeholder='Password'
            value={password}
            onChange={onChangePassword} />
          <span
            onClick={() => setIsShowPassword(!isShowPassword)}
            style={isShowPassword === true ? { backgroundImage: `url(${ImgConstants.PassswordShowImg})` } : { backgroundImage: `url(${ImgConstants.PassswordHideImg})` }}
            className='password-show'>
          </span>
          {renderErrorFor('password')}
        </div>
        <div className='mb-3 form-block'>
          <label htmlFor='password_confirmation' className='form-label'>Confirm Password</label>
          <input type={isShowConfirmPassword === true ? 'text' : 'password'}
            name='password_confirmation'
            className={`password-icon form-control ${hasErrorFor('password_confirmation') ? 'is-invalid' : ''}`}
            id='password_confirmation'
            placeholder='Confirm Password'
            value={password_confirmation}
            onChange={onChangeConfirmPassword} />
          <span
            onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
            style={isShowConfirmPassword === true ? { backgroundImage: `url(${ImgConstants.PassswordShowImg})` } : { backgroundImage: `url(${ImgConstants.PassswordHideImg})` }}
            className='password-show'>
          </span>
          {renderErrorFor('password_confirmation')}
        </div>
        <div className='form-btn'>
          <button type='submit' className='btn btn-primary'>Submit</button>
        </div>
      </form>
    </>
  )
}
export default VerifiedProfileForm;