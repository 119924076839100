import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react
import makeRootReducer from './Reducers'

const persistConfig = {
  key: 'root',
  storage: localStorage
}

export default (initialState = {}) => {
  const persistedReducer = persistReducer(persistConfig, makeRootReducer())

  const store = createStore(
    persistedReducer,
    initialState
  )

  const persistor = persistStore(store)

  return { store, persistor }
}