import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ShowToast } from "../common/CommonController";
import ConfirmModal from "../common/ConfirmModal";
import { MultipleDeleteTemplate } from "../Shared/Controller/commonApp";

const TempalateDataTable = (props) => {
  const columns = props.columns;
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteTemplates, setDeleteTemplates] = useState({ templateid: [] });

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
    let templateIds = [];
    state.selectedRows.map((item) => {
      templateIds.push(item.id);
      return null;
    });
    setDeleteTemplates({ ...deleteTemplates, templateid: templateIds });
  };

  const DeleteTemplates = () => {
    // setDeleteLoading(true);
    MultipleDeleteTemplate({...deleteTemplates, permanentDelete: props.permanentDelete}).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
        props.callbackDeleteTemplate();
        setSelectedRows([]);
        setShowModal(false);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setDeleteLoading(false);
    }).catch(error => {
      setDeleteLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      setShowModal(true);
    };
    if (selectedRows.length > 0) {
      return (
        <Button key="delete" className="btn btn-blue btn-nex" onClick={handleDelete} style={{ backgroundColor: '#ee7608' }} icon>
          Delete
        </Button>
      );
    }
    else {
      return null;
    }
  }, [selectedRows]);

  const customStyles = {
    columns: {
      style: {
        padding: "10px",
      }
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal show={showModal}
        type={props.permanentDelete === 1 ? "permanentDelete" : "delete"}
        module={"template"}
        deleteLoading={deleteLoading}
        onCloseModal={() => setShowModal(false)}
        confirmClick={() => DeleteTemplates()} />
      <DataTable
        columns={columns}
        actions={contextActions}
        data={props.data}
        defaultSortField="name"
        defaultSortAsc={true}
        pagination
        highlightOnHover
        selectableRows
        selectableRowsHighlight={true}
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        customStyles={customStyles}
      />
    </React.Fragment>
  )
}
export default TempalateDataTable;