import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ShowToast } from "../common/CommonController";
import { GetFileDetail } from "../Shared/Controller/filesApp";


const ViewFile = () => {
  const [loading, setLoading] = useState(false);
  const [fileDetail, setFileDetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getFileDetail();
  }, [])

  const getFileDetail = () => {
    let splitPath = window.location.pathname.split("/")
    let fileId = "";
    if (splitPath.length > 2) {
      fileId = splitPath[2];
    }
    if (fileId) {
      setLoading(true)
      GetFileDetail(fileId).then((response) => {
        if (response.data.code === 1) {
          setFileDetails(response.data.data);
        }
        else {
          if (response.data.message.constructor === Object) {
            ShowToast("object", "error", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setLoading(false);
      }).catch(error => {
        ShowToast("message", "error", "" + error);
      })
    }
  }

  const fieldLayout = {
    sm: "12",
    md: "12",
    lg: "6",
    xl: "6",
  }

  const parentFileId = fileDetail.parent_fileid !== 0 ? fileDetail.parent_fileid : fileDetail.id

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper">
        <div className="row align-items-center">
          <div className="col-12 text-end btn-block">
            <Link to={"/add-file/" + parentFileId} className="btn btn-blue btn-nex me-1">Add Revision File</Link>
            <button onClick={() => navigate(-1)} className="btn btn-blue btn-nex">Back</button>
          </div>
        </div>
        <div className="profile-block mt-3">
          <div className="card-main">
            <div className="profile-text-inner">
              {loading === false &&
                <>
                  <Row>
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>File Name</label>
                        </div>
                        <div className="profile-value">
                          <p>{fileDetail.original_filename}</p>
                        </div>
                      </div>
                    </Col>
                    {fileDetail.project_name &&
                      <Col {...fieldLayout}>
                        <div className="profile-card">
                          <div className="profile-label">
                            <label>Project Name</label>
                          </div>
                          <div className="profile-value">
                            <p>{fileDetail.project_name}</p>
                          </div>
                        </div>
                      </Col>
                    }
                    {fileDetail.imported_rows &&
                      <Col {...fieldLayout}>
                        <div className="profile-card">
                          <div className="profile-label">
                            <label>Imported Rows</label>
                          </div>
                          <div className="profile-value">
                            <p>{fileDetail.imported_rows}</p>
                          </div>
                        </div>
                      </Col>
                    }
                    {fileDetail.type &&
                      <Col {...fieldLayout}>
                        <div className="profile-card">
                          <div className="profile-label">
                            <label>Type</label>
                          </div>
                          <div className="profile-value">
                            <p>{fileDetail.type}</p>
                          </div>
                        </div>
                      </Col>
                    }
                    {fileDetail.parent_filename &&
                      <Col {...fieldLayout}>
                        <div className="profile-card">
                          <div className="profile-label">
                            <label>Parent File Name</label>
                          </div>
                          <div className="profile-value">
                            <p>{fileDetail.parent_filename}</p>
                          </div>
                        </div>
                      </Col>
                    }
                    {fileDetail.note &&
                      <Col {...fieldLayout}>
                        <div className="profile-card">
                          <div className="profile-label">
                            <label>Note</label>
                          </div>
                          <div className="profile-value">
                            <p>{fileDetail.note}</p>
                          </div>
                        </div>
                      </Col>
                    }
                    <Col {...fieldLayout}>
                      <div className="profile-card">
                        <div className="profile-label">
                          <label>Owner</label>
                        </div>
                        <div className="profile-value">
                          <p>{fileDetail.owner_name}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {Object.keys(fileDetail).length !== 0 && fileDetail.shared_with.length !== 0 &&
                    <Row>
                      <h5 className="mt-2">Shared Users</h5>
                      <div>
                        <Table className="tbl_view_project" size="sm">
                          <thead className="rdt_TableHead">
                            <tr className="rdt_TableHeadRow">
                              <th className="rdt_TableCol_Sortable">Name</th>
                              <th className="rdt_TableCol_Sortable">Email</th>
                              <th className="rdt_TableCol_Sortable">Username</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fileDetail.shared_with.map((item) => {
                              return (
                                <React.Fragment key={"user" + item.id}>
                                  <tr>
                                    <td>
                                      {item.firstname + " " + item.lastname}
                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.username}</td>
                                  </tr>
                                </React.Fragment>
                              )
                            })
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  }
                  {Object.keys(fileDetail).length !== 0 && Object.keys(fileDetail.revision_files).length !== 0 &&
                    <Row>
                      <h5 className="mt-2">Revision Files</h5>
                      <div>
                        <Table className="tbl_view_project" size="sm">
                          <thead className="rdt_TableHead">
                            <tr className="rdt_TableHeadRow">
                              <th className="rdt_TableCol_Sortable">File Name</th>
                              <th className="rdt_TableCol_Sortable">Owner</th>
                              <th className="rdt_TableCol_Sortable">Note</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fileDetail.revision_files.map((item) => {
                              return (
                                <React.Fragment key={"revision_file_" + item.id}>
                                  <tr>
                                    <td>
                                      {item.original_filename}
                                    </td>
                                    <td>{item.owner_name}</td>
                                    <td>{item.note}</td>
                                  </tr>
                                </React.Fragment>
                              )
                            })
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  }
                </>
              }

              {loading &&
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default ViewFile;