import React from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import platformApp from "../Shared/Controller/platformApp";

class AddPlatform extends platformApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      validated: false,
      saveLoading: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    else {
      let newData = { ...this.state }
      newData.status = 1;
      delete newData.loading
      delete newData.validated
      console.log("submit New platform", this.state, newData);
      if (this.props.isEdit) {
        newData.platformId = this.props.platformId
      }
      this.savePlatform(newData);
    }
    this.setState({ validated: true });
  };

  componentDidMount = () => {
    if (this.props.isEdit) {
      this.getPlatform(this.props.platformId)
    }
  }

  render() {
    return (
      <div className="dashboard-card-wrapper">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-lg-128 mb-3">
            <div className="card-main">
              {this.state.loading === true &&
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              }
              {this.state.loading === false &&
                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                  <Row className="row g-4">
                    <Form.Group md="4" className="col-md-12" controlId="validationCustom01">
                      <Form.Label>Platform Name</Form.Label>
                      <Form.Control
                        required
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">Please enter platform name</Form.Control.Feedback>
                    </Form.Group>
                    <div className="col-12 text-end">
                      {this.state.saveLoading === false &&
                        <Button className="btn btn-sm btn-blue" variant="secondary" type="submit">
                          Save
                        </Button>
                      }
                      {this.state.saveLoading === true &&
                        <Button className="btn btn-sm btn-blue" variant="secondary" type="button">
                          <Spinner animation="border" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Button>
                      }
                      <Link to="/platforms" className="btn btn-sm btn-blue ms-2" variant="secondary" >Cancel</Link>
                    </div>
                  </Row>
                </Form>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AddPlatform;