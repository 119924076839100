import React, { useEffect, useState } from "react";
import { Row, Spinner, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ShowToast } from "../common/CommonController";
import { GetDownloadLogs } from "../Shared/Controller/downloadLogApp";

const DownlodUserLog = (props) => {
  const [downloadLogList, setDownloadLogList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDownloadLogs();
  }, [])

  const getDownloadLogs = () => {
    setLoading(true);
    let data = {};
    if (props.userid) {
      data.userid = props.userid;
    }
    getDownlaodLogApiCall(data);
  }

  const getDownlaodLogApiCall = (data = {}) => {
    GetDownloadLogs(data).then((response) => {
      if (response.data.code === 1) {
        setDownloadLogList(response.data.data);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const getTableColumns = () => {
    let table_columns = [
      {
        name: "Downloaded By",
        selector: row => row.downloaded_byuser,
        sortable: true
      },
      {
        name: "Date",
        selector: row => row.download_date,
        sortable: true,
      },
      {
        name: "File Name",
        selector: row => row.file_details.original_filename,
        sortable: true,
        width: "30%",
        cell: d => { return d.file_details !== null ? d.file_details.original_filename : "" }
      },
      {
        name: "Owner",
        selector: row => row.file_details.owner_name,
        sortable: true,
        cell: d => { return d.file_details !== null ? d.file_details.owner_name : "" }
      }
    ]
    return table_columns;
  }

  return (
    <React.Fragment>
      {loading === false && downloadLogList.length !== 0 &&
        <Row>
          <h5 className="mt-2">Download Log</h5>
          <div>
            <DataTable
              noHeader
              defaultSortField="downloaded_byuser"
              defaultSortAsc={true}
              data={downloadLogList ? downloadLogList : []}
              columns={getTableColumns()}
              highlightOnHover
            />
          </div>
        </Row>
      }
      {loading === true &&
        <div className="text-center mt-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }
    </React.Fragment>
  )
}
export default DownlodUserLog;