import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useLocation } from "react-router-dom";

const UserPermissionModal = (props) => {
  const [show, setShow] = useState(props.show);
  const location = useLocation();

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const data = props.users;
  const columns = [
    {
      name: "Name",
      selector: row => row.firstname + " " + row.lastname,
      sortable: true
    },
    {
      name: "None",
      center: true,
      cell: d => {
        return (
          <>
            <Form.Check type='radio' id={"radioNone" + d.id}
              defaultChecked={getIsRadioChecked(d, "1")}
              onChange={() => changeNone(d)}
              name={"radioUser_" + d.id}
            />
          </>
        )
      }
    },
    {
      name: "Read",
      center: true,
      cell: d => {
        return (
          <>
            <Form.Check type='radio' id={"radioRead" + d.id}
              defaultChecked={getIsRadioChecked(d, "2")}
              // checked={props.formField.shared_with.find((item) => item.permission === 2)}
              onChange={() => changeRead(d)}
              name={"radioUser_" + d.id}
            />
          </>
        )
      }
    },
    {
      name: "Write",
      center: true,
      cell: d => {
        return (
          <>
            <Form.Check type='radio' id={"radioWrite" + d.id}
              defaultChecked={getIsRadioChecked(d, "3")}
              onChange={() => changeWrite(d)}
              name={"radioUser_" + d.id}
            />
          </>
        )
      }
    }
  ];

  const getIsRadioChecked = (data, permission) => {
    let permissionChangeData = {};
    let isPermissionChange = false;
    if (permission === "1") {
      permissionChangeData = props.formField.shared_with.find((item) => (data.id.toString() === item.user_id && item.permission === "1"))
      isPermissionChange = permissionChangeData ? true : false;
    }
    else if (permission === "2") {
      permissionChangeData = props.formField.shared_with.find((item) => (data.id.toString() === item.user_id && item.permission === "2"))
      isPermissionChange = permissionChangeData ? true : false;
    }
    else if (permission === "3") {
      permissionChangeData = props.formField.shared_with.find((item) => (data.id.toString() === item.user_id && item.permission === "3"))
      isPermissionChange = permissionChangeData ? true : false;
    }
    return isPermissionChange;
  }

  const changeNone = (data) => {
    let permissionData = {};
    permissionData.id = data.id;
    permissionData.permission = "1";
    props.handlePermission(permissionData);
  }

  const changeRead = (data) => {
    let permissionData = {};
    permissionData.id = data.id;
    permissionData.permission = "2";
    props.handlePermission(permissionData);
  }

  const changeWrite = (data) => {
    let permissionData = {};
    permissionData.id = data.id;
    permissionData.permission = "3";
    props.handlePermission(permissionData);
  }

  const tableData = {
    columns,
    data
  };

  const splitPath = location.pathname.split("/")

  return (
    <React.Fragment>
      <Modal className="permission-modal" enforceFocus={true} show={show} onHide={props.onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="permission-notes">
                <ul>
                  <li>
                    None: It will consider as a private document/folder.
                  </li>
                  <li>
                    Read: User can only view the document/folder.
                  </li>
                  <li>
                    Write: User can do all operation on the document/folder.
                  </li>
                  {props.modalFor === "folder" && (props.isEdit || splitPath.find((item) => item === "upload-folder") === "upload-folder") &&
                    <li>
                      The changes affect all subfolders and files as well.
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
          <>
            <DataTableExtensions className="text-right" {...tableData} print={false} export={false} filterPlaceholder="Filter User">
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="firstname"
                defaultSortAsc={true}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onCloseModal}>
            Cancel
          </Button>
          <Button variant="secondary" className="btn btn-blue btn-nex" onClick={props.confirmClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
export default UserPermissionModal;