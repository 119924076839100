import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddTemplate from "./AddTemplate";
import * as Constants from "../partials/CommonConstant"

const AddEditTemplate = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let isEdit = false;
  let splitPath = location.pathname.split("/")
  let templateId = "";
  if (splitPath.length > 2) {
    templateId = splitPath[2];
    isEdit = true;
  }

  const callBackSuccess = (data) => {
    if (isEdit) {
      navigate(Constants.templates);
    } else {
      navigate((Constants.editTemplate).replace(':templateId', data.data.id));
    }
  }

  return (
    <div className="dashboard-card-wrapper">
      <div className="row">
        <AddTemplate callBackSuccess={(data) => callBackSuccess(data)}
          isEdit={isEdit}
          templateId={templateId}
        />
      </div>
    </div>
  )
}
export default AddEditTemplate;