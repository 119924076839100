import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getReviewRowView } from "../Shared/Controller/projectReviewApp";
import { ShowToast } from "../common/CommonController";
import { ReviewContext } from "../context/ReviewContext";
import { Form, Spinner } from "react-bootstrap";
import ConfirmModal from "../common/ConfirmModal";
import ViewReviewRow from "../reviewproject/ViewReviewRow";

const ViewReview = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let userDetail = useSelector((state) => state.common.db.user)
  let params = useParams();

  const [RowData, setRowData] = useState({ SingleRow: {}, AllQuestions: {} });
  const [RowLoading, setRowLoading] = useState(false);
  const [grade, setGrade] = useState("");
  const [row_comment, setRowComment] = useState("");
  const [isReviewFinish, setIsReviewFinish] = useState(false);
  const [integrity_review] = useState(location.pathname.indexOf("review-view") !== -1 ? 0 : 1);
  const [getRowLoading, setGetRowLoading] = useState(false);
  const [showLastRowModal, setShowLastRowModal] = useState(false);
  const [nextId, setNextId] = useState("");

  useEffect(() => {
    let splitPath = location.pathname.split("/")
    if (splitPath.length > 2) {
      GetReviewRowView()
    }
  }, []);

  const checkLastRow = (next) => {
    if (RowData.SingleRow.id === RowData.SingleRow.last_row_id && next !== "") {
      setShowLastRowModal(true);
    }
    else {
      GetReviewRowView(next);
    }
  }

  const GetReviewRowView = (next = "") => {
    let data = {}
    data.projectid = params.project_id;

    if (next !== "") {
      data.nextid = nextId;
    }
    else {
      data.rowid = location.hash.replace("#", "");
    }
    setRowLoading(true);
    setGetRowLoading(true);
    getReviewRowView(data).then((response) => {
      if (response.data.code === 1) {
        setRowData({ SingleRow: response.data.data.singel_row, AllQuestions: response.data.data.all_questions })
        setGrade(response.data.data.singel_row.grade !== null ? response.data.data.singel_row.grade : "");
        setRowComment(response.data.data.singel_row.row_comment !== null ? response.data.data.singel_row.row_comment : "");
        setNextId(response.data.data.singel_row.id);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          if (response.data.message === "No pending task.") {
            setIsReviewFinish(true);
            ShowToast("message", "info", response.data.message)
          }
          else {
            ShowToast("message", "error", response.data.message)
          }
        }
        setRowData({ SingleRow: {}, AllQuestions: {} });
      }
      setRowLoading(false);
      setGetRowLoading(false);
    }).catch(error => {
      ShowToast("message", "error", "" + error);
    })
  }

  const onConfirmLastRowModal = () => {
    setShowLastRowModal(false);
    GetReviewRowView("next");
  }

  return (
    <React.Fragment>
      <div className="dashboard-card-wrapper reviewpage">
        <ConfirmModal show={showLastRowModal}
          customMessage={"You have reached end of the job"}
          showCancelButton={false}
          confirmBtnText="Ok"
          confirmClick={() => onConfirmLastRowModal()} />
        <ReviewContext.Provider value={{
          userDetail,
          RowData,
          grade,
          row_comment,
          integrity_review,
          isReviewFinish,
          getRowLoading,
          checkLastRow,
        }}>
          {RowLoading === false &&
            <Form noValidate>
              <ViewReviewRow />
            </Form>
          }
          {RowLoading === true &&
            <>
              <div className="card">
                <div className="p-5 text-center">
                  <Spinner animation="border" size="lg" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            </>
          }
        </ReviewContext.Provider>
      </div>
    </React.Fragment>
  )
}
export default ViewReview;