import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const ConfirmModal = (props) => {
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setShow(props.show)
  }, [props.show])

  return (
    <React.Fragment>
      <Modal show={show} onHide={props.onCloseModal}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-4">
          {props.customMessage && props.customMessage !== '' &&
            <>
              {props.customMessage}
            </>
          }
          {!props.customMessage &&
            <>
              Are you sure you want to {props.type === 'restore' ? "restore" : (props.type === 'permanentDelete' ? "permanently delete" : "delete")} this {props.module ? props.module : "record"}?
            </>
          }
        </Modal.Body>


        <Modal.Footer>
          {!props.showCancelButton && props.showCancelButton !== false &&
            <Button variant="secondary" onClick={props.onCloseModal}>
              Cancel
            </Button>
          }
          {props.deleteLoading && props.deleteLoading === false &&
            <Button variant="secondary" className="btn btn-orange btn-cfm" onClick={props.confirmClick}>
              {(!props.confirmBtnText || props.confirmBtnText === "") ? "Confirm" : props.confirmBtnText}
            </Button>
          }
          {props.deleteLoading && props.deleteLoading === true &&
            <Button className="btn btn-sm btn-blue" variant="secondary">
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Button>
          }
          {!props.deleteLoading &&
            <Button variant="secondary" className="btn btn-orange btn-cfm " onClick={props.confirmClick}>
              {(!props.confirmBtnText || props.confirmBtnText === "") ? "Confirm" : props.confirmBtnText}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
export default ConfirmModal;