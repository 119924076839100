import Errormsg from "../../partials/Errormsg"
import InlineErrormsg from "../../partials/InlineErrormsg"
import Successmsg from "../../partials/Successmsg"

export const hasErrorFor = (data) => {
  if (data.errors && Object.keys(data.errors).length > 0) {
    return data.errors[data.field]
  }
}

export const renderErrorFor = (data) => {
  if (hasErrorFor({ field: data.field, errors: data.errors })) {
    return (<InlineErrormsg message={data.errors[data.field][0]} />)
  }
}

export const renderSuccessMessage = (success_message) => {
  if (success_message !== '') {
    return (<Successmsg message={success_message} />)
  }
}

export const renderErrorMessage = (error_message) => {
  if (error_message !== '') {
    return (<Errormsg message={error_message} />)
  }
}