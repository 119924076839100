import React, { Component } from "react"
import { Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { GetTimeDifference, ShowToast } from "../../common/CommonController"
import { DeleteImg, EditImg, EyeImg, ResendImg } from "../../common/ImageConstants"
import UserServices from "../Services/UserServices"

class userApp extends Component {
  getUsers = () => {
    this.setState({ loading: true })
    let data = {
      // status: 1,
      exclude_userid: this.props.userDetail.id,
    }
    UserServices.getUsers(data).then((response) => {
      if (response.data.code === 1) {
        let dataList = response.data.data;
        dataList.map((item) => {
          item.fullname = item.firstname + " " + item.lastname;
        })
        this.setState({ userList: dataList, loading: false })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  saveUser = (data) => {
    this.setState({ saveLoading: true })
    UserServices.saveUser(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onSaveSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ saveLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveLoading: false });
    });
  }

  getUser = (userId) => {
    this.setState({ loading: true })
    UserServices.getUser(userId).then((response) => {
      if (response.data.code === 1) {
        let data = {
          loading: false,
          ...response.data.data
        }
        this.setState({ ...data })
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
        this.setState({ loading: false });
      }
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ saveLoading: false });
    });
  }

  deleteUser = (data) => {
    this.setState({ loading: true });
    UserServices.deleteUser(data).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onDeleteSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ loading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ loading: false });
    });
  }

  resendVerifyEmail = (userId) => {
    this.setState({ resendEmailLoading: true });
    UserServices.resendVerifyEmail(userId).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message)
        this.onResendEmailSuccess(response.data)
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      this.setState({ resendEmailLoading: false });
    }).catch(error => {
      ShowToast("message", "error", "" + error);
      this.setState({ resendEmailLoading: false });
    });
  }

  handleDelete = (e) => {
    let newData = { userId: this.state.deletedUserId }
    this.setState({ showModal: false })
    this.deleteUser(newData);
  };

  onClickDelete = (e, userId) => {
    e.preventDefault();
    this.setState({ showModal: true, deletedUserId: userId })
  }

  onCloseModal = () => {
    this.setState({ showModal: false })
  }

  onSaveSuccess = (data) => {
    this.props.callBackSuccess(data);
  }

  onDeleteSuccess = (data) => {
    this.getUsers();
  }

  handleResendVerifyEmail = (e, userId) => {
    e.preventDefault();
    this.setState({ resendEmailUseIds: [...this.state.resendEmailUseIds, userId] });
    this.resendVerifyEmail(userId);
  };

  onResendEmailSuccess = (data) => {
    this.setState({
      resendEmailUseIds: this.state.resendEmailUseIds.filter(function (userId) {
        return userId !== data.data.id
      }),
      resendSuccessIds: [...this.state.resendSuccessIds, data.data.id]
    });

  }

  getColumnData = (data) => {
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon text-center">
          {!data.item.email_verified_at && GetTimeDifference(data.item.updated_at, 'd') > 1 &&
            <>
              {this.state.resendEmailLoading === true && this.state.resendEmailUseIds.includes(data.item.id) &&
                <React.Fragment key={"user" + data.item.id}>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </React.Fragment>
              }
              {!this.state.resendEmailUseIds.includes(data.item.id) && !this.state.resendSuccessIds.includes(data.item.id) &&
                <Link to="/resend-email" onClick={(e) => this.handleResendVerifyEmail(e, data.item.id)}><img src={ResendImg} alt="Resend Email Verification" title="Resend Email Verification" /></Link>
              }
            </>
          }
          <>
            <Link to={"/view-user/" + data.item.id} className={this.state.resendEmailLoading === false ? "ms-2" : "ms-2 disabled-action"}
            >
              <img src={EyeImg} alt="View" title="View" />
            </Link>
            <Link to={"/edit-user/" + data.item.id} className={this.state.resendEmailLoading === false ? "ms-2" : "ms-2 disabled-action"}
            >
              <img src={EditImg} alt="Edit" title="Edit" />
            </Link>
            <Link to="/" className={this.state.resendEmailLoading === false ? "ms-2" : "ms-2 disabled-action"}
              onClick={(e) => this.onClickDelete(e, data.item.id)}>
              <img src={DeleteImg} alt="Delete" title="Delete" />
            </Link>
          </>
        </div>
      );
    }
    if (data.type === "status") {
      return (
        <>
          {data.item.status === 1 ? 'Active' : 'Inactive'}
        </>
      );
    }
    return columnData;
  }

  static mapStateToProps = (state) => {
    let userDetail = state.common.db.user;

    return {
      userDetail: userDetail,
    }
  }
}
export default userApp