import axios from "axios"
import { AppServices, HttpHeaders } from "../Services/AppServices";

const ProfileServices = {
    getProfile: (id) => {
        return axios.get(AppServices.GET_PROFILE(id), HttpHeaders())
    },
    SendCronJob: (data) => {
        return axios.get(AppServices.SEND_CRON_JOB_MAIL_DELETE_FILE(), HttpHeaders())
    },

    updateProfile: (data) => {
        return axios.post(AppServices.UPDATE_USER(data.userId), data, HttpHeaders())
    }
}

export default ProfileServices;
