import React, { useContext } from "react";
import { CheckmarkImg, CloseImg } from "../common/ImageConstants";
import $ from "jquery";
import { ReviewContext } from "../context/ReviewContext";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import NotFound from "../common/NotFound";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const IntegrityViewRow = () => {

  const RowProps = useContext(ReviewContext)
  let navigate = useNavigate();

  const onAccordian1Click = (e) => {
    e.preventDefault();
    $(".accord1").toggle();
    $(".btnacc1").parent().toggleClass('tclass');
  }

  /* Hide 3 field -> Account reference, current balance and delinquency flag */
  const checkFixedField = (item) => {
    let isChecked = true;
    if (item === "Account_Reference") {
      isChecked = false;
    }
    if (item === "Current_Balance") {
      isChecked = false;
    }
    if (item === "Delinquency_Flag") {
      isChecked = false;
    }
    return isChecked;
  }
  /* Hide 3 field -> Account reference, current balance and delinquency flag */

  const renderSelectedColumns = () => {
    let renderColumn = [];
    if (Object.keys(RowProps.RowData.SingleRow).length > 0 && RowProps.RowData.SingleRow.row_details && RowProps.RowData.SingleRow.prev_answers) {
      Object.keys(RowProps.RowData.SingleRow.row_details).map((item) => {
        let prevItem = RowProps.RowData.SingleRow.prev_answers.find((fitem) => fitem.column_name === item);
        if (prevItem) {
          renderColumn.push(
            <>
              {(checkFixedField(prevItem.column_name)) &&
                <tr>
                  <td>
                    <span>{prevItem.column_name}</span>
                  </td>
                  <td>
                    <span>{prevItem.data}</span>
                  </td>
                  <td width={"50%"}>
                    <span>
                      {prevItem.status}
                      <br />
                      {prevItem.comment &&
                        <>
                          <b>Comment: </b> {prevItem.comment}
                        </>
                      }
                    </span>
                  </td>
                </tr>
              }
            </>
          )
        }
      });
    }
    return renderColumn;
  }

  // console.log("IntegrityViewRow", RowProps)
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mt-md-0">
          <h5>{RowProps.RowData.SingleRow.project_name}</h5>
        </div>
        <div className="col-md-12  mt-md-0">
          {RowProps.isReviewFinish === false &&
            <React.Fragment>
              <div className="right-panel">
                <div className="btn-block">
                  {RowProps.saveRowLoading === false &&
                    <Button id="btnIntegritySaveNext"
                      onClick={() => RowProps.checkLastRow("next")}
                      variant="secondary" className={"btn btn-blue btn-nex mb-1"}>
                      Next
                    </Button>
                  }
                  {RowProps.saveRowLoading === true &&
                    <Button variant="secondary" className="btn btn-blue btn-nex mb-1">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                  &nbsp;
                  {/* <Link to={{pathname: `/project-records/${params?.project_id}`}} className="btn btn-blue btn-nex mb-1">Close</Link> */}
                  <Button onClick={() => navigate(-1)} className="btn btn-blue btn-nex mb-1">Back</Button>
                </div>
              </div>
              <p>Investor Reference - {RowProps.RowData.SingleRow.investor_ref}, Owner - {RowProps.RowData.SingleRow.owner_name} <br />
                Supplemental Data</p>
            </React.Fragment>
          }
        </div>
      </div>
      {RowProps.isReviewFinish === false &&
        <React.Fragment>
          <div className="row">
            <div className="col-md-12 mt-3 mt-md-0 accordian-row">
              <button onClick={(e) => onAccordian1Click(e)} className="btnacc1"> </button>
            </div>
          </div>
          <div className="row accord1">
            <div className="col-md-12 col-sm-12 col-lg-12 mb-3">
              <div className="card-main">
                <table className="table mb-0 tbl-integrity-view">
                  <thead>
                    <tr>
                      <th>Column</th>
                      <th>Data</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderSelectedColumns()}
                    {RowProps.RowData.SingleRow.prev_answers && RowProps.RowData.SingleRow.prev_answers.map((item, i) => {
                      return (
                        <React.Fragment key={"rowheader" + item}>
                          {/* <tr>
                              <td>
                                <span>{item.column_name}</span>
                              </td>
                              <td>
                                <span>{item.data}</span>
                              </td>
                              <td width={"50%"}>
                                <span>
                                  {item.status}
                                  <br />
                                  {item.comment &&
                                    <>
                                      <b>Comment: </b> {item.comment}
                                    </>
                                  }
                                </span>
                              </td>
                            </tr> */}
                        </React.Fragment>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      }
      {RowProps.isReviewFinish === true &&
        <div className="card mt-3">
          <NotFound type="content" message={"Integrity Finished"} />
        </div>
      }
    </React.Fragment>
  )
}
export default IntegrityViewRow;