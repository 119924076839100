import React from "react";
import { Form } from "react-bootstrap";

const RowQuestion = (props) => {
  const ShowHideComment = () => {
    let isShow = false;
    if (props.answers && props.answers.length) {
      props.item.choices && props.item.choices.map((citem) => {
        if (props.answers.find((aitem) => (aitem.selected_choice === citem.choice && aitem.questionid === props.item.id && citem.status.toString() === "1")) && props.item.comment_required.toString() === "1") {
          isShow = true;
        }
      })
    }
    return isShow;
  }

  const checIsCommentRequired = (value) => {
    let cmtrequired = 0;
    props.item.choices && props.item.choices.map((citem) => {
      if (citem.choice === value && props.item.comment_required === 1) {
        cmtrequired = citem.status;
      }
    });
    return cmtrequired;
  }

  const getCurrentAnswer = () => {
    let answer = "";
    if(props.answers.find((aitem) => (aitem.questionid === props.item.id))) {
      answer = props.answers.find((aitem) => (aitem.questionid === props.item.id))
    }
    return answer.selected_choice;
  }

  return (
    <React.Fragment>
      <div className={props.index === 0 ? "row g-4" : "row g-4 mt-1"}>
        <p className="mb-0 small-text">{props.item.question}</p>
        <div className="col-md-5 mt-0">
          <div className="m-1">
            <Form.Control
              as="select"
              name={props.item.id}
              required
              defaultValue={props.item.selected_choice}
              value={getCurrentAnswer()}
              isInvalid={!!props.reviewAnswerErrors["choice_" + props.item.id]}
              onChange={(e) => props.setQuestionChoice({ ...e, comment_required: checIsCommentRequired(e.target.value) })}
              disabled={(props.userDetail.role === 1 && props.take_ownership === 0 && props.integrity_review === 1) ? true : false}
            >
              <option value="">Select Choice</option>
              {props.item.choices && props.item.choices.map((citem) => {
                // if (citem.status.toString() === "1") {
                return (
                  <option key={"ch_" + citem.choice} value={citem.choice}>{citem.choice}</option>
                )
                // }
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{props.reviewAnswerErrors["choice_" + props.item.id]}</Form.Control.Feedback>
          </div>
        </div>
        {ShowHideComment() &&
          <div className="col-md-7 mt-0">
            <div className="m-1">
              <Form.Control
                required
                name={props.item.id}
                placeholder="Comment"
                rows={4}
                as="textarea"
                defaultValue={props.item.comment}
                isInvalid={!!props.reviewAnswerErrors["comment_" + props.item.id]}
                onChange={(e) => props.setQuestionComment(e)}
                disabled={(props.userDetail.role === 1 && props.take_ownership === 0 && props.integrity_review === 1) ? true : false}
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">{props.reviewAnswerErrors["comment_" + props.item.id]}</Form.Control.Feedback>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}
export default RowQuestion;