import React, { useContext } from "react";
import { ReviewContext } from "../context/ReviewContext";
import { getQuestionsCols } from "../Shared/Controller/projectReviewApp";
import RowQuestion from "./RowQuestion";

const FileQuestion = () => {
  const FileProps = useContext(ReviewContext);

  return (
    <React.Fragment>
      {FileProps.RowData.AllQuestions.file_questions &&
        <div className={getQuestionsCols({ typesOfQuestion: Object.keys(FileProps.RowData.AllQuestions).length, type: "file" }) + " mb-3"}>
          <div className="card-main">
            <h3>File</h3>
            {FileProps.RowData.AllQuestions.file_questions.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <RowQuestion item={item}
                    setQuestionChoice={FileProps.setQuestionChoice}
                    answers={FileProps.answers}
                    setQuestionComment={FileProps.setQuestionComment}
                    reviewAnswerErrors={FileProps.reviewAnswerErrors}
                    userDetail={FileProps.userDetail}
                    take_ownership={FileProps.take_ownership}
                    integrity_review={FileProps.integrity_review}
                    index={index}
                  />
                </React.Fragment>
              )
            })
            }
          </div>
        </div>
      }
    </React.Fragment>
  )
}
export default FileQuestion;