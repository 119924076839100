import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddUser from "./AddUser";

const AddEditUser = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let isEdit = false;
  let splitPath = location.pathname.split("/")
  let userId = "";
  if (splitPath.length > 2) {
    userId = splitPath[2];
    isEdit = true;
  }

  const callBackSuccess = (data) => {
    navigate("/users");
  }

  return (
    <div className="dashboard-card-wrapper">
      <div className="row">
        <AddUser callBackSuccess={(data) => callBackSuccess(data)}
          isEdit={isEdit}
          userId={userId}
        />
      </div>
    </div>
  )
}
export default AddEditUser;