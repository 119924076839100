import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link } from 'react-router-dom';
import { DeleteImg, EditImg } from '../common/ImageConstants';
import { QuestionReorder } from '../Shared/Controller/questionApp';
import { ShowToast } from '../common/CommonController';
import { Spinner } from 'react-bootstrap';

const QuestionDataTable = (props) => {
  const gridRef = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [columns] = useState(
    [
      {
        headerName: "",
        field: "question",
        rowDrag: true,
        filter: false,
        sortable: false,
        wrapText: true,
        autoHeight: true,
        valueFormatter: (d) => {
          return ""
        },
      },
      {
        headerName: "Question",
        width: "auto",
        field: "question",
        minWidth: 300,
        wrapText: true,
        autoHeight: true,
        filterParams: {
          trimInput: true,
          filterOptions: ['contains'],
        },
        cellRenderer: (d) => { return d.value }
      },
      {
        headerName: "Export Heading",
        width: "auto",
        field: "export_heading",
        wrapText: true,
        autoHeight: true,
        valueFormatter: (d) => {
          return d.value
        },
        filterParams: {
          trimInput: true,
          filterOptions: ['contains'],
        },
      },
      {
        headerName: "Category",
        width: "auto",
        field: "category_name",
        filterParams: {
          trimInput: true,
          filterOptions: ['contains'],
        },
      },
      {
        headerName: "Action",
        field: "action",
        filter: false,
        width: "auto",
        sortable: false,
        cellClass: "rdt_TableCell",
        // headerClass: "text-center",
        cellRenderer: (d) => { return getColumnData({ type: "action", item: d.data }) }
      }
    ]);

  const defaultColDef = useMemo(() => {
    return {
      // width: 170,
      sortable: true,
      filter: true,
      minWidth: 50,
    };
  }, []);

  useEffect(() => {
    let newData = [...props.data];
    newData.map((item, i) => {
      newData[i].category_name = item.category === 1 ? "File" : (item.category === 2 ? "Loan" : "Delinquency")
      return null;
    })
    setData(newData)
  }, [props.data])

  const onRowDragMove = useCallback(
    (e) => {
      let immutableStore = [...data]
      var movingNode = e.node;
      var overNode = e.overNode;
      var rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        var movingData = movingNode.data;
        var overData = overNode.data;
        var fromIndex = immutableStore.indexOf(movingData);
        var toIndex = immutableStore.indexOf(overData);
        var newStore = immutableStore.slice();
        moveInArray(newStore, fromIndex, toIndex);
        immutableStore = newStore;
        setData(newStore);
        gridRef.current.api.clearFocusedCell();
      }
      function moveInArray(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
    },
  );

  const onRowDragEnd = (e) => {
    let question = []
    data.map((item, i) => {
      question.push({ id: item.id, order_no: i + 1 });
    })
    let questionReorderParam = { template_id: props.templateId, question: question };
    setLoading(true);
    QuestionReorder({ ...questionReorderParam }).then((response) => {
      if (response.data.code === 1) {
        ShowToast("message", "success", response.data.message);
      }
      else {
        if (response.data.message.constructor === Object) {
          ShowToast("object", "error", response.data.message)
        }
        else {
          ShowToast("message", "error", response.data.message)
        }
      }
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      ShowToast("message", "error", "" + error);
    });
  }

  const getColumnData = (data) => {
    let columnData = "";
    if (data.type === "action") {
      return (
        <div className="edit-icon">
          <Link to="/" className="" onClick={(e) => props.onQuestionModalShow(e, data.item.id)}>
            <img src={EditImg} alt="Edit" title="Edit" />
          </Link>
          <Link to="/" className="ms-2" onClick={(e) => props.onClickQuestionDelete(e, data.item.id)}>
            <img src={DeleteImg} alt="Delete" title="Delete" />
          </Link>
        </div>
      );
    }
    if (data.type === "category") {
      return (
        <>
          {data.item === 1 ? "File" : (data.item === 2 ? "Loan" : "Delinquency")}
        </>
      );
    }
    return columnData;
  }

  return (
    <div>
      <div className="ag-theme-alpine pt-3">
        {loading === false &&
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            suppressMoveWhenRowDragging={true}
            rowDragManaged={true}
            animateRows={true}
            onRowDragMove={onRowDragMove}
            onRowDragEnd={onRowDragEnd}
          ></AgGridReact>
        }
        {loading &&
          <div className='text-center'>
            <Spinner animation="border" size='lg' role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      </div>
    </div>
  );
}
export default QuestionDataTable;