import axios from "axios"
import { AppServices, HttpHeaders } from "./AppServices";

const ProjectReviewService = {
  getProjectRow: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_PROJECT_ROW(), {
      params: data,
      headers: header.headers,
    })
  },
  getProjectRecordRow: (data) => {
    return axios.post(AppServices.GET_PROJECT_RECORD_ROW(), data,  HttpHeaders())
  },
  getIntegrityProjectRow: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_INTEGRITY_PROJECT_ROW(), {
      params: data,
      headers: header.headers,
    })
  },
  getIntegrityProjectRecordRow: (data) => {
    return axios.post(AppServices.GET_INTEGRITY_PROJECT_RECORD_ROW(), data,  HttpHeaders())
  },
  saveReviewRow: (data) => {
    return axios.post(AppServices.SAVE_REVIEW_ROW(), data, HttpHeaders())
  },
  saveIntegrityRow: (data) => {
    return axios.post(AppServices.SAVE_INTEGRITY_ROW(), data, HttpHeaders())
  },
  getIntegrityRowView: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_INTEGRITY_ROW_VIEW(), {
      params: data,
      headers: header.headers,
    })
  },
  getReviewRowView: (data) => {
    const header = HttpHeaders();
    return axios.get(AppServices.GET_REVIEW_ROW_VIEW(), {
      params: data,
      headers: header.headers,
    })
  },
}
export default ProjectReviewService;
