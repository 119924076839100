import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { GetRole } from "../common/CommonController";
import { UserImg } from "../common/ImageConstants";
import projectApp from "../Shared/Controller/projectApp";

class ProjectUsers extends projectApp {
  constructor(props) {
    super(props)
    this.state = {
      usersLoading: false,
      saveUsersLoading: false,
      usersList: [],
      authorizedUserList: [],
      userids: [],
      isEdit: false,
    }
  }

  componentDidMount = () => {
    if (this.props.currentStep === 5 && this.props.projectDetail.last_completed_step >= 4) {
      this.setState({ isEdit: true });
    }
    this.getUsers();
  }

  editCallBack = (SelectedUserList) => {
    let userAuthList = SelectedUserList;
    if (this.props.projectDetail.assignedusers.length > 0) {
      this.props.projectDetail.assignedusers.map((item) => {
        item.fullname = item.firstname + " " + item.lastname;
        return null;
      });
      userAuthList = this.props.projectDetail.assignedusers.filter((item) => item.role !== 3);
    }
    this.afterSuccess({ SelectedUsers: userAuthList, type: "remove" })
    this.setState({ authorizedUserList: userAuthList });
  }

  afterSuccess = (data) => {
    let newUsersList = this.moveUserList(data);
    this.setState({ usersList: newUsersList })
  }

  onSelectUser = (e) => {
    e.preventDefault();
    let authorizedUserList = this.state.authorizedUserList;
    this.state.usersList.map((item) => {
      if (item.id === parseInt(e.target.value) && !this.checkExistsSelectedUser(item)) {
        authorizedUserList.push(item);
      }
      return null;
    });
    let newUserList = this.moveUserList({ SelectedUsers: authorizedUserList, type: "remove" });
    this.setState({ authorizedUserList: authorizedUserList, usersList: newUserList })
  }

  moveUserList = (data) => {
    let userList = [...this.state.usersList];
    let newList = [];
    if (data.type === "remove") {
      data.SelectedUsers.map((item) => {
        userList.map((uitem, index) => {
          if (uitem.id === item.id) {
            userList.splice(index, 1);
          }
          return null;
        });
        return null;
      });
      newList = userList;
    }
    else if (data.type === "add") {
      userList.push(data.removedArray)
      newList = userList;
    }
    return newList;
  }

  checkExistsSelectedUser = (data) => {
    let isExists = false;
    this.state.authorizedUserList.map((item) => {
      if (parseInt(data.id) === item.id) {
        isExists = true;
      }
      return null;
    });
    return isExists;
  }

  deleteSelectedUser = (e) => {
    e.preventDefault();
    let selArray = this.state.authorizedUserList.filter((item) => item.id !== parseInt(e.target.value));
    let removedArray = this.state.authorizedUserList.find((item) => item.id === parseInt(e.target.value));
    let newUserList = this.moveUserList({ removedArray: removedArray, type: "add" });
    this.setState({ authorizedUserList: selArray, usersList: newUserList })
  }

  onSaveUsersClick = (e) => {
    e.preventDefault();
    /* const newErrors = this.questionsFormErrors()
    if (Object.keys(newErrors).length > 0) {
      this.setState({ ...newErrors })
    } */
    let userids = [];
    this.state.authorizedUserList.map((item) => {
      userids.push(item.id);
      return null;
    })
    let newData = {
      projectid: this.props.project_id,
      userids: userids,
    }
    newData.status = 1;
    this.saveProjectUsers(newData);
  }

  onUsersPreviousClick = (e) => {
    e.preventDefault();
    this.props.callBackPreviousUsers();
  }

  render() {
    return (
      <>
        <div className="step-list-wrap">
          <h2 className="step-title">Users</h2>
          {this.state.usersLoading === false &&
            <React.Fragment>
              <div className="step-content">
                <div className="uplod-inner">
                  <p>Please select which users you wish to grant access to this portfolio. click a user to move from one list to the other.<br />[Please note: Administrator have access to all portfolios even if you do not provide explicit permission here]</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="investor-box">
                      <div className="available-list user-list">
                        <h3 className="inner-step-block">Available</h3>
                        {this.state.usersList.length > 0 && this.state.usersList.map((item) => {
                          return (
                            <Form.Control as="button"
                              key={"user" + item.id}
                              onClick={(e) => this.onSelectUser(e)}
                              value={item.id}
                              className="available-box">
                              <div className="available-box-icon"><img alt="userImage" src={UserImg} /></div>
                              <div className="available-box-content">
                                <h6>{item.fullname}</h6>
                                <p>{GetRole(item.role)}</p>
                              </div>
                            </Form.Control>
                          )
                        })
                        }
                        {this.state.usersList.length === 0 &&
                          <>
                            <span>No Standard Users</span>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="investor-box">
                      <div className="selected-list user-list">
                        <h3 className="inner-step-block">Authorised</h3>
                        {this.state.authorizedUserList.length > 0 && this.state.authorizedUserList.map((item) => {
                          return (
                            <React.Fragment key={"selecteduser" + item.id}>
                              <div className="selected-box">
                                <div className="selected-box-icon"><img alt="userImage" src={UserImg} /></div>
                                <div className="selected-box-content">
                                  <h6>{item.fullname}</h6>
                                  <p>{GetRole(item.role)}</p>
                                  {item.role === 2 &&
                                    <Button onClick={(e) => this.deleteSelectedUser(e)}
                                      value={item.id}>
                                      <i className="material-icon">close</i>
                                    </Button>
                                  }
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        })
                        }
                        {this.state.authorizedUserList.length === 0 &&
                          <>
                            <span>No Authorised Users</span>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="setp-btn-row">
                  <Button variant="secondary"
                    onClick={(e) => this.onUsersPreviousClick(e)}
                    className="btn btn-blue btn-pre">Previous</Button>
                  {/* <Button variant="secondary" className="btn btn-blue btn-nex">Cancel</Button> */}
                  {this.state.saveUsersLoading === false &&
                    <Button variant="secondary"
                      onClick={(e) => this.onSaveUsersClick(e)}
                      className="btn btn-blue btn-nex">Save</Button>
                  }
                  {this.state.saveUsersLoading === true &&
                    <Button className="btn btn-blue btn-nex" variant="secondary">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  }
                </div>
                <div className="clear"></div>
              </div>
            </React.Fragment>
          }
          {this.state.usersLoading === true &&
            <>
              <div className="text-center m-4">
                <Spinner animation="border" size="lg" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </>
          }
        </div>
      </>
    )
  }
}
export default ProjectUsers;