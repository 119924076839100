import React from "react"
import { Spinner } from "react-bootstrap"
import userApp from "../Shared/Controller/userApp"
import { GetRole } from "../common/CommonController";
import { Link } from "react-router-dom";
import * as Constants from "../partials/CommonConstant";
import NotFound from "../common/NotFound";
import ConfirmModal from "../common/ConfirmModal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { connect } from "react-redux";

class Users extends userApp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      resendEmailLoading: false,
      resendEmailUseIds: [],
      resendSuccessIds: [],
      userList: [],
      showModal: false,
      deletedUserId: "",
    }
  }

  componentDidMount = () => {
    this.getUsers();
  }

  render() {
    // Create table headers consisting of 4 columns.
    const data = this.state.userList;
    const columns = [
      {
        name: "Username",
        selector: row => row.username,
        sortable: true
      },
      {
        name: "Name",
        selector: row => row.fullname,
        sortable: true,
        cell: d => { return d.fullname }
      },
      {
        name: "Email",
        selector: row => row.email,
        sortable: true
      },
      {
        name: "Role",
        selector: row => row.role,
        sortable: true,
        cell: d => { return GetRole(d.role) }
      },
      {
        name: "Status",
        selector: row => row.status,
        sortable: true,
        cell: d => { return this.getColumnData({ type: "status", item: d }) }
      },
      {
        name: "Action",
        center: true,
        cell: d => { return this.getColumnData({ type: "action", item: d }) }
      }
    ];

    const tableData = {
      columns,
      data
    };

    return (
      <>
        <div className="dashboard-card-wrapper">
          <ConfirmModal show={this.state.showModal}
            onCloseModal={() => this.onCloseModal()}
            module="user"
            confirmClick={() => this.handleDelete()} />
          <div className="row align-items-center">
            <div className="col-12 text-end">
              <Link to={Constants.addUser} className="btn btn-blue btn-nex me-2">Add User</Link>
              <Link to={Constants.deletedUsers} className="btn btn-blue btn-nex">Deleted User</Link>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-12 mt-3">
            <div className="card-main p-0">
              <div className="dark-table-wrapper table-scroll data-table">
                {/* <Table className="mb-0">
                  {this.state.userList && this.state.userList.length !== 0 && this.state.loading === false &&
                    <>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" className="no-id">Username</th>
                          <th scope="col" className="name-td">Name</th>
                          <th scope="col" className="date-td">Email</th>
                          <th scope="col" className="has-td">Role</th>
                          <th scope="col" className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.userList && this.state.userList.map((item) => {
                          return (
                            <React.Fragment key={"user" + item.id}>
                              <tr>
                                <td className="date-td">{item.username}</td>
                                <td className="name-td">{item.firstname + " " + item.lastname}</td>
                                <td className="date-td">{item.email}</td>
                                <td className="has-td">{GetRole(item.role)}</td>
                                <td className="action-td">
                                  <div className="edit-icon text-center">
                                    {!item.email_verified_at && GetTimeDifference(item.updated_at, 'd') > 1 &&
                                      <>
                                        {this.state.resendEmailLoading === true && this.state.resendEmailUseIds.includes(item.id) &&
                                          <React.Fragment key={"user" + item.id}>
                                            <Spinner animation="border" role="status" size="sm">
                                              <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                          </React.Fragment>
                                        }
                                        {!this.state.resendEmailUseIds.includes(item.id) && !this.state.resendSuccessIds.includes(item.id) &&
                                          <Link to="/resend-email" onClick={(e) => this.handleResendVerifyEmail(e, item.id)}><img src={ResendImg} alt="Resend Email Verification" title="Resend Email Verification" /></Link>
                                        }
                                      </>
                                    }
                                    <>
                                      <Link to={"/view-user/" + item.id} className={this.state.resendEmailLoading === false ? "ms-2" : "ms-2 disabled-action"}
                                      >
                                        <img src={EyeImg} alt="View" title="View" />
                                      </Link>
                                      <Link to={"/edit-user/" + item.id} className={this.state.resendEmailLoading === false ? "ms-2" : "ms-2 disabled-action"}
                                      >
                                        <img src={EditImg} alt="Edit" title="Edit" />
                                      </Link>
                                      <Link to="/" className={this.state.resendEmailLoading === false ? "ms-2" : "ms-2 disabled-action"}
                                        onClick={(e) => this.onClickDelete(e, item.id)}>
                                        <img src={DeleteImg} alt="Delete" title="delete" />
                                      </Link>
                                    </>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        })}
                      </tbody>
                    </>
                  }
                  {this.state.loading === true &&
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  }
                </Table> */}
                {this.state.userList && this.state.userList.length !== 0 && this.state.loading === false &&
                  <DataTableExtensions {...tableData} print={false} export={false} filterPlaceholder="Search">
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="username"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                    />
                  </DataTableExtensions>
                }
                {this.state.loading === true &&
                  <table className="mb-0 table">
                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
                {/* Not found */}
                {this.state.loading === false && this.state.userList && this.state.userList.length === 0 &&
                  <NotFound message="No Data Found" type="content" />
                }

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default connect(userApp.mapStateToProps)(Users);